import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler } from "../../../../components/table_empty/table_empty";
import { LoadingType } from "../../../../enums";
import DeleteIcon from "../../../../../assets/icons/delete-icon.svg";
import EditIcon from "../../../../../assets/icons/edit_button.svg";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import './table_body.scss';

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {
    const { t } = useTranslation();
    const default_paytypes = ["Regular", "Over Time", "Double Time", "Mileage", "Holiday Pay", "Vacation Pay"]
    function getActions(doc) {
        return (
            <TableData>
                {default_paytypes.includes(doc.value) ?
                    <>
                    </>
                    :
                    <>
                        <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                            onClick={() => handleShowEdit(doc)} />
                        {category.label === 'bank_account' ? null : <img src={DeleteIcon} alt='delete_button'
                            onClick={() => handleShowDelete(doc.id)} />}
                    </>
                }
            </TableData>
        )
    }

    if (state?.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    // else if (data?.length == 0) {
    //     return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    // }
    else {
        return (
            <div className="bo-ob-table-body">
                <SortableTable headerList={category.headers}>
                    {data && data!.length > 0 ?
                        data?.map((doc, index) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData>{doc.id}</TableData>
                                    {category.label! === "mark_up" ?
                                        (<>
                                            {category.value?.map((value, index) => {
                                                return (
                                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                                        <span>{typeof (doc[value]) === "number" ? !Number.isInteger(doc[value]) ? `${doc[value]}` + `${"%"}` : `${doc[value]}` + `${".00%"}` : doc[value]}</span>
                                                    </TableData>
                                                )
                                            })}</>)
                                        :
                                        (
                                            <>
                                                {category.value?.map((value, index) => (
                                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                                        {category.label === "pay_type" && value === 'taxable' ? (
                                                            <div className="toggle-switch">
                                                                <ToggleSwitch
                                                                    label={""}
                                                                    checked={doc[value]}
                                                                    onChange={() => { }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <span>{doc[value]}</span>
                                                        )}
                                                    </TableData>
                                                ))}
                                            </>
                                        )
                                    }
                                    {getActions(doc)}
                                </tr>
                            );
                        })
                        :
                        <tr>
                            <TableData><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                        </tr>
                    }
                </SortableTable>
            </div>
        )
    }
}

export default TableBody;