import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../../components/api_error";
import { THEME, LoadingType } from "../../../../../enums";
import { useAppDispatch } from "../../../../../redux/store";
import { CustomButton } from "../../../../../utils";

export const DeleteRecordPopup = (props) => {
    const { doc, visible, onClose, onDissmiss, onSuccessClose, msg,removeState,handleClearError } =
      props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
  
    return (
      <ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={430}
        onClose={onClose}
        onDissmiss={onDissmiss}
      >
        <div className="del-role-container">
          <div className="del-role-header">
            <div className="title-txt">
              <span>{`${t("attention")}!!`}</span>
            </div>
          </div>
          <div className="del-role-content">
            <div className="message-txt">
              <span>
                {msg}
              </span>
            </div>
            {removeState?.error && (
              <div className={"error-section"}>
                <ApiError message={removeState?.error.message} onClose={handleClearError} />
              </div>
            )}
          </div>
          <div className="del-role-action-holder">
            <div className="del-role-action">
              <div className="btn-no">
                <CustomButton
                  loading={false}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("cancel")}
                  enable={true}
                  backgroundColor={THEME.secondaryColor4}
                  onClick={() => closePopup(onClose)}
                />
              </div>
              <div className="btn-yes">
                <CustomButton
                  loading={removeState?.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={t("delete")}
                  enable={doc != null}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={()=>onSuccessClose(doc)}
                />
              </div>
            </div>
          </div>
        </div>
      </ActionDialogHolder>
    );
  
    
  
    function closePopup(action?: () => void) {
      if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
  };

  export default DeleteRecordPopup;