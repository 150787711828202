import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, TrafficLightEngagementMetric } from "../../../../interfaces";
import * as thunk from "./traffic_light_engagement_metric_thunk";

export interface TrafficLightEngagementMetricState extends EntityState<TrafficLightEngagementMetric> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const trafficLightEngagementMetricAdapter: EntityAdapter<TrafficLightEngagementMetric> = createEntityAdapter<TrafficLightEngagementMetric>({
    selectId: (doc) => doc.id
})

const initialState: TrafficLightEngagementMetricState = trafficLightEngagementMetricAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const trafficLightEngagementMetricSlice = createSlice({
    name: 'trafficLightEngagementMetric',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getTrafficLightEngagementMetrics.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getTrafficLightEngagementMetrics.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getTrafficLightEngagementMetrics.fulfilled, (state, action) => trafficLightEngagementMetricAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createTrafficLightEngagementMetric.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createTrafficLightEngagementMetric.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createTrafficLightEngagementMetric.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteTrafficLightEngagementMetric.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteTrafficLightEngagementMetric.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteTrafficLightEngagementMetric.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateTrafficLightEngagementMetric.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateTrafficLightEngagementMetric.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateTrafficLightEngagementMetric.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = trafficLightEngagementMetricSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = trafficLightEngagementMetricAdapter.getSelectors();
export const selectState = (state: TrafficLightEngagementMetricState) => state;
export const selectLoading = (state: TrafficLightEngagementMetricState) => state.loading;
export const selectError = (state: TrafficLightEngagementMetricState) => state.error;


