import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import CloseIcon from '../../../../assets/icons/close-btn2.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { AddCircleOutline } from "@material-ui/icons";
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createEngagementActivityType,
    createEngagementMethod,
    deleteEngagementActivityMethod,
    deleteEngagementActivityType,
    getEngagementActivityMethods,
    getEngagementActivityType,
    selectEngagementActivityMethodList,
    selectEngagementActivityMethodListState,
    selectEngagementActivityTypeList,
    selectEngagementActivityTypeListState,
    updateEngagementActivityMethod,
    updateEngagementActivityType,
    createBusinessSector, getBusinessSectors, updateBusinessSector, deleteBusinessSector,
    selectSalesBusinessSectorState, selectBusinessSectorList,
    createStatusCode, getStatusCodes, updateStatusCode, deleteStatusCode,
    selectStatusCodeState, selectStatusCodeList,
    createServiceProfile, getServiceProfile, updateServiceProfile, deleteServiceProfile,
    selectServiceProfileListState, selectServiceProfileList,
    createCheckDelivery, updateCheckDelivery, getCheckDelivery, deleteCheckDelivery,
    selectCheckDeliveryState, selectCheckDeliveryList,
    createOnboardingStep, updateOnboardingStep, getOnboardingSteps, deleteOnboardingStep,
    selectOnboardingStepState, selectOnboardingStepList,
    createServiceCall, updateServiceCall, getServiceCalls, deleteServiceCall,
    selectServiceCallState, selectServiceCallList,
    createCompanyStatus, updateCompanyStatus, getCompanyStatus, deleteCompanyStatus,
    selectCompanyStatusState, selectCompanyStatusList,
    createContactStatus, updateContactStatus, getContactStatus, deleteContactStatus,
    selectContactStatusState, selectContactStatusList,
    createTalentStatus, updateTalentStatus, getTalentStatus, deleteTalentStatus,
    selectTalentStatusState, selectTalentStatusList,
    createJobStatus, updateJobStatus, getJobStatus, deleteJobStatus,
    selectJobStatusState, selectJobStatusList,
    createContactMethod, updateContactMethod, getContactMethods, deleteContactMethod,
    selectContactMethodState, selectContactMethodList,
    createPayCycle, updatePayCycle, getPayCycles, deletePayCycle,
    selectPayCycleState, selectPayCycleList,
    createPayPeriod, updatePayPeriod, getPayPeriods, deletePayPeriod,
    selectPayPeriodState, selectPayPeriodList
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import '../../../custom.scss';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import { FormInput } from "../../../utils/form_input";
import CustomFormSelect from '../../../components/form_selector/form_select';

const tableHeader = [
    { title: 'id', code: 'id' },
    { title: 'value', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

// merge with above later
const tableHeaderStatusCode = [
    { title: 'id', code: 'id' },
    { title: 'title', code: 'actions' },
    { title: 'description', code: 'actions' },
    { title: 'actions', code: 'actions' },
];

const categories = {
    1: {
        label: "engagement_activity_types", value: ["engagement_activity_type"], buttonLabel: "add_engagement_activity_type",
        inputLabel: "engagement_activity_type", deleteLabel: "delete_engagement_activity_type",
        deleteMessage: "delete_engagement_activity_type_message", updateLabel: "update_engagement_activity_type",
        methods: {
            create: createEngagementActivityType, update: updateEngagementActivityType, remove: deleteEngagementActivityType,
            get: getEngagementActivityType
        },
        headers: tableHeader,
        // deleteAlertMessage: "engagement_activity_type_deleted_successfully!"
        deleteAlertMessage: "Engagement Activity Type Deleted Successfully!"
    },
    2: {
        label: "engagement_activity_methods", value: ["engagement_activity_method"], buttonLabel: "add_engagement_activity_method",
        inputLabel: "engagement_activity_method", deleteLabel: "delete_engagement_activity_method",
        deleteMessage: "delete_engagement_activity_method_message", updateLabel: "update_engagement_activity_method",
        methods: {
            create: createEngagementMethod, update: updateEngagementActivityMethod, remove: deleteEngagementActivityMethod,
            get: getEngagementActivityMethods
        },
        headers: tableHeader,
        // deleteAlertMessage: "engagement_activity_method_deleted_successfully!"
        deleteAlertMessage: "Engagement Activity Method Deleted Successfully!"
    },
    3: {
        label: "business_sectors", value: ["business_sector"], buttonLabel: "add_business_sector",
        inputLabel: "business_sector", deleteLabel: "delete_business_sector",
        deleteMessage: "delete_business_sector_message", updateLabel: "update_business_sector",
        methods: {
            create: createBusinessSector, update: updateBusinessSector, remove: deleteBusinessSector,
            get: getBusinessSectors
        },
        headers: tableHeader,
        // deleteAlertMessage: "business_sector_deleted_successfully!"
        deleteAlertMessage: "Business Sector Deleted Successfully!"
    },
    5: {
        label: "service_profiles", value: ["service_profile"], buttonLabel: "add_service_profile",
        inputLabel: "service_profile", deleteLabel: "delete_service_profile",
        deleteMessage: "delete_service_profile_message", updateLabel: "update_service_profile",
        methods: {
            create: createServiceProfile, update: updateServiceProfile, remove: deleteServiceProfile,
            get: getServiceProfile
        },
        headers: tableHeader,
        // deleteAlertMessage: "service_profile_deleted_successfully!"
        deleteAlertMessage: "Service Profile Question Deleted Successfully!"
    },
    6: {
        label: "check_deliveries", value: ["check_delivery"], buttonLabel: "add_check_delivery",
        inputLabel: "check_delivery", deleteLabel: "delete_check_delivery",
        deleteMessage: "delete_check_delivery_message", updateLabel: "update_check_delivery",
        methods: {
            create: createCheckDelivery, update: updateCheckDelivery, remove: deleteCheckDelivery,
            get: getCheckDelivery
        },
        headers: tableHeader,
        // deleteAlertMessage: "check_delivery_deleted_successfully!"
        deleteAlertMessage: "Check Delivery Deleted Successfully!"
    },
    7: {
        label: "onboarding_steps", value: ["onboarding"], buttonLabel: "add_onboarding_step",
        inputLabel: "onboarding_step", deleteLabel: "delete_check_delivery",
        deleteMessage: "delete_onboarding_step_message", updateLabel: "update_onboarding_step",
        methods: {
            create: createOnboardingStep, update: updateOnboardingStep, remove: deleteOnboardingStep,
            get: getOnboardingSteps
        },
        headers: tableHeader,
        // deleteAlertMessage: "onboarding_step_deleted_successfully!"
        deleteAlertMessage: "Onboarding Step Deleted Successfully!"
    },
    // 8: {
    //     label: "service_calls", value: ["service_call"], buttonLabel: "add_service_call",
    //     inputLabel: "service_call", deleteLabel: "delete_service_call",
    //     deleteMessage: "delete_service_cal_message", updateLabel: "update_service_call",
    //     methods: {create: createServiceCall, update: updateServiceCall, remove: deleteServiceCall,
    //         get: getServiceCalls},
    //     headers: [{title: 'id', code: 'id'},{title: 'value', code: 'actions'}]
    // },
    9: {
        label: "company_status", value: ["company_status", "description"], buttonLabel: "add_company_status",
        inputLabel: "title", deleteLabel: "delete_company_status",
        deleteMessage: "delete_company_status_message", updateLabel: "update_company_status",
        inputLabel2: "description",
        methods: {
            create: createCompanyStatus, update: updateCompanyStatus, remove: deleteCompanyStatus,
            get: getCompanyStatus
        },
        headers: tableHeaderStatusCode,
        // deleteAlertMessage: "company_status_deleted_successfully!"
        deleteAlertMessage: "Company Status Deleted Successfully!"
    },
    10: {
        label: "contact_status", value: ["contact_status", "description"], buttonLabel: "add_contact_status",
        inputLabel: "title", deleteLabel: "delete_contact_status",
        deleteMessage: "delete_contact_status_message", updateLabel: "update_contact_status",
        inputLabel2: "description",
        methods: {
            create: createContactStatus, update: updateContactStatus, remove: deleteContactStatus,
            get: getContactStatus
        },
        headers: tableHeaderStatusCode,
        // deleteAlertMessage: "contact_status_deleted_successfully!"
        deleteAlertMessage: "Contact Status Deleted Successfully!"
    },
    11: {
        label: "talent_status", value: ["talent_status", "description"], buttonLabel: "add_talent_status",
        inputLabel: "title", deleteLabel: "delete_talent_status",
        deleteMessage: "delete_talent_status_message", updateLabel: "update_talent_status",
        inputLabel2: "description",
        methods: {
            create: createTalentStatus, update: updateTalentStatus, remove: deleteTalentStatus,
            get: getTalentStatus
        },
        headers: tableHeaderStatusCode,
        // deleteAlertMessage: "talent_status_deleted_successfully!"
        deleteAlertMessage: "Talent Status Deleted Successfully!"
    },
    // 12: {
    //     label: "job_status", value: ["job_status", "description"], buttonLabel: "add_job_status",
    //     inputLabel: "title", deleteLabel: "delete_job_status",
    //     deleteMessage: "delete_job_status_message", updateLabel: "update_job_status",
    //     inputLabel2: "description",
    //     methods: {create: createJobStatus, update: updateJobStatus, remove: deleteJobStatus,
    //         get: getJobStatus},
    //     headers: [{title: 'id', code: 'id'},{title: 'value', code: 'actions'}, {title: 'description', code: 'actions'}, {title: 'actions', code: 'actions'}]
    // },
    13: {
        label: "contact_methods", value: ["title", "description"], buttonLabel: "add_contact_method",
        inputLabel: "title", deleteLabel: "delete_contact_method",
        deleteMessage: "delete_contact_method_message", updateLabel: "update_contact_method",
        inputLabel2: "description",
        methods: {
            create: createContactMethod, update: updateContactMethod, remove: deleteContactMethod,
            get: getContactMethods
        },
        headers: tableHeaderStatusCode,
        // deleteAlertMessage: "contact_method_deleted_successfully!"
        deleteAlertMessage: "Contact Method Deleted Successfully!"
    },
    14: {
        label: "pay_cycles", value: ["pay_cycle", "description"], buttonLabel: "add_pay_cycle",
        inputLabel: "title", deleteLabel: "delete_pay_cycle",
        deleteMessage: "delete_pay_cycle_message", updateLabel: "update_pay_cycle",
        inputLabel2: "description",
        methods: {
            create: createPayCycle, update: updatePayCycle, remove: deletePayCycle,
            get: getPayCycles
        },
        headers: tableHeaderStatusCode,
        // deleteAlertMessage: "pay_cycle_deleted_successfully!"
        deleteAlertMessage: "Pay Cycle Deleted Successfully!"
    },
    // 15: {
    //     label: "pay_periods", value: ["pay_period", "description"], buttonLabel: "add_pay_period",
    //     inputLabel: "title", deleteLabel: "delete_pay_period",
    //     deleteMessage: "delete_pay_period_message", updateLabel: "update_pay_period",
    //     inputLabel2: "description",
    //     methods: {create: createPayPeriod, update: updatePayPeriod, remove: deletePayPeriod,
    //         get: getPayPeriods},
    //     headers: tableHeaderStatusCode
    // }
}

const SystemWidePage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        'id': '', 'value': '', 'description': '',
        'sequence_number': 0
    });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const exceptionList = [9, 10, 11, 12, 13, 14, 15];

    const engagementActivityMethodState = useAppSelector((state) => selectEngagementActivityMethodListState(state));
    const engagementActivityMethodList = useAppSelector((state) => selectEngagementActivityMethodList(state));
    const engagementActivityTypeState = useAppSelector((state) => selectEngagementActivityTypeListState(state));
    const engagementActivityTypeList = useAppSelector((state) => selectEngagementActivityTypeList(state));
    const businessSectorState = useAppSelector((state) => selectSalesBusinessSectorState(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const statusCodeState = useAppSelector((state) => selectStatusCodeState(state));
    const statusCodeList = useAppSelector((state) => selectStatusCodeList(state));
    const serviceProfileState = useAppSelector((state) => selectServiceProfileListState(state));
    const serviceProfileList = useAppSelector((state) => selectServiceProfileList(state));
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));
    const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));
    const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
    const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
    const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));
    const jobStatusState = useAppSelector((state) => selectJobStatusState(state));
    const jobStatusList = useAppSelector((state) => selectJobStatusList(state));
    const contactMethodState = useAppSelector((state) => selectContactMethodState(state));
    const contactMethodList = useAppSelector((state) => selectContactMethodList(state));
    const payCycleState = useAppSelector((state) => selectPayCycleState(state));
    const payCycleList = useAppSelector((state) => selectPayCycleList(state));
    const payPeriodState = useAppSelector((state) => selectPayPeriodState(state));
    const payPeriodList = useAppSelector((state) => selectPayPeriodList(state));

    const reduxMapping = {
        1: { state: engagementActivityTypeState, list: engagementActivityTypeList },
        2: { state: engagementActivityMethodState, list: engagementActivityMethodList },
        3: { state: businessSectorState, list: businessSectorList },
        4: { state: statusCodeState, list: statusCodeList },
        5: { state: serviceProfileState, list: serviceProfileList },
        6: { state: checkDeliveryState, list: checkDeliveryList },
        7: { state: onboardingStepState, list: onboardingStepList },
        8: { state: serviceCallState, list: serviceCallList },
        9: { state: companyStatusState, list: companyStatusList },
        10: { state: contactStatusState, list: contactStatusList },
        11: { state: talentStatusState, list: talentStatusList },
        12: { state: jobStatusState, list: jobStatusList },
        13: { state: contactMethodState, list: contactMethodList },
        14: { state: payCycleState, list: payCycleList },
        15: { state: payPeriodState, list: payPeriodList },
    }

    useEffect(() => {
        if (
            engagementActivityTypeState.remove?.loading === LoadingType.succeeded ||
            engagementActivityMethodState.remove?.loading === LoadingType.succeeded ||
            businessSectorState.remove?.loading === LoadingType.succeeded ||
            statusCodeState.remove?.loading === LoadingType.succeeded ||
            serviceProfileState.remove?.loading === LoadingType.succeeded ||
            checkDeliveryState.remove?.loading === LoadingType.succeeded ||
            onboardingStepState.remove?.loading === LoadingType.succeeded ||
            serviceCallState.remove?.loading === LoadingType.succeeded ||
            companyStatusState.remove?.loading === LoadingType.succeeded ||
            contactStatusState.remove?.loading === LoadingType.succeeded ||
            talentStatusState.remove?.loading === LoadingType.succeeded ||
            jobStatusState.remove?.loading === LoadingType.succeeded ||
            contactMethodState.remove?.loading === LoadingType.succeeded ||
            payCycleState.remove?.loading === LoadingType.succeeded ||
            payPeriodState.remove?.loading === LoadingType.succeeded

        ) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [
        engagementActivityTypeState.remove?.loading,
        engagementActivityMethodState.remove?.loading,
        businessSectorState.remove?.loading,
        statusCodeState.remove?.loading,
        serviceProfileState.remove?.loading,
        checkDeliveryState.remove?.loading,
        onboardingStepState.remove?.loading,
        serviceCallState.remove?.loading,
        companyStatusState.remove?.loading,
        contactStatusState.remove?.loading,
        talentStatusState.remove?.loading,
        jobStatusState.remove?.loading,
        contactMethodState.remove?.loading,
        payCycleState.remove?.loading,
        payPeriodState.remove?.loading
    ])

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(false);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id) {
        setShowDeleteDialog(true);
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        setSnackBarMessage(getCategory().deleteAlertMessage)
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (exceptionList.includes(selectedCategory)) {
            setMethodValue({
                ...methodValue, 'id': method.id, 'value': method[getCategory().value[0]],
                'description': method[getCategory().value[1]], 'sequence_number': method['sequence_number']
            });
        }
        else
            setMethodValue({ ...methodValue, 'id': method.id, 'value': method[getCategory().value] });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("talent_dropdown")}
                        list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('engagement_activity_types')}
                        required={false}
                        label={t('please_select_system_wide_category')}
                        customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                    />
                    <div>
                        {![8, 12].includes(selectedCategory) ?
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                enable={true}
                                leftIcon={getPlusBtnIcon()}
                                name={t(categories[selectedCategory].buttonLabel)}
                                backgroundColor={THEME.buttonColor1}
                                onClick={() => (setShowCreateDialog(true))}
                                customStyle={{ width: "max-content" }}
                            /> : <></>}
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                {exceptionList.includes(selectedCategory) ? <CreateAction2
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                /> : <CreateAction
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                />}
            </Portal>}
            {showDeleteDialog && <Portal>
                <DeleteAction
                    visible={showDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onDismiss={handleCloseDeleteDialog}
                    onApprove={handleDeleteApprove}
                    category={getCategory()}
                />
            </Portal>}
            {showEditDialog && <Portal>
                {exceptionList.includes(selectedCategory) ? <EditAction2
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                /> : <EditAction
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                />}
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete }) => {

    function getActions(doc) {
        if (category.label === 'service_calls')
            return <td style={{ "display": "none" }}></td>;
        else if (category.label === 'job_status')
            return <td></td>
        else
            return (
                <TableData>
                    <img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                        onClick={() => handleShowEdit(doc)} />
                    <img src={DeleteIcon} alt='delete_button'
                        onClick={() => handleShowDelete(doc.id)} />
                </TableData>
            )
    }

    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    }
    else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData>{doc.id}</TableData>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            {getActions(doc)}
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

const CreateAction = ({ onClose, onDismiss, visible, category, category_id }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const engagementActivityMethodState = useAppSelector((state) => selectEngagementActivityMethodListState(state));
    const engagementActivityMethodList = useAppSelector((state) => selectEngagementActivityMethodList(state));
    const engagementActivityTypeState = useAppSelector((state) => selectEngagementActivityTypeListState(state));
    const engagementActivityTypeList = useAppSelector((state) => selectEngagementActivityTypeList(state));
    const businessSectorState = useAppSelector((state) => selectSalesBusinessSectorState(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const statusCodeState = useAppSelector((state) => selectStatusCodeState(state));
    const statusCodeList = useAppSelector((state) => selectStatusCodeList(state));
    const serviceProfileState = useAppSelector((state) => selectServiceProfileListState(state));
    const serviceProfileList = useAppSelector((state) => selectServiceProfileList(state));
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));

    const reduxMapping = {
        1: { state: engagementActivityTypeState, list: engagementActivityTypeList },
        2: { state: engagementActivityMethodState, list: engagementActivityMethodList },
        3: { state: businessSectorState, list: businessSectorList },
        4: { state: statusCodeState, list: statusCodeList },
        5: { state: serviceProfileState, list: serviceProfileList },
        6: { state: checkDeliveryState, list: checkDeliveryList },
        7: { state: onboardingStepState, list: onboardingStepList },
        8: { state: serviceCallState, list: serviceCallList }
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (methodValue && state.create.loading === LoadingType.succeeded) {
            setMethodValue('');
            setShowButton(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading])

    function handleInput(value) {
        if (value.trim()) {
            setShowButton(true);
        }
        else {
            setShowButton(false);
        }
        setMethodValue(value);
    }

    function handleSave() {
        setSentRequest(true);
        dispatch(category.methods.create({ 'value': methodValue.trim() }));
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container">
                <div className="dialog-header">
                    <span className='update-title'>{t(category.buttonLabel)}</span>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(val))}
                        type={"text"}
                        value={methodValue}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.create.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={showButton}
                        loading={getMapping().state.create.loading == LoadingType.pending}

                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const CreateAction2 = ({ onClose, onDismiss, visible, category, category_id }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState({ value: '', description: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));
    const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
    const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
    const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));
    const jobStatusState = useAppSelector((state) => selectJobStatusState(state));
    const jobStatusList = useAppSelector((state) => selectJobStatusList(state));
    const contactMethodState = useAppSelector((state) => selectContactMethodState(state));
    const contactMethodList = useAppSelector((state) => selectContactMethodList(state));
    const payCycleState = useAppSelector((state) => selectPayCycleState(state));
    const payCycleList = useAppSelector((state) => selectPayCycleList(state));
    const payPeriodState = useAppSelector((state) => selectPayPeriodState(state));
    const payPeriodList = useAppSelector((state) => selectPayPeriodList(state));

    const reduxMapping = {
        9: { state: companyStatusState, list: companyStatusList },
        10: { state: contactStatusState, list: contactStatusList },
        11: { state: talentStatusState, list: talentStatusList },
        12: { state: jobStatusState, list: jobStatusList },
        13: { state: contactMethodState, list: contactMethodList },
        14: { state: payCycleState, list: payCycleList },
        15: { state: payPeriodState, list: payPeriodList },
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (sentRequest && state.create.loading === LoadingType.succeeded) {
            setMethodValue({ ...methodValue, value: '', description: '' });
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading])

    function handleInput(id, value) {
        if (id === 'input-value') {
            setMethodValue({ ...methodValue, value: value })
            // if (value.trim() && methodValue.description.trim())
            if (value.trim())
                setShowButton(true);
            else
                setShowButton(false);
        }
        else {
            setMethodValue({ ...methodValue, description: value })
            // if (value.trim() && methodValue.value.trim())
            if (methodValue.value.trim())
                setShowButton(true);
            else
                setShowButton(false);
        }
    }

    function handleSave() {
        setSentRequest(true);
        dispatch(category.methods.create({
            'value': methodValue.value.trim(),
            'description': methodValue.description.trim()
        }));
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container dialog-container-2">
                <div className="dialog-header">
                    <h5>{t(category.buttonLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.value}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"input-desc"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.description}
                        label={t(category.inputLabel2)}
                        placeholder=""
                    // required={true}
                    />
                    {getMapping().state.create.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={showButton}
                        loading={getMapping().state.create.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const DeleteAction = ({ onClose, onDismiss, visible, onApprove, category }) => {

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={400}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-delete-container">
                <div className="dialog-header">
                    <span style={{ color: THEME.red, fontSize: '1.05vw' }}>{t(category.deleteLabel)}</span>
                </div>
                <div className="dialog-content">
                    <span>{t(category.deleteMessage)}</span>
                </div>
                <div className="dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('yes')}
                            enable={true}
                            backgroundColor={'#0079C1'}
                            onClick={() => closePopup(onApprove)}
                        />
                    </div>
                    <div className='btn-cancel'>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('no')}
                            enable={true}
                            backgroundColor={'#5A3F98'}
                            onClick={() => closePopup(onClose)}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

const EditAction = ({ onClose, onDismiss, visible, methodValue, category, category_id }) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [inputValue, setInputValue] = useState(methodValue.value);
    const [showButton, setShowButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const engagementActivityMethodState = useAppSelector((state) => selectEngagementActivityMethodListState(state));
    const engagementActivityMethodList = useAppSelector((state) => selectEngagementActivityMethodList(state));
    const engagementActivityTypeState = useAppSelector((state) => selectEngagementActivityTypeListState(state));
    const engagementActivityTypeList = useAppSelector((state) => selectEngagementActivityTypeList(state));
    const businessSectorState = useAppSelector((state) => selectSalesBusinessSectorState(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const statusCodeState = useAppSelector((state) => selectStatusCodeState(state));
    const statusCodeList = useAppSelector((state) => selectStatusCodeList(state));
    const serviceProfileState = useAppSelector((state) => selectServiceProfileListState(state));
    const serviceProfileList = useAppSelector((state) => selectServiceProfileList(state));
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));

    const reduxMapping = {
        1: { state: engagementActivityTypeState, list: engagementActivityTypeList },
        2: { state: engagementActivityMethodState, list: engagementActivityMethodList },
        3: { state: businessSectorState, list: businessSectorList },
        4: { state: statusCodeState, list: statusCodeList },
        5: { state: serviceProfileState, list: serviceProfileList },
        6: { state: checkDeliveryState, list: checkDeliveryList },
        7: { state: onboardingStepState, list: onboardingStepList },
        8: { state: serviceCallState, list: serviceCallList }
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(value) {
        if (value.trim() === methodValue.value) {
            setShowButton(false);
        }
        else if (value.trim()) {
            setShowButton(true);
        }
        else {
            setShowButton(false);
        }
        setInputValue(value);
    }

    function handleUpdate() {
        setSentRequest(true);
        dispatch(category.methods.update({ 'id': methodValue.id, 'value': inputValue.trim() }));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && methodValue.value != inputValue) {
            setInputValue(inputValue.trim());
            methodValue.value = inputValue.trim();
            setShowButton(false);
            setSuccessMessage(getMapping().state.update.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container">
                <div className="dialog-header">
                    <span className='update-title'>{t(category.updateLabel)}</span>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(val))}
                        type={"text"}
                        value={inputValue}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.update.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.update.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('update')}
                        enable={showButton}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )
}

const EditAction2 = ({ onClose, onDismiss, visible, methodValue, category, category_id }) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [inputValue, setInputValue] = useState({ value: methodValue.value, description: methodValue.description });
    const [showButton, setShowButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const companyStatusState = useAppSelector((state) => selectCompanyStatusState(state));
    const companyStatusList = useAppSelector((state) => selectCompanyStatusList(state));
    const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
    const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
    const talentStatusState = useAppSelector((state) => selectTalentStatusState(state));
    const talentStatusList = useAppSelector((state) => selectTalentStatusList(state));
    const jobStatusState = useAppSelector((state) => selectJobStatusState(state));
    const jobStatusList = useAppSelector((state) => selectJobStatusList(state));
    const contactMethodState = useAppSelector((state) => selectContactMethodState(state));
    const contactMethodList = useAppSelector((state) => selectContactMethodList(state));
    const payCycleState = useAppSelector((state) => selectPayCycleState(state));
    const payCycleList = useAppSelector((state) => selectPayCycleList(state));
    const payPeriodState = useAppSelector((state) => selectPayPeriodState(state));
    const payPeriodList = useAppSelector((state) => selectPayPeriodList(state));

    const reduxMapping = {
        9: { state: companyStatusState, list: companyStatusList },
        10: { state: contactStatusState, list: contactStatusList },
        11: { state: talentStatusState, list: talentStatusList },
        12: { state: jobStatusState, list: jobStatusList },
        13: { state: contactMethodState, list: contactMethodList },
        14: { state: payCycleState, list: payCycleList },
        15: { state: payPeriodState, list: payPeriodList },
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(id, value) {
        if (id === 'input-value') {
            setInputValue({ ...inputValue, value: value });
            if (value.trim() === methodValue.value && methodValue.description === inputValue.description.trim())
                setShowButton(false);
            else {
                // if (value.trim() && inputValue.description.trim())
                if (value.trim())
                    setShowButton(true);
                else
                    setShowButton(false);
            }
        }
        else {
            setInputValue({ ...inputValue, description: value })
            if (value.trim() === methodValue.description && methodValue.value === inputValue.value.trim())
                setShowButton(false);
            else {
                // if (value.trim() && inputValue.value.trim())
                if (inputValue.value.trim())
                    setShowButton(true);
                else
                    setShowButton(false);
            }
        }
    }

    function handleUpdate() {
        setSentRequest(true);
        dispatch(category.methods.update({
            'id': methodValue.id, 'value': inputValue.value.trim(),
            'description': inputValue.description.trim(), 'sequence_number': methodValue.sequence_number
        }));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && sentRequest) {
            setInputValue({ ...inputValue, value: inputValue.value.trim(), description: inputValue.description.trim() });
            methodValue.value = inputValue.value.trim();
            methodValue.description = inputValue.description.trim();
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(getMapping().state.update.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container dialog-container-2">
                <div className="dialog-header">
                    <span className='update-title'>{t(category.updateLabel)}</span>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputValue.value}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"input-desc"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputValue.description}
                        label={t(category.inputLabel2)}
                        placeholder=""
                    // required={true}
                    />
                    {getMapping().state.update.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.update.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('update')}
                        enable={showButton}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default SystemWidePage;