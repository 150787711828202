import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, AgencyOnboarding, IHandbookUploadUrls } from "../../../../interfaces";
import * as thunk from "./onboarding_thunk";

export interface AgencyonboardingState {
  error?: AppError | null;
  loading: LoadingType;
  agencyOnboardingData: AgencyOnboarding;

  update: {
    error?: AppError | null;
    loading: LoadingType;
    response: string | null;
  };
  fileURL: {
    error?: AppError | null;
    loading: LoadingType;
    response: IHandbookUploadUrls | null;
  };
  upload: {
    error?: AppError | null;
    loading: LoadingType;
    response: any | null;
  };
  fileDownloadResponse: {
    error?: AppError | null,
    loading: LoadingType,
    data: any
  }
}

const initialState: AgencyonboardingState = {
  error: null,
  loading: LoadingType.idle,
  agencyOnboardingData: {} as AgencyOnboarding,
  update: {
    error: null,
    loading: LoadingType.idle,
    response: "",
  },
  fileURL: {
    error: null,
    loading: LoadingType.idle,
    response: null,

  },
  upload: {
    error: null,
    loading: LoadingType.idle,
    response: null,
  },
  fileDownloadResponse: {
    error: null,
    loading: LoadingType.idle,
    data: null
  }

};

export const getAgencyOnboardingDataSlice = createSlice({
  name: "agencyOnboarding",
  initialState: initialState,
  reducers: {
    clearState(state) {
      return { ...initialState };
    },
    clearHealthCareError(state) {
      return { ...state, error: null };
    },
    clearUpdateState(state) {
      return { ...state, ...initialState.update };

    },
    clearOnboardingState(state) {
      return {
        ...state,
        loading: LoadingType.pending,
        error: null,
        agencyOnboardingData: {} as AgencyOnboarding,
        update: {
          ...initialState.update,
          error: null,
        },
        fileURL: {
          ...initialState.fileURL,
          error: null,
        },
        upload: {
          ...initialState.upload,
          error: null,
        },
      };
    },
    clearOnboardingURLState(state) {
      return {
        ...state,
        loading: LoadingType.pending,
        error: null,
        update: {
          ...initialState.update,
          error: null,
        },
        fileURL: {
          ...initialState.fileURL,
          error: null,
        },
        upload: {
          ...initialState.upload,
          error: null,
        },
      };
    },
    clearUploadUrlsState(state) {
      return { ...state, fileURL: { ...initialState.fileURL } };
    },
    clearUploadFilesState(state) {
      return { ...state, upload: { ...initialState.upload } };
    },
    clearFileDownloadState(state) {
      return {
        ...state, fileDownloadResponse: {
          error: null,
          loading: LoadingType.idle,
          data: null,
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getAgencyOnBoarding.pending, (state, action) => ({
        ...state,
        loading: LoadingType.pending,
        error: null,
      }))
      .addCase(thunk.getAgencyOnBoarding.rejected, (state, action) => ({
        ...state,
        loading: LoadingType.failed,
        error: action.payload,
      }))
      .addCase(thunk.getAgencyOnBoarding.fulfilled, (state, action) => ({
        ...state,
        loading: LoadingType.succeeded,
        agencyOnboardingData: action.payload,
      }))

      .addCase(thunk.updateAgencyOnboarding.pending, (state, action) => ({
        ...state,
        update: { ...state.update, loading: LoadingType.pending, error: null },
      }))
      .addCase(thunk.updateAgencyOnboarding.rejected, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.failed,
          error: action.payload,
        },
      }))
      .addCase(thunk.updateAgencyOnboarding.fulfilled, (state, action) => ({
        ...state,
        update: {
          ...state.update,
          loading: LoadingType.succeeded,
          response: action.payload,
        },
      }))

      .addCase(thunk.getAgencyOnboardingHandbookUploadURL.pending, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.getAgencyOnboardingHandbookUploadURL.rejected, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.failed, error: action.payload, response: null, } }))
      .addCase(thunk.getAgencyOnboardingHandbookUploadURL.fulfilled, (state, action) => ({ ...state, fileURL: { ...state.fileURL, loading: LoadingType.succeeded, error: null, response: action.payload } }))

      .addCase(thunk.uploadAgencyOnboardingHandbookURL.pending, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.pending, error: null, } }))
      .addCase(thunk.uploadAgencyOnboardingHandbookURL.rejected, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.failed, error: action.payload, response: null, } }))
      .addCase(thunk.uploadAgencyOnboardingHandbookURL.fulfilled, (state, action) => ({ ...state, upload: { ...state.upload, loading: LoadingType.succeeded, error: null, response: action.payload } }))


      .addCase(thunk.getHandbookFileDownloadURL.pending, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.pending, error: null } }))
      .addCase(thunk.getHandbookFileDownloadURL.rejected, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.failed, error: action.payload } }))
      .addCase(thunk.getHandbookFileDownloadURL.fulfilled, (state, action) => ({ ...state, fileDownloadResponse: { ...state.fileDownloadResponse, loading: LoadingType.succeeded, error: null, data: action.payload } }))

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const manageAgencyOnboardingActions =
  getAgencyOnboardingDataSlice.actions;
export const { clearHealthCareError } = getAgencyOnboardingDataSlice.actions;
export const selectUpdateState = (state: AgencyonboardingState) => state.update;
export const selectAgencyOnboardingState = (state: AgencyonboardingState) =>
  state;
export const selectAgencyOnboardingFileURL = (state: AgencyonboardingState) => state.fileURL;
export const selecAgencyOnboardingtUpload = (state: AgencyonboardingState) => state.upload;
export const selecAgencyOnboardingDownload = (state: AgencyonboardingState) => state.fileDownloadResponse;
