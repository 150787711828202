import './toggle_switch.scss';
import { Tooltip } from "@material-ui/core";
import { THEME } from "../../enums";


interface IToggleSwitchProps {
    required?: boolean,
    label?: string,
    labelPosition?: "top" | "right" | "left",
    checked: boolean | null,
    onChange: (value: boolean) => void,
    tooltip?: string | null,
    disabled?: boolean,
}
const ToggleSwitch = ({ required, label, labelPosition, checked, onChange, tooltip, disabled }: IToggleSwitchProps) => {

    function returnLabel() {
        if (tooltip)
            return (
                <div className={"toggle-label-holder"}>
                    {(label && label != "") && <Tooltip
                        placement="top-start"
                        title={tooltip}
                    >
                        <span
                            style={{
                                color: checked ? THEME.defaultHighLightColor : THEME.toggleDisableColor
                            }}
                            className={'toggle-label-text'}
                        >
                            {label}
                        </span>
                    </Tooltip>
                    }
                </div>
            );
        return (
            <div className={"toggle-label-holder"}>
                {(label && label != "") && <span
                    style={{
                        color: checked ? THEME.defaultHighLightColor : THEME.toggleDisableColor
                    }}
                    className={'toggle-label-text'}
                >
                    {label}
                </span>
                }
            </div>
        );
    }
    if (labelPosition === "top") {
        return (
            <div className={"switch-holder-col-top"}>
               {label && <div className="switch-label"><span>{label + (required ? ' *' : '')}</span></div>}
                <div className="switch">
                    <input type="checkbox"
                        checked={checked ? true : false}
                        onChange={(event) => onChange(event.target.checked)}
                        style={{ display: 'block' }}
                        disabled={disabled}
                    />
                    <div className="slider round" />
                </div>
            </div>
        )
    }
    if (labelPosition === "left") {
        return (
            <div className={"switch-holder-col-left"}>
               {label && <div className="switch-label"><span>{label + (required ? ' *' : '')}</span></div>}
                <div className="switch">
                    <input type="checkbox"
                        checked={checked ? true : false}
                        onChange={(event) => onChange(event.target.checked)}
                        style={{ display: 'block' }}
                        disabled={disabled}
                    />
                    <div className="slider round" />
                </div>
            </div>
        )
    }

    return (
        <div className="switch-holder">
            <div className="switch">
                <input type="checkbox"
                    checked={checked ? true : false}
                    onChange={(event) => onChange(event.target.checked)}
                    style={{ display: 'block' }}
                    disabled={disabled}
                />
                <div className="slider round" />
            </div>
            {label && returnLabel()}
        </div>
    )
}
export default ToggleSwitch;