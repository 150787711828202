import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPaycheckPDFURLThunk, getPayCheckThunk, getWeekEndingDatesDropdownThunk, mailBOPaycheckThunk, selectePaycheckInvoiceState, selectMailPaycheckInvoiceState, selectPayCheckList, selectPayCheckState, selectProfileState, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { CustomButton, CustomCheckBox, SpinnerScreen, convertNumberToMoney, currencyConversion } from "../../../../../utils";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { useEffect, useState } from "react";
import { LoadingType, THEME } from "../../../../../enums";
import SearchBar from "../../../../../components/search_bar";
import { getDateString } from "../../../../../variables";
import "./paycheck_directory.scss"
import { SearchIcon } from "../../../../../icons";
import { Portal } from "react-portal";
import ViewCheckInvoicePopup from "../../../popups/view_check/view_check";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { AppRoutes } from "../../../../../routes";
import { EmailOutlined } from "@material-ui/icons";
import SendInvoiceEdocsByMailPopup from "../../../../companies/popup/send_invoice_edocs_mail/send_invoice_edocs_mail";
import { mailInvoicePaycheckSliceActions } from "../../../../../redux/back_office/mail_paycheck_invoices/mail_paycheck_invoices_reducer";

interface Props extends RouteComponentProps<any> { }
const PayCheckDirectoryPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string; date: string; }>({ id: "", date: "" });
    const [openViewCheckPopup, setOpenViewCheckPopup] = useState<boolean>(false)
    const [paycheckViewURL, setPaycheckViewURL] = useState<string>("");
    const [showMailPopup, setShowMailPopup] = useState<boolean>(false);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
    const weekEndingDatesListState = useAppSelector((state) =>
        selectWeekEndingDatesState(state)
    );
    const weekEndingDatesList = useAppSelector((state) =>
        selectWeekEndingDatesDDList(state)
    );
    const payCheckState = useAppSelector((state) =>
        selectPayCheckState(state)
    );
    const payCheckList = useAppSelector((state) => selectPayCheckList(state));
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const mailPaycheckState = useAppSelector((state) => selectMailPaycheckInvoiceState(state));
    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        if (
            weekEndingDatesListState &&
            weekEndingDatesListState.loading === LoadingType.succeeded &&
            weekEndingDatesList.length > 0
        ) {
            setSelectedWeekDate({
                id: weekEndingDatesList[0].id,
                date: getDateString(
                    weekEndingDatesList[0].week_ending_date,
                    "mm/dd/yyyy"
                ),
            });
            dispatch(getPayCheckThunk({ week_accounting_id: weekEndingDatesList[0].id }));
        }
    }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            setPaycheckViewURL(paycheckPDFURLState.paycheck.response)
        }
    }, [paycheckPDFURLState.paycheck, paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === payCheckList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "employee_name", code: "employee_name" },
        { title: "check_number", code: "check_number" },
        { title: "check_date", code: "check_date" },
        { title: "gross_amount", code: "gross_amount" },
        { title: "total_deductions", code: "total_deduction" },
        { title: "total_tax_amount", code: "total_tax_amount" },
        { title: "net_amount", code: "net_amount" },
        { title: "date_cleared", code: "date_cleared" },
        { title: "check_status", code: "check_status" },
        { title: "check_delivery", code: "check_delivery" },
        { title: "", code: "" },
    ];
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === payCheckList?.length) {
            setSelectedItems(newSet);
        } else {
            payCheckList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value)) {
            newSet.delete(value);
        } else {
            newSet.set(value, { id: value, name: "" });
        }
        setSelectedItems(newSet);
    };
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(
            (obj) => obj.id === val
        );
        setSelectedWeekDate({
            id: val,
            date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy"),
        });
        dispatch(getPayCheckThunk({ week_accounting_id: val }));
    };

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
        setOpenViewCheckPopup(true)
    }

    function getFilteredList() {
        let list;
        if (sortedField != null && payCheckList) {
            list = [...payCheckList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? payCheckList)?.filter(doc => {
            const str = search.trim().toLowerCase();
            const empNameFilter = doc.employee_name ? doc.employee_name.trim().toLowerCase().includes(str) : false;
            const checkNumberFilter = doc.check_number ? doc.check_number.toString().trim().toLowerCase().includes(str) : false;
            const grossAmountFilter = doc.gross_amount ? convertNumberToMoney(doc.gross_amount).toString().trim().toLowerCase().includes(str) : false;
            const totalDeductionFilter = doc.total_deduction ? convertNumberToMoney(doc.total_deduction).toString().trim().toLowerCase().includes(str) : false;
            const totalTaxesFilter = doc.total_tax_amount ? convertNumberToMoney(doc.total_tax_amount).toString().trim().toLowerCase().includes(str) : false;
            const netAmountFilter = doc.net_amount ? convertNumberToMoney(doc.net_amount).toString().trim().toLowerCase().includes(str) : false;
            return empNameFilter || checkNumberFilter || grossAmountFilter || totalDeductionFilter || netAmountFilter || totalTaxesFilter;
        });
    };

    const handleClose = () => {
        setOpenViewCheckPopup(false);
        dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        setPaycheckViewURL("");
    }

    function handleUserSelect(value) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.employee_name,
            }
        })
    }

    const handleEmailClose = () => {
        setShowMailPopup(false);
        dispatch(mailInvoicePaycheckSliceActions.clearMailPaycheckState());
        setSelectedItems(new Map());
    }
    const sendPaycheckByMail = (paycheck_ids: string[]) => {
        dispatch(mailBOPaycheckThunk(paycheck_ids));
    }

    return (
        <div className="payroll-paycheck-container">
            <div className="payroll-paycheck-header">
                <div className="select-row">
                    <div>
                        <CustomFormSelect
                            list={weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))}
                            customStyle={{ width: "100%" }}
                            name={"week_ending_date"}
                            value={selectedWeekDate.id}
                            label={t("week_ending_date")}
                            placeholder={"Select"}
                            onChange={handleWeekEndChange}
                            isClearable={false}
                        />
                    </div>
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                </div>
                <div className='bi-header-right'>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            leftIcon={<div><EmailOutlined style={{ color: THEME.white, height: "1vw", width: "1vw" }} /></div>}
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => {
                                sendPaycheckByMail(Array.from(
                                    selectedItems.values(),
                                    (entry) => entry.id
                                )); setShowMailPopup(true)
                            }}
                            enable={selectedItems.size > 0}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                    </div>
                </div>
            </div>
            <div className="payroll-paycheck-content">
                <div className="payroll-paycheck-table">
                    {payCheckList &&
                        payCheckState.loading === LoadingType.pending ? (
                        <>
                            <SpinnerScreen></SpinnerScreen>
                        </>
                    ) : (
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList() && getFilteredList()!.length > 0 ?
                                getFilteredList()?.map((doc) => {
                                    return (
                                        <tr key={doc.id}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                <CustomCheckBox
                                                    name={""}
                                                    title={""}
                                                    checked={selectedItems.has(doc.id)}
                                                    onClick={() => handleMarkItem(doc.id)}
                                                />
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleUserSelect(doc)}>
                                                <span>{doc.employee_name}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{doc.check_number}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                                <span>{getDateString(doc.check_date, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                                <span>{currencyConversion(doc.gross_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                <span>{currencyConversion(doc.total_deduction, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                <span>{currencyConversion(doc.total_tax_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                <span>{currencyConversion(doc.net_amount, 2)}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                <span>{getDateString(doc.date_cleared, "mm/dd/yyyy")}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                                <span>{doc.check_status}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                                <span>{doc.check_delivery}</span>
                                            </TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                <div className="view-check-btn">
                                                    <CustomButton
                                                        loading={false}
                                                        customStyle={{ width: '-webkit-fill-available' }}
                                                        leftIcon={<span style={{ color: "#fff" }}><SearchIcon /></span>}
                                                        textStyle={{ textTransform: 'capitalize' }}
                                                        name={t("view_check")}
                                                        enable={true}
                                                        backgroundColor={THEME.buttonBackgroundColor3}
                                                        onClick={() => handleViewCheck(doc.id)}
                                                    />
                                                </div>
                                                {/* <div className="mail-icon" onClick={() => handleMailCheck(doc.id)}>
                                                    <EmailOutlined width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                                                </div> */}
                                            </TableData>
                                        </tr>
                                    );
                                })
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(14) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_data_available")}</div></TableData>
                                </tr>
                            }
                        </SortableTable>
                    )}
                </div>
            </div>
            {openViewCheckPopup &&
                (
                    <Portal>
                        <ViewCheckInvoicePopup
                            visible={openViewCheckPopup}
                            title={t("view_check")}
                            onClose={handleClose}
                            state={paycheckPDFURLState.paycheck}
                            responseURL={paycheckViewURL}
                        />
                    </Portal>
                )
            }
            {showMailPopup &&
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => {}}
                        mailState={mailPaycheckState.mailPaycheck}
                        currentLoginUserName={currentLoginUserName}
                        title={t("hi_lets_send_paycheck", {
                            name: currentLoginUserName,
                        })}
                        successTitle={t("sent_paycheck_by_email")}
                        successMsg={t("you_sent_paycheck_to", { count: 1 })}
                        subTitle={t("to_whom_you_send_paycheck")}
                    />
                </Portal>
            }
        </div>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 0.2;
        if (value === 1) return 1.2;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1.1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1.5;
        if (value === 11) return 1;
        if (value === 12) return 1;
        return 1;
    }
}

export default PayCheckDirectoryPage;