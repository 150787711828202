
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolder, ActionDialogHolderType } from '../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../components/api_error';
import { LoadingType, THEME } from '../../../enums';
import { deleteCertification, deleteCredentials, deleteEducationRequirement, deleteEquipment, deleteSkill, selectDelete_State } from '../../../redux/admin_center';
import { manageSkillListActions } from '../../../redux/admin_center/system_configuration/skill_list/skill_list_reducer';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../redux/store';
import { CustomButton } from '../../../utils';

interface IDeleteActionProps {
    showDeleteDialog: boolean,
    setShowDeleteDialog: any,
    selectedData:any,
    categoryId:number,
}

export const DeleteActionDialog = (props: IDeleteActionProps) => {
    const {
        selectedData,
        showDeleteDialog,
        setShowDeleteDialog,
        categoryId
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { loading, error, response } = useAppSelector((state) => selectDelete_State(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            setShowDeleteDialog(false);
            dispatch(manageSkillListActions.clearRemoveState());
        }
        return () => { }
    }, [loading])

    const onClose = () => {
        setShowDeleteDialog(false);
    }
    const onDissmiss = () => {
        setShowDeleteDialog(false);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={showDeleteDialog}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="del-role-container">
                <div className="del-role-header">
                    <div className="title-txt">
                        <span>{`${t('attention')}!!`}</span>
                    </div>
                </div>
                <div className="del-role-content">
                    <div className="message-txt">
                        <span>{t('are_you_sure_you_want_to_delete', { value: selectedData.value })}</span>
                    </div>
                    {error && <div className={"error-section"}>
                        <ApiError message={error.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="del-role-action-holder">
                    <div className="del-role-action">
                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={loading === LoadingType.pending}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('delete')}
                                enable={selectedData.id != ""}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={deleteCategoryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    );

    function deleteCategoryData() {
        if (selectedData.id != "") {
            switch (categoryId) {
                case 1:
                    dispatch(deleteSkill(selectedData.id));
                    break;
                case 2:
                    dispatch(deleteCertification(selectedData.id));
                    break;
                case 3:
                    dispatch(deleteCredentials(selectedData.id));
                    break;
                case 4:
                    dispatch(deleteEducationRequirement(selectedData.id));
                    break;
                case 5:
                    dispatch(deleteEquipment(selectedData.id));
                    break;
                default:
                    break;
            }
        }

    }

    function handleClearError() {
        dispatch(manageSkillListActions.clearRemoveState());
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default DeleteActionDialog;