import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { JobOrderAPI } from "../../../../apis";
import ApiError from "../../../../components/api_error";
import CustomStepIndicator from "../../../../components/custom_step_indicator/custom_step_indicator";
import { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { JOB_STATUS, LoadingType, THEME } from "../../../../enums";
import { Jobsicon, LinkIcon } from "../../../../icons";
import {
  AppError,
  ICompanyCertification,
  ICompanyCredential,
  ICompanyEducationRequirement,
  ICompanyRequiredEquipment,
  ICompanySkillSet,
  ICreateJob,
  ICreateJobForm,
  IJobOrderDetail,
  ISuccessProfileQA,
  JobOrderPlacement,
  JobOrderSource,
  ServiceCall,
  SuccessProfileQuestions,
  SuccessProfileQuestionStep,
  WorkCompCode,
} from "../../../../interfaces";
import { companyJobOrderActions } from "../../../../redux/companies/job_order/job_order_reducer";
import { departmentsDropdownListActions } from "../../../../redux/selectors/departments_list/departments_list_reducer";
import {
  getCompaniesDropdown,
  getContactsDropdownThunk,
  getDepartmentsDropdown,
  postSnackbarMessage,
  selectCompaniesDropdownList,
  selectCompaniesDropdownListState,
  selectContactsDropdownList,
  selectDepartmentsDropdownList,
  selectDepartmentsDropdownListState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { convertNumberToMoney, CustomButton, RegxPattern } from "../../../../utils";
import { lowBtnStyle, outlineBtnStyle } from "../../../../variables";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import "./job_form.scss";
import StepOneForm from "./step_one/step_one";
import StepSevenForm from "./step_seven/step_seven";
import StepSixForm from "./step_six/step_six";
import StepSuccessProfileQuestionsForm from "./step_success_profile_questions/step_success_profile_questions";
import { SingleContactState } from "../../../../redux/contacts/details/dashboard/contact_details/contact_details_reducer";
import { WorkCompConfiguration } from "../../../../interfaces/work_comp_configuration";

interface Props {
  title: string;
  successTitle: string;
  currentLoginUserName?: string;
  loading: LoadingType;
  error: AppError | null | undefined;
  job?: IJobOrderDetail;
  data?: {
    company_id?: string;
    department_id?: string;
    department_ids?: string[];
  };
  contactState?: SingleContactState,
  // workCompCodesList: WorkCompCode[];
  workCompCodesList: WorkCompConfiguration[];

  orderSourceList: JobOrderSource[];
  placementTypeList: JobOrderPlacement[];

  lastStep: number;
  successProfileStepsList: SuccessProfileQuestionStep[];
  successProfileQuestionsList: SuccessProfileQuestions[];
  successProfileGroup: { [key: number]: SuccessProfileQuestions[] };

  serviceCallList: Array<ServiceCall>;

  skillSetList: Array<ICompanySkillSet>;
  credentialsList: Array<ICompanyCredential>;
  certificationsList: Array<ICompanyCertification>;
  educationList: Array<ICompanyEducationRequirement>;
  requiredEquipmentList: Array<ICompanyRequiredEquipment>;

  onClose: () => void;
  onSubmit: (doc: { forceAdd: boolean; value: ICreateJob }) => void;
  onClearError: () => void;
  /// photo
  selectedPhoto: File | null;
  onPhotoChange: (value: File) => void;
  onRemovePhoto: () => void;
  /// resume
  selectedResume: File | null;
  onResumeChange: (value: File) => void;
  onRemoveResume: () => void;

  onGotoAgencyDashboard: () => void;
  onCreateNew: () => void;
  onGotoCreated: (name: string) => void;
}

const getJobFormData = (
  value: IJobOrderDetail,
  successProfileQuestions: { [key: string]: ISuccessProfileQA },
  serviceCalls: { [key: string]: boolean }
): ICreateJobForm => {
  return {
    company_id: value.company_id ?? null,
    department_id: value.department_id ?? null,
    job_title: value.job_title ?? "",
    regular_pay_rate: value.regular_pay_rate ?? 0,
    regular_bill_rate: value.regular_bill_rate ?? 0,
    overtime_pay_rate: value.overtime_pay_rate ?? 0,
    overtime_bill_rate: value.overtime_bill_rate ?? 0,
    double_time_pay_rate: value.double_time_pay_rate ?? 0,
    double_time_bill_rate: value.double_time_bill_rate ?? 0,
    status: value.status ?? JOB_STATUS.open,
    placement_type: value.placement_type ?? "",
    job_order_source: value.job_order_source ?? "",
    wc_code: value.wc_code ?? "",
    rating: value.rating ?? 0,
    requested: value.requested ?? 1,
    placed: value.placed ?? 0,
    over_under_placed: value.over_under_placed ?? 0,
    start_date: value.start_date ?? 0,
    end_date: value.end_date ?? 0,
    order_date: value.order_date ?? 0,
    account_manager: value.account_manager ?? "",
    recruiter: value.recruiter ?? "",
    attach_timecard: value.attach_timecard ?? false,
    bill_to_attn: value.bill_to_attn ?? "",
    work_comp_code: value.work_comp_code ?? "",
    contact_id_for_placement: value.contact_id_for_placement ?? "",
    ordered_by_contact_id: value.ordered_by_contact_id ?? null,
    report_to_contact_id: value.report_to_contact_id ?? null,
    company_name: value.company_name ?? "",
    department_name: value.department_name ?? "",
    company_address: value.company_address ?? "",
    contact_name_for_placement: value.contact_name_for_placement ?? "",
    ordered_by_contact_name: value.ordered_by_contact_name ?? "",
    report_to_contact_name: value.report_to_contact_name ?? "",
    street_address: value.street_address ?? "",
    city: value.city ?? "",
    state: value.state ?? "",
    zip_code: value.zip_code ?? "",
    success_profile_question_answers: value.success_profile_question_answers
      ? {
        ...successProfileQuestions,
        ...value.success_profile_question_answers.reduce(
          (a, v) => ({ ...a, [v.question_id]: v }),
          {}
        ),
      }
      : successProfileQuestions,
    placement_type_id: value.placement_type_id ?? null,
    job_order_source_id: value.job_order_source_id ?? null,
    placement_type_value: value.placement_type_value ?? "",
    job_order_source_value: value.job_order_source_value ?? "",
    service_calls: value.service_calls
      ? {
        ...serviceCalls,
        ...value.service_calls.reduce((a, v) => ({ ...a, [v]: true }), {}),
      }
      : serviceCalls,
    triple_time_pay_rate: value.triple_time_pay_rate ?? 0,
    triple_time_bill_rate: value.triple_time_bill_rate ?? 0,
    account_managers: value.account_managers ?? [],
    recruiters: value.recruiters ?? [],
    open_orders: value.open_orders ?? 0,
    open_under_placed: 0,
    certifications: value.certifications ?? [],
    credentials: value.credentials ?? [],
    education: value.education ?? [],
    professional_skills: value.professional_skills ?? [],
    safety_equipment: value.safety_equipment ?? [],
    job_status: value.job_status ?? "",
    shift_name: value.shift_name ?? "",
    start_time: value.start_time ?? 0,
    end_time: value.end_time ?? 0,
    break_in_minutes: value.break_in_minutes ?? 0,
    estimated_shift_hours: value.estimated_shift_hours ?? 0,
  };
};

function getInitialForm(
  successProfileQuestions: { [key: string]: ISuccessProfileQA },
  serviceCalls: { [key: string]: boolean },
  data?: {
    company_id?: string;
    department_id?: string;
    department_ids?: string[];
  },
  contactState?: SingleContactState
): ICreateJobForm {
  return {
    company_id: data && data.company_id ? data.company_id : null,
    department_id:
      data && data.company_id && contactState?.data?.department_ids ? contactState?.data?.department_ids[0]! : null,
    job_title: "",
    regular_pay_rate: 0,
    regular_bill_rate: 0,
    overtime_pay_rate: 0,
    overtime_bill_rate: 0,
    double_time_pay_rate: 0,
    double_time_bill_rate: 0,
    status: JOB_STATUS.open,
    placement_type: "",
    job_order_source: "",
    wc_code: "",
    branch_id: null,
    agency_id: null,
    rating: 0,
    requested: 1,
    placed: 0,
    over_under_placed: 0,
    start_date: 0,
    end_date: 0,
    order_date: 0,
    account_manager: "",
    recruiter: "",
    attach_timecard: false,
    bill_to_attn: "",
    work_comp_code: "",
    contact_id_for_placement: null,
    ordered_by_contact_id: null,
    report_to_contact_id: null,
    company_name: "",
    department_name: "",
    company_address: "",
    contact_name_for_placement: "",
    ordered_by_contact_name: "",
    report_to_contact_name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    success_profile_question_answers: successProfileQuestions,
    placement_type_id: null,
    job_order_source_id: null,
    placement_type_value: "",
    job_order_source_value: "",
    service_calls: serviceCalls,
    triple_time_pay_rate: 0,
    triple_time_bill_rate: 0,
    account_managers: [],
    recruiters: [],
    open_orders: 0,
    open_under_placed: 0,
    certifications: [],
    credentials: [],
    education: [],
    professional_skills: [],
    safety_equipment: [],
    job_status: "",
    shift_name: "",
    start_time: 0,
    end_time: 0,
    break_in_minutes: 0,
    estimated_shift_hours: 0,
  };
}

const JobForm: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companiesDropdownState = useAppSelector((state) =>
    selectCompaniesDropdownListState(state)
  );
  const companiesList = useAppSelector((state) =>
    selectCompaniesDropdownList(state)
  );
  const departmentsDropdownState = useAppSelector((state) =>
    selectDepartmentsDropdownListState(state)
  );
  const departmentsList = useAppSelector((state) =>
    selectDepartmentsDropdownList(state)
  );
  const contactsList = useAppSelector((state) =>
    selectContactsDropdownList(state)
  );
  const successProfileQuestions = props.successProfileQuestionsList.reduce(
    (a, v) => ({
      ...a,
      [v.id]: {
        question_id: v.id,
        answer: "",
        question_value: v.success_profile_question,
      },
    }),
    {}
  ) as { [key: string]: ISuccessProfileQA };
  const serviceCallsMap = props.serviceCallList.reduce(
    (a, v) => ({ ...a, [v.id]: false }),
    {}
  ) as { [key: string]: boolean };
  const [formState, setFormState] = useState<ICreateJobForm>(
    props.job
      ? getJobFormData(props.job, successProfileQuestions, serviceCallsMap)
      : getInitialForm(successProfileQuestions, serviceCallsMap, props.data, props.contactState)
  );
  const [currentStep, setCurrentStep] = useState<{
    label: string;
    value: number;
  }>({ label: t("step_option", { value: 1 }), value: 0 });
  const [toggleCompetitors, setToggleCompetitors] = useState<boolean>(false);

  const stepsList: Array<{ label: string; value: number }> = [
    { label: t("step_option", { value: 1 }), value: 0 },
    ...Array.from(Array(props.lastStep).keys()).map((doc, index) => ({
      label: t("step_option", { value: index + 2 }),
      value: index + 1,
    })),
    {
      label: t("step_option", { value: props.lastStep + 2 }),
      value: props.lastStep + 1,
    },
    {
      label: t("step_option", { value: props.lastStep + 3 }),
      value: props.lastStep + 2,
    },
  ];

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);
  const history = useHistory();
  // let currIndex = 0;
  // const indicators = document.getElementsByClassName("stp-container");
  // const progressElm = document.getElementsByClassName("progress-line")[0];
  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await JobOrderAPI.deleteJob(props.job?.id ?? "");
      if (Array.isArray(res.data)) {
        const message = res.data.join(", ");
        setSnackBarMessage(message);
      } else if (res.data) {
        setShowDeleteDialog(false);
        dispatch(postSnackbarMessage(res.data));
        history.push(AppRoutes.jobsPage);
      }
    } catch (e: any) {
      const message = e?.response?.data?.detail?.error?.join("\n");
      if (!!message) {
        setSnackBarMessage(message);
      }
    } finally {
      setDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  useEffect(() => {
    dispatch(getCompaniesDropdown());
    if (props.data && props.data.company_id) {
      dispatch(getDepartmentsDropdown(props.data.company_id));
    }
  }, []);

  useEffect(() => {
    if (formState.company_id && formState.company_id != "") {
      dispatch(getDepartmentsDropdown(formState.company_id));
    } else {
      dispatch(
        departmentsDropdownListActions.clearDepartmentsDropdownListState()
      );
      handleFieldChange("department_id", null);
    }

    if (
      (formState.company_id && formState.company_id != "") ||
      (formState.department_id && formState.department_id != "")
    ) {
      dispatch(
        getContactsDropdownThunk({
          company_id: formState.company_id!,
          department_id: formState.department_id
            ? formState.department_id
            : undefined,
        })
      );
    } else {
      dispatch(companyJobOrderActions.clearContactsByCompany());
      handleFieldChange("contact_id_for_placement", null);
      handleFieldChange("ordered_by_contact_id", null);
      handleFieldChange("report_to_contact_id", null);
    }
  }, [formState.company_id, formState.department_id]);

  function handleFieldChange(fieldId: string, value: any, parent?: string) {
    if (parent) {
      if (parent === "success_profile_question_answers") {
        setFormState({
          ...formState,
          [parent]: {
            ...formState[parent],
            [fieldId]: {
              ...formState[parent][fieldId],
              answer: value,
            },
          },
        });
        return;
      }
      if (parent === "service_calls") {
        setFormState({
          ...formState,
          [parent]: {
            ...formState[parent],
            [fieldId]: value,
          },
        });
        return;
      }
      setFormState({
        ...formState,
        [parent]: { ...formState[parent], [fieldId]: value },
      });
    } else {
      setFormState({ ...formState, [fieldId]: value });
    }
  }

  function getSubTitleByStep(): string {
    if (currentStep.value === props.lastStep + 2)
      return t("ok_lets_wrap_us_some_details_answer_fallowing_questions", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value === props.lastStep + 1)
      return t("okay_lets_wrap_us_some_details_select_the_service_calls", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value === 4)
      return t("last_set_of_questions_after_this_we_will_wrap", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value === 3)
      return t("talk_about_day_to_day_of_this_job", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value === 2)
      return t("talk_about_results_of_person_need_to_produce", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value != 0 && currentStep.value <= props.lastStep)
      return t("now_general_overview_by_answering_fallowing_questions", {
        name: props.currentLoginUserName,
      });
    if (currentStep.value === 0)
      return t("setup_job_order_by_answering_questions_below", {
        name: props.currentLoginUserName,
      });
    return t("setup_job_order_by_answering_questions_below", {
      name: props.currentLoginUserName,
    });
  }

  if (props.loading === LoadingType.succeeded) {
    return (
      <div className="cnt-f-container">
        <div className="cnt-f-header">
          <FormDialogTilteHeader
            title={props.successTitle}
            titleIcon={<Jobsicon width={"100%"} height={"100%"} />}
          />
        </div>
        <div className="cnt-f-content">
          <div className="saved-doc-holder">
            <DocSavedText>
              <span className="aknw-name">
                {t("good_job", { name: props.currentLoginUserName })}
              </span>
              <span className="info-txt">{`${t(
                "a_new_job_order_has_been_created_to_your_jobs_database"
              )}`}</span>
            </DocSavedText>
          </div>
        </div>
        <div className="cnt-f-actions">
          <div className="btn-cancel">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize", ...lowBtnStyle.text }}
              name={t("go_to_my_agency_dashboard")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onGotoAgencyDashboard}
              customStyle={lowBtnStyle.btn}
            />
          </div>
          <div className="btn-new">
            <CustomButton
              loading={false}
              textStyle={{
                textTransform: "capitalize",
                ...outlineBtnStyle.text,
              }}
              name={t("create_new", { name: t("job_order") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => {
                setFormState(
                  getInitialForm(successProfileQuestions, serviceCallsMap, props.data)
                );
                setCurrentStep({
                  label: t("step_option", { value: 1 }),
                  value: 0,
                });
                props.onCreateNew();
              }}
              customStyle={outlineBtnStyle.btn}
            />
          </div>

          <div className="btn-save">
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("go_to_this_newly_created", { name: t("job_order") })}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={() => props.onGotoCreated(formState.job_title)}
            />
          </div>
        </div>
      </div>
    );
  }

  const snackbarAction = () => {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackBarMessage(null)}
        >
          <Close fontSize="small" />
        </IconButton>
      </>
    );
  };

  return (
    <div className="cnt-f-container">
      <div className="cnt-f-header">
        <FormDialogTilteHeader
          title={props.title}
          subTitle={getSubTitleByStep()}
          titleIcon={<Jobsicon width={"100%"} height={"100%"} />}
        />
      </div>
      <div className="cnt-f-steps-indicator">
        <CustomStepIndicator
          selected={currentStep}
          stepsList={stepsList}
          onClick={(value) => {
          }}
        />
      </div>
      <div className="cnt-f-content">
        {getActiveStep()}
        {props.error && (
          <div className={"error-section"}>
            <ApiError
              message={props.error.message}
              onClose={props.onClearError}
            />
          </div>
        )}
      </div>
      <div className="cnt-f-actions">
        {props.job && (
          <div style={{ marginRight: "auto" }}>
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("delete_field", { name: t("job") })}
              enable={true}
              backgroundColor={THEME.statusInActiveColor}
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        )}
        <div className="btn-cancel">
          {currentStep.value != 0 && currentStep.value <= props.lastStep + 2 ? (
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("back")}
              enable={_isCurrentStepValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleBack}
            />
          ) : (
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("cancel")}
              enable={true}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={props.onClose}
            />
          )}
        </div>

        <div className="btn-save">
          {currentStep.value === props.lastStep + 2 ? (
            <CustomButton
              loading={props.loading === LoadingType.pending}
              textStyle={{ textTransform: "capitalize" }}
              name={props.job ? t("update") : t("finish")}
              enable={_isFormValid()}
              backgroundColor={
                props.error != null && props.error?.code === 409
                  ? THEME.secondaryColor6
                  : THEME.defaultHighLightColor
              }
              onClick={() =>
                props.onSubmit({
                  forceAdd:
                    props.error != null && props.error?.code === 409
                      ? true
                      : false,
                  value: {
                    ...formState,
                    job_title: formState?.job_title.trim(),
                    success_profile_question_answers: Object.keys(
                      formState.success_profile_question_answers
                    ).map(
                      (key) => formState.success_profile_question_answers[key]
                    ),
                    service_calls: Object.keys(formState.service_calls).filter(
                      (key) => formState.service_calls[key] === true
                    ),
                    regular_pay_rate: convertNumberToMoney(formState.regular_pay_rate, 'number') as number,
                  },
                })
              }
            />
          ) : (
            <CustomButton
              loading={false}
              textStyle={{ textTransform: "capitalize" }}
              name={t("next")}
              enable={_isCurrentStepValid()}
              backgroundColor={THEME.defaultHighLightColor}
              onClick={handleNext}
            />
          )}
        </div>
      </div>
      {showDeleteDialog && props.job && (
        <DeleteConfirmationDialog
          message={t("delete_job_message", {
            job: props.job?.job_title,
          })}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          deleting={deleting}
          deleteType={t("job")}
        />
      )}
      <Snackbar
        open={snackBarMessage !== null}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage}
        action={snackbarAction()}
      />
    </div>
  );

  function handleBack() {
    if (currentStep.value != 0 && currentStep.value <= props.lastStep + 2) {
      setCurrentStep(stepsList[currentStep.value - 1]);
      // (indicators[currIndex] as HTMLElement).style.transitionDelay = "0s";
      // indicators[currIndex].classList.remove("completed");
      // --currIndex;
      // (progressElm as HTMLElement).style.width = `${(currIndex / (indicators.length - 1)) * 100}%`;
    }
  }



  function handleNext() {
    if (currentStep.value < props.lastStep + 2) {
      if (currentStep.value === 0) {
        if (_isFormValid()) {
          setCheckValidationOnSubmit(false)
          setCurrentStep(stepsList[currentStep.value + 1]);
        } else {
          setCheckValidationOnSubmit(true)
        }
      } else {
        setCurrentStep(stepsList[currentStep.value + 1]);
      }
      // ++currIndex;
      // // Delay should be applied when adding class
      // (indicators[currIndex] as HTMLElement).style.transitionDelay = "0.6s";
      // indicators[currIndex].classList.add("completed");
      // (progressElm as HTMLElement).style.width = `${(currIndex / (indicators.length - 1)) * 100}%`;
    }
  }

  function getActiveStep() {
    if (currentStep.value === props.lastStep + 2) {
      return (
        <StepSevenForm
          formState={formState}
          skillSetList={props.skillSetList}
          credentialsList={props.credentialsList}
          certificationsList={props.certificationsList}
          educationList={props.educationList}
          requiredEquipmentList={props.requiredEquipmentList}
          handleFieldChange={handleFieldChange}
          validators={formValidators}
        />
      );
    }
    if (currentStep.value === props.lastStep + 1) {
      return (
        <StepSixForm
          formState={formState}
          workCompCodesList={props.workCompCodesList}
          serviceCalls={props.serviceCallList}
          handleFieldChange={handleFieldChange}
          validators={formValidators}
        />
      );
    }
    if (currentStep.value != 0 && currentStep.value <= props.lastStep) {
      return (
        <StepSuccessProfileQuestionsForm
          formState={formState}
          questions={props.successProfileGroup[getStepNumber(currentStep.value)] ?? []}
          handleFieldChange={handleFieldChange}
          validators={formValidators}
        />
      );
    }

    return (
      <StepOneForm
        formState={formState}
        companies={companiesList}
        departments={
          // props.data && props.data.department_ids && 
          departmentsList
          // ? departmentsList.filter((doc) =>
          //   props.data?.department_ids?.includes(doc.id)
          // )
          // : departmentsList
        }
        contactsList={contactsList}
        orderSourceList={props.orderSourceList}
        placementTypeList={props.placementTypeList}
        data={props.data}
        handleFieldChange={handleFieldChange}
        validators={formValidators}
        checkValidationOnSubmit={checkValidationOnSubmit}
      />
    );
  }

  function getStepNumber(value: number) {
    const nextStep = Object.keys(props.successProfileGroup)[value - 1];
    return nextStep;
  }

  function _isCurrentStepValid() {
    if (currentStep.value === 0) return true;
    return _isFormValid();
  }

  function _stepOneValid() {
    const { company_id, job_title, status } = formState;
    if (
      company_id === null ||
      company_id === "" ||
      job_title === "" ||
      status === ""
    ) {
      return false;
    }
    if (RegxPattern.username.test(job_title) === false) return false;
    return true;
  }

  function getLinkIcon() {
    return (
      <div className="cnt-f-link-icon">
        <LinkIcon width={"100%"} height={"100%"} />
      </div>
    );
  }

  function _isFormValid() {
    const { company_id, job_title, status, placement_type_id } = formState;
    switch (true) {
      case company_id === null || company_id === undefined || company_id === '':
        return false;
      case placement_type_id === null || placement_type_id === undefined || placement_type_id === '':
        return false;
      case job_title === null || job_title === undefined || job_title === '':
        return false;
      case status === null || status === undefined || status === '':
        return false;
      default:
        return true;
    }

  }

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "job_title": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.username.test(value.text) === false) return t("validators.enterValidName");
        return null;
      }
      case "company_id": {
        if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
        return null;
      }
      case "placement_type_id": {
        if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
        return null;
      }
      case "status": {
        if (value.text === "" || value.text === null || value.text === undefined) return t("validators.required");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default JobForm;
