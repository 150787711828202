import TableEmpty, { TableErrorHandler } from "../../../../../../components/table_empty/table_empty"
import { LoadingType, THEME } from "../../../../../../enums"
import { LeftArrowIcon, RightArrowIcon } from "../../../../../../icons"
import { SingleJobDocumentState } from "../../../../../../redux/job_order/details/document/document_list/document_list_reducer";
import VidePlayer from "../video_player/video_player"

interface Props {
    state: SingleJobDocumentState;
    cursor: number;
    data: any;
    cursorLeftClick: () => void;
    cursorRightClick: () => void;
    onRetry: () => void;
}

const VideoData: React.FC<Props> = (props) => {
    const { data, cursorLeftClick, cursorRightClick, cursor, onRetry, state } = props
    if (state.loading === LoadingType.failed)
        return (<div className="job-order-documents-videos-content-body no-data"><TableErrorHandler error={state?.error!} onRefresh={onRetry} /></div>)
    if (data.length > 0) {
        return (
            <div className="job-order-documents-videos-content-body data-found">
                <LeftArrowIcon onClick={cursorLeftClick} style={{ "cursor": cursor === 0 ? "auto" : "pointer" }} />
                <VidePlayer url={data[2 * cursor].file_path!} title={data[2 * cursor].file_name} id={data[2 * cursor]?.id} onRetry={onRetry} job_document_id={data[2 * cursor]?.job_order_id}/>
                {data.length > 1 + (2 * cursor) ? <VidePlayer url={data[(2 * cursor) + 1].file_path!} title={data[(2 * cursor) + 1].file_name} id={data[(2 * cursor) + 1]?.id} onRetry={onRetry} job_document_id={data[2 * cursor]?.job_order_id}/> : <></>}
                <RightArrowIcon onClick={cursorRightClick} style={{ "cursor": data.length > (2 * cursor) + 2 ? "pointer" : "auto", color: THEME.defaultHighLightColor }} />
            </div>
        )
    }
    return (
        (<div className="job-order-documents-videos-content-body no-data"><TableEmpty title={"No data found"} onClick={onRetry} /></div>)
    )
}

export default VideoData;