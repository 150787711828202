import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import locale from "date-fns/locale/en-IN";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FONT, THEME } from "../../enums";
import CustomText from "../../utils/custom_text";
import { convertDateToTimeStamp, getDateFromTimeStamp } from "../../variables";
import "./date_input.scss";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}
/* ----------------- DateInput --------------------- */

interface Props {
  value: any;
  secure?: any;
  placeholder: any;
  id: string;
  label?: string;
  labelIcon?: React.ReactNode;
  fieldStyles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  clearButtonMode?: any;
  editable?: any;
  placeholderTextColor?: string;
  required?: boolean;
  backgroundColor?: string;
  format?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
  minDate?: ParsableDate;
  setHoursToZero?: boolean;
  labelPosition?: "top" | "left" | "right";
  views?: Array<"year" | "month" | "date">,
  validations?: (value: { text: string; field: string }) => string | null;
  onChange: (id: string, value: any) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined,
  onError?: (
    error: React.ReactNode,
    value: string | number | object | Date | null | undefined
  ) => void;
  customStyle?: React.CSSProperties;
  customInputStyle?: React.CSSProperties;
  clearable?: boolean;
  renderDay?: ((day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => JSX.Element) | undefined
  checkValidationOnSubmit?: boolean,
  isJobForm?: boolean,
}

export function DateInput(props: Props) {
  const { t } = useTranslation();
  const {
    value,
    secure,
    placeholder,
    id,
    label,
    views,
    labelIcon,
    labelStyles,
    fieldStyles,
    clearButtonMode,
    editable,
    placeholderTextColor,
    required,
    format,
    backgroundColor,
    disablePast,
    disableFuture,
    minDate,
    setHoursToZero,
    onError,
    onKeyDown,
    customStyle,
    customInputStyle,
    clearable,
    renderDay,
    disabled,
    isJobForm
  } = props;
  const [error, setError] = useState<string | null>("");
  const [open, setOpen] = useState(false);

  const handleChange = (value: any) => {
    const text = value;
    props.onChange(props.id, text);
  };

  const handleValidation = (text: any) => {
    setError(
      props.validations ? props.validations({ text, field: props.id }) : null
    );
  };

  useEffect(() => {
    if (props.checkValidationOnSubmit !== undefined && props.checkValidationOnSubmit) {
      handleValidation(props.value)
    }
  }, [props.checkValidationOnSubmit, props.validations]);


  // const handleKeyDown = (event) => {
  //   if (event.key === 't' || event.key === 'T') {
  //     event.preventDefault();
  //     handleChange(convertDateToTimeStamp(new Date()));
  //   }
  // };

  // function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
  //   let isFutureDate = day > new Date();
  
  //   // Customize the style for future dates
  //   const style = isFutureDate
  //     ? {
  //         backgroundColor: 'whitesmoke',
  //       }
  //     : {};
  
  //   return React.cloneElement(dayComponent, {
  //     style,
  //   });
  // }
  

  return (
    <div
      style={{
        ...(isJobForm ? {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        } : {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }),
        ...fieldStyles,
        ...customStyle,
      }}
    >
      {label || labelIcon ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1.07em",
          }}
        >
          <CustomText
            customStyle={{
              ...styles.lableHeader,
              ...labelStyles,
            }}
          >
            {label + (required ? " *" : "")}
          </CustomText>
          {props.labelIcon ?? <div></div>}
        </div>
      ) : null}
      <div
        style={{
          ...styles.inputIconView,
          ...(editable === false ? { opacity: 0.4 } : null),
          ...(props.disabled ? { opacity: 0.4, } : null),
        }}
        className={`date-input-holder-div ${error ? "required" : ""}`}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <KeyboardDatePicker
            views={views}
            disablePast={disablePast ?? false}
            minDate={minDate}
            autoOk={true}
            disableToolbar={false}
            variant={"inline"}
            inputVariant={"outlined"}
            format={format ?? "MM/dd/yyyy"}
            margin={"normal"}
            value={value && value !== 0 ? getDateFromTimeStamp(value) : null}
            className={"date-input"}
            style={customInputStyle}
            disableFuture={disableFuture ?? false}
            size={"small"}
            onChange={(date, value) => {
              if (date && date.toString() !== "Invalid Date") {
                handleChange(convertDateToTimeStamp(date, setHoursToZero));
                handleValidation(convertDateToTimeStamp(date, setHoursToZero));
              }
              if (!value && !date) {
                handleChange(null);
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "from date",
              "aria-required": "true",
            }}
            onError={onError}
            error={error ? true : false}
            helperText={error}
            aria-required={"true"}
            // onKeyDown={handleKeyDown}
            onKeyDown={onKeyDown}
            renderDay={renderDay}
            placeholder={placeholder}
            disabled={disabled}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onClick={() => setOpen(true)}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  lableHeader: {
    fontSize: "0.729vw",
    fontFamily: FONT.lato, //Helvatica
    marginBottom: 10,
    // marginTop: 10,
    fontWeight: 900,
    color: THEME.defaultHighLightColor,
  },
  inputIconView: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    // textAlignVertical: "top",
    minHeight: 45,
    color: THEME.black,
    borderRadius: 10,
    paddingLeft: 15,
    fontFamily: FONT.lato,
    borderWidth: 1,
    borderBlockStyle: "solid",
    borderColor: THEME.inputBorderColor,
    fontSize: "0.625vw",
  },
  validatorError: {
    color: "red",
    fontSize: '0.629vw',
    fontFamily: FONT.lato,
  },

  //selector styles
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  innercontainer: {
    position: "relative",
    width: "100%",
    borderRadius: 10,
    borderWidth: 2,
    borderBlockStyle: "solid",
    borderColor: "rgb(244,244,244)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
  },
  labelTxt: {
    fontSize: "0.625vw",
    fontFamily: FONT.lato,
  },
  innervalue: {
    display: "flex",
    flexDirection: "row",
  },
  thumbnail: {
    width: 30,
    height: 25,
    marginTop: 10,
    marginRight: 10,
    borderRadius: 10,
  },
  triangle: {
    height: 6,
    width: 10,
    backgroundColor: "transparent",
    borderColor: "transparent",
  },

  multiInput: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  selectorText: {
    fontSize: "0.572vw",
    fontFamily: FONT.lato, //Helvatica
    color: THEME.black,
  },

  selectorPlaceholder: {
    fontSize: "0.572vw",
    fontFamily: FONT.lato, //Helvatica
  },

  //Image input

  imgInputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 10,
    padding: 10,
    borderWidth: 1,
    borderBlockStyle: "solid",
    borderColor: THEME.inputBorderColor,
    borderStyle: "dashed",
  },
  imgInstructions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgPreviewContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imgInputPlaceholder: {
    fontSize: "0.833vw",
    color: "#B2B2B2",
  },
  brwTxt: {
    textDecorationLine: "underline",
  },

  //Image & form input styles

  optionImg: {
    width: 20,
    height: 30,
    borderWidth: 1,
    borderBlockStyle: "solid",
    borderColor: "rgba(130,130,130,0.2)",
  },
  optionImgIcon: {
    width: 20,
    height: 20,
  },

  optionMenuContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: THEME.white,
  },

  optionMenu: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: THEME.white,
    borderWidth: 1,
    borderBlockStyle: "solid",
    borderColor: THEME.inputBorderColor,
  },

  optionBtn: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
};
