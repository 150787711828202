import { useState } from "react";
import "./outline_input.scss";
import { CircularProgress } from "@material-ui/core";
import { Done } from '@material-ui/icons';
import { THEME } from "../../enums";
import { StringMap } from "i18next";

interface Props {
    id: string,
    label: string,
    type: string,
    value: string,
    needValidation?: boolean,
    pattern?: any,
    suffix?: React.ReactNode,
    setValue: (fieldId: any, value: any) => void
    handlePasswordVisiblity?: () => void;
}

const OutlineInput = (props: Props) => {

    const [isValid, setIsValid] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    function getLoading() {
        return (
            <CircularProgress
                style={{
                    ...{ marginLeft: 10, color: THEME.primaryColor },
                }}
                size={'0.833333vw'}
            />
        )
    }

    function handleChange(e) {
        props.setValue(props.id, e.target.value);
        if (e.target.value.search(props.pattern) != -1)
            setIsValid(true);
        else
            setIsValid(false);
    }

    function validation() {
        if (isValid)
            return <Done style={{ color: "green" }} />;
        if (!isValid && props.value)
            return getLoading();
    }


    return (
        <div className="outline-container">
            <div className="outline-border">
                <input type={props.type} value={props.value} onChange={handleChange} />
                <div className="suffix-holder" onClick={props.handlePasswordVisiblity} style={{cursor: "pointer"}}>
                    {props.suffix}
                </div>
                {props.needValidation ? validation() : <></>}
            </div>
            <div className="outline-label">
                <label> {props.label} </label>
            </div>
        </div>
    )
}

export default OutlineInput;