import { PayrollStatus } from "../enums/back_office";

export class PayrollBatch {
    bank_id: string;
    bank_name: string;
    check_count: number;
    check_date: number;
    check_message: string;
    check_sort_options: string[];
    created_by: string;
    batch_description: string;
    ending_check_number: number;
    id: string;
    make_all_checks_live: boolean;
    modified_by: string;
    modified_date: number;
    preview_check: boolean;
    run_type_id: string;
    run_type: string;
    starting_check_number: number;
    status: PayrollStatus;
    time_cards: string[];
    transaction_count: number;
    week_accounting_id: string;
    week_ending_date: number;
    created_date: number;
    batch_number: string;
    generate_original_pay_units: string;
    date_ach_generated: number;
    date_printed: number;
    date_processed: number;
  
  }
  
  export interface IPayCheck {
    modified_by: string;
    created_by: string;
    modified_date: number;
    week_accounting_id: string;
    payroll_batch_id: string;
    talent_id: string;
    employee_name: string;
    check_date: number;
    check_number: number;
    gross_amount: number;
    total_deduction: number;
    total_tax_amount: number;
    net_amount: number;
    date_cleared: number;
    check_status: string;
    check_delivery: string;
    id:string;
  }

export class PayCheck {
    week_accounting_id: string;
    payroll_batch_id: string;
    talent_id: string;
    employee_name: string;
    check_date: number;
    check_number: number;
    gross_amount: number;
    total_deduction: number;
    total_tax_amount: number;
    net_amount: number;
    date_cleared: number;
    check_status: string;
    check_delivery: string;
    id:string;
}
export class TransactionSummary {
    week_accounting_id: string;
    payroll_batch_id: string;
    talent_id: string;
    talent_name: string;
    talent_ssn: string;
    company_id: string;
    company_name: string;
    week_ending_date: number;
    total_pay: number;
}