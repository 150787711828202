import React, { PureComponent, RefObject, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import CustomText from './custom_text';
import { DEVICE_TYPE, FONT, THEME } from '../enums';
import { IconProvider } from './icon_provider';
import { formValidators } from './form_validations';
import { getLabelText } from './helpers';

/* ----------------- FormInput --------------------- */


interface Props {
    value: any,
    secure?: any,
    placeholder: any,
    keyboardtype?: any,
    type: React.HTMLInputTypeAttribute | undefined,
    id: string,
    pattern?: RegExp,
    label?: string,
    labelPosition?: "top" | "left" | "right",
    labelIcon?: React.ReactNode,

    clearButtonMode?: any,
    disabled?: boolean,
    placeholderTextColor?: string,
    confirmPassword?: string,
    multiline?: boolean,
    numberOfLines?: number,
    required?: boolean,
    backgroundColor?: string,
    maxLength?: number,
    prefix?: React.ReactNode,
    suffix?: React.ReactNode,
    step?: string | number | undefined,
    min?: string | number | undefined,
    max?: string | number | undefined,
    validations?: (value: { text: string, field: string }) => string | null,
    onChange: (id: string, value: any) => void,
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined,
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined,
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined,
    fieldStyles?: React.CSSProperties,
    labelStyles?: React.CSSProperties,
    labelContainerStyles?: React.CSSProperties,
    customStyle?: React.CSSProperties,
    customInputStyle?: React.CSSProperties,
    wrapperStyles?: React.CSSProperties,
    checkValidationOnSubmit?: boolean,
    onClick?: () => void,
    inputRef?: RefObject<HTMLInputElement>,
    tabIndex?: number
}

export function FormInput(props: Props) {
    const { t } = useTranslation();
    const {
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        labelPosition,
        label,
        labelIcon,
        labelStyles,
        fieldStyles,
        clearButtonMode,
        disabled,
        placeholderTextColor,
        multiline,
        numberOfLines,
        required,
        backgroundColor,
        maxLength,
        prefix,
        suffix,
        onKeyDown,
        onKeyUp,
        onBlur,
        customStyle,
        customInputStyle,
        labelContainerStyles,
        wrapperStyles,
        checkValidationOnSubmit,
        onClick,
        inputRef,
        tabIndex
    } = props;
    const [error, setError] = useState<string | null>('');

    const handleChange = (value: any) => {
        const text = value;
        props.onChange(props.id, text);
    }
    const handleValidation = (text: any) => {
        setError(props.validations ? props.validations({ text, field: props.id }) : null);
    }

    useEffect(() => {
        if (checkValidationOnSubmit !== undefined && checkValidationOnSubmit) {
            handleValidation(props.value)
        }
    }, [checkValidationOnSubmit, props.validations])

    if (prefix || suffix) {

        return (
            <div
                style={{
                    ...{ display: 'flex', flexDirection: 'column' },
                    ...(labelPosition === "top") && { flexDirection: 'column' },
                    ...(labelPosition === "left") && { flexDirection: 'row', alignItems: 'center' },
                    ...(labelPosition === "top") && { flexDirection: 'row-reverse', alignItems: 'center' },
                    ...fieldStyles,
                    ...customStyle,
                }}>
                {labelComponent()}
                <div
                    style={{
                        ...styles.inputIconView,
                        ...(disabled ? { opacity: 0.6, } : null),
                        ...wrapperStyles,
                    }}
                >
                    <div className="form-input-holder">
                        <div className="prefix-holder">
                            {prefix}
                        </div>
                        <input
                            className={"form-input-tag"}
                            style={{
                                ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.white, minWidth: '8.895833333333334vw' },

                                ...customInputStyle,
                            }}
                            type={type}
                            placeholder={t(placeholder)}
                            onChange={event => {
                                handleChange(event.target.value);
                                handleValidation(event.target.value);
                            }}
                            value={value ?? ''}
                            autoCapitalize={"none"}
                            autoFocus={false}
                            disabled={disabled}
                            maxLength={maxLength}
                            onKeyDown={onKeyDown}
                            onKeyUp={onKeyUp}
                            onBlur={onBlur}
                            step={props.step}
                            min={props.min}
                            max={props.max}
                            ref={inputRef}
                            tabIndex={tabIndex}
                        />
                        <div className="suffix-holder" onClick={onClick}>
                            {suffix}
                        </div>

                    </div>
                    {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
                </div>

            </div>
        )
    }



    return (
        <div
            style={{
                ...{ display: 'flex', flexDirection: 'column' },
                ...(labelPosition === "top") && { flexDirection: 'column' },
                ...(labelPosition === "left") && { flexDirection: 'row', alignItems: 'center' },
                ...(labelPosition === "top") && { flexDirection: 'row-reverse', alignItems: 'center' },
                ...fieldStyles,
                ...customStyle
            }}
        >
            {labelComponent()}
            <div
                style={{
                    ...styles.inputIconView,
                    ...(disabled ? { opacity: 0.6, } : null),
                    ...wrapperStyles,
                }}
            >
                {multiline ?
                    <textarea
                        className={"form-input"}
                        style={{
                            ...{
                                minWidth: '100%',
                                maxWidth: '100%',
                                boxSizing: 'border-box',
                                minHeight: 80,
                                maxHeight: numberOfLines ? numberOfLines * 30 : 45
                            },
                            ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.white },
                            ...customInputStyle,
                            borderColor: error ? 'red' : 'hsl(0, 0%, 80%)'
                        }}
                        placeholder={t(placeholder)}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={value}
                        autoCapitalize={"none"}
                        autoFocus={false}
                        disabled={disabled}

                    />
                    : <input
                        className={"form-input"}
                        style={{
                            ...{ backgroundColor: backgroundColor ? backgroundColor : THEME.white },
                            ...customInputStyle,
                            borderColor: error ? 'red' : THEME.inputoutlineColor

                        }}
                        type={type}
                        placeholder={t(placeholder)}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={value ?? ''}
                        autoCapitalize={"none"}
                        autoFocus={false}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        onBlur={onBlur}
                        step={props.step}
                        min={props.min}
                        max={props.max}
                    />}
                {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
            </div>

        </div>
    );




    function labelComponent() {
        if (label || labelIcon) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ...labelPosition === 'left' && { marginRight: '1em', width: '-webkit-fill-available' },
                        ...labelPosition === 'right' && { marginLeft: '1em', width: '-webkit-fill-available' },
                    }}
                >
                    <CustomText
                        customStyle={{
                            ...styles.lableHeader,
                            ...labelStyles,
                        }}
                    >
                        {label && getLabelText(label, { position: labelPosition, required: required })}
                    </CustomText>
                    {props.labelIcon ?? <div></div>}
                </div>
            )
        }
        return null;
    }


}


const boxWidth = 70;

/* ----------------- Radio button --------------------- */
interface CustomRadioButtonProps {
    selected: boolean,
    label: string | React.ReactNode,
    disabled?: boolean,
    highlightColor?: string,
    customStyle?: React.CSSProperties,
    labelStyle?: React.CSSProperties,
    onClick: (value: boolean) => void,
}
export function CustomRadioButton(props: CustomRadioButtonProps) {
    const { selected, label, disabled, highlightColor, customStyle, labelStyle, onClick } = props;

    return (
        <div
            style={{
                ...customStyle
            }}
            onClick={() => {
                if (disabled) return;
                onClick(!selected);
            }}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',

            }}>
                <div style={{
                    ...{
                        display: 'flex',
                        height: '1.7582417582417582vw',
                        width: '1.7582417582417582vw',
                        borderRadius: '1.7582417582417582vw',
                        borderWidth: 0.3,
                        borderStyle: 'solid',
                        borderColor: THEME.inputoutlineColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 10,
                        cursor: 'pointer'
                        // backgroundColor: pressed ? THEME.inputBorderColor : (selected ? THEME.white : "#D8D8D8")
                    }
                }}>
                    {
                        (selected && !disabled) ?
                            <div style={{
                                height: '1.1721611721611722vw',
                                width: '1.1721611721611722vw',
                                borderRadius: '1.1721611721611722vw',
                                backgroundColor: highlightColor ? highlightColor : THEME.primaryColor,
                            }} />
                            : null
                    }
                </div>
                {getLabel()}
            </div >

        </div >
    );

    function getLabel() {
        if (typeof label === "string") {
            return (
                <div style={{}}>
                    <CustomText customStyle={{ ...{ textTransform: 'capitalize', color: selected ? THEME.primaryColor : THEME.black }, ...labelStyle }}>{label}</CustomText>
                </div>
            )
        }
        return label;
    }
}




const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: '0.729vw',
        fontFamily: FONT.lato, //Helvatica
        marginBottom: 5,
        marginTop: 10,
        fontWeight: 900,
        color: THEME.defaultHighLightColor
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        // textAlignVertical: "top",
        minHeight: 45,
        color: THEME.black,
        borderRadius: 10,
        paddingLeft: 15,
        fontFamily: FONT.lato,
        borderWidth:'0.3px',
        borderBlockStyle: 'solid',
        borderColor: THEME.inputoutlineColor,
        fontSize: '0.625vw'
    },
    validatorError: {
        color: 'red',
        padding: 5,
        fontSize: 10,
        fontFamily: FONT.lato

    },

    //selector styles
    container: {
        display: 'flex', flexDirection: 'column',
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
    },
    // label: {
    //     position: 'absolute',
    //     top: -8,
    //     left: 20,
    //     backgroundColor: THEME.white,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    // },
    labelTxt: {
        fontSize: '0.625vw',
        fontFamily: FONT.lato
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },

    multiInput: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    selectorText: {
        fontSize: '0.572vw',
        fontFamily: FONT.lato, //Helvatica
        color: THEME.black
    },

    selectorPlaceholder: {
        fontSize: '0.572vw',
        fontFamily: FONT.lato, //Helvatica
        // color: THEME.PLACEHOLDER
    },

    //Image input

    imgInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: 10,
        padding: 10,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.inputBorderColor,
        borderStyle: 'dashed'
    },
    imgInstructions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgPreviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    imgInputPlaceholder: {
        fontSize: '0.833vw', color: '#B2B2B2'
    },
    brwTxt: {
        textDecorationLine: 'underline'
    },

    //image preview styles

    imgPreviewItem: {
        position: 'relative',
        display: 'flex',
        width: boxWidth,
        height: boxWidth + 10,
        marginTop: 8,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.inputBorderColor,
        borderRadius: 5,
        marginRight: 10
    },

    addMoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: boxWidth - 20,
        height: boxWidth - 20,
        marginTop: 20,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.inputBorderColor,
        borderRadius: 5
    },


    //Image & form input styles

    optionImg: {
        width: 20, height: 30, borderWidth: 1, borderBlockStyle: 'solid', borderColor: 'rgba(130,130,130,0.2)'
    },
    optionImgIcon: {
        width: 20, height: 20
    },

    optionMenuContainer: {
        // position: 'absolute',
        // bottom: -65,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        paddingTop: 5, paddingBottom: 5,
        backgroundColor: THEME.white,
    },

    optionMenu: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.white,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.inputBorderColor,
        // borderRadius: 10,
        // shadowColor: 'rgba(0,0,0,0.2)',
        // shadowOffset: { width: 0, height: 0 },
        // shadowOpacity: 0.7,
        // shadowRadius: 2,
        // elevation: 5
    },

    optionBtn: {
        paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5
    },

});

// export default withTranslation()(FieldForm) ;