import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, IUnEmploymentClaim ,IUnEmploymentClaimTalentData} from "../../../../interfaces";
import * as thunk from "./unemployment_claims_list_thunk";

export interface UnEmploymentClaimsListState extends EntityState<IUnEmploymentClaim> {
    error?: AppError | null,
    loading: LoadingType,
    saveResponse: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },
    documentUpload: {
        error?: AppError | null,
        loading: LoadingType,
        response: any
    },
    details: {
        error?: AppError | null,
        loading: LoadingType,
        data: IUnEmploymentClaimTalentData
    },
}

const unEmploymentClaimsListAdapter: EntityAdapter<IUnEmploymentClaim> = createEntityAdapter<IUnEmploymentClaim>({
    selectId: (doc) => doc.id,
})

const initialState: UnEmploymentClaimsListState = unEmploymentClaimsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    saveResponse: {
        error: null,
        loading: LoadingType.idle,
        response: "",
    },
    documentUpload: {
        error: null,
        loading: LoadingType.idle,
        response: []
    },
    details: {
        error: null,
        loading: LoadingType.idle,
        data: {} as IUnEmploymentClaimTalentData
    },
})


export const unEmploymentClaimsListSlice = createSlice({
    name: 'unEmploymentClaimsList',
    initialState: initialState,
    reducers: {
        clearunEmploymentClaimsListError(state){
            return {...state, error: null};
        },
        clearUnEmploymentClaimsListState(state){
            return unEmploymentClaimsListAdapter.removeAll({...state, loading: LoadingType.idle, error: null});
        },
        clearUnEmploymentClaimSaveState(state) {
            return {
                ...state, saveResponse: {
                    error: null,
                    loading: LoadingType.idle,
                    response: "",
                }
            }
        },
        clearUnEmploymentClaimDocState(state) {
            return {
                ...state, documentUpload: {
                    error: null,
                    loading: LoadingType.idle,
                    response: "",
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getAllUnEmploymentClaimsList.pending, (state, action) => ({ ...state, loading: state.ids.length === 0 ? LoadingType.pending : state.loading, error: null, }))
            .addCase(thunk.getAllUnEmploymentClaimsList.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getAllUnEmploymentClaimsList.fulfilled, (state, action) => unEmploymentClaimsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.addUnEmploymentClaim.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.addUnEmploymentClaim.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.addUnEmploymentClaim.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateUnEmploymentClaim.pending, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.updateUnEmploymentClaim.rejected, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.updateUnEmploymentClaim.fulfilled, (state, action) => ({ ...state, saveResponse: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getUnEmploymentClaimDocs.pending, (state, action) => ({ ...state, documentUpload: { ...state.saveResponse, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getUnEmploymentClaimDocs.rejected, (state, action) => ({ ...state, documentUpload: { ...state.saveResponse, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getUnEmploymentClaimDocs.fulfilled, (state, action) => ({ ...state, documentUpload: { ...state.saveResponse, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.getUnEmploymentClaimDetailsByTalentId.pending, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.pending, error: null } }))
            .addCase(thunk.getUnEmploymentClaimDetailsByTalentId.rejected, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.failed, error: action.payload } }))
            .addCase(thunk.getUnEmploymentClaimDetailsByTalentId.fulfilled, (state, action) => ({ ...state, details: { ...state.details, loading: LoadingType.succeeded, error: null, data: action.payload } }))


            .addDefaultCase(state => ({ ...state }));
    }
});

export const unEmploymentClaimsListActions = unEmploymentClaimsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = unEmploymentClaimsListAdapter.getSelectors();
export const selectState = (state: UnEmploymentClaimsListState) => state;
export const selectLoading = (state: UnEmploymentClaimsListState) => state.loading;
export const selectError = (state: UnEmploymentClaimsListState) => state.error;
export const selectUnEmploymentClaimSave = (state: UnEmploymentClaimsListState) => state.saveResponse;
export const selectUnEmploymentClaimDocUpload = (state:UnEmploymentClaimsListState)=>state.documentUpload;
export const selectDetailsState=(state:UnEmploymentClaimsListState)=>state.details;

