import { useEffect, useRef, useState } from 'react';
import { LoadingType, THEME } from '../../../../../enums';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { IJobServiceProfile, IJobServiceUpdateProfile, IShift } from '../../../../../interfaces';
import { deleteCompanyShift, getCompanyServiceProfile, getCompanyShifts, saveCompanyShift, _saveCompanyServiceProfile } from '../../../../../redux/companies';
import { manageCompanyProfileActions } from '../../../../../redux/companies/universal_directory/company_profile/company_profile_reducer';
import { selectCompanyServiceProfile, selectRemove_state, selectShiftSave_State, selectShiftsData } from '../../../../../redux/companies/universal_directory/company_profile/company_profile_selector';
import {
    getJobServiceProfile,
    postSnackbarMessage,
    updateJobServiceProfile,
    selectJobServiceProfileByIdState,
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store';
import { AppRoutes } from '../../../../../routes';
import { convertNumberToMoney, CustomButton, formatToMoney, FormInput, moneyFormat, SpinnerScreen } from '../../../../../utils';
import './service_profile.scss';
import { cloneDeep } from 'lodash';
import {
    selectCheckDeliveryList,
    selectCheckDeliveryState,
    selectOnboardingStepState,
    selectOnboardingStepList,
    selectServiceCallState,
    selectServiceCallList,
    getOnboardingSteps,
    getCheckDelivery,
    getServiceCalls
} from '../../../../../redux/admin_center';
import CustomFormSelect from '../../../../../components/form_selector/form_select';
import { AddCircleOutline, Timer } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import ToggleSwitch from '../../../../../components/toggle_switch/toggle_switch';
import { TimeInput } from '../../../../../components/time_input/time_input';
import { manageJobActions } from '../../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer';
import TimePickerComponent from '../../../../../components/time_picker/time_picker';
import { convertStringTimeToTimestamp, extractTimeFromTimestamp } from '../../../../../variables';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    }
}));


interface Props extends RouteComponentProps<any> { }
const PageLoading = (props) => {
    return (
        <div className="table-loading">
            <SpinnerScreen />
        </div>
    );
}

const weekDays = [
    { id: "SUNDAY", label: "Sunday" },
    { id: "MONDAY", label: "Monday" },
    { id: "TUESDAY", label: "Tuesday" },
    { id: "WEDNESDAY", label: "Wednesday" },
    { id: "THURSDAY", label: "Thursday" },
    { id: "FRIDAY", label: "Friday" },
    { id: "SATURDAY", label: "Saturday" },
];

const payCycles = [
    { id: "WEEKLY", label: "Weekly" },
    { id: "BIWEEKLY", label: "Bi-Weekly" },
    { id: "SEMI_MONTHLY", label: "Semi-Monthly" },
    { id: "MONTHLY", label: "Monthly" },
];

const payPeriods = [
    { id: "PAY_PERIOD_1", label: "Pay Period 1" },
    { id: "PAY_PERIOD_2", label: "Pay Period 2" },
    { id: "PAY_PERIOD_3", label: "Pay Period 3" },
    { id: "PAY_PERIOD_4", label: "Pay Period 4" },
];

const tableHeader: any = [
    { title: 'Shift Name', code: 'shift_name' },
    { title: 'Start', code: 'start_time' },
    { title: 'End', code: 'end_time' },
    { title: 'Break', code: 'break_time' },
    { title: 'Estimated Shift Hours', code: 'estimated_shift_hours' },
    { title: 'Actions', code: 'actions' },
];


const JobServiceProfilePage: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { history, location, match } = props;
    const { params } = match;
    const serviceProfileState = useAppSelector(state => selectJobServiceProfileByIdState(params.id)(state));
    const updateState = serviceProfileState.update;
    const shiftsData = useAppSelector((state) => selectShiftsData(state));
    const [profileData, setProfileData] = useState({} as IJobServiceUpdateProfile);
    const [saveInitiated, setSaveInitiated] = useState(false);
    const [loader, setIsLoader] = useState(true);
    const checkDeliveryState = useAppSelector((state) => selectCheckDeliveryState(state));
    const checkDeliveryList = useAppSelector((state) => selectCheckDeliveryList(state));
    const onboardingStepState = useAppSelector((state) => selectOnboardingStepState(state));
    const onboardingStepList = useAppSelector((state) => selectOnboardingStepList(state));
    const serviceCallState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [availabelShifts, setAvailableShifts]: any = useState([]);
    const [selectedShift, setSelectedShift]: any = useState(null);
    const [jobId, setJobId]: any = useState("");
    const shiftSaveResponse = useAppSelector((state) => selectShiftSave_State(state));

    useEffect(() => {
        dispatch(getOnboardingSteps());
        dispatch(getCheckDelivery());
        dispatch(getServiceCalls());
        if (params && params.id) {
            dispatch(manageCompanyProfileActions.clearState());
            setJobId(params.id);
            //dispatch(getJob(params.id));
            dispatch(getJobServiceProfile(params.id));
        }
    }, []);

    useEffect(() => {
        if (serviceProfileState && serviceProfileState.data) {
            updateForm(serviceProfileState.data);
            setIsLoader(false);
        }
    }, [serviceProfileState.data]);

    useEffect(() => {
        if (shiftsData.data && shiftsData.data) {
            setAvailableShifts(shiftsData.data);
        }
    }, [shiftsData])

    useEffect(() => {
        if (updateState.loading == LoadingType.succeeded) {
            dispatch(postSnackbarMessage(updateState.response ?? null));
            dispatch(manageJobActions.clearManageJobState(["photo", "resume"]));
        }
        return () => { }
    }, [updateState]);

    /* useEffect(() => {
         if (shiftSaveResponse.loading == LoadingType.succeeded) {
             dispatch(getCompanyShifts(companyId));
             dispatch(postSnackbarMessage(shiftSaveResponse.response ?? null));
             dispatch(manageCompanyProfileActions.clearShiftSaveState());
             setShowSaveDialog(false);
 
         }
         return () => { }
     }, [shiftSaveResponse]);
     */

    const handleSaveClick = () => {
        const startTimeTimeStampValue = convertStringTimeToTimestamp(profileData?.start_time!)
        const endTimeTimeStampValue = convertStringTimeToTimestamp(profileData?.end_time!)
        setSaveInitiated(true);
        dispatch(updateJobServiceProfile({
            job_id: params.id,
            data: {
                ...profileData,
                mileage_rate: convertNumberToMoney(profileData.mileage_rate, 'number') as number,
                start_time: startTimeTimeStampValue!/1000,
                end_time: endTimeTimeStampValue!/1000
            }
        }));
    }

    function handleSaveCloseDialog() {
        setShowSaveDialog(false);
    }
    function handleDeleteCloseDialog() {
        setShowDeleteDialog(false);
    }
    function isLoading() {
        return serviceProfileState.loading === LoadingType.pending || loader
            || checkDeliveryState.loading === LoadingType.pending
            || onboardingStepState.loading === LoadingType.pending
            || serviceCallState.loading === LoadingType.pending;
    }

    function updateForm(value: IJobServiceProfile) {
        const startTime = extractTimeFromTimestamp(value?.start_time!)
        const endTime = extractTimeFromTimestamp(value?.end_time!)
        setProfileData({
            ...value,
            onboarding_steps: value.onboarding_steps ? [...value.onboarding_steps] : [],
            check_delivery: value.check_delivery ? [...value.check_delivery] : [],
            service_calls: value.service_calls ? [...value.service_calls] : [],
            mileage_rate: value.mileage_rate ? convertNumberToMoney(value.mileage_rate, 'string') as string : '',
            start_time: startTime ?? '',
            end_time: endTime ?? ''
        });
    }
    const handleClearTime = (id: string) => {
        if (id === "start_time") {
            setProfileData({ ...profileData, start_time: "" })
        }
        if (id === "end_time") {
            setProfileData({ ...profileData, end_time: "" })
        }
    }

    return (
        <>
            {
                isLoading() ? <PageLoading /> :
                    <div className="ac-service-profile-container">
                        <div className="ac-service-profile-content">

                            <div className='ac-sp-field-row' style={{ marginTop: "1em" }}><p className='ac-service-text'>ONBOARDING STEPS</p></div>

                            {onboardingStepList && onboardingStepList.map((data: any, index: number) => {
                                return (

                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.onboarding}
                                            checked={((profileData && profileData.onboarding_steps && profileData?.onboarding_steps?.filter((step: any) => step === data.id).length > 0) ? true : false)}
                                            onChange={(value) => {
                                                const onboarding_steps = profileData.onboarding_steps ? profileData.onboarding_steps : [];
                                                if (value) {
                                                    onboarding_steps.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        onboarding_steps: onboarding_steps
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        onboarding_steps: onboarding_steps.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>

                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text'>CHECK DELIVERY</p></div>
                            {checkDeliveryList && checkDeliveryList.map((data: any, index: number) => {
                                return (
                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.check_delivery}
                                            checked={((profileData?.check_delivery && profileData?.check_delivery?.filter((check: any) => check === data.id).length > 0) ? true : false)}
                                            onChange={(value) => {
                                                const checkDelivery = profileData.check_delivery ? profileData.check_delivery : [];
                                                if (value) {
                                                    checkDelivery.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        check_delivery: checkDelivery
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        check_delivery: checkDelivery.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>
                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text'>SERVICE CALLS</p></div>
                            {serviceCallList && serviceCallList.map((data: any, index: number) => {
                                return (
                                    <div key={index} className='ac-service-field' style={{ display: "inline-flex" }}>
                                        <ToggleSwitch label={data.service_call}
                                            checked={((profileData?.service_calls && profileData?.service_calls?.filter((call: any) => call === data.id).length > 0) ? true : false)}
                                            onChange={(value) => {
                                                const service_calls = profileData.service_calls ? profileData.service_calls : [];
                                                if (value) {
                                                    service_calls.push(data.id);
                                                    setProfileData({
                                                        ...profileData,
                                                        service_calls: service_calls
                                                    })
                                                }
                                                else {
                                                    setProfileData({
                                                        ...profileData,
                                                        service_calls: service_calls.filter((step: any) => step !== data.id)
                                                    })
                                                }
                                            }} />
                                    </div>
                                )
                            })}
                            <hr />
                            <div className='ac-sp-field-row'>
                                <div className='ac-service-field'>
                                    <label className='field-label-text'>Timeclock</label>
                                    <br></br>
                                    <ToggleSwitch label={t("active")}
                                        checked={profileData.time_clock}
                                        onChange={(value) => {
                                            setProfileData({
                                                ...profileData,
                                                time_clock: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className='ac-service-field'>
                                    <FormInput
                                        id={'mileage_rate'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    mileage_rate: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        prefix={<span>$</span>}
                                        type={"text"}
                                        value={profileData?.mileage_rate!}
                                        label={"Mileage Rate"}
                                        onBlur={formatToMoney}
                                        onKeyDown={formatToMoney}
                                        onKeyUp={formatToMoney}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text1'>Payroll Options</p></div>
                            <div className='ac-sp-field-row'>
                                <div className="ac-sc-fields">
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"week_ending_day"}
                                        label={"Week Ending Day"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.week_ending_day : ''}
                                        list={weekDays.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    week_ending_day: value,
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                                <div className="ac-sc-fields" >
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"pay_cycle"}
                                        label={"Pay Cycle"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.pay_cycle : ''}
                                        list={payCycles.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    pay_cycle: value
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                                <div className="ac-sc-fields" >
                                    <CustomFormSelect
                                        customStyle={{ width: '-webkit-fill-available' }}
                                        name={"pay_period"}
                                        label={"Pay Period"}
                                        placeholder={"Select"}
                                        value={profileData.payroll_options ? profileData.payroll_options.pay_period : ''}
                                        list={payPeriods.map(doc => ({ label: doc.label, value: doc.id }))}
                                        onChange={(value) => {
                                            setProfileData((prevState) => ({
                                                ...prevState,
                                                payroll_options: {
                                                    ...prevState.payroll_options,
                                                    pay_period: value
                                                },
                                            }));
                                        }
                                        }
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className='ac-sp-field-row'><p className='ac-service-text1'>Job Shift</p></div>
                            <div className='ac-sp-field-row'>
                                <div className='ac-sc-fields'>
                                    <FormInput
                                        id={'shift_name'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    shift_name: value,
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"text"}
                                        value={profileData.shift_name}
                                        label={"Shift Name"}
                                    />
                                </div>
                                <div className='ac-sc-fields-time'>
                                    {/* <TimeInput
                                        id={'start_time'}
                                        onChange={(fieldId, value) => {
                                            setProfileData((prevState: any) => ({
                                                ...prevState,
                                                start_time: value,
                                            }));
                                        }}
                                        required={true}
                                        placeholder={''}
                                        value={profileData.start_time}
                                        label={t('start_time')}
                                    /> */}
                                    <TimePickerComponent
                                        id={"start_time"}
                                        onChange={(fieldId, value) => {
                                            setProfileData((prevState: any) => ({
                                                ...prevState,
                                                start_time: value,
                                            }));
                                        }}
                                        placeholder={''}
                                        required={true}
                                        label={t("start_time")}
                                        value={profileData.start_time}
                                        onClearTime={() => handleClearTime("start_time")}
                                        icon={<Timer />}
                                    />

                                </div>
                                <div className='ac-sc-fields-time'>
                                    {/* <TimeInput
                                        id={'end_time'}
                                        onChange={(fieldId, value) => {
                                            setProfileData((prevState: any) => ({
                                                ...prevState,
                                                end_time: value,
                                            }));
                                        }}
                                        required={true}
                                        placeholder={''}
                                        value={profileData.end_time}
                                        label={t('end_time')}
                                    /> */}
                                    <TimePickerComponent
                                        id={"end_time"}
                                        onChange={(fieldId, value) => {
                                            setProfileData((prevState: any) => ({
                                                ...prevState,
                                                end_time: value,
                                            }));
                                        }}
                                        placeholder={''}
                                        required={true}
                                        value={profileData.end_time}
                                        onClearTime={() => handleClearTime("end_time")}
                                        label={t("end_time")}
                                        icon={<Timer />}
                                    />
                                </div>
                            </div>
                            <div className='ac-sp-field-row'>
                                <div className='ac-sc-fields'>
                                    <FormInput
                                        id={'break_in_minutes'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    break_in_minutes: Number(value),
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"number"}
                                        value={profileData.break_in_minutes}
                                        label={"Break Length (In Minutes)"}

                                    />
                                </div>
                                <div className='ac-sc-fields'>
                                    <FormInput
                                        id={'estimated_shift_hours'}
                                        onChange={(field: any, value: any) => {
                                            if (value !== null) {
                                                setProfileData((prevState: any) => ({
                                                    ...prevState,
                                                    estimated_shift_hours: Number(value),
                                                }));
                                            }
                                        }}
                                        required={false}
                                        placeholder={""}
                                        type={"number"}
                                        value={profileData.estimated_shift_hours}
                                        label={"Estimated Shift Hours"}
                                    />
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="add-role-action-holder">
                            <div className="add-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.defaultActionColor}
                                        onClick={() => {
                                            if (serviceProfileState.data) updateForm(serviceProfileState.data);
                                            //  history.push(AppRoutes.companiesPage) 
                                        }}
                                    />
                                </div>
                                <div className="btn-yes">
                                    <CustomButton
                                        loading={updateState.loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={'Save'}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={handleSaveClick}
                                        enable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div >
            }
        </>
    )
}


export default JobServiceProfilePage;