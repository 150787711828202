import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../../enums";
import { DeleteIcon, DownloadIcon, SearchIcon } from "../../../../../icons";
import { IUnEmploymentClaimData } from "../../../../../interfaces";
import { getFileDownloadURL, selectFileDownloadResponseState, updateUnEmploymentClaim } from "../../../../../redux/risk_management";
import { workCompClaimsListActions } from "../../../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { convertNumberToMoney, CustomButton } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import './unemploymentclaim_tabData.scss';
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";
interface IWorkCompClaimDetails {
    unEmploymentClaimData: IUnEmploymentClaimData
}

const UnEmploymentClaimTabDataPage = ({ unEmploymentClaimData }: IWorkCompClaimDetails) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fileDownloadState = useAppSelector((state) => selectFileDownloadResponseState(state));
    const [downLoadingFile, setDownLoadingFile]: any = useState("");
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, filePath: string, selectedIndex: number } | null>(null);

    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.data?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(workCompClaimsListActions.clearFileDownloadState());
            setDownLoadingFile("");
        }

    }, [fileDownloadState.loading])

    function onDeleteDoc() {
        if (unEmploymentClaimData && typeof showDeleteDialog?.selectedIndex === 'number' && showDeleteDialog?.selectedIndex >= 0 && showDeleteDialog?.selectedIndex < unEmploymentClaimData.claim_docs.length) {
            const indexnum = showDeleteDialog?.selectedIndex
            const docsArray = unEmploymentClaimData.claim_docs;
            const updated = [...docsArray.slice(0, indexnum), ...docsArray.slice(indexnum + 1)]
            dispatch(updateUnEmploymentClaim({
                ...unEmploymentClaimData, claim_docs: updated,
            }));
        }
        setShowDeleteDialog(null);
    }


    function handleDownload(filePath) {
        setDownLoadingFile(filePath);
        dispatch(getFileDownloadURL(filePath));

    }

    function getDocumentName(doc, id) {
        let name = doc.split('-');
        if (name.length > 1)
            return name[1]

    }
    return (
        <div className="uwcc-tab-container">
            <div className="wcc-tab-field-row wcc-tab-border">
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('claim_status').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">
                        <span style={{ color: (unEmploymentClaimData.claim_status === "OPEN") ? THEME.statusInActiveColor : THEME.statusDisabledColor }}>{unEmploymentClaimData.claim_status}</span>
                    </div>
                </div>
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('claim_number').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.claim_number}</div>
                </div>
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('claim_date').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.claim_date ? `${getDateString(unEmploymentClaimData.claim_date)}` : ''}</div>
                </div>
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('date_closed').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.date_closed ? `${getDateString(unEmploymentClaimData.date_closed)}` : ''}</div>
                </div>
            </div>
            <div className="wcc-tab-field-row wcc-tab-border">
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('potential_charges').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.potential_charges ? `${'$ '}${convertNumberToMoney(unEmploymentClaimData.potential_charges, "string") as string}` : ''}</div>
                </div>
                <div className="wcc-tab-field-column">
                    <div className="wcc-tab-field-header">{t('cost_to_date').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.cost_to_date ? `${'$ '}${convertNumberToMoney(unEmploymentClaimData.cost_to_date, "string") as string}` : ''}</div>
                </div>
            </div>
            <div className="wcc-tab-field-row wcc-tab-border">
                <div className="wcc-tab-field-column1">
                    <div className="wcc-tab-field-header">{t('notes').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.notes}</div>
                </div>
                <div className="wcc-tab-field-column1">
                    <div className="wcc-tab-field-header">{t('next_steps').toUpperCase()}</div>
                    <div className="wcc-tab-field-label">{unEmploymentClaimData.next_steps}</div>
                </div>

            </div>
            <div className="wcc-tab-field-row">
                <div className="wcc-tab-field-column2">
                    <div className="wcc-tab-field-header">{t('claim_docs').toUpperCase()}</div>
                    <div className="wcc-claim-row">
                        {unEmploymentClaimData.claim_docs && unEmploymentClaimData.claim_docs.length > 0 &&
                            unEmploymentClaimData.claim_docs.map((h: string, index) => {
                                const spList = h.split(`${unEmploymentClaimData.id}-`);
                                return (
                                    <div className="wcc-tab-claim-doc">
                                         <div style={{width: "-webkit-fill-available"}}>{spList[spList.length - 1]}</div>
                                        <div  className="icons-row">
                                            <CustomButton
                                                customClassName={"wcc-tab-download-button"}
                                                enable={true}
                                                leftIcon={<div><DownloadIcon /></div>}
                                                name={''}
                                                onClick={() => (handleDownload(h))}
                                                loading={h === downLoadingFile && fileDownloadState.loading === LoadingType.pending}
                                            />
                                            <CustomButton
                                                customClassName="wccr-tab-del-button"
                                                enable={true}
                                                leftIcon={<div><DeleteIcon /></div>}
                                                name={''}
                                                backgroundColor={THEME.buttonColor2A}
                                                onClick={() => setShowDeleteDialog({ visible: true, filePath: spList[spList.length - 1], selectedIndex: index })}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            {showDeleteDialog && (
                <DeleteConfirmationDialog
                    message={t("delete_risk_management_message", {
                        uploadDocFile: showDeleteDialog?.filePath,
                    })}
                    onCancel={() => setShowDeleteDialog(null)}
                    onConfirm={onDeleteDoc}
                    deleting={false}
                    deleteType={t("document")}
                />
            )}
        </div>
    )
}
export default UnEmploymentClaimTabDataPage;