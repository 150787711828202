import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../../enums";
import { IPaycheckHistory } from "../../../../../interfaces";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import { getDateString } from "../../../../../variables";
import { Portal } from "react-portal";
import ViewCheckInvoicePopup from "../../../../back_office/popups/view_check/view_check";
import './paycheck_history_section.scss'
import { getPaycheckPDFURLThunk, mailBOPaycheckThunk, selectMailPaycheckInvoiceState, selectProfileState, selectePaycheckInvoiceState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { CustomButton, CustomCheckBox, SpinnerScreen, currencyConversion } from "../../../../../utils";
import { SearchIcon } from "../../../../../icons";
import { mailInvoicePaycheckSliceActions } from "../../../../../redux/back_office/mail_paycheck_invoices/mail_paycheck_invoices_reducer";
import SendInvoiceEdocsByMailPopup from "../../../../companies/popup/send_invoice_edocs_mail/send_invoice_edocs_mail";
import { EmailOutlined } from "@material-ui/icons";
import SearchBar from "../../../../../components/search_bar";

interface Props {
    paycheckHistoryList: IPaycheckHistory[];
    loading: boolean;
}

const PaycheckHistorySection: React.FunctionComponent<Props> = (props) => {
    const {
        paycheckHistoryList,
        loading,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [payCheckViewURL, setPayCheckViewURL] = useState<string>("");
    const [openViewCheckPopup, setOpenViewCheckPopup] = useState<boolean>(false)
    const [showMailPopup, setShowMailPopup] = useState<boolean>(false);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));
    const mailPaycheckState = useAppSelector((state) => selectMailPaycheckInvoiceState(state));

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            setPayCheckViewURL(paycheckPDFURLState.paycheck.response)
        }
    }, [paycheckPDFURLState.paycheck, paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === paycheckHistoryList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const tableheaderList = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "check_hash", code: "check_number" },
        { title: "check_date", code: "check_date" },
        { title: "gross_amount", code: "gross_amount" },
        { title: "taxes", code: "taxes" },
        { title: "deductions", code: "deductions" },
        { title: "net_amount", code: "net_amount" },
        { title: "direct_deposit", code: "", sort: false },
        { title: "bank_name", code: "bank_name" },
        { title: "account_number", code: "account_number" },
        { title: "routing_number", code: "routing_number" },
        { title: "", code: "" },
    ];
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === paycheckHistoryList?.length) {
            setSelectedItems(newSet);
        } else {
            paycheckHistoryList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value)) {
            newSet.delete(value);
        } else {
            newSet.set(value, { id: value, name: "" });
        }
        setSelectedItems(newSet);
    };
    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 0.3;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1.1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 0.5;
        if (value === 20) return 1;
        return 1;
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
        setOpenViewCheckPopup(true)
    }

    const handleClose = () => {
        setOpenViewCheckPopup(false);
        dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        setPayCheckViewURL("");
    }

    const getFilteredList = () => {
        let list = paycheckHistoryList;

        if (paycheckHistoryList && sortedField != null) {
            list = [...paycheckHistoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }
    const handleEmailClose = () => {
        setShowMailPopup(false);
        dispatch(mailInvoicePaycheckSliceActions.clearMailPaycheckState());
        setSelectedItems(new Map());
    }
    const sendPaycheckByMail = (paycheck_ids: string[]) => {
        dispatch(mailBOPaycheckThunk(paycheck_ids));
    }
    return (
        <div className="ep-pd-ph-container">
            <div className="ep-pd-ph-header">
                <div className="ep-pd-ph-header-title">
                    <span>{t("paycheck_history").toUpperCase()}</span>
                </div>
                <div className="ep-pd-ph-header-right">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <b>{getFilteredList()?.length}</b>
                        </span>
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={setSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            leftIcon={<div><EmailOutlined style={{ color: THEME.white, height: "1vw", width: "1vw" }} /></div>}
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => {
                                sendPaycheckByMail(Array.from(
                                    selectedItems.values(),
                                    (entry) => entry.id
                                )); setShowMailPopup(true)
                            }}
                            enable={selectedItems.size > 0}
                        />
                    </div>
                </div>
            </div>
            <div className="ep-pd-ph-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, index) => {
                                        return (
                                            <tr key={doc.id + index}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                                    <CustomCheckBox
                                                        name={""}
                                                        title={""}
                                                        checked={selectedItems.has(doc.id)}
                                                        onClick={() => handleMarkItem(doc.id)}
                                                    />
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.check_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{getDateString(doc.check_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{currencyConversion(doc.gross_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{currencyConversion(doc.total_tax_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{currencyConversion(doc.total_deduction, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{currencyConversion(doc.net_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                    <span>
                                                        {
                                                            <ToggleSwitch
                                                                checked={true}
                                                                onChange={() => { }}
                                                            />

                                                        }
                                                    </span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.bank_name}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{doc.account_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(10) }}><span>{doc.routing_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(11) }}>
                                                    <div>
                                                        <CustomButton
                                                            loading={false}
                                                            customStyle={{ width: '80%', padding: "0 0.5em 0 0.5em", cursor: "pointer" }}
                                                            leftIcon={<span style={{ color: "#fff" }}><SearchIcon width={"1vw"} height={"1vw"} /></span>}
                                                            textStyle={{ textTransform: 'capitalize' }}
                                                            name={t("")}
                                                            enable={true}
                                                            backgroundColor={THEME.buttonBackgroundColor3}
                                                            onClick={() => handleViewCheck(doc.id)}
                                                        />
                                                    </div>
                                                </TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="ep-pd-ph-nra">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                            <div className="na_div">
                                                {t("no_paycheck_history_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
            {openViewCheckPopup &&
                (
                    <Portal>
                        <ViewCheckInvoicePopup
                            visible={openViewCheckPopup}
                            title={t("view_check")}
                            onClose={handleClose}
                            state={paycheckPDFURLState.paycheck}
                            responseURL={payCheckViewURL}
                        />
                    </Portal>
                )
            }
            {showMailPopup &&
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => { }}
                        mailState={mailPaycheckState.mailPaycheck}
                        currentLoginUserName={currentLoginUserName}
                        title={t("hi_lets_send_paycheck", {
                            name: currentLoginUserName,
                        })}
                        successTitle={t("sent_paycheck_by_email")}
                        successMsg={t("you_sent_paycheck_to", { count: 1 })}
                        subTitle={t("to_whom_you_send_paycheck")}
                    />
                </Portal>
            }
        </div>
    );
}

interface TableHeaderOptionsProps {
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    return (
        <div className="ep-pd-ph-header-container">
            <div className="ep-pd-ph-header-title">
                <span>{t("paycheck_history").toUpperCase()}</span>
            </div>

            {/* <div className="ep-pd-ph-header-btns">
                <div className="ep-pd-ph-custom-btn">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        leftIcon={(<div><span style={{ color: "#fff" }}><SearchIcon /></span></div>)}
                        name={t("view_paycheck")}
                        enable={true}
                        backgroundColor={THEME.buttonBackgroundColor3}
                        customStyle={{ width: '10.057vw', marginRight: "1em" }}
                        onClick={() => { }}
                    />
                </div>
                <div className="ep-pd-ph-custom-btn">
                    <CustomButton
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        leftIcon={(<div><span style={{ color: "#fff" }}><DownloadIcon /></span></div>)}
                        name={t("download")}
                        enable={true}
                        backgroundColor={THEME.buttonColor16}
                        customStyle={{ width: '-webkit-fill-available' }}
                        onClick={() => { }}
                    />
                </div>
            </div> */}
        </div>
    );
};

export default PaycheckHistorySection;