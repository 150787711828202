import { RouteComponentProps } from "react-router-dom";
import { getWeekEndingDatesDropdownThunk, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ErrorsReportsPage from "../../errors_reports_page/errors_reports_page";
import { LoadingType } from "../../../../../enums";
import { approveBIErrorThunk, getBERDownloadUrl, getBIERListThunk, resolveBIErrorThunk, selectBERDownloadUrlState, selectBIErrorsReportListState } from "../../../../../redux/back_office/billing_invoices";
import { biErrorReportListSliceActions } from "../../../../../redux/back_office/billing_invoices/errors_report/errors_report_reducer";
import { CustomCheckBox } from "../../../../../utils";

interface Props extends RouteComponentProps<any> { }

const BIErrorsReportsPage: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { history } = props;
    const biErrorsReportListState = useAppSelector((state) => selectBIErrorsReportListState(state));

    const biErrorsReportDownloadUrlState = useAppSelector((state) => selectBERDownloadUrlState(state));

    const [errorReportsList, setErrorReportsList] = useState<any[] | null>(null);
    const [weekendId, setWeekendId] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());

    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (biErrorsReportListState && biErrorsReportListState.loading === LoadingType.succeeded) {
            setErrorReportsList(biErrorsReportListState.response);
        }
    }, [biErrorsReportListState, biErrorsReportListState.loading]);

    useEffect(() => {
        if (biErrorsReportDownloadUrlState.loading === LoadingType.succeeded && biErrorsReportDownloadUrlState.response &&
            biErrorsReportDownloadUrlState.response.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', biErrorsReportDownloadUrlState?.response?.url!);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(biErrorReportListSliceActions.clearDownloadState());
        }
    }, [biErrorsReportDownloadUrlState.loading]);

    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === errorReportsList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };

    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: 'batch', code: 'batch_number' },
        { title: 'status', code: 'status' },
        { title: 'category', code: 'type' },
        { title: 'detailed_error_msg', code: 'error_msg' },
        { title: 'talent_payee', code: 'talent_name' },
        { title: 'company_bill_to', code: 'company_name' },
        { title: 'job_order', code: 'job_title' },
        { title: 'transaction_number', code: 'transaction_number' },
        { title: 'resolve_error', code: 'resolve_error' },
    ];

    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === errorReportsList?.length) {
            setSelectedItems(newSet);
        } else {
            errorReportsList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { id: value.id, name: "" });
        }
        setSelectedItems(newSet);
    };

    const handleWeekEndChange = (week_id: string) => {
        dispatch(getBIERListThunk(week_id));
    }

    const handleApproveError = (timeCardids: string[]) => {
        dispatch(approveBIErrorThunk(timeCardids));
    }
    const handleResolveError = (timeCardid: string) => {
        dispatch(resolveBIErrorThunk(timeCardid))
    }
    const handleClearApproveError = () => {
        dispatch(biErrorReportListSliceActions.clearAprroveErrorState());
        dispatch(biErrorReportListSliceActions.clearResolveErrorState());
    }
    const handleOnSuccessClose = () => {
        dispatch(biErrorReportListSliceActions.clearAprroveState());
        dispatch(biErrorReportListSliceActions.clearResolveState());
        dispatch(getBIERListThunk(weekendId));
    }

    const handleSetWeekendId = (week_id: string) => {
        setWeekendId(week_id)
    }

    function handleDownload() {
        dispatch(getBERDownloadUrl(weekendId));
    }

    const resetSelectedItems = () => {
        setSelectedItems(new Map());
    };

    return (
        <ErrorsReportsPage
            tableheaderList={tableHeader}
            reportsList={errorReportsList}
            source="timecards"
            weekendChange={handleWeekEndChange}
            WeekendId={(week_id) => handleSetWeekendId(week_id)}
            loading={biErrorsReportListState.loading === LoadingType.pending}
            onApprove={handleApproveError}
            onResolve={handleResolveError}
            state={biErrorsReportListState.approve}
            resolveState={biErrorsReportListState.resolve}
            handleClearError={handleClearApproveError}
            handleOnSuccessClose={handleOnSuccessClose}
            handleDownload={handleDownload}
            history={history}
            selectedItems={selectedItems}
            handleMarkItem={handleMarkItem}
            handleClearSelectedItems={resetSelectedItems}
        />
    );
}

export default BIErrorsReportsPage;