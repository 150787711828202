import { createAsyncThunk } from "@reduxjs/toolkit";
import { TalentAPI } from "../../../../../apis";
import { AppError, ICertiUploadUrlResponse, ICreateTalentCrimeHistory, ITalentCrimeHistory,  } from "../../../../../interfaces";
import { catchRequestError, invalidResponse } from "../../../../../utils";
import { S3API } from "../../../../../apis/s3_api";

export const getTalentCrimeHistoryThunk = createAsyncThunk<
    Array<ITalentCrimeHistory>,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/crime-history/get',
    async (talent_id, thunkAPI) => {
        try {
            const res = await TalentAPI.getTalentCrimeHistory(talent_id);
            if (typeof res.data === 'string') return [];
            return res.data as ITalentCrimeHistory;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const addNewTalentCrimeHistoryThunk = createAsyncThunk<
    ICertiUploadUrlResponse[],
    { talent_id: string, data: ICreateTalentCrimeHistory, files: Array<File> | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/crime-history/post',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.postTalentCrimeHistory(doc.data);
            thunkAPI.dispatch(getTalentCrimeHistoryThunk(doc.talent_id));
            if(res.data && doc.files){
                doc.files.map((file:any) => {
                    const url = res.data[file.name];
                    S3API.uploadWithPreSignedURL(url, file);
                })
            }
            return res.data as ICertiUploadUrlResponse[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const updateTalentCrimeHistoryThunk = createAsyncThunk<
    ICertiUploadUrlResponse[],
    { talent_id: string, data: ICreateTalentCrimeHistory | undefined, files?: Array<File> | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/crime-history/patch',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.updateTalentCrimeHistory(doc.data);
            thunkAPI.dispatch(getTalentCrimeHistoryThunk(doc.talent_id));
            if(res.data && doc.files){
                doc.files.map((file:any) => {
                    const url = res.data[file.name];
                    S3API.uploadWithPreSignedURL(url, file);
                })
            }
            return res.data as ICertiUploadUrlResponse[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const deleteTalentCrimeHistoryThunk = createAsyncThunk<
    string,
    { talent_id: string, object_id: string },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/crime-history/delete',
    async (doc, thunkAPI) => {
        try {
            const res = await TalentAPI.deleteTalentCrimeHistory(doc.object_id);
            return res.data as string;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

export const uploadCrimeHistFileThunk = createAsyncThunk<
    any,
    { type: string, url: string, file: File | null },
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@talent/details/onboard-docs/crime-hostory/post',
    async (payload, thunkAPI) => {
        try {
            if (payload.file && payload.url) {
                const photoRes = await S3API.uploadFile(payload.url, payload.file);

                return photoRes.data;
            }
            return thunkAPI.rejectWithValue(invalidResponse, invalidResponse);
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);