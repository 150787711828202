import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { S3API } from "../../../../apis/s3_api";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DropZone from "../../../../components/file_upload/file_upload_page";
import { LoadingType, THEME } from "../../../../enums";
import CloseIcon from "../../../../../assets/icons/close-btn2.svg";
import { CreateSalesDocument, SalesDocument } from "../../../../interfaces";
import { createUploadDocument, updateUploadDocument } from "../../../../redux/sales_marketing";
import { useAppDispatch, useAppSelector, selectSalesDocumentState } from "../../../../redux/store";
import { FormInput, CustomButton } from "../../../../utils";
import './marketing_docs.scss';
import { SalesDocumentState, salesDocumentActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_docs_list/marketing_docs_reducer";

interface Props {
    onClose: () => void,
    onDismiss: () => void,
    visible: boolean,
    onApprove: () => void,
    data?: SalesDocument,
    salesDocumentState?: SalesDocumentState,
}

const initial = {
    file_name: "",
    file_category: "",
    file_extension: "",
    date_uploaded: 0,
    uploaded_by: "",
    notes: "",
    branch_id: "",
    file_path: "",
    is_video: false,
}

const UploadDocument: React.FC<Props> = ({ data, onClose, onDismiss, visible, onApprove, salesDocumentState }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const state = useAppSelector((state) => selectSalesDocumentState(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    const [files, setFiles] = useState<any>();
    const [formState, setFormState] = useState<CreateSalesDocument>(data ?? initial);

    const [sentRequest, setSentRequest] = useState(false);

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleFiles(files) {
        setFiles(files);
    }

    function _isFormValid() {
        if (!data?.id)
            if (!files)
                return false;
        return true;
    }

    function saveDocument() {
        if (data && data?.id) {
            dispatch(updateUploadDocument({
                ...formState,
                id: data?.id,
                file_name: files ? files[0].name : data.file_name,
            }));
        }
        else {
            dispatch(createUploadDocument({ file_name: files[0].name, notes: formState.notes }));
        }
        setSentRequest(true);
    }

    useEffect(() => {
        if (salesDocumentState?.documentUpload && salesDocumentState.documentUpload.loading === LoadingType.succeeded && sentRequest) {
            S3API.uploadWithPreSignedURL(salesDocumentState.documentUpload.response, files[0]);
            setSentRequest(false);
            onApprove();
            closePopup(onClose);
            dispatch(salesDocumentActions.clearUploadState());
        }
    }, [salesDocumentState?.documentUpload])

    useEffect(() => {
        if (salesDocumentState?.documentUpdate && salesDocumentState?.documentUpdate.loading === LoadingType.succeeded && sentRequest) {
            S3API.uploadWithPreSignedURL(salesDocumentState?.documentUpdate.response, files ? files[0] : data?.file_name);
            setSentRequest(false);
            onApprove();
            onClose();
            dispatch(salesDocumentActions.clearUpdateState());
        }
    }, [salesDocumentState?.documentUpdate, salesDocumentState?.documentUpdate.loading])
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={600}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="document-upload-container">
                <div className="document-upload-container-dialog-header">
                    <h5>{data?.id ? t('update_document') : t('upload_document')}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} style={{ "cursor": "pointer" }} />
                </div>
                <div className="document-upload-container-dialog-content">
                    <FormInput
                        id={"notes"}
                        onChange={handleFieldChange}
                        type={"text"}
                        value={formState?.notes}
                        label={t("note")}
                        placeholder=""
                    />
                    <p>{t('documents')}:</p>
                    <div>
                        <DropZone maxFiles={1} handleFiles={handleFiles} maxSize={26214400} />
                    </div>
                </div>
                <div className="document-upload-container-dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            // enable={files}
                            enable={_isFormValid()}
                            loading={sentRequest}
                            name={data?.id ? t('update_document') : t('upload_document')}
                            padding={12}
                            fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={saveDocument}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default UploadDocument;