import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DragAndDrop from "../../../components/drag_and_drop/drag_and_drop";
import PhotoInput from "../../../components/photo_input/photo_input";
import RenderInput from "../../../components/render_input/render_input";
import { LinkIcon } from "../../../icons";
import {
  FormInputOption,
  ICreateTalentProfile,
  IOnboardingTalentData,
} from "../../../interfaces";
import {
  enforceFormat,
  formatToPhone,
  FormInput,
  RegxPattern,
} from "../../../utils";
import { US_states } from "../../../utils/States_US";
import "./onboarding_profile.scss";

interface Props {
  formState: ICreateTalentProfile | undefined;
  //   talentData:IOnboardingTalentData | undefined;
  handleFieldChange: (id: string, value: any) => void;
}

const formOptions: (FormInputOption | FormInputOption[])[] = [
  [
    {
      field: "first_name",
      type: "text",
      label: "first_name",
      secure: false,
      required: true,
      placeholder: "",
      keyboardtype: "",
    },

    {
      field: "last_name",
      type: "text",
      label: "last_name",
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
  ],
  [
    {
      field: "street_address",
      type: "text",
      label: "street_address",
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
    {
      field: "city",
      type: "text",
      label: "city",
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
  ],
  [
    {
      field: "state",
      type: "selector",
      label: "state",
      secure: false,
      required: false,
      placeholder: "select",
      keyboardtype: "",
      selectorList: US_states.map((doc) => ({
        label: doc.name,
        value: doc.code,
      })),
      multi: false,
    },
    {
      field: "zip_code",
      type: "text",
      label: "zip_code",
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
      maxLength: 5,
    },
  ],
  /// 3rd row
  [
    {
      field: "phone",
      type: "tel",
      label: "mobile",
      // pattern: RegxPattern.phone,
      secure: false,
      required: true,
      placeholder: "(888) 888-8888",
      keyboardtype: "",
      maxLength: 16,
      onKeyUp: formatToPhone,
      onKeyDown: enforceFormat,
    },
    {
      field: "email",
      type: "text",
      label: "email",
      pattern: RegxPattern.email,
      secure: false,
      required: true,
      placeholder: "",
      keyboardtype: "",
      disabled: true,
    },
  ],
  /// 4th row
  [
    {
      field: "linkedin_url",
      parentField: "social_media_links",
      type: "text",
      label: "linked_in",
      labelIcon: getLinkIcon(),
      pattern: RegxPattern.url,
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
    {
      field: "facebook_url",
      parentField: "social_media_links",
      type: "text",
      label: "facebook",
      labelIcon: getLinkIcon(),
      pattern: RegxPattern.url,
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
  ],
  [
    {
      field: "twitter_url",
      parentField: "social_media_links",
      type: "text",
      label: "twitter",
      labelIcon: getLinkIcon(),
      pattern: RegxPattern.url,
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
    {
      field: "pinterest_url",
      parentField: "social_media_links",
      type: "text",
      label: "pinterest",
      labelIcon: getLinkIcon(),
      pattern: RegxPattern.url,
      secure: false,
      required: false,
      placeholder: "",
      keyboardtype: "",
    },
  ],
];

function getLinkIcon() {
  return (
    <div className="ob-cnt-link-icon">
      <LinkIcon width={"100%"} height={"100%"} />
    </div>
  );
}

const OnboardingTalentProfile: React.FunctionComponent<Props> = (props) => {
  const { handleFieldChange, formState } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="team-title-holder">
        <span>
          {t("hi_name_please_verify_info", {
            name: `${formState?.first_name ?? ""}`,
          })}
        </span>
      </div>
      <div className="ob-cnt-inf-form-container">
        {formOptions.map((doc, index) => {
          if (Array.isArray(doc)) {
            return (
              <div key={index + "row"} className="ob-cnt-inf-form-row">
                {doc.map((subDoc, subIndex) => {
                  return (
                    <RenderInput
                      key={index + subIndex}
                      doc={subDoc}
                      index={subIndex}
                      formState={formState}
                      handleFieldChange={handleFieldChange}
                      formValidators={formValidators}
                      className={"ob-cnt-inf-f-input-holder"}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <RenderInput
              key={index}
              doc={doc}
              index={index}
              formState={formState}
              handleFieldChange={handleFieldChange}
              formValidators={formValidators}
              className={"ob-cnt-inf-f-input-holder"}
            />
          );
        })}
      </div>
    </>
  );

  function formValidators(value: { text: string; field: string }) {
    switch (value.field) {
      case "first_name": {
        if (value.text === "") return t("validators.required");
        return null;
      }
      case "email": {
        if (value.text === "") return t("validators.required");
        if (RegxPattern.email.test(value.text) === false)
          return t("validators.enterValidEmail");
        return null;
      }
      case "phone": {
        if (!value.text) return null;
        if (RegxPattern.phone.test(value.text) === false)
          return t("validators.enterValidPhoneNumber");
        return null;
      }
      case "linkedin_url":
      case "facebook_url":
      case "twitter_url":
      case "pinterest_url": {
        if (!value.text) return null;
        if (RegxPattern.url.test(value.text) === false)
          return t("validators.enterValidUrl");
        return null;
      }
      default: {
        return null;
      }
    }
  }
};

export default OnboardingTalentProfile;
