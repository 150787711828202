import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getPRTransactionSummaryThunk, getWeekEndingDatesDropdownThunk, selectPRTransactionSummaryList, selectPRTransactionSummaryState, selectTimecardsReportListState, selectWeekEndingDatesDDList, selectWeekEndingDatesState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { getDateString } from "../../../../../variables";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SearchBar from "../../../../../components/search_bar";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { SpinnerScreen, currencyConversion } from "../../../../../utils";
import { LoadingType } from "../../../../../enums";
import { AppRoutes } from "../../../../../routes";

const tableHeader = [
    { title: 'talent_payee', code: 'talent_payee' },
    { title: 'ssn_hash', code: 'ssn' },
    { title: 'company_bill_to_name', code: 'company_bill_to' },
    { title: 'week_ending', code: 'week_ending' },
    { title: 'total_pay', code: 'total_pay' },
    { title: '', code: '' },
];

interface Props extends RouteComponentProps<any> { }

const PayrollTransactionSummary: React.FunctionComponent<Props> = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    
    const weekEndingDatesListState = useAppSelector((state) =>selectWeekEndingDatesState(state));
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));

    const prTransactionSummaryListState = useAppSelector((state) => selectPRTransactionSummaryState(state));
    const prTransactionSummaryList = useAppSelector((state) => selectPRTransactionSummaryList(state));

    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    
    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);

    useEffect(() => {
        if (weekEndingDatesListState &&
            weekEndingDatesListState.loading === LoadingType.succeeded &&
            weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            dispatch(getPRTransactionSummaryThunk(weekEndingDatesList[0].id));

        }
    }, [weekEndingDatesList.length, weekEndingDatesListState.loading]);

    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 1;
        if (value === 1) return 0.7;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 2;

        return 1;
    }
    const getFilteredList = () => {
        let list = prTransactionSummaryList;

        if (prTransactionSummaryList && sortedField != null) {
            list = [...prTransactionSummaryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter((doc => {
            const str = search.trim().toLowerCase();
            const talentFilter = doc.talent_name ? doc.talent_name.trim().toLowerCase().includes(str) : false;
            const ssnFilter = doc.talent_ssn ? doc.talent_ssn.trim().toLowerCase().includes(str) : false;
            const companyFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;
            const totalpayFilter = doc.total_pay ? doc.total_pay.toString().trim().toLowerCase().includes(str) : false;

            return talentFilter || ssnFilter || companyFilter || totalpayFilter;
        }));
    }

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getPRTransactionSummaryThunk(val));
    }

    function handleUserSelect(value) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            }
        })
    }

    function handleCompanySelect(value) {
        props.history.push({
          pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
          state: {
            id: value.company_id,
            name: value.company_name,
          },
        });
      }

    return (
        <div className="errors-report-container">
            <div className="er-header">
                <div>
                    <CustomFormSelect
                        list={
                            weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))
                        }
                        customStyle={{ width: "100%" }}
                        name={"week_ending_date"}
                        value={selectedWeekDate.id}
                        label={t("week_ending_date")}
                        placeholder={"Select"}
                        onChange={handleWeekEndChange}
                        isClearable={false}
                    />
                </div>
                <div className="right-col">
                    <div className="ml search-bar">
                        <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="er-content">
                <div className="er-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {prTransactionSummaryListState.loading === LoadingType.pending ?
                            <SpinnerScreen />
                            :
                            <>
                                {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                    <>
                                        {getFilteredList()?.map((doc, index) => {
                                            return (
                                                <tr key={doc.week_accounting_id + index}>
                                                    <TableData customStyle={{ flex: getFlexNumber(0) }} isButton={true} onClick={() => handleUserSelect(doc)}><span>{doc.talent_name}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.talent_ssn}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(2) }} isButton={true} onClick={() => handleCompanySelect(doc)}><span>{doc.company_name}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{getDateString(doc.week_ending_date, "mm/dd/yyyy")}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{currencyConversion(doc.total_pay, 2)}</span></TableData>
                                                    <TableData customStyle={{ flex: getFlexNumber(5) }}><span></span></TableData>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )
                                    :
                                    <>
                                        <tr>
                                            <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                                <div className="na_div">
                                                    {t("no_data_available")}
                                                </div>
                                            </TableData>
                                        </tr>
                                    </>
                                }

                            </>
                        }
                    </SortableTable>
                </div>
            </div>
        </div>


    );
}

export default PayrollTransactionSummary;