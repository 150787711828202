import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./deduction_institution_thunk";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";

export interface DeductionInstituteListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const DeductionInstituteListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: DeductionInstituteListState = DeductionInstituteListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const DeductionInstituteListSlice = createSlice({
    name: 'DeductionInstituteList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDeductionInstitution.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getDeductionInstitution.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getDeductionInstitution.fulfilled, (state, action) => DeductionInstituteListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createDeductionInstitution.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createDeductionInstitution.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createDeductionInstitution.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateDeductionInstitution.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateDeductionInstitution.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateDeductionInstitution.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deleteDeductionInstitution.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteDeductionInstitution.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteDeductionInstitution.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = DeductionInstituteListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = DeductionInstituteListAdapter.getSelectors();
export const selectState = (state: DeductionInstituteListState) => state;
export const selectLoading = (state: DeductionInstituteListState) => state.loading;
export const selectError = (state: DeductionInstituteListState) => state.error;


