import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import { CustomMultiFormSelect } from "../../../../components/form_selector/form_select";
import SearchBar from "../../../../components/search_bar";
import SortableTable, {
  TableData,
} from "../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../enums";
import { CalenderIcon, CompletedCallIcon, CompletedCallWhiteIcon, EditIcon, ScheduledCallIcon, ScheduledCallWhiteIcon, TickGreenIcon, TickGreyIcon } from "../../../../icons";
import { SalesCall } from "../../../../interfaces/sales_call";
import { getContactStatus, selectContactStatusList, selectContactStatusState } from "../../../../redux/admin_center";
import { manageSalesCallActions } from "../../../../redux/sales_marketing/plan_my_sales_calls/manage_sales_calls/manage_sales_calls_reducer";
import { getRoleUsersList, postSnackbarMessage, selectProfileState, selectRoleUsersList, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { AppRoutes } from "../../../../routes";
import { CustomButton, getContactIcon, getPlusBtnIcon, getUserNameFromProfileDropdown } from "../../../../utils";
import { convertDateToTimeStamp, dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateString, timestampToStringDate } from "../../../../variables";
import { PlanMySalesCalls } from "../plan_my_sales_calls/plan_my_sales_calls";
import { CompleteSalesCall } from "./complete_sales_call";
import TableEmpty, {
  TableErrorHandler,
  TableLoading,
} from "../../../../components/table_empty/table_empty";
import { selectSalesCallListState, selectSalesCallList } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_selector";
import { getSalesCallFilteredList } from "../../../../redux/sales_marketing/my_call_deck/sales_call_list/sales_call_list_thunk";
import { getSalesCallByIdThunk } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_thunk";
import { selectSalesCallDetailsState } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_selector";
import { salesCallDetailActions } from "../../../../redux/sales_marketing/my_call_deck/sales_call_details/sales_call_details_reducer";


interface Props extends RouteComponentProps<any> { }
const SalesCallActivity: React.FunctionComponent<Props> = (props) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { location, history } = props;

  const tableHeader = [
    { title: "complete_call", code: "complete_call" },
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "", code: "" },
  ];

  const completedCallsTableheader = [
    { title: "company", code: "company_name" },
    { title: "department", code: "department_name" },
    { title: "contact", code: "contact_first_name" },
    { title: "DM Reached", code: "dm_reached" },
    { title: "Call Method", code: "contact_method" },
    { title: "Industry Sector", code: "industry_sector_name" },
    { title: "Call Goal", code: "call_goal" },
    { title: "Date of Scheduled Sales Call", code: "schedule_date_time" },
    { title: "Date of Completed Sales Call", code: "call_date_time" },
    { title: "Call Results", code: "call_result" },
    { title: "", code: "" },
  ];

  const contactStatusList = useAppSelector((state) => selectContactStatusList(state));
  const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
  const profileState = useAppSelector((state) => selectProfileState(state));
  const usersList = useAppSelector((state) => selectRoleUsersList(state));
  const { loading, error } = useAppSelector((state) => selectSalesCallListState(state));
  const salescalls: SalesCall[] = useAppSelector((state) => selectSalesCallList(state));
  const salescallDetailData = useAppSelector((state) => selectSalesCallDetailsState(state));

  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('');
  const [managerFilter, setManagerFilter]: any = useState("");
  const [managerList, setManagerList]: any = useState([]);
  const [sortedField, setSortedField] = useState<string | null>("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [search, setSearch] = useState("");
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [showCompletePopup, setShowCompletePopup] = useState<boolean>(false);


  const [selectedCall, setSelectedCall] = useState<SalesCall>(new SalesCall());


  const [showPlanPopup, setShowPlanPopup] = useState<boolean>(false);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [salesFormState, setSalesFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
  const [salesDateRange, setSalesDateRange] = useState([thirtyDaysAgo, new Date()]);
  const [salesStartDate, salesEndDate] = salesDateRange;
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  // const [stateSalesCallId, setStateSalesCallId] = useState<string>("");
  const [stateSource, setStateSource] = useState<{ source: string, contactId?: string, companyId?: string }>({ source: "", contactId: "", companyId: "" });


  //useEffects
  useEffect(() => {
    dispatch(getRoleUsersList());
    dispatch(getContactStatus());
  }, []);

  useEffect(() => {
    if (contactStatusList && contactStatusList.length !== 0) {
      setTab(contactStatusList[0].contact_status)
    }
  }, [contactStatusList]);

  useEffect(() => {
    if (usersList && usersList.length > 0) {
      const list: any = [];
      usersList.map((h: any) => {
        list.push({
          value: h.id,
          label: h.last_name + " " + h.first_name,
        });
      });
      setManagerList(list);
    }
  }, [usersList]);

  useEffect(() => {
    if (error != null && salescalls.length !== 0) {
      dispatch(postSnackbarMessage(error.message));
    }
  }, [error]);
  useEffect(() => {
    const state = location.state as any;
    if (state) {
      setStateSource({
        source: state["source"],
        contactId: state["contactId"],
        companyId: state["companyId"]
      });

      if (state["planCall"]) {
        setSelectedCall({
          ...selectedCall,
          contact_id: state["contactId"] ?? "",
          company_id: state["companyId"] ?? "",
          department_id: state["departmentId"] ?? "",
          planned_by_id: state["planned_by_id"] ?? "",
          planned_by_name: state["planned_by_name"] ?? "",
        });
        setShowPlanPopup(true);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
      if (state["completeCall"] || state["editCall"]) {
        dispatch(getSalesCallByIdThunk(state["id"]));
      }
    }
  }, [location]);

  useEffect(() => {
    const state = location.state as any;

    if (state && salescallDetailData && salescallDetailData.loading === LoadingType.succeeded && salescallDetailData.response !== null) {
      const call = salescallDetailData.response;
      if (state['completeCall']) {
        setSelectedCall({ ...call });
        setShowCompletePopup(true);
        setTab(call.company_status);
        setShowCompleted(call.is_completed);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
      if (state['editCall']) {
        setSelectedCall({ ...call });
        setShowPlanPopup(true);
        history.replace({ ...history.location, state: undefined });
        window.history.replaceState({}, document.title);
      }
    }
  }, [salescallDetailData, salescallDetailData.response]);

  useEffect(() => {
    if (managerFilter.length !== 0) {
      setIsFiltersApplied(true)
    }
    else {
      setIsFiltersApplied(false)
    }
  }, [managerFilter.length]);

  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0) || ((salesFormState.available_from === 0 && salesFormState.available_to === 0))) {
      getSalesCallsList();
    }
  }, [showCompleted, salesFormState.available_from, salesFormState.available_to]);

  useEffect(() => {
    if ((salesStartDate === null && salesEndDate === null) || (salesFormState.available_from === 0 && salesFormState.available_to === 0)) {
      setSalesDateRange([thirtyDaysAgo, new Date()]);
      setSalesFormState({ available_from: convertDateToTimeStamp(thirtyDaysAgo, true), available_to: convertDateToTimeStamp(new Date(), true) })
    }
  }, [showCompleted]);


  useEffect(() => {
    if ((salesFormState.available_from !== 0 && salesFormState.available_to !== 0)) {
      setDatePickerOpen(false)
    }
  }, [salesFormState.available_from, salesFormState.available_to])

  //functions 

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };
  const getSalesCallsList = () => {
    dispatch(getSalesCallFilteredList({ my_upcoming_calls: !showCompleted, start_date: salesFormState.available_from, end_date: salesFormState.available_to }))
  }
  const planNextCallWithContact = (call: SalesCall) => {
    // setStateSalesCallId("");
    setStateSource({ source: "", contactId: "", companyId: "" })
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
    dispatch(manageSalesCallActions.clearUpdateSalesCallState());
    dispatch(salesCallDetailActions.clearSalesCallDetailState());

    setShowCompletePopup(false);
    setShowPlanPopup(true);
    const newCall = new SalesCall();

    setSelectedCall({
      ...newCall,
      contact_id: call.contact_id ?? "",
      company_id: call.company_id ?? "",
      department_id: call.department_id ?? "",
    });
  };
  ///Add user action
  function handleAddSalesCallSuccess() {
    setShowCompletePopup(false);
    setShowPlanPopup(false);
    setSelectedCall(new SalesCall());
    getSalesCallsList();
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
    dispatch(manageSalesCallActions.clearUpdateSalesCallState());
  }

  function handleChange(value) {
    setSalesFormState({
      ...salesFormState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
      available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
    });
    setSalesDateRange(value);

  }

  function handleGotoAgencyDashboard() {
    handleAddSalesCallSuccess();
    props.history.push(AppRoutes.dashboardPage);
    dispatch(salesCallDetailActions.clearSalesCallDetailState());
    setStateSource({ source: "", contactId: "", companyId: "" })
  }
  function handleCreateNew() {
    dispatch(manageSalesCallActions.clearCreateSalesCallState());
  }

  function handleOnGotoSalesCall(id: string, name: string) {
    handleAddSalesCallSuccess();
  }
  const handleSortFieldChange = (value: string) => {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  };
  function handleCompanySelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
      },
    });
    return;
  }
  function handleDepartmentSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
      state: {
        id: value.company_id,
        name: value.company_name,
        departmentId: value.department_id,
        departmentName: value.department_name,
      },
    });
    return;
  }

  function handleContactSelect(value: SalesCall) {
    props.history.push({
      pathname: `${AppRoutes.contactsDetailsPage}/${value.contact_id}`,
      state: {
        id: value.contact_id,
        name: `${value.contact_first_name ?? ""} ${value.contact_last_name ?? ""
          }`,
      },
    });
    return;
  }
  function getFlexNumber(value: number) {
    if (value === 0) return 1.2;
    if (value === 1) return 1;
    if (value === 2) return 1;
    if (value === 3) return 1;
    if (value === 4) return 1;
    if (value === 5) return 1;
    if (value === 6) return 1;
    if (value === 7) return 2;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10 && showCompleted) return 2;
    if (value === 11) return 1;
    return 1;
  }

  const showSpinner = () => loading === LoadingType.pending;

  const handleEdit = (val: SalesCall) => {
    const dateSting = extractDateFromTimestamp(val.schedule_date_time)
    const dateTimeStamp= dateToTimestamp(dateSting)
    const time = extractTimeFromTimestamp(val.schedule_date_time)
    const updatedSalesCall= {
      ...val,
      schedule_date: dateTimeStamp,
      schedule_time: time
    }
    setSelectedCall(updatedSalesCall);
    val.is_completed ? setShowCompletePopup(true) : setShowPlanPopup(true);
  };

  const getTabCount = (tabName) => {

    return salescalls.filter(
      (item) => item.status === tabName
    ).length;
  };
  const getTabFilterCount = (tabName) => {
    let list: SalesCall[] = [...salescalls];
    let filteredList;

    if (managerFilter.length !== 0) {
      filteredList = salescalls.filter((doc) => {
        const userIncluded = (doc.assigned_to_id !== null ? managerFilter.includes(doc.assigned_to_id!) : (doc.modified_by !== null ? managerFilter.includes(doc?.modified_by!) : managerFilter.includes(doc?.created_by!)));
        return userIncluded && doc.is_completed === showCompleted && doc.status === tabName;
      }).length;
    }

    return (filteredList ?? list.length)
  }
  const getFilteredList = () => {
    let list: SalesCall[] = [...salescalls];
    let sortedList: SalesCall[] | undefined;
    list = list.filter(
      (item) => item.status === tab
    );
    if (managerFilter?.length !== 0) {
      sortedList = list.filter((doc) => {
        const userIncluded = (doc.assigned_to_id !== null ? managerFilter.includes(doc.assigned_to_id!) : (doc.modified_by !== null ? managerFilter.includes(doc?.modified_by!) : managerFilter.includes(doc?.created_by!)));
        return userIncluded;
      });
    }
    if (sortedField !== null) {
      sortedList = [...(sortedList ?? list)].sort((a, b) => {
        const valueA =
          a[sortedField] != null && a[sortedField] !== undefined
            ? typeof a[sortedField] == typeof "1"
              ? a[sortedField].trim().toLowerCase()
              : a[sortedField]
            : "";
        const valueB =
          b[sortedField] != null
            ? typeof b[sortedField] == typeof "1"
              ? b[sortedField].trim().toLowerCase()
              : b[sortedField]
            : "";
        if (sortDirection === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (sortedList ?? list)?.filter((doc) => {
      const str = search.trim().toLowerCase();
      const companyName = doc.company_name
        ? doc.company_name.trim().toLowerCase().includes(str)
        : false;
      const departmentName = doc.department_name
        ? doc.department_name.trim().toLowerCase().includes(str)
        : false;
      const industrySectorName = doc.industry_sector_name
        ? doc.industry_sector_name.trim().toLowerCase().includes(str)
        : false;
      const callGoal = doc.call_goal
        ? doc.call_goal.trim().toLowerCase().includes(str)
        : false;
      const callResult = doc.call_result
        ? doc.call_result.trim().toLowerCase().includes(str)
        : false;
      const contactName = `${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`
        ? `${doc.contact_first_name ?? ""} ${doc.contact_last_name ?? ""}`.trim().toLowerCase().includes(str)
        : false;
      return companyName || departmentName || contactName || industrySectorName || callGoal || callResult;
    });
  }
  const getTable = () => {
    if (showSpinner()) return <TableLoading />;

    if (error != null && salescalls.length === 0) {
      return <TableErrorHandler error={error} onRefresh={getSalesCallsList} />;
    }

    if (salescalls.length === 0 || getFilteredList()?.length === 0) {
      return <TableEmpty title={"No calls found"} onClick={getSalesCallsList} />;
    }

    if (getFilteredList()?.length !== 0) {
      return (
        <SortableTable
          headerList={showCompleted ? completedCallsTableheader : tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          isAsc={sortDirection}
        >
          {getFilteredList()?.map((doc) => {
            return (
              <tr key={doc.id}>
                {!showCompleted ? (
                  <TableData customStyle={{ flex: getFlexNumber(0) }}>
                    <button
                      className="complete-call"
                      onClick={() => {
                        setSelectedCall(doc);
                        setShowCompletePopup(true);
                      }}
                    >
                      <span>{t("complete_call")}</span>
                    </button>
                  </TableData>
                ) : (
                  <TableData
                    customStyle={{ flex: getFlexNumber(0) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                {!showCompleted && (
                  <TableData
                    customStyle={{ flex: getFlexNumber(1) }}
                    isButton={true}
                    onClick={() => handleCompanySelect(doc)}
                  >
                    <span>{doc.company_name}</span>
                  </TableData>
                )}
                <TableData
                  customStyle={{ flex: getFlexNumber(2) }}
                  // isButton={doc.department_name ? true : false}
                  isButton={true}
                  onClick={() => handleDepartmentSelect(doc)}
                >
                  <span>{doc.department_name ?? t("corporate")}</span>
                </TableData>
                <TableData
                  customStyle={{ flex: getFlexNumber(3) }}
                  isButton={true}
                  onClick={() => handleContactSelect(doc)}
                >
                  <span>
                    {doc.contact_first_name
                      ? doc.contact_first_name
                      : "" + " " + doc.contact_last_name
                        ? doc.contact_last_name
                        : "" ?? ""}
                  </span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                  {doc.dm_reached ? <TickGreenIcon /> : <TickGreyIcon />}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                  {getContactIcon(doc.contact_method)}
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                  <span>{doc.industry_sector_name}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                  <span>{doc != null && doc?.call_goal?.length > 100 ? doc?.call_goal?.substring(0, 100) + "..." : doc?.call_goal}</span>
                </TableData>
                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                  <span>{getDateString(doc.schedule_date_time && doc.schedule_date_time)}</span>
                </TableData>
                {showCompleted && (
                  <>
                    <TableData customStyle={{ flex: getFlexNumber(9) }}>
                      <span>{getDateString(doc.call_date_time)}</span>
                    </TableData>
                    <TableData customStyle={{ flex: getFlexNumber(10) }}>
                      <span>{doc != null && doc?.call_result?.length > 100 ? doc?.call_result?.substring(0, 100) + "..." : doc?.call_result}</span>
                    </TableData>
                  </>
                )}
                <TableData customStyle={{ flex: getFlexNumber(11) }}>
                  <span>
                    <button
                      className="icon-btn tdp-edit-icon"
                      onClick={() => handleEdit(doc)}
                    >
                      <EditIcon
                        width={"100%"}
                        height={"100%"}
                        style={{ color: "#fff" }}
                      />
                    </button>
                  </span>
                </TableData>
              </tr>
            );
          })}
        </SortableTable>
      );
    }
  };

  return (
    <div className="sales-call-activity">
      <div className="flex-row main-header">
        <div
          className={`flex-grow main-header-item ${!showCompleted ? "selected" : ""
            }`}
          onClick={() => setShowCompleted(false)}
        >
          {!showCompleted ? (
            <ScheduledCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <ScheduledCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Scheduled Sales Calls
        </div>
        <div
          className={`flex-grow main-header-item ${showCompleted ? "selected" : ""
            }`}
          onClick={() => setShowCompleted(true)}
        >
          {showCompleted ? (
            <CompletedCallWhiteIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          ) : (
            <CompletedCallIcon
              height={"20px"}
              width={"20px"}
              style={{ marginRight: "15px" }}
            />
          )}
          Completed Sales Calls
        </div>
      </div>
      <div className="tab-navigation-container">
        <div className="header">
          <div className="tab-options-container">
            {props && contactStatusList && contactStatusList.map((doc, index) => {
              return (
                <div
                  key={index}
                  className={`tab-option ${tab === doc.contact_status ? "selected" : ""}`}
                  onClick={() => setTab(doc.contact_status)}
                >
                  <span>{t(doc.contact_status)}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>


      <div className="table-container ">
        <div className="actions">
          <div className="table-header">
            <CustomMultiFormSelect
              customStyle={{ width: "initial", marginLeft: "1em" }}
              label={t("select_user")}
              name={"select_user"}
              value={managerFilter}
              placeholder={"All"}
              list={managerList}
              onChange={(value) => {
                setManagerFilter(value);
                // getAllFilteredCount();
              }}
            />
            <div className="dateRange">
              <label>{t("date_range")}</label>
              <div className="date-input-icon">
                <div className="calendar-icon-div">
                  <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                </div>
                <DatePicker
                  selectsRange={true}
                  startDate={salesStartDate}
                  endDate={salesEndDate}
                  onChange={handleChange}
                  // isClearable={!showCompleted ? true : false}
                  isClearable={true}
                  placeholderText={'Start Date -  End Date'}
                  dateFormat={'MM.dd.yyyy'}
                  // onKeyDown={(e) => {
                  //   e.preventDefault();
                  // }}
                  onClickOutside={() => setDatePickerOpen(false)}
                  open={isDatePickerOpen}
                  onFocus={() => setDatePickerOpen(true)}
                />
              </div>

            </div>

            <div className="total-count-action">
              <span>
                {`${t("total_count")}: `}
                <span className="total-count-number">
                  {getFilteredList().filter((item) => item.status !== "")?.length}
                </span>
              </span>
            </div>
          </div>
          <div className="sm-sca-search">
            <div>
              <SearchBar
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => { }}
              />
            </div>
            {/* <div> */}
            {!showCompleted && (
              <div className="add-campaign">
                <CustomButton
                  leftIcon={getPlusBtnIcon()}
                  loading={false}
                  name={t("Plan a Sales Call")}
                  enable={true}
                  backgroundColor={THEME.defaultHighLightColor}
                  onClick={() => setShowPlanPopup(true)}
                  customStyle={{ marginTop: "3em" }}
                />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>

        {getTable()}
        <div className="total-count">
          Total {tab + " Calls:  " + getFilteredList()?.length}
        </div>
      </div>
      <div className="call-counts">
        {contactStatusList.map((company) => {
          return <div className="total-count">
            Total  {company.contact_status}  Calls:
            {isFiltersApplied ? getTabFilterCount(company.contact_status) : getTabCount(company.contact_status)}
          </div>
        })}


        <div className="sales-count total-count">
          {`Total ${showCompleted ? "Completed" : "Scheduled"} Sales Calls: `}
          {contactStatusList.reduce((prev, next) => {
            return prev + (isFiltersApplied ? getTabFilterCount(next.contact_status)! : getTabCount(next.contact_status));
            // return prev + getTabCount(next.contact_status);

          }, 0)}
        </div>
      </div>
      {showCompletePopup && profileState?.profile && (
        <Portal>
          <CompleteSalesCall
            showModal={showCompletePopup}
            callType={tab}
            salesCall={selectedCall}
            successTitle={t("complete_call")}
            currentLoginUserName={getUserNameFromProfileDropdown(
              profileState?.profile
            )}
            usersList={usersList}
            onClose={() => {
              setShowCompletePopup(false);
              setSelectedCall(new SalesCall());
              dispatch(manageSalesCallActions.clearCreateSalesCallState());
              dispatch(manageSalesCallActions.clearUpdateSalesCallState());
              dispatch(salesCallDetailActions.clearSalesCallDetailState());
              if (stateSource && stateSource.source === "dashboard") {
                handleGotoAgencyDashboard();
              }
              if (stateSource && stateSource.source === "contacts") {
                props.history.push({ pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}/sales-profile` });
              }

            }}
            onSuccessClose={handleAddSalesCallSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoSalesCall}
            planNextCall={planNextCallWithContact}
            availableFrom={salesFormState.available_from}
            availableTo={salesFormState.available_to}
            showCompleted={showCompleted}
            clearStateSource={() => setStateSource({ source: "", contactId: "", companyId: "" })}
          />
        </Portal>
      )}
      {showPlanPopup && profileState?.profile && (
        <Portal>
          <PlanMySalesCalls
            showModal={showPlanPopup}
            onClose={() => {
              setShowPlanPopup(false);

              dispatch(manageSalesCallActions.clearCreateSalesCallState());
              dispatch(manageSalesCallActions.clearUpdateSalesCallState());
              dispatch(salesCallDetailActions.clearSalesCallDetailState());
              if (stateSource && stateSource.source === "dashboard") {
                handleGotoAgencyDashboard();
              }
              if (stateSource && stateSource.source === "contacts") {
                props.history.push({ pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}/sales-profile` });
              }
              if (stateSource && stateSource.source === "contacts-details") {
                props.history.push({ pathname: `${AppRoutes.contactsDetailsPage}/${stateSource.contactId}` });
              }
              if (stateSource && stateSource.source === "companies") {
                props.history.push({ pathname: `${AppRoutes.companiesDetailsPage}/${stateSource.companyId}` });
              }
              setSelectedCall(new SalesCall());
            }}
            salesCallInfo={selectedCall}
            successTitle={t("plan_my_sales_calls")}
            currentLoginUserName={getUserNameFromProfileDropdown(
              profileState?.profile
            )}
            currentLoginUserId={profileState?.profile?.id}
            onSuccessClose={handleAddSalesCallSuccess}
            onGotoAgencyDashboard={handleGotoAgencyDashboard}
            onGotoCreated={handleOnGotoSalesCall}
            onCreateNew={handleCreateNew}
            usersList={usersList}
            availableFrom={salesFormState.available_from}
            availableTo={salesFormState.available_to}
            showCompleted={showCompleted}
            clearStateSource={() => setStateSource({ source: "", contactId: "", companyId: "" })}
          />
        </Portal>
      )}
    </div>
  );


}

export default SalesCallActivity;