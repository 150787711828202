import { createAsyncThunk } from "@reduxjs/toolkit";
import { S3API } from "../../../apis/s3_api";
import { AppError } from "../../../interfaces";
import { catchRequestError } from "../../../utils";

export const getDownloadFileURLThunk = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@files/file/download-url/get',
    async (query, thunkAPI) => {
        try {
            const res = await S3API.downloadFile(query)
            if (typeof res.data === 'string') return {};
            return res.data.url;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);

