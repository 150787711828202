import { TFunction } from 'react-i18next';
import { BankActionPurposeType, COMPANY_STATUS, CONTACT_METHODS, JOB_STATUS, ORIENTATION, ROLE, TALENT_STATUS, THEME } from './enums';
import * as dateFns from 'date-fns';
import { ISelectorOption } from './interfaces';
import config from './config';
import { WebViewerOptions } from '@pdftron/webviewer';
import { getContactTypeIcon } from './utils';
import { TickBlueIcon } from './icons';
import { ONBOARDING_STATUS } from './enums/onboarding_status';
import { ACCOUNT_TYPE, PAYCHECK_TYPE } from './enums/onboarding_direct_deposit';
import { PAYROLLTAX_CONFIG_TAX, PAYROLLTAX_CONFIG_TAX_LIMIT, PAYROLLTAX_CONFIG_TAX_TYPE } from './enums/admin_center';
import { INFO_TEXT_CATEGORY } from './enums/info_text_category';

const currentWindowWidth = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const currentWindowHeight = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return height;
}

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const currentOrientation = () => {
  const { innerWidth: width, innerHeight: height } = window;
  if (height >= width) return ORIENTATION.PORTRAIT;
  else return ORIENTATION.LANDSCAPE;
}

const scale = size => window.innerWidth / guidelineBaseWidth * size;
const verticalScale = size => window.innerHeight / guidelineBaseHeight * size;
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;

const DEFAULT_SAFE_AREA_STYLES = { display: 'flex', backgroundColor: '#000' };

const IS_DEVELOPMENT = (!config.ENV || config.ENV === 'development');
const IS_PRODUCTION = (config.ENV && config.ENV === 'production');
const TEXT_EM_ALL_BROADCAST_STATUS_CHANGE = config.BROADCAST_STATUS_CHANGE;
const TEXT_EM_ALL_TEXT_IN_BOUND = config.TEXT_IN_BOUND;
const TEXT_EM_ALL_TEXT_OUT_BOUND = config.TEXT_OUT_BOUND;
const TEXT_EM_ALL_CONSUMER_KEY = config.TEXT_EM_ALL_CONSUMER_KEY;
const TEXT_EM_ALL_SECRET_KEY = config.TEXT_EM_ALL_SECRET_KEY;




const CURRENT_OS = "web";
const ASPECT_RATIO = currentWindowWidth() / currentWindowHeight();
// const PIXEL_RATIO = PixelRatio.get();

const BUTTON_HEIGHT = 50;
const BOOK_BUTTON_HEIGHT = 75;
const ICON_HEIGHT = 100;


const rolesList = [
  { label: 'administrator', value: ROLE.administrator },
  { label: 'super_user', value: ROLE.superUser },
  { label: 'branch_manager', value: ROLE.branchManager },
  { label: 'account_manager', value: ROLE.accountManager },
  { label: 'recruiter', value: ROLE.recruiter },
  { label: 'call_deck_owner', value: ROLE.callDeskOwner },
  { label: 'front_office_coordinator', value: ROLE.frontOfficeCoordinator },
  { label: 'super_admin', value: ROLE.superAdmin },
]

export const infoTextCategory = [
  { label: "agency_setup", value: INFO_TEXT_CATEGORY.AGENCY_SETUP },
  { label: "payroll_tax_configuration", value: INFO_TEXT_CATEGORY.PAYROLL_TAX_CONFIGURATION },
  { label: "sales_tax_configuration", value: INFO_TEXT_CATEGORY.SALES_TAX_CONFIGURATION },
  { label: "work_comp_configuration", value: INFO_TEXT_CATEGORY.WORK_COMP_CONFIGURATION },
]

const GENDERS = [
  { name: "male", code: "MALE" },
  { name: "female", code: "FEMALE" },
  { name: "others", code: "OTHERS" },
];

const FILE_SIZE_LIMIT = 30;

export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getDateFromTimeStamp = (value: number) => {
  return new Date(value * 1000);
}

export const convertDateToTimeStamp = (value: Date, setHours?: boolean) => {
  if (setHours) return value.setHours(0, 0, 0, 0) / 1000;
  return value.getTime() / 1000;
}

export const extractDateFromTimestamp = (timestamp: number): string => {
  // Check if timestamp is not a valid number or is null/undefined
  if (isNaN(timestamp) || timestamp === null || timestamp === undefined) {
    return ''; // Return an empty string or any default value when the timestamp is not available or not valid
  }
  // Convert the timestamp to milliseconds
  const timestampInMilliseconds = timestamp * 1000;

  // Create a new Date object from the timestamp
  const dateTime = new Date(timestampInMilliseconds);

  // Check if the date is invalid (e.g., timestamp is not valid)
  if (isNaN(dateTime.getTime())) {
    return ''; // Return an empty string or any default value when the date is invalid
  }

  // Extract date components
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');

  // Construct date string in YYYY-MM-DD format
  return `${month}-${day}-${year}`;
};

export const dateToTimestamp = (dateString: string): number => {
  // Parse the date string in the format MM-DD-YYYY
  const [month, day, year] = dateString.split('-').map(Number);

  // Create a new Date object from the parsed components
  const dateTime = new Date(year, month - 1, day); // Note: month is 0-indexed, so subtract 1

  // Return the Unix timestamp in milliseconds
  return dateTime.getTime() / 1000; // Convert milliseconds to seconds
};

export const extractTimeFromTimestamp = (timestamp: number | string): string => {
  // Check if timestamp is not a valid number or is null/undefined
  if (isNaN(Number(timestamp)) || timestamp === null || timestamp === undefined) {
    return ''; // Return an empty string or any default value when the timestamp is not available or not valid
  }

  // Convert the timestamp to a number
  const numericTimestamp = typeof timestamp === 'string' ? parseFloat(timestamp) : timestamp;
  // Convert the timestamp to milliseconds
  const timestampInMilliseconds = numericTimestamp * 1000;

  // Create a new Date object from the timestamp
  const dateTime = new Date(timestampInMilliseconds);

  // Extract time components
  let hours = dateTime.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const seconds = String(dateTime.getSeconds()).padStart(2, '0');

  // Construct time string with AM/PM in HH:MM:SS AM/PM format
  return `${hours}:${minutes}:${seconds} ${ampm}`;
};

export const dateTimeToTimestamp = (dateString, timeString) => {
  if (dateString !== null && timeString !== null && dateString !== undefined && timeString !== undefined) {
    // Parse the date string
    const [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));

    // Parse the time string including AM/PM
    let [time, ampm] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(part => parseInt(part, 10));

    // Error checking
    if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hours) || isNaN(minutes)) {
      return null; // or throw an error, depending on your requirements
    }

    // Adjust hours for PM if necessary
    if (ampm === 'PM' && hours !== 12) {
      hours += 12; // Convert to 24-hour format
    } else if (ampm === 'AM' && hours === 12) {
      hours = 0; // Convert 12 AM to 00
    }

    // Create a new Date object with the combined date, time, and AM/PM
    const combinedDateTime = new Date(year, month - 1, day, hours, minutes);

    // Check if the Date object is valid
    if (isNaN(combinedDateTime.getTime())) {
      return null; // or throw an error, depending on your requirements
    }

    // Return the timestamp
    return combinedDateTime.getTime();
  }
};


export const timestampToStringDate = (timestampDate) => {
  // Create a new Date object from the timestamp
  const date = new Date(timestampDate * 1000); // Multiply by 1000 if timestamp is in seconds

  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Construct the date string in the format "YYYY-MM-DD"
  const dateString = `${year}-${month}-${day}`;

  // Return the date string
  return dateString;
};

export const convertStringTimeToTimestamp = (time: string): number | null => {
  if (time === null || time === undefined || time === "") {
    return convertTime("12:00 AM");
  } else {
    return convertTime(time);
  }

  function convertTime(time: string) {
    if (typeof time === "string") {
      const [timePart, meridiem] = time.split(" ");
      const [hoursStr, minutesStr] = timePart.split(":");
      let hours = parseInt(hoursStr);
      const minutes = parseInt(minutesStr);

      // Special case for "12:00 AM"
      if (hours === 12 && meridiem === "AM") {
        hours = 0; // Set hours to 0 for "12:00 AM"
      } else if (meridiem === "PM" && hours < 12) {
        hours += 12;
      }

      // Create a Date object with today's date
      const currentDate = new Date();
      currentDate.setHours(hours, minutes, 0, 0);
      // Return the epoch time
      return currentDate.getTime();
    } else {
      return null;
    }
  }

};

export const convertTimestampToStringTime = (timestamp) => {

  if (timestamp === null || timestamp === undefined || timestamp === "" || timestamp === 0) {
    return "12:00 AM";
  }

  if (isNaN(timestamp)) {
    return "12:00 AM";
  }

  // Create a new Date object using the timestamp
  const date = new Date(timestamp);

  // Extract hours, minutes, and AM/PM from the Date object
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Ensure hours is a number and not NaN
  if (isNaN(hours)) {
    minutes = 0;
  }
  // Ensure minutes is a number and not NaN
  if (isNaN(minutes)) {
    minutes = 0;
  }

  // Determine AM/PM
  const ampm = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  hours = hours % 12;
  if (hours === 0) {
    hours = 12;
  }

  // Construct the formatted hours string
  const formattedHours = hours < 10 ? '0' + hours : hours;

  // Construct the formatted minutes string
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Construct the formatted time string
  const timeString = formattedHours + ':' + formattedMinutes + ' ' + ampm;

  return timeString;
}


export const getDatesBetween = (
  startDate: number,
  endDate: number,
  includeEndDate?: boolean
) => {
  const dates: Array<number> = [];
  const start = getDateFromTimeStamp(startDate);
  const end = getDateFromTimeStamp(endDate);
  while (start < end) {
    dates.push(convertDateToTimeStamp(start, true));
    start.setDate(start.getDate() + 1);
  }
  if (includeEndDate) dates.push(convertDateToTimeStamp(end, true));
  return dates;
};

export const getDateString = (value?: number | Date, type?: "default" | "ddmmmyyyy" | "dd/mm/yyyy" | "mm/dd/yyyy" | "yyyy-mm-dd") => {
  if (value == null || value == 0) return 'NA';
  const date = typeof value === "number" ? getDateFromTimeStamp(value) : value;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (type === "ddmmmyyyy") {
    return `${String(day).padStart(2, '0')} ${MONTHS[month - 1]} ${year}`;
  }
  if (type === "dd/mm/yyyy") {
    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
  }
  if (type === "mm/dd/yyyy") {
    return `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}/${year}`;
  }
  if (type === "yyyy-mm-dd") {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  }
  return `${String(month).padStart(2, '0')}.${String(day).padStart(2, '0')}.${year}`;
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getDaysRemainingString = (t: TFunction<"translation", undefined>, value?: number) => {
  if (value == null) return 'NA';
  const date = getDateFromTimeStamp(value);
  const currentDate = Date.now();
  const difference = Math.abs(currentDate - date.valueOf());
  const diffDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${diffDays} ${t('days_ago')} @ ${month}.${day}.${year}`;
}

export const getDaysAgoWithAMPM = (t: TFunction<"translation", undefined>, value?: number) => {
  if (value == null) return 'NA';
  const date = getDateFromTimeStamp(value);
  const time = formatAMPM(date);
  const currentDate = Date.now();
  const difference = Math.abs(currentDate - date.valueOf());
  const diffDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  return `${diffDays} ${t('days_ago')} @ ${time}`;
}

export const getDaysAgoWithDateAMPM = (t: TFunction<"translation", undefined>, value?: number) => {
  if (value == null) return 'NA';
  const date = getDateFromTimeStamp(value);
  const time = formatAMPM(date);
  const currentDate = Date.now();
  const difference = Math.abs(currentDate - date.valueOf());
  const diffDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${diffDays} ${t('days_ago')} - \n ${month}.${day}.${year} ${time}`;
}

export const getDaysDifference = (end_date, start_date) => {

  end_date = new Date(end_date);
  start_date = new Date(start_date);
  const diffInMs = Math.abs(end_date - start_date);
  let diffDaysNUm = (diffInMs / (1000 * 60 * 60 * 24));
  const years = Math.floor(diffDaysNUm / 365);
  const months = Math.floor(diffDaysNUm % 365 / 30);
  const days = Math.floor(diffDaysNUm % 365 % 30);
  const result = (years >= 1) ? `${years} years ${months} months ${days} days` : (months >= 1) ? `${months} months ${days} days` : `${days} days`;
  return `(${result})`;
  // return `(${(diffInMs / (1000 * 60 * 60 * 24)).toFixed(0)} days)`;
}

// export const getDateStringFormat = (value?: number) => {
//   if (value == null) return 'NA';
//   const date = getDateFromTimeStamp(value);
//   const day = date.getDate().toString().padStart(2, "0");
//   const month = (date.getMonth() + 1).toString().padStart(2, "0");
//   const year = date.getFullYear();
//   return `${year}-${month}-${day}`;
// }



export function getPrefferedContact(value: string) {
  return (
    <div className="pref-contact-icon">
      {getContactTypeIcon(value, "#00A4DC")}
    </div>
  );
}

export function getClickedOnLinkContact(value: boolean) {
  if (value) {
    return (
      <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <div style={{ width: "1.25vw" }}><TickBlueIcon fill="#00A4DC" style={{ width: "100%", height: "100%" }} /></div>
      </div>
    );
  }
}

export function getStatus(value: string, t) {
  if (value === ONBOARDING_STATUS.talentOnboardingInProgress) return (<span>{t('talent_onboarding_in_progress')}</span>);
  else if (value === ONBOARDING_STATUS.onboardingLinkSent) return (<span>{t('onboarding_link_sent')}</span>);
  else if (value === ONBOARDING_STATUS.pendingForReview) return (<span>{t('pending_for_review')}</span>);
  else if (value === ONBOARDING_STATUS.onboaardingComplete) return (<span>{t('onboarding_complete')}</span>);
  else return (<span>{''}</span>);
}

export function getBOERStatus(status: string) {
  if (status === "OPEN")
    return (
      <span style={{ color: THEME.statusInActiveColor }}>{status}</span>
    );
  else if (status === "RESOLVED")
    return <span style={{ color: THEME.statusDisabledColor }}>{status}</span>;
  else if (status === "APPROVED")
    return <span style={{ color: THEME.buttonColor17 }}>{status}</span>;
  else return <span>{""}</span>;
}

const outlineBtnStyle: { btn: React.CSSProperties, text: React.CSSProperties } = {
  btn: {
    border: `2px solid ${THEME.defaultHighLightColor}`,
    backgroundColor: THEME.white,
  },
  text: {
    color: THEME.defaultHighLightColor
  }
}

const lowBtnStyle: { btn: React.CSSProperties, text: React.CSSProperties } = {
  btn: {
    backgroundColor: "#B5BAC5",
  },
  text: {
    color: THEME.white,
  }
}
export const dateType: ISelectorOption[] = [
  { label: "check_date", value: true },
  { label: "week_ending_date", value: false },
];
export const invoiceDateType: ISelectorOption[] = [
  { label: "invoice_date", value: true },
  { label: "week_ending_date", value: false },
];
export const onBoardingHealthCareOptions: ISelectorOption[] = [
  { label: "yes", value: true, },
  { label: "no", value: false, },
];
export const payrollTaxConfigTaxOptions: ISelectorOption[] = [
  { label: "employer", value: PAYROLLTAX_CONFIG_TAX.employer, },
  { label: "employee", value: PAYROLLTAX_CONFIG_TAX.employee, },
];
export const payrollTaxConfigTaxTypeOptions: ISelectorOption[] = [
  { label: "federal", value: PAYROLLTAX_CONFIG_TAX_TYPE.federal, },
  { label: "state", value: PAYROLLTAX_CONFIG_TAX_TYPE.state, },
  { label: "local", value: PAYROLLTAX_CONFIG_TAX_TYPE.local, },
];
export const salesTaxConfigTaxTypeOptions: ISelectorOption[] = [
  { label: "state", value: PAYROLLTAX_CONFIG_TAX_TYPE.state, },
  { label: "local", value: PAYROLLTAX_CONFIG_TAX_TYPE.local, },
];
export const payrollTaxConfigTaxLimitOptions: ISelectorOption[] = [
  { label: "yes", value: PAYROLLTAX_CONFIG_TAX_LIMIT.yes, },
  { label: "no", value: PAYROLLTAX_CONFIG_TAX_LIMIT.no, },
];

export const adminCenterBackOfficeBankAccountPurposeOptions: ISelectorOption[] = [
  { label: "Payments", value: BankActionPurposeType.PAYMENTS },
  { label: "Receivables", value: BankActionPurposeType.RECEIVABLES },
];
export const onBoardingDirectDepositAccountTypeOptions: ISelectorOption[] = [
  { label: "Checking", value: ACCOUNT_TYPE.checking },
  { label: "Savings", value: ACCOUNT_TYPE.savings },
  { label: "PayCard", value: ACCOUNT_TYPE.paycard },
];
export const onBoardingDirectDepositPaycheckTypeOptions: ISelectorOption[] = [
  { label: "Fixed $ Amount", value: PAYCHECK_TYPE.fixed$amount },
  { label: "Percentage", value: PAYCHECK_TYPE.percentage },
  { label: "Remaining Amount", value: PAYCHECK_TYPE.remainingamount },
];

// const companyStatusOptions: ISelectorOption[] = [
//   { label: "prospect", value: COMPANY_STATUS.prospect, backgroundColor: '#00a4dc10', color: '#00a4dc' },
//   { label: "suspect", value: COMPANY_STATUS.suspect, backgroundColor: '#00a4dc10', color: '#00a4dc' },
//   { label: "current_customer", value: COMPANY_STATUS.currentCustomer, backgroundColor: '#00a4dc10', color: '#00a4dc' },
//   { label: "inactive", value: COMPANY_STATUS.inActive, backgroundColor: '#b1b1b110', color: '#b1b1b1' },
// ]

const statusOptions: ISelectorOption[] = [
  { label: "active", value: TALENT_STATUS.active, backgroundColor: '#00be4b10', color: '#00be4b' },
  { label: "inactive", value: TALENT_STATUS.inactive, backgroundColor: '#b1b1b110', color: '#b1b1b1' },
];

const jobsStatusOptions: ISelectorOption[] = [
  { label: 'open', value: JOB_STATUS.open, backgroundColor: '#ff000010', color: '#ff0000' },
  { label: 'filled', value: JOB_STATUS.filled, backgroundColor: '#00be4b10', color: '#00be4b' },
  { label: 'cancelled_by_client', value: JOB_STATUS.cancelledByClient, backgroundColor: '#b1b1b110', color: '#b1b1b1' },
  { label: 'mistaken_order', value: JOB_STATUS.mistakenOrder, backgroundColor: '#b1b1b110', color: '#b1b1b1' },
  { label: 'closed', value: JOB_STATUS.closed, backgroundColor: '#b1b1b110', color: '#b1b1b1' },
];

const contactMethodOptions: ISelectorOption[] = [
  { label: "email", value: CONTACT_METHODS.email },
  { label: "phone", value: CONTACT_METHODS.phone },
  { label: "text", value: CONTACT_METHODS.text },
  { label: "zip_recruiter", value: CONTACT_METHODS.zipRecruiter },
  { label: "indeed", value: CONTACT_METHODS.indeed },
  { label: "facebook", value: CONTACT_METHODS.facebook },
  { label: "linked_in", value: CONTACT_METHODS.linkedIn },
  { label: "in_person", value: CONTACT_METHODS.inperson },
  { label: "zoom", value: CONTACT_METHODS.zoom },
  { label: "teams", value: CONTACT_METHODS.teams },
  { label: "google_meet", value: CONTACT_METHODS.googleMeet },
]

const webviewOptions: WebViewerOptions = {
  path: '/webviewer',
  disabledElements: [
    // 'toolsHeader',
    'contextMenuPopup',
    'ribbons',
    'toggleNotesButton',
    'searchButton',
    'menuButton',
    'rubberStampToolGroupButton',
    'stampToolGroupButton',
    'fileAttachmentToolGroupButton',
    'calloutToolGroupButton',
    'undo',
    'redo',
    'eraserToolButton',
    'viewControlsButton',
    'viewControlsOverlay',
    'panToolButton',
    'selectToolButton',
    'leftPanelButton',
  ],
};
export const textEmAllHeaderOptions = {
  "Content-type": "application/json; charset=UTF-8",
  "Authorization": `OAuth "oauth_consumer_key"= ${TEXT_EM_ALL_CONSUMER_KEY} "oauth_secret_key"= ${TEXT_EM_ALL_SECRET_KEY}
  "oauth_version"="1.0"
  "oauth_signature_method"="HMAC-SHA1"
  "oauth_token"="00000000-0000-0000-0000-000000000000"`,
}
export {

  scale, verticalScale, moderateScale, currentOrientation,
  currentWindowWidth, currentWindowHeight,

  ASPECT_RATIO,
  ORIENTATION,
  BUTTON_HEIGHT,
  BOOK_BUTTON_HEIGHT,
  ICON_HEIGHT,

  CURRENT_OS,
  DEFAULT_SAFE_AREA_STYLES,
  GENDERS,
  FILE_SIZE_LIMIT,

  rolesList,
  // companyStatusOptions,
  statusOptions,
  jobsStatusOptions,
  contactMethodOptions,

  outlineBtnStyle,
  lowBtnStyle,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  TEXT_EM_ALL_BROADCAST_STATUS_CHANGE,
  TEXT_EM_ALL_TEXT_IN_BOUND,
  TEXT_EM_ALL_TEXT_OUT_BOUND,
  TEXT_EM_ALL_SECRET_KEY,
  TEXT_EM_ALL_CONSUMER_KEY,
  webviewOptions,
};




