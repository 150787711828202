import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../enums";
import CloseIcon from "../../../../../assets/icons/close-btn2.svg";
import { createUploadVideo } from "../../../../redux/sales_marketing";
import { useAppDispatch } from "../../../../redux/store";
import { FormInput, CustomButton } from "../../../../utils";
import './marketing_docs.scss';

const UploadVideo = ({ onClose, onDismiss, visible, onApprove, state, jobOrderId }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    const [showButton, setShowButton] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [inputData, setInputData] = useState({ 'file_name': '', 'file_path': '' });

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleInput(id, value) {
        let newData = { [id]: value };
        setInputData({ ...inputData, ...newData });
        setShowButton(true);
        let finalData = { ...inputData, [id]: value };
        for (let key of Object.keys(finalData))
            if (!finalData[key]) {
                setShowButton(false);
                break;
            }
        // if (!finalData.file_path.toLowerCase().includes('v='))
        //     setShowButton(false)
    }

    function handleSave() {
        dispatch(createUploadVideo({ ...inputData }));
        setSentRequest(true);
    }

    useEffect(() => {
        if (state?.videoUpload && state.videoUpload.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            setInputData({ ...inputData, file_name: '', file_path: '' });
            setSuccessMessage(state.videoUpload.response);
            setTimeout(() => (setSuccessMessage('')), 4000);
            onApprove();
        }
    }, [state?.videoUpload])
    useEffect(() => {
        if (state?.delete_document && state.delete_document.loading === LoadingType.succeeded) {
            onApprove();
        }
    }, [state.delete_document])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={600}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="video-upload-container">
                <div className="video-upload-container-dialog-header">
                    <h5>{t('upload_video')}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} style={{ "cursor": "pointer" }} />
                </div>
                <div className="video-upload-container-dialog-content">
                    <FormInput
                        id={"file_name"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputData.file_name}
                        label={t("title")}
                        placeholder=""
                    />
                    <FormInput
                        id={"file_path"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputData.file_path}
                        label={t("youtube_link")}
                        placeholder=""
                    />
                </div>
                <p className="video-upload-message">{successMessage}</p>
                <div className="video-upload-container-dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={showButton}
                            loading={sentRequest}
                            name={t("upload_video")}
                            padding={12}
                            fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default UploadVideo;