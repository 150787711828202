import React, {useEffect, useRef, useState} from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import CloseIcon from '../../../../assets/icons/close-btn2.svg';
import {LoadingType, THEME} from "../../../enums";
import {CustomButton, getPlusBtnIcon } from "../../../utils";
import {useTranslation} from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import {AddCircleOutline} from "@material-ui/icons";
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createContactRole, getContactRoles, updateContactRole, deleteContactRole,
    selectContactRoleState, selectContactRoleList
} from "../../../redux/admin_center";
import {postSnackbarMessage, useAppDispatch, useAppSelector} from "../../../redux/store";
import './system_wide_page.scss';
import {Portal} from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import '../../../custom.scss';
import {FormControl, MenuItem, Select} from "@material-ui/core";
import TableEmpty, {TableErrorHandler, TableLoading} from "../../../components/table_empty/table_empty";
import {FormInput} from "../../../utils/form_input";
import CustomFormSelect from '../../../components/form_selector/form_select';

const tableHeader = [
    {title: 'id', code: 'id'},
    {title: 'value', code: 'actions'},
    {title: 'actions', code: 'actions'},
];

const categories = {
    1: {
        label: "contact_roles", value: ["contact_role"], buttonLabel: "add_contact_role",
        inputLabel: "contact_role", deleteLabel: "delete_contact_role",
        deleteMessage: "delete_contact_role_message", updateLabel: "update_contact_role",
        methods: {create: createContactRole, update: updateContactRole, remove: deleteContactRole,
            get: getContactRoles},
        headers: tableHeader,
        deleteAlertMessage: "Contact Role Deleted Successfully!"

    }
}

const SystemWidePage = (props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({'id': '', 'value': ''});
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const contactRoleState = useAppSelector((state) => selectContactRoleState(state));
    const contactRoleList = useAppSelector((state) => selectContactRoleList(state));

    const reduxMapping = {1: {state: contactRoleState, list: contactRoleList}}

    useEffect(() => {
        if (contactRoleState.remove?.loading === LoadingType.succeeded) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [contactRoleState.remove?.loading])

    function getCategory(id=null){
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id=null){
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog(){
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog(){
        setShowDeleteDialog(false);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id){
        setShowDeleteDialog(true);
        setDeleteItemId(id);
    }

    function handleDeleteApprove(){
        if (deleteItemId){
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        setSnackBarMessage(getCategory().deleteAlertMessage)
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method){
        setShowEditDialog(true);
        setMethodValue({...methodValue, 'id': method.id, 'value': method[getCategory().value]});
    }

    function handleCloseEditDialog(){
        setShowEditDialog(false);
    }

    function handleDropdownChange(value){
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function retryFetchingData(){
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if(getMapping().state.loading == LoadingType.succeeded){
            setDropDownChanged(false);
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if(getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                 </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <CustomFormSelect
                        name={t("contacts_dropdown")}
                        list={Object.keys(categories).map(id => ({value: id, label: categories[id].label}))}
                        onChange={(val) => handleDropdownChange(val)}
                        value={selectedCategory}
                        placeholder={t('contact_roles')}
                        required={false}
                        label={t('please_select_contact_category')}
                        customStyle={{marginTop: "0 !important", maxWidth: "20vw"}}
                    />
                    <div>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            enable={true}
                            leftIcon={getPlusBtnIcon()}
                            name={t(categories[selectedCategory].buttonLabel)}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowCreateDialog(true))}
                            customStyle={{width: "max-content"}}
                        />
                    </div>
                </div>
            </div>
            <div className="ac-sc-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading/> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                    />
                }
            </div>
            {showCreateDialog && <Portal>
                <CreateAction
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                />
            </Portal>}
            {showDeleteDialog && <Portal>
                <DeleteAction
                    visible={showDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onDismiss={handleCloseDeleteDialog}
                    onApprove={handleDeleteApprove}
                    category={getCategory()}
                />
            </Portal>}
            {showEditDialog && <Portal>
                <EditAction
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                />
            </Portal>}
        </div>

    )
}

const TableBody = ({state, onRetry, data, category, handleShowEdit, handleShowDelete}) => {
    if (state.loading == LoadingType.failed){
        return (<TableErrorHandler error={state.error} onRefresh={onRetry}/>)
    }
    else if (data.length == 0){
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }
    else{
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData>{doc.id}</TableData>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id+index} customStyle={{wordWrap: "break-word"}}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                <img src={EditIcon} alt='edit_button' style={{marginRight: '20px'}}
                                     onClick={() => handleShowEdit(doc)}/>
                                <img src={DeleteIcon} alt='delete_button'
                                     onClick={() => handleShowDelete(doc.id)}/>
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

const CreateAction = ({onClose, onDismiss, visible, category, category_id}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const contactRoleState = useAppSelector((state) => selectContactRoleState(state));
    const contactRoleList = useAppSelector((state) => selectContactRoleList(state));

    const reduxMapping = {1: {state: contactRoleState, list: contactRoleList}}

    function getMapping(){
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (methodValue && state.create.loading === LoadingType.succeeded){
            setMethodValue('');
            setShowButton(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 1000);
            setTimeout(() => (onClose()), 2000);
            dispatch(category.methods.get());
        }
    }, [getMapping().state.create.loading])

    function handleInput(value){
        if (value.trim()){
            setShowButton(true);
        }
        else{
            setShowButton(false);
        }
        setMethodValue(value);
    }

    function handleSave(){
        setSentRequest(true);
        dispatch(category.methods.create({'value': methodValue.trim()}));
    }

    return(
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container">
                <div className="dialog-header">
                    <h5>{t(category.buttonLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)}/>
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(val))}
                        type={"text"}
                        value={methodValue}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.create.error && sentRequest ?
                        (<p style={{color: 'red'}} className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                        (<p style={{color: 'green'}} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{textTransform: 'capitalize'}}
                        name={t('save')}
                        enable={showButton}
                        loading={getMapping().state.create.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const DeleteAction = ({onClose, onDismiss, visible, onApprove, category}) => {

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={400}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-delete-container" style={{height: "150px"}}>
                <div className="dialog-header">
                    <h5 style={{color: THEME.red}}>{t(category.deleteLabel)}</h5>
                </div>
                <div className="dialog-content">
                    <p>{t(category.deleteMessage)}</p>
                </div>
                <div className="dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            name={t('yes')}
                            enable={true}
                            backgroundColor={'#0079C1'}
                            onClick={() => closePopup(onApprove)}
                        />
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            name={t('no')}
                            enable={true}
                            backgroundColor={'#5A3F98'}
                            onClick={() => closePopup(onClose)}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

const EditAction = ({onClose, onDismiss, visible, methodValue, category, category_id}) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [inputValue, setInputValue] = useState(methodValue.value);
    const [showButton, setShowButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const contactRoleState = useAppSelector((state) => selectContactRoleState(state));
    const contactRoleList = useAppSelector((state) => selectContactRoleList(state));

    const reduxMapping = {1: {state: contactRoleState, list: contactRoleList}}

    function getMapping(){
        return reduxMapping[category_id];
    }

    function handleInput(value){
        if(value.trim() === methodValue.value){
            setShowButton(false);
        }
        else if (value.trim()){
            setShowButton(true);
        }
        else{
            setShowButton(false);
        }
        setInputValue(value);
    }

    function handleUpdate(){
        setSentRequest(true);
        dispatch(category.methods.update({'id': methodValue.id, 'value': inputValue.trim()}));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if(getMapping().state.update.loading == LoadingType.succeeded && methodValue.value != inputValue){
            setInputValue(inputValue.trim());
            methodValue.value = inputValue.trim();
            setShowButton(false);
            setSuccessMessage(getMapping().state.update.response);
            setTimeout(() => (setSuccessMessage('')), 1000);
            setTimeout(() => (onClose()), 2000);
            dispatch(category.methods.get(category.url));
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container">
                <div className="dialog-header">
                    <h5>{t(category.updateLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)}/>
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(val))}
                        type={"text"}
                        value={inputValue}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.update.error && sentRequest ?
                        (<p style={{color: 'red'}} className="message">{getMapping().state.update.error.response.detail.error}</p>) :
                        (<p style={{color: 'green'}} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{textTransform: 'capitalize'}}
                        name={t('update')}
                        enable={showButton}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default SystemWidePage;

function setSnackBarMessage(deleteAlertMessage: any) {
    throw new Error('Function not implemented.');
}
