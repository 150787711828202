import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import InDevelopment from "../../others/in_development";
import MetricsPage from "./pages/metrics_page/metrics_page";
import { AppRoutes, getDashboardNavHeaderProps } from "../../routes";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import TalentEngagementUniversalDirectoryPage from "./pages/talent_engagement/universal_directory/universal_directory";
import CompanyEngagementUniversalDirectoryPage from "./pages/company_engagement/universal_directory/universal_directory";
import AvailableCandidatesUniversalDirectoryPage from "./pages/available_candidates/universal_directory/universal_directory";
import OnlineApplicationsReceivedUniversalDirectory from "./pages/online_applications_received/universal_directory";
import { OnlineApplicationsSentUniversalDirectory } from "./pages/online_applications_sent/universal_directory";
import { OnlineApplicationReceivedDashboard } from "./pages/online_applications_received/dashboard";
import { Close } from "@material-ui/icons";
import { ScheduledInterviewUniversalDirectory } from "./pages/scheduled_internal_interviews/universal_directory";
import { OnlineApplicationSentDashboard } from "./pages/online_applications_sent/dashboard";
import OnlineEmploymentHistoryPage from "./pages/online_employment_history/online_employment_history";
import OnboardingCandidateInputRequiredTabNav from "./onboarding_candidate_input_required";
import OnboardingReadyForApprovalTabNav from "./onboarding_ready_for_approval";
import OnboardingCompletedThisWeekTabNav from "./onboarding_completed_this_week";
import { GetCloseAction } from "../../utils";
import ContactEngagementUniversalDirectoryPage from "./pages/contacts_engagement/universal_directory";


interface Props extends RouteComponentProps<any> { }

/// [DASHBOARD]-->[Talent Engagement] Universal directory folder
const TalentEngagementTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "universal_directory",
            route: `${AppRoutes.dashboardPage}/${params.type}/talent-engagement/universal-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
        actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.dashboardPage)}/>}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.dashboardPage}/:type/talent-engagement/universal-directory`}
            component={TalentEngagementUniversalDirectoryPage}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.dashboardPage}/${params.type}/talent-engagement/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [DASHBOARD]-->[Company Engagement] Universal directory folder
const CompaniesEngagementTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "universal_directory",
            route: `${AppRoutes.dashboardPage}/${params.type}/company-engagement/universal-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
        actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.dashboardPage)}/>}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.dashboardPage}/:type/company-engagement/universal-directory`}
            component={CompanyEngagementUniversalDirectoryPage}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.dashboardPage}/${params.type}/company-engagement/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};
/// [DASHBOARD]-->[Contact Engagement] Universal directory folder
const ContactEngagementTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "universal_directory",
            route: `${AppRoutes.dashboardPage}/${params.type}/contact-engagement/universal-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
        actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.dashboardPage)}/>}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.dashboardPage}/:type/contact-engagement/universal-directory`}
            component={ContactEngagementUniversalDirectoryPage}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.dashboardPage}/${params.type}/contact-engagement/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [DASHBOARD]-->[Available candidates] Universal directory folder
const AvailableCandidatesTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "universal_directory",
            route: `${AppRoutes.dashboardPage}/available-candidates/universal-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.dashboardPage}/available-candidates/universal-directory`}
            component={AvailableCandidatesUniversalDirectoryPage}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.dashboardPage}/available-candidates/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [INTERVIEWS]
/// [DASHBOARD]-->[Remaining Interviews] Universal directory folder
const RemainingInternalInterviewsTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "remaining_internal_interviews_week",
            route: `${AppRoutes.internalInterviewsScheduled}/universal-directory`,
          },
          {
            title: "archive_directory",
            route: `${AppRoutes.internalInterviewsScheduled}/archive-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.internalInterviewsScheduled}/universal-directory`}
            render={(props) => (
              <ScheduledInterviewUniversalDirectory
                isArchiveDirectory={false}
                isCompleted={false}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${AppRoutes.internalInterviewsScheduled}/archive-directory`}
            render={(props) => (
              <ScheduledInterviewUniversalDirectory
                isArchiveDirectory
                isCompleted={false}
                {...props}
              />
            )}
          />
          <Route
            path={`${AppRoutes.internalInterviewsScheduled}/:id`}
            component={InDevelopment}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.internalInterviewsScheduled}/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [DASHBOARD]-->[Completed Interviews] Universal directory folder
const CompletedInternalInterviewsTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "active_directory",
            route: `${AppRoutes.internalInterviewsCompleted}/universal-directory`,
          },
          {
            title: "archive_directory",
            route: `${AppRoutes.internalInterviewsCompleted}/archive-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.internalInterviewsCompleted}/universal-directory`}
            render={(props) => (
              <ScheduledInterviewUniversalDirectory
                isArchiveDirectory={false}
                isCompleted
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${AppRoutes.internalInterviewsCompleted}/archive-directory`}
            render={(props) => (
              <ScheduledInterviewUniversalDirectory
                isArchiveDirectory
                isCompleted
                {...props}
              />
            )}
          />
          <Route
            path={`${AppRoutes.internalInterviewsCompleted}/:id`}
            component={InDevelopment}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.internalInterviewsCompleted}/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [APPLICATIONS]
const OnlineApplicationsReceivedTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "active_directory",
            route: `${AppRoutes.onlineApplicationsReceived}/universal-directory`,
          },
          {
            title: "archive_directory",
            route: `${AppRoutes.onlineApplicationsReceived}/archive-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsReceived}/universal-directory`}
            render={(props) => (
              <OnlineApplicationsReceivedUniversalDirectory
                isArchiveDirectory={false}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsReceived}/archive-directory`}
            render={(props) => (
              <OnlineApplicationsReceivedUniversalDirectory
                isArchiveDirectory
                {...props}
              />
            )}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.onlineApplicationsReceived}/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

const OnlineApplicationsSentTabNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { t } = useTranslation();
  const headerOptions = getDashboardNavHeaderProps(location, match, t);
  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "active_directory",
            route: `${AppRoutes.onlineApplicationsSent}/universal-directory`,
          },
          {
            title: "archive_directory",
            route: `${AppRoutes.onlineApplicationsSent}/archive-directory`,
          },
        ]}
        onChange={(value) => history.push(value)}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsSent}/universal-directory`}
            render={(props) => (
              <OnlineApplicationsSentUniversalDirectory
                isArchiveDirectory={false}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsSent}/archive-directory`}
            render={(props) => (
              <OnlineApplicationsSentUniversalDirectory
                isArchiveDirectory
                {...props}
              />
            )}
          />
          <Route
            path={`${AppRoutes.onlineApplicationsSent}/:id`}
            component={InDevelopment}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${AppRoutes.onlineApplicationsSent}/universal-directory`,
                  state: { from: location, ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

const onlineApplicationReceivedDetailsNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();

  function handleNavigationChange(value: string) {
    history.push({
      pathname: value,
      state: location.state,
    });
  }
  const headerOptions = getDashboardNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[
          {
            title: "online_application",
            route: `${AppRoutes.onlineApplicationsReceived}/details/${params.id}/online-application`,
          },
          {
            title: "employment_history",
            route: `${AppRoutes.onlineApplicationsReceived}/details/${params.id}/employment-history`,
          },
        ]}
        onChange={handleNavigationChange}
        actions={<GetCloseAction name={t('close')} onClick={() => history.push(`${AppRoutes.onlineApplicationsReceived}/universal-directory`)}/>}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsReceived}/details/:id/online-application`}
            component={OnlineApplicationReceivedDashboard}
          />
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsReceived}/details/:id/employment-history`}
            component={OnlineEmploymentHistoryPage}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${location.pathname}/online-application`,
                  state: { ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

const onlineApplicationSentDetailsNav: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { history, location, match } = props;
  const { params } = match;
  const { t } = useTranslation();

  function handleNavigationChange(value: string) {
    history.push({
      pathname: value,
      state: location.state,
    });
  }
  const headerOptions = getDashboardNavHeaderProps(location, match, t);

  return (
    <PageNavHeader data={headerOptions} history={history} location={location}>
      <TabNavigation
        activeRoute={location.pathname}
        tabList={[]}
        onChange={handleNavigationChange}
        actions={<GetCloseAction name={t('close')} onClick={() => history.push(`${AppRoutes.onlineApplicationsSent}/universal-directory`)}/>}
      >
        <Switch>
          <Route
            exact
            path={`${AppRoutes.onlineApplicationsSent}/details/:id/online-application`}
            component={OnlineApplicationSentDashboard}
          />
          <Route
            path={"/"}
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `${location.pathname}/online-application`,
                  state: { ...(location.state as any) },
                }}
              />
            )}
          />
        </Switch>
      </TabNavigation>
    </PageNavHeader>
  );
};

/// [ONBOARDING]



export const DashboardNavigation: React.FunctionComponent<Props> = (
  props: Props
) => {
  return (
    <Switch>
      <Route
        path={`${AppRoutes.onlineApplicationsReceived}/details/:id`}
        component={onlineApplicationReceivedDetailsNav}
      />
      <Route
        path={`${AppRoutes.onlineApplicationsSent}/details/:id`}
        component={onlineApplicationSentDetailsNav}
      />
      <Route
        path={`${AppRoutes.dashboardPage}/available-candidates`}
        component={AvailableCandidatesTabNav}
      />
      <Route
        path={`${AppRoutes.onlineApplicationsReceived}`}
        component={OnlineApplicationsReceivedTabNav}
      />
      <Route
        path={`${AppRoutes.onlineApplicationsSent}`}
        component={OnlineApplicationsSentTabNav}
      />
      <Route
        path={`${AppRoutes.dashboardPage}/:type/company-engagement`}
        component={CompaniesEngagementTabNav}
      />
      <Route
        path={`${AppRoutes.dashboardPage}/:type/contact-engagement`}
        component={ContactEngagementTabNav}
      />
      <Route
        path={`${AppRoutes.dashboardPage}/:type/talent-engagement`}
        component={TalentEngagementTabNav}
      />
      <Route
        path={`${AppRoutes.internalInterviewsScheduled}`}
        component={RemainingInternalInterviewsTabNav}
      />
      <Route
        path={`${AppRoutes.internalInterviewsCompleted}`}
        component={CompletedInternalInterviewsTabNav}
      />
      <Route
        path={AppRoutes.onboardingCandidateInputRequired}
        component={OnboardingCandidateInputRequiredTabNav}
      />
      <Route
        path={AppRoutes.onboardingReadyForApprovalByRecruiter}
        component={OnboardingReadyForApprovalTabNav}
      />
      <Route
        path={AppRoutes.onboardingCompletedThisWeek}
        component={OnboardingCompletedThisWeekTabNav}
      />
      <Route exact path={"/dashboard/boards"} component={MetricsPage} />
      <Route
        path={"/"}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/dashboard/boards",
              state: { from: location },
            }}
          />
        )}
      />
    </Switch>
  );
};
