import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { LoadingType } from "../../../enums";
import PaycheckHistorySection from "./paycheck_history/paycheck_history_section";
import DeductionHistorySection from "./deduction_history/deduction_history_section";
import ContributionHistorySection from "./contribution_history/contribution_history_section";
import { getEDPayrollDetailsList, selectEDPayrollDetailsState } from "../../../redux/employee_dashboard/payroll_details";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import './payroll_details.scss'

interface Props {
    history: RouteComponentProps["history"];
}

export const PayrollDetails: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { history } = props;

    const edPayrollDetailsState = useAppSelector((state) => selectEDPayrollDetailsState(state))

    const contributionHistoryList = []

    useEffect(() => {
        dispatch(getEDPayrollDetailsList());
    }, [])

    return (
        <div className="ep-pd-container">
            <div className="ep-pd-section">
                <PaycheckHistorySection
                    paycheckHistoryList={edPayrollDetailsState?.response?.paychecks!}
                    history={history}
                    loading={edPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
            <div className="ep-pd-section">
                <DeductionHistorySection
                    deductionHistoryList={edPayrollDetailsState?.response?.deductions!}
                    history={history}
                    loading={edPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
            <div className="ep-pd-section">
                <ContributionHistorySection
                    contributionHistoryList={contributionHistoryList}
                    history={history}
                    loading={edPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
        </div>
    );
}

