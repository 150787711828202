import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";
import * as thunk from './employee_contribution_thunk';


export interface EmployeeContributionsListState extends EntityState<AdminCenterBackOfficeType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const EmployeeContributionsListAdapter: EntityAdapter<AdminCenterBackOfficeType> = createEntityAdapter<AdminCenterBackOfficeType>({
    selectId: (doc) => doc.id
})

const initialState: EmployeeContributionsListState = EmployeeContributionsListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const EmployeeContributionsListSlice = createSlice({
    name: 'EmployeeContributionsList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEmployeeContribution.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEmployeeContribution.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEmployeeContribution.fulfilled, (state, action) => EmployeeContributionsListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createEmployeeContribution.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createEmployeeContribution.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createEmployeeContribution.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateEmployeeContribution.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateEmployeeContribution.rejected, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateEmployeeContribution.fulfilled, (state, action) => ({ ...state, update: {...state.update, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            
            .addCase(thunk.deleteEmployeeContribution.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteEmployeeContribution.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteEmployeeContribution.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = EmployeeContributionsListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = EmployeeContributionsListAdapter.getSelectors();
export const selectState = (state: EmployeeContributionsListState) => state;
export const selectLoading = (state: EmployeeContributionsListState) => state.loading;
export const selectError = (state: EmployeeContributionsListState) => state.error;


