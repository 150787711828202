import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder'
import ApiError from '../../../../components/api_error'
import { THEME, LoadingType } from '../../../../enums'
import { CustomButton } from '../../../../utils'
import { AppError } from '../../../../interfaces'

interface Props {
    visible?: boolean;
    fileName?: string;
    batchNumber?: string;
    onClose: () => void;
    handleClearError: () => void;
    onConfirm: () => void;
    onDissmiss: () => void;
    error: AppError | null | undefined;
    loading: LoadingType;
    message: string;
    headerText: string;
    btnName: string;
}

const DeleteDocumentPopup: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { onClose, handleClearError, onConfirm, onDissmiss, error, loading, visible, fileName, message, headerText, btnName } = props
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (loading === LoadingType.succeeded) {
            closePopup(onClose);
        }
    }, [loading])

    return (
        <div>
            <ActionDialogHolder
                ref={actionRef}
                visible={visible!}
                wrapperWidth={430}
                onClose={onClose}
                onDissmiss={onDissmiss}
            >
                <div className='del-doc-container'>
                    <div className="del-role-container" style={{padding: "0.5em"}}>
                        <div className="del-role-header">
                            <div className="title-txt">
                                <span>{headerText}</span>
                            </div>
                        </div>
                        <div className="del-role-content">
                            <div className="message-txt">
                                <div className='del-msg-text'>
                                    <span style={{textTransform: "none", fontSize: "0.919vw"}}>{message}&nbsp;</span>
                                    {fileName}
                                </div>
                            </div>
                            {error && <div className={"error-section"}>
                                <ApiError message={error?.message} onClose={handleClearError} />
                            </div>}
                        </div>
                        <div className="del-role-action-holder">
                            <div className="del-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.secondaryColor4}
                                        onClick={()=>{
                                            onClose();
                                            handleClearError()
                                        }}
                                    />
                                </div>
                                <div className="password-update-dialog-footer">
                                    <CustomButton
                                        loading={loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        enable={true}
                                        name={btnName}
                                        backgroundColor={THEME.red}
                                        onClick={onConfirm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ActionDialogHolder>
        </div>
    )
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default DeleteDocumentPopup
