import { t } from "i18next";
import CurveTabNavigation from "../../../../../components/curve_tab_navigation/curve_tab_navigation";
import "./workcompcompliancereport_dashboard.scss";
import { EditIcon } from "../../../../../icons";
import { useEffect, useState } from "react";
import { Portal } from "react-portal";
import CreateReportPopup from "../../../popup/create_report";
import InsuranceCompanyDetailsSection from "./insurance_company_details_section";
import {
  IUpdateReport,
  IWorkCompAuditAndComplianceReporting,
} from "../../../../../interfaces/work_comp_audit_and_compliance_reporting";
import WCCRDetailsSection from "./wccr_details_section";
import {
  postSnackbarMessage,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store";
import {
  selectWorkCompAuditAndComplianceReportingListState,
  selectWorkCompAuditAndComplianceReportinByID,
  getAllWorkCompAuditAndComplianceReportingList,
  selectRiskMgmtInsuranceCompanyByID,
  getAllInsuranceCompanies,
  selectRiskMgmtInsuranceCompanyState,
  workCompAuditAndComplianceReportUpload,
  workCompAuditAndComplianceReportUpdate,
  selectWCCRUpdateState,
  getAllWorkCompAuditAndComplianceReportById,
  selectWCCRUploadDocState,
} from "../../../../../redux/risk_management/universal_directory";
import { LoadingType } from "../../../../../enums";
import { Spinner } from "reactstrap";
import { workCompAuditAndComplianceReportingListActions } from "../../../../../redux/risk_management/universal_directory/work_comp_audit_and_compliance_reporting/work_comp_audit_and_compliance_reporting_reducer";
import StateChangeHandler from "../../../../../components/state_change_handler/state_change_handler";
import { AppRoutes } from "../../../../../routes";
import { S3API } from "../../../../../apis/s3_api";

interface IProps {
  match: any;
  location: any;
  history: any;
}

export const WCCReportDashboard: React.FunctionComponent<IProps> = (props) => {
  const { match, location, history } = props;
  const { params } = match;
  const dispatch = useAppDispatch();

  const wccrID = params.id;
  const selectState = useAppSelector(
    selectWorkCompAuditAndComplianceReportingListState
  );

  const wccrUpdateState = useAppSelector(
    selectWCCRUpdateState
  );

  const wccrUploadDocState = useAppSelector(selectWCCRUploadDocState);

  const [reportInfo, setReportInfo] = useState<IWorkCompAuditAndComplianceReporting>();
  const [editReport, setEditReport] = useState<{ visible: boolean, data: IWorkCompAuditAndComplianceReporting } | null>(null);
  const [wcurAttachedDocs, setWcurAttachedDocs] = useState<Array<File> | null>(null);

  const insuranceCompany = useAppSelector(
    selectRiskMgmtInsuranceCompanyByID(reportInfo?.insurance_company_id!)
  );

  useEffect(() => {
    getWCCRDetails()
  }, []);

  useEffect(() => {
    if (selectState.getById.loading === LoadingType.succeeded) {
      setReportInfo(selectState.getById.response)
    }
  }, [selectState.getById.loading]);

  useEffect(() => {
    if (selectState.delete.loading === LoadingType.succeeded) {
      props?.history?.push({
        pathname: `${AppRoutes.riskManagementUniversalDirectory}`,
      });
      dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRDeleteState())
    }
  }, [selectState.delete.loading])

  useEffect(() => {
    if (
      wccrUpdateState.loading === LoadingType.succeeded
    ) {
      if (wcurAttachedDocs && reportInfo) {
        dispatch(workCompAuditAndComplianceReportUpload({ id: reportInfo?.id, attachedDocs: wcurAttachedDocs }));
      }
      setEditReport(null);
      dispatch(postSnackbarMessage(wccrUpdateState.response))
    }
    if (wccrUploadDocState.loading == LoadingType.succeeded) {
      if (wcurAttachedDocs) {
        wcurAttachedDocs.forEach(doc => {
          S3API.uploadFile(wccrUploadDocState.response[doc.name].url, doc)
            .then(response => {

            }).catch(error => { });
        })
      }
      setWcurAttachedDocs(null)
    }
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRUpdateState());
    dispatch(workCompAuditAndComplianceReportingListActions.clearWCCRUploadState());
    dispatch(getAllWorkCompAuditAndComplianceReportById(wccrID));
  }, [wccrUpdateState.loading, wccrUploadDocState.loading]);

  const getTabList = () => {
    return [
      {
        title: reportInfo?.report_name ? reportInfo.report_name.toUpperCase() : "",
        content: <WCCRDetailsSection reportInfo={reportInfo ? reportInfo : {} as IWorkCompAuditAndComplianceReporting} />,
      },
    ];
  };

  function handleEdit() {
    setEditReport({ visible: true, data: selectState.getById.response! });
  }

  function getWCCRDetails() {
    dispatch(getAllWorkCompAuditAndComplianceReportById(wccrID));
    dispatch(getAllWorkCompAuditAndComplianceReportingList());
    dispatch(getAllInsuranceCompanies());
  }

  const getEditAction = () => {
    return (
      <button className="icon-btn cdp-edit-icon" onClick={handleEdit}>
        <EditIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
      </button>
    );
  };

  const handleEditReportSuccess = async (
    data: IUpdateReport,
    attachedDocs: Array<File> | null
  ) => {
    let newReportInfo = { ...reportInfo!, ...data };
    setReportInfo(newReportInfo);
    setWcurAttachedDocs(attachedDocs);
    dispatch(workCompAuditAndComplianceReportUpdate(data));
  };

  return (
    <>
      <StateChangeHandler
        loading={selectState.getById.loading}
        error={selectState.getById.error}
        onRefresh={getWCCRDetails}
      >
        <div className="report-dsh-container">
          <div className="report-dsh-left-panel">
            <InsuranceCompanyDetailsSection
              insurance_company={
                insuranceCompany
                  ? insuranceCompany
                  : reportInfo?.insurance_company
              }
            />
          </div>
          <div className="report-dsh-right-panel">
            <CurveTabNavigation
              tabs={getTabList()}
              selected={0}
              onChange={(value) => { }}
              actions={getEditAction()}
            />
          </div>
        </div>
      </StateChangeHandler>
      {editReport && (
        <Portal>
          <CreateReportPopup
            visible={editReport.visible}
            title={"Edit " + t("work_comp_audit_and_compliance_reporting")}
            onClose={() => setEditReport(null)}
            onSubmit={handleEditReportSuccess}
            reportInfo={editReport.data}
            state={selectState.update}
            history={history}
          />
        </Portal>
      )}
    </>
  );
};
