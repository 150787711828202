import { combineReducers } from "@reduxjs/toolkit";
import { AditionalTaxListSlice } from "./additional_tax/additional_tax_reducer";
import { BankAccountListSlice } from "./bank_account/bank_account_reducer";
import { BillingPeriodListSlice } from "./billing_period/billing_period_reducer";
import { BillingRateListSlice } from "./billing_rate/billing_rate_reducer";
import { DeductionInstituteListSlice } from "./deduction_institution/deduction_institution_reducer";
import { DeductionTypeListSlice } from "./deduction_type/deduction_type_reducer";
import { DeafaultPaymentOptionListSlice } from "./default_payment_option/default_payment_option_reducer";
import { EmployeeDeductionListSlice } from "./employee_deduction/employee_deduction_reducer";
import { GroupByListSlice } from "./group_by/group_by_reducer";
import { MappingGroupListSlice } from "./mapping_group/mapping_group_reducer";
import { PayTypeListSlice } from "./pay_type/pay_type_reducer";
import { PaymentMethodListSlice } from "./payment_method/payment_method_reducer";
import { PaymentTermsListSlice } from "./payment_terms/payment_terms_reducer";
import { ProcessingMethodListSlice } from "./processing_method/processing_method_reducer";
import { RunTypeListSlice } from "./run_type/run_type_reducer";
import { TransactionTypeListSlice } from "./transaction_type/transaction_type_reducer";
import { MarkUpListSlice } from "./mark_up/mark_up_reducer";
import { EmployeeContributionsListSlice } from "./employee_contributions/employee_contribution_reducer";

export const adminCenterBackOfficeReducers = combineReducers({
    [AditionalTaxListSlice.name]: AditionalTaxListSlice.reducer,
    [BankAccountListSlice.name]: BankAccountListSlice.reducer,
    [BillingPeriodListSlice.name]: BillingPeriodListSlice.reducer,
    [BillingRateListSlice.name]: BillingRateListSlice.reducer,
    [DeductionInstituteListSlice.name]: DeductionInstituteListSlice.reducer,
    [DeductionTypeListSlice.name]: DeductionTypeListSlice.reducer,
    [DeafaultPaymentOptionListSlice.name]: DeafaultPaymentOptionListSlice.reducer,
    [EmployeeDeductionListSlice.name]: EmployeeDeductionListSlice.reducer,
    [GroupByListSlice.name]: GroupByListSlice.reducer,
    [MappingGroupListSlice.name]: MappingGroupListSlice.reducer,
    [PayTypeListSlice.name]: PayTypeListSlice.reducer,
    [MarkUpListSlice.name]: MarkUpListSlice.reducer,
    [PaymentMethodListSlice.name]: PaymentMethodListSlice.reducer,
    [PaymentTermsListSlice.name]: PaymentTermsListSlice.reducer,
    [ProcessingMethodListSlice.name]: ProcessingMethodListSlice.reducer,
    [RunTypeListSlice.name]: RunTypeListSlice.reducer,
    [TransactionTypeListSlice.name]: TransactionTypeListSlice.reducer,
    [EmployeeContributionsListSlice.name]: EmployeeContributionsListSlice.reducer,
});

export * from "./additional_tax/additional_tax_thunk"
export * from "./additional_tax/additional_tax_selector"

export * from "./bank_account/bank_account_thunk"
export * from "./bank_account/bank_account_selector"

export * from "./billing_period/billing_period_thunk"
export * from "./billing_period/billing_period_selector"

export * from "./billing_rate/billing_rate_thunk"
export * from "./billing_rate/billing_rate_selector"

export * from "./deduction_institution/deduction_institution_thunk"
export * from "./deduction_institution/deduction_institution_selector"

export * from "./deduction_type/deduction_type_thunk"
export * from "./deduction_type/deduction_type_selector"

export * from "./default_payment_option/default_payment_option_thunk"
export * from "./default_payment_option/default_payment_option_selector"

export * from "./default_payment_option/default_payment_option_thunk"
export * from "./default_payment_option/default_payment_option_selector"

export * from "./employee_deduction/employee_deduction_thunk"
export * from "./employee_deduction/employee_deduction_selector"

export * from "./group_by/group_by_thunk"
export * from "./group_by/group_by_selector"

export * from "./mapping_group/mapping_group_thunk"
export * from "./mapping_group/mapping_group_selector"

export * from "./pay_type/pay_type_thunk"
export * from "./pay_type/pay_type_selector"

export * from "./payment_method/payment_method_thunk"
export * from "./payment_method/payment_method_selector"

export * from "./payment_terms/payment_terms_thunk"
export * from "./payment_terms/payment_terms_selector"

export * from "./processing_method/processing_method_thunk"
export * from "./processing_method/processing_method_selector"

export * from "./run_type/run_type_thunk"
export * from "./run_type/run_type_selector"

export * from "./transaction_type/transaction_type_thunk"
export * from "./transaction_type/transaction_type_selector"

export * from "./mark_up/mark_up_thunk"
export * from "./mark_up/mark_up_selector"

export * from "./employee_contributions/employee_contribution_thunk"
export * from "./employee_contributions/employee_contribution_selector"
