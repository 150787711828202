import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
import { LoadingType, THEME } from "../../../../../../enums";
import DialogWrapper from "../../../../../../components/dialog_wrapper/dialog_wrapper";
import { CustomButton, FormInput, RegxPattern, mapOptions } from "../../../../../../utils";
import CustomFormSelect from "../../../../../../components/form_selector/form_select";
import { SocialMediaProfile } from "../../../../../../interfaces/risk_mgmt_insurance_company";
import { LinkIcon } from "../../../../../../icons";
import { US_states } from "../../../../../../utils/States_US";
import "./create_action.scss"

const CreateAction = ({ onClose, onDismiss, visible, category, category_id, reduxMapping }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean | undefined>(false);
    const [methodValue, setMethodValue] = useState<{
        insurance_company_name: string,
        contact_name: string,
        street_address: string,
        city: string,
        state: string,
        zip_code: string,
        website: string,
        social_media: SocialMediaProfile
    }>
        ({
            insurance_company_name: "",
            contact_name: "",
            street_address: "",
            city: "",
            state: "",
            zip_code: "",
            website: "",
            social_media: {
                linkedin_url: "",
                twitter_url: "",
                facebook_url: "",
                pinterest_url: ""
            }
        });
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (sentRequest && state.create.loading === LoadingType.succeeded) {
            setMethodValue({
                insurance_company_name: "", contact_name: "", street_address: "", city: "", state: "", zip_code: "", website: "", social_media: {
                    linkedin_url: "",
                    twitter_url: "",
                    facebook_url: "",
                    pinterest_url: ""
                }
            });
            setSentRequest(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading])

    function handleInput(id, value) {
        setMethodValue({ ...methodValue, [id]: value })
    }

    const updateSocialMediaProp = (key, value) => {
        setMethodValue({
            ...methodValue,
            social_media: { ...methodValue.social_media, [key]: value },
        });
    };

    function handleSave() {
        setSentRequest(true);
        setCheckValidationOnSubmit(true);
        if (_isFormValid()) {
            dispatch(category.methods.create({
                'insurance_company_name': methodValue.insurance_company_name.trim(),
                'contact_name': methodValue?.contact_name.trim(),
                'website': methodValue?.website.trim(),
                'street_address': methodValue?.street_address.trim(),
                'city': methodValue?.city.trim(),
                'state': methodValue?.state.trim(),
                'zip_code': methodValue?.zip_code.trim(),
                'social_media': {
                    linkedin_url: methodValue.social_media.linkedin_url,
                    twitter_url: methodValue.social_media.twitter_url,
                    facebook_url: methodValue.social_media.facebook_url,
                    pinterest_url: methodValue.social_media.pinterest_url,
                }
            }));
            setCheckValidationOnSubmit(false);
        }
    }

    function _isFormValid() {
        const { insurance_company_name, contact_name, street_address, city, state, zip_code, website, social_media } = methodValue;
        // const { facebook_url, twitter_url, pinterest_url, linkedin_url } = social_media;
        if (insurance_company_name === '') return false;
        // if (contact_name === '') return false;
        // if (street_address === '') return false;
        // if (city === '') return false;
        // if (state === '') return false;
        // if (zip_code != '' && RegxPattern.zipCode.test(zip_code) === false) return false;
        // if (
        //     website != null &&
        //     website !== "" &&
        //     RegxPattern.url.test(website) === false
        // )
        //     return false;
        // if (
        //     linkedin_url != null &&
        //     linkedin_url !== "" &&
        //     RegxPattern.url.test(linkedin_url) === false
        // )
        //     return false;
        // if (
        //     facebook_url != null &&
        //     facebook_url !== "" &&
        //     RegxPattern.url.test(facebook_url) === false
        // )
        //     return false;
        // if (
        //     twitter_url != null &&
        //     twitter_url !== "" &&
        //     RegxPattern.url.test(twitter_url) === false
        // )
        //     return false;
        // if (
        //     pinterest_url != null &&
        //     pinterest_url !== "" &&
        //     RegxPattern.url.test(pinterest_url) === false
        // )
        //     return false;

        return true;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <DialogWrapper onClose={onClose}>
                <div className="ac-sc-rm-dialog-header">
                    <div className="dialog-header">
                        <span>{t(category.addLabel)}</span>
                    </div>
                    <div className="dialog-content">
                        <FormInput
                            id={"insurance_company_name"}
                            onChange={(id, val) => (handleInput(id, val))}
                            type={"text"}
                            value={methodValue.insurance_company_name}
                            label={t("insurance_company_name")}
                            placeholder=""
                            required={true}
                            validations={formValidators}
                            checkValidationOnSubmit={checkValidationOnSubmit}
                        />
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"street_address"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.street_address}
                                    label={t("street_address")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"city"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.city}
                                    label={t("city")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <CustomFormSelect
                                    customStyle={{ width: "-webkit-fill-available" }}
                                    value={methodValue.state}
                                    name="state"
                                    label={t("state")}
                                    placeholder={t("select_placeholder")}
                                    list={mapOptions(US_states, "name", "code")}
                                    onChange={(value) => handleInput("state", value)}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"zip_code"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"zip_code"}
                                    value={methodValue.zip_code}
                                    label={t("zip_code")}
                                    placeholder=""
                                    validations={formValidators}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"contact_name"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.contact_name}
                                    label={t("contact_name")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"website"}
                                    onChange={(id, val) => (handleInput(id, val))}
                                    type={"text"}
                                    value={methodValue.website}
                                    label={t("website")}
                                    placeholder=""
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"facebook_url"}
                                    onChange={updateSocialMediaProp}
                                    type={"text"}
                                    value={methodValue.social_media?.facebook_url}
                                    label={t("facebook")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div>
                                <FormInput
                                    id={"linkedin_url"}
                                    onChange={updateSocialMediaProp}
                                    type={"text"}
                                    value={methodValue.social_media?.linkedin_url}
                                    label={t("linked_in")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        <div className="row-content">
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"twitter_url"}
                                    onChange={updateSocialMediaProp}
                                    type={"text"}
                                    value={methodValue.social_media?.twitter_url}
                                    label={t("twitter")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                            <div className="form-input-algnt">
                                <FormInput
                                    id={"pinterest_url"}
                                    onChange={updateSocialMediaProp}
                                    type={"text"}
                                    value={methodValue.social_media?.pinterest_url}
                                    label={t("pinterest")}
                                    pattern={RegxPattern.url}
                                    placeholder=""
                                    labelIcon={<LinkIcon width={"0.833vw"} height={"0.833vw"} />}
                                    validations={formValidators}
                                />
                            </div>
                        </div>
                        {getMapping().state.create.error && sentRequest ?
                            (<p style={{ color: 'red' }} className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                            (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                    </div>
                    <div className="dialog-footer">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('save')}
                                enable={true}
                                loading={getMapping().state.create.loading == LoadingType.pending}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>
                    </div>
                </div>
            </DialogWrapper>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function formValidators(value: { text: string; field: string }) {
        switch (value.field) {
            case "insurance_company_name": {
                if (value.text === "") return t("validators.required");
                if (RegxPattern.username.test(value.text) === false)
                    return t("validators.enterValidName");
                return null;
            }
            // case "contact_name": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            case "zip_code": {
                // if (value.text === "") return t("validators.required");
                if (RegxPattern.zipCode.test(value.text) === false)
                    return t("validators.enterValidZipCode");
                return null;
            }
            // case "street_address": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            // case "city": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            // case "state": {
            //     if (value.text === "") return t("validators.required");
            //     return null;
            // }
            case "website":
            case "linkedin_url":
            case "twitter_url":
            case "facebook_url":
            case "pinterest_url": {
                if (value.text === "") return t("");
                if (RegxPattern.url.test(value.text) === false)
                    return t("validators.enterValidUrl");
                return null;
            }
            default: {
                return null;
            }
        }
    }

}

export default CreateAction;