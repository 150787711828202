
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../../enums";
import { DeleteIcon, DownloadIcon } from "../../../../../icons";
import { getFileDownloadURL, selectFileDownloadResponseState, workCompAuditAndComplianceReportUpdate } from "../../../../../redux/risk_management";
import { workCompClaimsListActions } from "../../../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import './wccr_details_section.scss';
import { IWorkCompAuditAndComplianceReporting } from "../../../../../interfaces/work_comp_audit_and_compliance_reporting";
import { CustomButton } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import { DeleteConfirmationDialog } from "../../../../components/delete_confirmation_dialog";

interface IWCCRDetails {
    reportInfo: IWorkCompAuditAndComplianceReporting;
}

const WCCRDetailsSection = ({ reportInfo }: IWCCRDetails) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fileDownloadState = useAppSelector((state) => selectFileDownloadResponseState(state));
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, filePath: string, selectedIndex: number } | null>(null);

    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded && fileDownloadState.data?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.data?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(workCompClaimsListActions.clearFileDownloadState());
        }

    }, [fileDownloadState.loading])

    function onDeleteDoc() {
        if (reportInfo && typeof showDeleteDialog?.selectedIndex === 'number' && showDeleteDialog?.selectedIndex >= 0 && showDeleteDialog?.selectedIndex < reportInfo.report_docs.length) {
            const indexnum = showDeleteDialog?.selectedIndex
            const docsArray = reportInfo.report_docs;
            const updated = [...docsArray.slice(0, indexnum), ...docsArray.slice(indexnum + 1)]
            dispatch(workCompAuditAndComplianceReportUpdate({ ...reportInfo, report_docs: updated }));
        }
        setShowDeleteDialog(null);
    }

    function handleDownload(filePath) {
        dispatch(getFileDownloadURL(filePath));
    }

    function getDocumentName(doc, id) {
        let name = doc.split('-');
        if (name.length > 1)
            return name[1]

    }
    function getReportStatus(status: string) {
        if (status === "Due")
            return (
                <span style={{ color: THEME.statusInActiveColor }}>{t("due")}</span>
            );
        else if (status === "Complete & Submitted")
            return <span style={{ color: "#b1b1b1" }}>{t("completed")}</span>;
        else return <span>{""}</span>;
    }

    return (
        <div className="wccr-tab-container">
            <div className="wccr-tab-field-row wccr-tab-border">
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('report_name').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">
                        {reportInfo.report_name}
                    </div>
                </div>
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('report_status').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{getReportStatus(reportInfo.report_status)}</div>
                </div>
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('report_number').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{reportInfo.report_number}</div>
                </div>
            </div>
            <div className="wccr-tab-field-row wccr-tab-border">
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('report_or_audit_period').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{getDateString(reportInfo.report_or_audit_period_start) + " - " + getDateString(reportInfo.report_or_audit_period_end)}</div>
                </div>
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('date_due').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{getDateString(reportInfo.date_due)}</div>
                </div>
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('date_submitted').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{getDateString(reportInfo.date_submitted)}</div>
                </div>
                <div className="wccr-tab-field-column">
                    <div className="wccr-tab-field-header">{t('date_completed').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{getDateString(reportInfo.date_completed)}</div>
                </div>
            </div>
            <div className="wccr-tab-field-row wccr-tab-border">
                <div className="wccr-tab-field-column1">
                    <div className="wccr-tab-field-header">{t('notes').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{reportInfo.notes}</div>
                </div>
                <div className="wccr-tab-field-column1">
                    <div className="wccr-tab-field-header">{t('next_steps').toUpperCase()}</div>
                    <div className="wccr-tab-field-label">{reportInfo.next_steps}</div>
                </div>
            </div>
            <div className="wccr-tab-field-row">
                <div className="wccr-tab-field-column2">
                    <div className="wccr-tab-field-header">{t('claim_docs').toUpperCase()}</div>
                    <div className="wccr-claim-row">
                        {reportInfo.report_docs && reportInfo.report_docs.length > 0 &&
                            reportInfo.report_docs.map((h: string, index) => {
                                const spList = h.split(`${reportInfo.id}-`);
                                return (
                                    <div className="wccr-tab-claim-doc">
                                        <div style={{width: "-webkit-fill-available"}}>{spList[spList.length - 1]}</div>
                                        <div className="icons-row">
                                            <CustomButton
                                                customClassName={"wccr-tab-download-button"}
                                                enable={true}
                                                leftIcon={<div><DownloadIcon /></div>}
                                                name={''}
                                                onClick={() => (handleDownload(h))}
                                                loading={fileDownloadState.loading === LoadingType.pending}
                                            />
                                            <CustomButton
                                                customClassName="wccr-tab-del-button"
                                                enable={true}
                                                leftIcon={<div><DeleteIcon /></div>}
                                                name={''}
                                                backgroundColor={THEME.buttonColor2A}
                                                onClick={() => setShowDeleteDialog({ visible: true, filePath: spList[spList.length - 1], selectedIndex: index })}
                                            />

                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>

            </div>
            {showDeleteDialog && reportInfo.report_docs && (
                <DeleteConfirmationDialog
                    message={t("delete_risk_management_message", {
                        uploadDocFile: showDeleteDialog?.filePath,
                    })}
                    onCancel={() => setShowDeleteDialog(null)}
                    onConfirm={onDeleteDoc}
                    deleting={false}
                    deleteType={t("document")}
                />
            )}
        </div>
    )
}
export default WCCRDetailsSection;