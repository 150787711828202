import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { AppError } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { useState } from "react";
import { EditIcon, TickGreenIcon, TickGreyIcon } from "../../../../../icons";
import './contributions.scss'
import { CustomButton, currencyConversion, getPlusBtnIcon } from "../../../../../utils";
import { Portal } from "react-portal";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { ITalentBackOfficeContributions, ITalentBackOfficePayrollSetup } from "../../../../../interfaces/talent_back_office";
import AddContributionPopup from "../../../popups/add_contribution/add_contribution";
import { RouteComponentProps } from "react-router-dom";
import { AppRoutes } from "../../../../../routes";
interface Props {
    contributionUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    formState: ITalentBackOfficePayrollSetup;
    onClearError: () => void;
    handleEditCancel?: () => void;
    isLoading?: boolean,
    currentLoginUserName: string;
    talentId: string;
    talentName: string;
    handleUpdateClearState: () => void;
    getTalentBOPayrollData: () => void;
    handleAddContribution: (doc: ITalentBackOfficeContributions, parent: string, index?: number, removeItem?: boolean) => void;
    history: RouteComponentProps['history'];
}
const contibutionsTableHeader = [
    { title: 'name_institution', code: 'contribution_institute_value' },
    { title: 'amount_of_percentage', code: '' },
    { title: 'active', code: 'is_active' },
    { title: 'Frequency', code: 'frequency' },
    { title: 'notes', code: 'note' },
    { title: '', code: '' },

];

const initialForm = {
    contribution_institute_id: "",
    contribution_institute_value: "",
    contribution_reference_no: 0,
    contribution_pay_period_limit: 0,
    contribution_monthly_limit: 0,
    contribution_annual_limit: 0,
    contribution_life_time_limit: 0,
    contribution_start_date: 0,
    contribution_end_date: 0,
    contribution_amount: 0,
    contribution_percentage: 0,
    is_active: false,
    frequency: "",
    note: ""
}
const Contributions: React.FC<Props> = props => {
    const {
        formState,
        onClearError,
        currentLoginUserName,
        talentId,
        talentName,
        handleUpdateClearState,
        getTalentBOPayrollData,
        handleAddContribution,
        contributionUpdateState
    } = props;

    const { t } = useTranslation();
    const parentField = "talent_contribution";
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [addcontribution, setAddcontribution] = useState<boolean>(false);
    const [contributionFormState, setContributionFormState] = useState<ITalentBackOfficeContributions>(initialForm);
    const [contributionSorted, setcontributionSorted] = useState<string | null>(null);
    function handleCSortFieldChange(value: string) {
        if (contributionSorted && contributionSorted === value) {
            setcontributionSorted(null);
        } else {
            setcontributionSorted(value);
        }
    }
    function getCPFilteredList(): ITalentBackOfficeContributions[] {
        let sortedList: ITalentBackOfficeContributions[] | undefined;
        if (contributionSorted != null) {
            sortedList = [...(formState?.talent_contribution ?? [])].sort((a, b) => {
                if (a[contributionSorted] < b[contributionSorted]) {
                    return -1;
                }
                if (a[contributionSorted] > b[contributionSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState?.talent_contribution ?? []));
    }
    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        if (fieldId === "contribution_percentage") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setContributionFormState({
                    ...contributionFormState,
                    [fieldId]: value,
                });
            }
        }
        else {
            setContributionFormState({
                ...contributionFormState,
                [fieldId]: value,
            });
        }

    };
    const handleClose = () => {
        setAddcontribution(false);
        setContributionFormState(initialForm);
        setEditIndex(undefined);
    }
    const handleOnSuccessClose = () => {
        handleClose();
        handleUpdateClearState();
        getTalentBOPayrollData();
    }
    const handleEdit = (val: ITalentBackOfficeContributions, index: number) => {
        setAddcontribution(true);
        setContributionFormState(val);
        setEditIndex(index);
    };
    const handleSave = () => {
        if (JSON.stringify(contributionFormState) === JSON.stringify(initialForm)) {
            handleClose();
        }
        else {
            handleAddContribution(contributionFormState, parentField, editIndex);
        }
    }

    function handleGoToContributionHistory(talent_id: string, talent_name: string) {
        props.history.push({
            pathname: `${AppRoutes.employeePortalDetailsPage}/${talent_id}`,
            state: {
                id: talent_id,
                name: talent_name,
            }
        })
    }

    return (
        // <></>
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('contributions')} >
            <div className="contribution-container">
                <div className="contribution-header">
                    <div className="section-header">{t("contribution_setup")}</div>
                    <div><CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_contribution")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddcontribution(true)}
                    /></div>
                </div>
                <div className="contribution-content">
                    {/* {formState?.talent_contribution && formState?.talent_contribution?.length === 0 ?
                        (
                            <div className="empty-data-msg">
                                <span>{t("no_data_available")}</span>
                            </div>
                        )
                        :
                        ( */}
                    <div className="contribution-content-table">
                        <SortableTable
                            headerList={contibutionsTableHeader}
                            sortedField={contributionSorted}
                            onSortChange={handleCSortFieldChange}
                            flexNumber={getFlexNumber}
                        >
                            {getCPFilteredList().map((doc, index) => {
                                return (
                                    <tr key={index} onClick={() => { }}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>{doc.contribution_institute_value}</TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.contribution_amount && (doc.contribution_amount !== null || doc.contribution_amount === 0)
                                            ? currencyConversion(doc.contribution_amount)
                                            : doc.contribution_percentage + "%"}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.is_active ?
                                            <div className="tick-icon">
                                                <TickGreenIcon width={"100%"} height={"100%"} />
                                            </div> : <div className="tick-icon">
                                                <TickGreyIcon width={"100%"} height={"100%"} />
                                            </div>}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>{doc.frequency}</TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.note && doc.note.length > 50 ? doc.note.substring(0, 50) : doc.note}</span></TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                            <span>
                                                <button
                                                    className="icon-btn tdp-edit-icon"
                                                    onClick={() => handleEdit(doc, index)}
                                                >
                                                    <EditIcon
                                                        width={"100%"}
                                                        height={"100%"}
                                                        style={{ color: "#fff" }}
                                                    />
                                                </button>
                                            </span>
                                        </TableData>

                                    </tr>
                                );
                            })}
                        </SortableTable>
                    </div>
                    {/* )
                    } */}
                </div>
                <div className="contribution-history-label" onClick={()=>handleGoToContributionHistory(talentId, talentName)}><span>{t("contribution_history")}</span></div>
            </div>
            {addcontribution &&
                <Portal>
                    <AddContributionPopup
                        visible={addcontribution}
                        title={t("Contribution")}
                        successTitle={t("Contribution")}
                        onClose={handleClose}
                        onSuccessClose={handleOnSuccessClose}
                        onDissmiss={handleClose}
                        contributionFormState={contributionFormState}
                        handleFormFieldChange={handleFormFieldChange}
                        handleSave={handleSave}
                        currentLoginUserName={currentLoginUserName}
                        index={editIndex}
                        talentName={talentName ? talentName.split(" ")[0] : ""}
                        parentField={parentField}
                        contributionUpdateState={contributionUpdateState}
                        handleClearError={onClearError}
                    />
                </Portal>}
        </SectionCard>
    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1.5;
        if (value === 1) return 1;
        if (value === 2) return 0.6;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 0.5;


        return 1;
    }
}

export default Contributions;