import { RouteComponentProps } from "react-router-dom";
import { useEffect, useState } from "react";
import { IWeeklyAccounting } from "../../../../../interfaces/back_office_weekly_accounting";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { useTranslation } from "react-i18next";
import './weekly_accounting_dashboard.scss';
import { LoadingType, THEME } from "../../../../../enums";
import { getDateString } from "../../../../../variables";
import { AccountingWeekStatus } from "../../../../../enums/back_office";
import { CustomButton } from "../../../../../utils";
import OpenCloseWeekEndingPopup from "../../../popups/open_close_week_ending/open_close_week_ending";
import { Portal } from "react-portal";
import { weeklyAccountingSliceActions } from "../../../../../redux/back_office/weekly_accounting/weekly_accounting_dashboard/weekly_accounting_dashboard_reducer";
import { selectWeeklyAccountingState, getWeeklyAccountingThunk, getWeeklyAccountingOpenThunk, getWeeklyAccountingCloseThunk } from "../../../../../redux/back_office";
import { useAppDispatch, useAppSelector, selectProfileState } from "../../../../../redux/store";
import NoteCard from "../../../../../components/note_card/note_card";
import { BulbIcon, RightArrowIcon } from "../../../../../icons";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import popupInfoContent from "../../../popup_info_text";
import InfoTextPopup from "../../../popups/infoTextPopup/info_text_popup";
const tableHeader = [
    { title: 'week_ending_date', code: 'week_ending_date' },
    { title: 'current_status', code: 'current_status' },
    { title: 'errors_warnings_total', code: 'errors_warnings_total' },
    { title: 'approved_errors_warnings', code: 'approved_errors_warnings' },
];
interface Props extends RouteComponentProps<any> { }
const WeeklyAccountingDashboardPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const currentLoginUserName = `${profileState.profile?.first_name ?? ""} ${profileState.profile?.last_name ?? ""
        }`;
    const weeklyAccountingState = useAppSelector((state) =>
        selectWeeklyAccountingState(state)
    );
    useEffect(() => {
        dispatch(getWeeklyAccountingThunk());
    }, []);

    const [weeklyAccountingData, setWeeklyAccountData] = useState<IWeeklyAccounting[]>([]);
    useEffect(() => {

        if (weeklyAccountingState && weeklyAccountingState.loading === LoadingType.succeeded && weeklyAccountingState.response) {
            setWeeklyAccountData(weeklyAccountingState.response)
        }
    }, [weeklyAccountingState, weeklyAccountingState.loading, weeklyAccountingState.response])
    const [openWeekPopup, setOpenWeekPopup] = useState<boolean>(false);
    const [closeWeekPopup, setCloseWeekPopup] = useState<boolean>(false);
    const [weekEndingDate, setWeekEndingDate] = useState<number>();
    const [weekEndingId, setWeekEndingId] = useState<string>("");

    const [openWeekInfoPopup, setOpenWeekInfoPopup] = useState<boolean>(false);
    const [showClosedWeekends, setShowClosedWeekends] = useState<boolean>(false);

    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    function getFilteredList() {
        let list;
        if (sortedField != null) {
            list = [...weeklyAccountingData].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        if (!showClosedWeekends) {
            const filteredData = weeklyAccountingData.filter((doc) => doc.status !== "Closed");
            return filteredData
        }
        return (list ?? weeklyAccountingData);
    }

    const handleClearState = () => {
        dispatch(weeklyAccountingSliceActions.clearState())
    }
    const handleSuccessClose = (action: string) => {
        if (action === "open") {
            setOpenWeekPopup(false);
        }
        else {
            setCloseWeekPopup(false);
        };
        handleClearState();
        dispatch(getWeeklyAccountingThunk());
    }
    const handleButtonClick = (action: string, doc: IWeeklyAccounting) => {
        if (action === "open") {
            setOpenWeekPopup(true);
        }
        else {
            setCloseWeekPopup(true);
        };
        setWeekEndingDate(doc.week_ending_date);
        setWeekEndingId(doc.id);
    }
    const handleWeekOpen = () => {
        dispatch(getWeeklyAccountingOpenThunk(weekEndingId));
    }
    const handleWeekClose = () => {
        dispatch(getWeeklyAccountingCloseThunk(weekEndingId));
    }
    const handleClearError = () => {
        dispatch(weeklyAccountingSliceActions.clearErrorState())
    }
    return (
        <div className="bo-wa-dashboard-container">
            <div className="bo-wad-header">
                <span>{t('weekly_ending_date_accounting_overview')}</span>

            </div>
            <div className="bo-wad-content">
                <div className="toggle-switch">
                    <ToggleSwitch
                        onChange={(value) => setShowClosedWeekends(value)}
                        label={t("show_closed_weekends")}
                        checked={showClosedWeekends}
                    />
                </div>
                <div className="bo-wad-content-table">
                    <SortableTable
                        headerList={tableHeader}
                        sortedField={sortedField}
                        onSortChange={handleSortFieldChange}
                        flexNumber={getFlexNumber}
                        isAsc={sortDirection}
                    >
                        {getFilteredList().map((doc, index) => {
                            return (
                                <tr key={doc.id}>
                                    <TableData customStyle={{ flex: getFlexNumber(0) }}>{getDateString(doc.week_ending_date, "mm/dd/yyyy")}</TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(1) }} ><div style={{ width: '100%' }}>
                                        {doc.status === AccountingWeekStatus.TO_BE_OPENED ?
                                            (
                                                <div className="status-btn-row">
                                                    <div>
                                                        <span></span>
                                                    </div>
                                                    <div>
                                                        <CustomButton
                                                            customStyle={{ width: '-webkit-fill-available' }}
                                                            loading={false}
                                                            textStyle={{ textTransform: 'none' }}
                                                            name={t('open_week_ending')}
                                                            enable={true}
                                                            onClick={() => handleButtonClick('open', doc)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <>
                                                    {doc.status === AccountingWeekStatus.CLOSED ?
                                                        (
                                                            <div className="status-btn-row">
                                                                <div>
                                                                    <span>{doc.status}</span>
                                                                </div>
                                                                <div>

                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="status-btn-row">
                                                                <div>
                                                                    <span>{doc.status}</span>
                                                                </div>
                                                                <div>
                                                                    <CustomButton
                                                                        customStyle={{ width: '-webkit-fill-available' }}
                                                                        loading={false}
                                                                        textStyle={{ textTransform: 'none' }}
                                                                        backgroundColor={THEME.statusInActiveColor}
                                                                        name={t('close_week_ending')}
                                                                        enable={true}
                                                                        onClick={() => handleButtonClick('close', doc)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </div></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.total_errors_and_warnings}</span></TableData>
                                    <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.approved_errors_and_warnings}</span></TableData>
                                </tr>
                            );
                        })}
                    </SortableTable>
                </div>

                <div className="wa-card-note1">
                    <NoteCard
                        icon={<div><RightArrowIcon /><RightArrowIcon /></div>}
                        textLineOne={t("weekly_accounting_section_message_one", { name: currentLoginUserName })}
                        textLineTwo={t("weekly_accounting_section_message_two")}
                        confirmBtn={false}
                        customTextColor={"#119646"}
                        customIconBGColor={"#E6FAED"}
                        customCardBGColor={"#FAFFFB"}
                        customTextWidthLineOne={"100%"}
                        customTextWidthLineTwo={"100%"}
                    />
                </div>
                <div className="wa-card-note2">
                    <NoteCard
                        icon={<BulbIcon />}
                        textLineOne={t("need_to_see_weekly_accounting_process_flow")}
                        confirmBtn={false}
                        customTextColor={"#074783"}
                        customIconBGColor={"#D9E6F4"}
                        customCardWidth={"84%"}
                        customCardBGColor={"#EAF4FF"}
                        customTextWidthLineOne={"84%"}
                        cursor="pointer"
                        onClick={() => setOpenWeekInfoPopup(true)}
                        customFontWeight={'800'}

                    />
                </div>
            </div>
            {openWeekPopup &&
                <Portal>
                    <OpenCloseWeekEndingPopup
                        visible={openWeekPopup}
                        title={t("open_week_ending")}
                        onClose={() => { setOpenWeekPopup(false); handleClearError(); }}
                        onSuccessClose={() => handleSuccessClose('open')}
                        handleSubmit={handleWeekOpen}
                        action={"open"}
                        weekEndingDate={getDateString(weekEndingDate, "mm/dd/yyyy")}
                        currentLoginUserName={currentLoginUserName}
                        loading={weeklyAccountingState.open.loading}
                        error={weeklyAccountingState.open.error}
                        handleClearError={handleClearError}
                    />
                </Portal>}
            {closeWeekPopup &&
                <Portal>
                    <OpenCloseWeekEndingPopup
                        visible={closeWeekPopup}
                        title={t("close_week_ending")}
                        onClose={() => { setCloseWeekPopup(false); handleClearError(); }}
                        onSuccessClose={() => handleSuccessClose('close')}
                        handleSubmit={handleWeekClose}
                        action={"close"}
                        weekEndingDate={getDateString(weekEndingDate, "mm/dd/yyyy")}
                        currentLoginUserName={currentLoginUserName}
                        loading={weeklyAccountingState.close.loading}
                        error={weeklyAccountingState.close.error}
                        handleClearError={handleClearError}
                    />
                </Portal>}
            {openWeekInfoPopup &&
                <Portal>
                    <InfoTextPopup
                        visible={openWeekInfoPopup}
                        onClose={() => setOpenWeekInfoPopup(false)}
                        onDismiss={() => setOpenWeekInfoPopup(false)}
                        title={t("weekly_accounting_help")}
                        textContent={popupInfoContent.weeklyAccountingInfo}
                    />
                </Portal>}
        </div>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;

        return 1;
    }
}

export default WeeklyAccountingDashboardPage;