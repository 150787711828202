import { combineReducers } from "@reduxjs/toolkit";
import { empDashboardOBDocsReducers } from "./e_docs";
import { empDashboardDDReducers } from './direct_deposit';
import { employeeDashboardPDReducers } from "./payroll_details";
export const employeeDashboardReducers = combineReducers({
    'eDocsOnboardingDocs': empDashboardOBDocsReducers,
    'empDashboardDDReducers': empDashboardDDReducers,
    'employeeDashboardPDReducers': employeeDashboardPDReducers,
});
export * from "./e_docs"
export * from "./direct_deposit"
export * from "./payroll_details"