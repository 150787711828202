import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../../../enums";
import { IVacationHistory } from "../../../../../interfaces";
import { getDateString } from "../../../../../variables";
import { Portal } from "react-portal";
import ViewCheckInvoicePopup from "../../../../back_office/popups/view_check/view_check";
import "../paycheck_history/paycheck_history_section.scss";
import { getPaycheckPDFURLThunk, selectePaycheckInvoiceState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { paycheckInvoiceSliceActions } from "../../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";
import { CustomButton, SpinnerScreen } from "../../../../../utils";
import { SearchIcon } from "../../../../../icons";
import SearchBar from "../../../../../components/search_bar";

interface Props {
    vacationHistoryList: IVacationHistory[];
    loading: boolean;
}

const VacationHistorySection: React.FunctionComponent<Props> = (props) => {
    const {
        vacationHistoryList,
        loading,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [payCheckViewURL, setPayCheckViewURL] = useState<string>("");
    const [openViewCheckPopup, setOpenViewCheckPopup] = useState<boolean>(false)
    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            setPayCheckViewURL(paycheckPDFURLState.paycheck.response)
        }
    }, [paycheckPDFURLState.paycheck, paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);

    const tableheaderList = [
        { title: "week_ending_date", code: "week_ending_date" },
        { title: "check_hash", code: "paycheck_number" },
        { title: "pay_type", code: "pay_type" },
        { title: "hours_taken", code: "hours_taken" },
        { title: "", code: "" },
    ];

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 1;
        if (value === 1) return 1.2;
        if (value === 2) return 1.2;
        if (value === 3) return 1;
        if (value === 4) return 0.5;
        if (value === 5) return 1;
        if (value === 6) return 1.1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 20) return 1;
        return 1;
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
        setOpenViewCheckPopup(true)
    }

    const handleClose = () => {
        setOpenViewCheckPopup(false);
        dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        setPayCheckViewURL("");
    }

    const getFilteredList = () => {
        let list = vacationHistoryList;

        if (vacationHistoryList && sortedField != null) {
            list = [...vacationHistoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    return (
        <div className="ep-pd-ph-container">
            <div className="ep-pd-ph-header">
                <div className="ep-pd-ph-header-title">
                    <span>{t("vacation_history").toUpperCase()}</span>
                </div>
                <div className="ep-pd-ph-header-right">
                    <div className="total-count">
                        <span>
                            {`${t("total_count")}: `}
                            <b>{getFilteredList()?.length}</b>
                        </span>
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={setSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="ep-pd-ph-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, index) => {
                                        return (
                                            <tr key={doc.paycheck_id + index}>

                                                <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{getDateString(doc.week_ending_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.paycheck_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.pay_type}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.hours_taken}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <div>
                                                        <CustomButton
                                                            loading={false}
                                                            customStyle={{ width: '80%', padding: "0 0.5em 0 0.5em", cursor: "pointer" }}
                                                            leftIcon={<span style={{ color: "#fff" }}><SearchIcon width={"1vw"} height={"1vw"} /></span>}
                                                            textStyle={{ textTransform: 'capitalize' }}
                                                            name={t("")}
                                                            enable={true}
                                                            backgroundColor={THEME.buttonBackgroundColor3}
                                                            onClick={() => handleViewCheck(doc.paycheck_id)}
                                                        />
                                                    </div>
                                                </TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="ep-pd-ph-nra">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                            <div className="na_div">
                                                {t("no_paycheck_history_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
            {openViewCheckPopup &&
                (
                    <Portal>
                        <ViewCheckInvoicePopup
                            visible={openViewCheckPopup}
                            title={t("view_check")}
                            onClose={handleClose}
                            state={paycheckPDFURLState.paycheck}
                            responseURL={payCheckViewURL}
                        />
                    </Portal>
                )
            }
        </div>
    );
}


export default VacationHistorySection;