import { useTranslation } from "react-i18next";
import { THEME } from "../../../../enums";
import { DeleteIcon, PaperPlaneIcon } from "../../../../icons";
import { deleteUploadDocument, selectProfileState, selectSalesDocumentSendByEmailState, sendVideoByEmail, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import './marketing_docs.scss';
import { useState } from "react";
import { Portal } from "react-portal";
import SendByEmailPopup from "../../popups/send_by_email/send_by_email";
import { salesDocumentSendByEmailActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_doc_send_by_email/send_by_email_reducer";
import { SendByEmail } from "../../../../interfaces/marketing_campaign";

const VidePlayer = ({ url, title, id, onRetry }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));
    const sendByemailState = useAppSelector((state) => selectSalesDocumentSendByEmailState(state))
    const [showSendByEmail, setShowSendByEmail] = useState<boolean>(false);
    let videoId = '';
    if (url.toLowerCase().includes('v='))
        videoId = url.split('v=')[1].split('&')[0];
    else {
        videoId = url.split('/');
        videoId = videoId[videoId.length - 1];
    }

    function handleDelete(doc: string) {
        onRetry()
        dispatch(deleteUploadDocument(doc))
    }

    return (
        <div>
            <iframe style={{ 'borderRadius': "5px" }} width="420" height="315" src={`https://www.youtube.com/embed/${videoId}?controls=1`} allowFullScreen={true} frameBorder={0}></iframe>
            <div className="mdv-container">
                <h5 className="mdv-font-style">{title}</h5>
                <button onClick={() => handleDelete(id)}>
                    <div className="icon-btn tdp-edit-icon">
                        <DeleteIcon width={"100%"} height={"100%"} />
                    </div>
                </button>
            </div>
            <div>
                <CustomButton
                    textStyle={{ textTransform: 'capitalize' }}
                    enable={true}
                    leftIcon={
                        <div>
                            <PaperPlaneIcon
                                width={20}
                                height={20}
                                style={{ color: THEME.white }}
                            />
                        </div>
                    }
                    name={t("send_by_email")}
                    padding={12}
                    fontSize={13}
                    backgroundColor={THEME.buttonBackgroundColor4}
                    onClick={() => setShowSendByEmail(true)}
                />
            </div>
            {showSendByEmail && (
                <Portal>
                    <SendByEmailPopup
                        visible={showSendByEmail}
                        DocumentId={id}
                        onClose={() => setShowSendByEmail(false)}
                        onDissmiss={() => setShowSendByEmail(false)}
                        onSuccessClose={handleOnSendByEmailSuccess}
                        onSubmit={handleOnSubmit}
                        profileState={profileState}
                        sendByemailState={sendByemailState}
                        loading={sendByemailState.sendVideoLinkToEmail.loading}
                        error={sendByemailState.sendVideoLinkToEmail.error}
                    />
                </Portal>
            )}
        </div>
    )

    function handleOnSubmit(data: SendByEmail) {
        dispatch(sendVideoByEmail(data));
    }

    function handleOnSendByEmailSuccess() {
        setShowSendByEmail(false);
        dispatch(salesDocumentSendByEmailActions.clearsendVideoLinkToEmailState());
    }
}

export default VidePlayer;