import { AddCircleOutline } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { RouteComponentProps } from "react-router-dom";
import SearchBar from "../../../components/search_bar";
import SortableTable, { TableData } from "../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from "../../../components/table_empty/table_empty";
import TabOptionsHeader from "../../../components/tab_options_header/tab_options_header";
import { THEME, LoadingType, ROLE } from "../../../enums";
import { AdministratorIcon, DeleteIcon } from "../../../icons";
import { Profile, ProfileRole } from "../../../interfaces";
import { selectRoleUsersListState, selectRoleUsersList, getRoleUsersList } from "../../../redux/selectors";
import { clearRoleUserListState } from "../../../redux/selectors/role_users_list/role_users_list_reducer";
import { manageUserActions } from "../../../redux/admin_center/user_management/manage_user/manage_user_reducer";

import { useAppDispatch, useAppSelector, selectProfileState, postSnackbarMessage } from "../../../redux/store";
import { CustomButton, getTableAccessIcon, getUserRoleText, getPlusBtnIcon, getUserRoleIcon } from "../../../utils";
import { getDaysRemainingString, getDateString } from "../../../variables";
import { AddFixedRoleAction, DeleteRoleAction } from "../user_management/action";
import { getRmRoleUsersList, selectRMRoleUsersList, selectRMRoleUsersListState } from "../../../redux/admin_center/role_management";
import './administrator_page.scss'

const tabOptions = [
    {
        title: "administrator_directory",
    }
];

const tableHeader = [
    { title: 'role', code: 'role' },
    { title: 'name', code: 'first_name' },
    { title: 'email', code: 'email' },
    { title: 'member_since', code: 'member_since' },
    { title: 'actions', code: 'actions' },
];

interface Props extends RouteComponentProps<any> { }

const AdministratorPage: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => selectProfileState(state));
    const { loading, error, } = useAppSelector((state) => selectRMRoleUsersListState(state));
    const usersList = useAppSelector((state) => selectRMRoleUsersList(state));
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const { t } = useTranslation();
    const [addUser, setAddUser] = useState<boolean>(false);
    const [role, setRole] = useState<string | null>(null);
    const [showDeleteAction, setShowDeleteAction] = useState<{ role: ProfileRole, visible: boolean, user: Profile } | null>(null);
    const [showAddRoleAction, setShowAddRoleAction] = useState<{ role: string, visible: boolean } | null>(null);

    useEffect(() => {

        if (location && location.search) {
            const query = new URLSearchParams((location as any).search);
            const role = query.get('role');
            if (role) getUsers(role);
        }

        return () => {
            dispatch(clearRoleUserListState());
        }
    }, [])

    useEffect(() => {
        if (error != null && usersList.length != 0) {
            dispatch(postSnackbarMessage(error.message));
        }
        return () => {
        }
    }, [error])

    function getUsers(value: string) {
        setRole(value);
        dispatch(getRmRoleUsersList({ branch_id: '', role: value }));
    }


    function getFilteredList(): Profile[] {
        let list: Profile[] | undefined = usersList;
        let sortedList: Profile[] | undefined;
        if (sortedField != null) {
            sortedList = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (sortedList?? list ?? usersList).filter(doc => {
            const str = search.trim().toLowerCase();
            // const userNameFilter = doc.username.trim().toLowerCase().includes(str);
            const nameFilter = `${doc.first_name ?? ""} ${doc.last_name ?? ""}`.trim().toLowerCase().includes(str);
            const emailFilter = doc.email.trim().toLowerCase().includes(str);
            return nameFilter || emailFilter;
        });
    }



    return (
        <div className="ac-um-container ac-ap-container">
            <div className="ac-um-header">
                <TabOptionsHeader list={[{ title: t("role_directory", { value: t(getUserRoleText(role ?? '')) }) }]} onClose={() => props.history.goBack()} />
            </div>
            <div className="ac-um-content">
                <div className="ac-um-table-holder">
                    <div className="ac-um-table-header">
                        <div className="ac-um-table-left">
                            <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} />
                            <div className="total-count">
                                <span>{`${t('total_count')}: `} <span className="total-count-number">{getFilteredList()?.length}</span> </span>
                            </div>
                        </div>
                        <div className="ac-um-actions">
                            <div className="ac-um-add-user-btn" style={{ width: 'fit-content' }}>
                                <CustomButton
                                    leftIcon={getPlusBtnIcon()}
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('add_role_user', { value: t(getUserRoleText(role ?? '')) })}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={handleOpenAddRoleDialog}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ac-um-table">
                        {getUsersTableList()}
                    </div>
                </div>
            </div>


            {showAddRoleAction && profile != null && <Portal>
                <AddFixedRoleAction
                    headerTitle={t('add_role_user', { value: t(getUserRoleText(role ?? '')) })}
                    selectedRole={showAddRoleAction.role}
                    visible={showAddRoleAction.visible}
                    onClose={handleCloseAddDialog}
                    onDissmiss={handleCloseAddDialog}
                    onSuccessClose={handleOnSuccessAddDialog}
                    titleIcon={getUserRoleIcon(role ?? "")}
                />
            </Portal>}

            {showDeleteAction && <Portal>
                <DeleteRoleAction
                    role={showDeleteAction.role.role}
                    userId={showDeleteAction.user.id!}
                    visible={showDeleteAction.visible}
                    onClose={handleCloseDeleteDialog}
                    onDissmiss={handleCloseDeleteDialog}
                    onSuccessClose={handleOnSuccessDeleteRoleDialog}
                />
            </Portal>}
        </div>

    );

    /// Users table list
    function getUsersTableList() {
        if (loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (error != null && usersList.length === 0) {
            return (
                <TableErrorHandler error={error} onRefresh={() => getUsers(role!)} />
            );
        }
        if (usersList.length === 0) {
            return (
                <TableEmpty title={t('no_users_found')} onClick={() => getUsers(role!)} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_users_found')} />
            );
        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
            >
                {getFilteredList().map((doc, index) => {
                    const currentRole = getCurrentProfileRole(doc.roles);
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0), }}><span>{t(getUserRoleText(role ?? ''))}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1), }}><span>{`${doc.first_name} ${doc.last_name}`}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2), }}><span>{doc.email}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3), }}><span>{currentRole ? (getDateString(currentRole.member_since) ?? '') : ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4), }}>
                                <button className="delete-icon" onClick={() => handleDeleteRole(currentRole, doc)}>
                                    <DeleteIcon width={"100%"} height={"100%"} />
                                </button>
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 1;
        return 1;
    }



    function getCurrentProfileRole(docs: ProfileRole[]) {
        return docs.filter(doc => doc.role === role)[0];
    }

    /// close delete role dialog
    function handleCloseDeleteDialog() {
        setShowDeleteAction(null);
    }

    ///Delete Role
    function handleDeleteRole(doc: ProfileRole, user: Profile) {
        setShowDeleteAction({ role: doc, visible: true, user: user });
    }

    ///Delete user role
    function handleOnSuccessDeleteRoleDialog() {
        const state = location.state as { role: string };
        // dispatch(getRoleUsersList({ branch_id: '', role: state.role }));
        dispatch(getRmRoleUsersList({ branch_id: '', role: state.role }));
        handleCloseDeleteDialog();
        dispatch(manageUserActions.clearToggleRoleState());

    }



    function handleChange(value: string) {
        setSearch(value);
    }

    function handleSearch() {
        console.log(search);
    }



    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function handleCloseAddDialog() {
        setShowAddRoleAction(null);
    }

    /// handle open add role dialog
    function handleOpenAddRoleDialog() {
        setShowAddRoleAction({ role: role!, visible: true });
    }

    /// handle on success add dialog
    function handleOnSuccessAddDialog() {
        getUsers(role!);
        handleCloseAddDialog();
        dispatch(manageUserActions.clearToggleRoleState());
    }
    ///Add user action
    function handleAddUserSuccess() {
        setAddUser(false);
        dispatch(manageUserActions.clearCreateState());
    }
}




export default AdministratorPage;