
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDepartmentsDropdown, getEmployeeAssignmentReportList, getReportDownloadURL, getRoleUsersList, postSnackbarMessage, selectAccountManagerRoleUsersList, selectDepartmentsDropdownList, selectEmployeeAssignmentReportListState, selectProfileState, selectRecruiterRoleUsersList, selectReportsDownloadState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { LoadingType, THEME } from '../../../../../enums';
import TableEmpty, { TableErrorHandler, TableLoading } from '../../../../../components/table_empty/table_empty';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import { currencyConversion, CustomButton, getContactIcon } from '../../../../../utils';
import TabOptionsHeader from '../../../../../components/tab_options_header/tab_options_header';
import CustomFormSelect, { CustomMultiFormSelect } from '../../../../../components/form_selector/form_select';
import { CalenderIcon, DownloadIcon, TalentActiveStatusIcon } from '../../../../../icons';
import DatePicker from "react-datepicker";
import { convertDateToTimeStamp, getDateString } from '../../../../../variables';
import { AppRoutes } from '../../../../../routes';
import { departmentsDropdownListActions } from '../../../../../redux/selectors/departments_list/departments_list_reducer';
import SearchBar from '../../../../../components/search_bar';
import { IReportCenterEmpAssignmentReport } from '../../../../../interfaces/report_center_talent';
import UrlBuilder from '../../../../../apis/url_builder';
import SSNHolder from '../../../../../components/ssn_holder/ssn_holder';
import { talentRequestSSNActions } from '../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import { Portal } from 'react-portal';
import ViewSSNPopup from '../../../../talent/popups/view_ssn/view_ssn';
import { reportDownloadSliceActions } from '../../../../../redux/report_center/download_report/download_report_reducer';
import './employee_assignment_report.scss'

interface Props extends RouteComponentProps<any> { }
const EmployeeAssignmentReport: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { location, history } = props;

    const tableHeader = [
        { title: "customer_name", code: "company_name" },
        { title: "department", code: "department_name" },
        { title: "employee_name", code: "talent_name" },
        { title: "employee_ssn", code: "talent_ssn" },
        { title: "job_title", code: "job_title" },
        { title: "start_date", code: "start_date" },
        { title: "end_date", code: "end_date" },
        { title: "pay_rate", code: "pay_rate" },
        { title: "bill_rate", code: "bill_rate" },
        { title: "preferred_contact", code: "preferred_contact" },
    ];

    const departmentsList = useAppSelector((state) => selectDepartmentsDropdownList(state));
    const accountManagersList = useAppSelector((state) => selectAccountManagerRoleUsersList(state));
    const recruitersList = useAppSelector((state) => selectRecruiterRoleUsersList(state));
    const employeeAssignementReportListState = useAppSelector((state) => selectEmployeeAssignmentReportListState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));
    const profileState = useAppSelector((state) => selectProfileState(state));

    const [employeeAssignmentReportList, setEmployeeAssignmentReportList] = useState<IReportCenterEmpAssignmentReport[] | null>(null);
    const [company, setCompany] = useState<string>("");
    const [department, setDepartment] = useState<string>("");
    const [accountManagers, setAccountManagers] = useState<string[]>([]);
    const [recruiters, setRecruiters] = useState<string[]>([]);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");
    const [companiesList, setCompaniesList] = useState<{ label: string, value: string }[]>([])

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>({ available_from: convertDateToTimeStamp(new Date(), true), available_to: convertDateToTimeStamp(new Date(), true) })
    const [jobOrderHRDateRange, setJobOrderHRDateRange] = useState([new Date(), new Date()]);
    const [jobOrderHRStartDate, jobOrderHREndDate] = jobOrderHRDateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [showSSNPopup, setShowSSNPopup] = useState<{
        talentId: string;
        visible: boolean;
    } | null>(null);

    useEffect(() => {
        dispatch(getRoleUsersList());
    }, []);

    useEffect(() => {
        if (company) {
            dispatch(getDepartmentsDropdown(company));
        }
        else {
            dispatch(
                departmentsDropdownListActions.clearDepartmentsDropdownListState()
            );
            setDepartment("");
        }
    }, [company]);

    useEffect(() => {
        if (employeeAssignementReportListState && employeeAssignementReportListState?.loading === LoadingType.succeeded && employeeAssignementReportListState.response) {
            setEmployeeAssignmentReportList(employeeAssignementReportListState?.response);
            const updatedCompaniesList = employeeAssignementReportListState.response?.reduce<{ label: string; value: string }[]>((acc, company) => {
                if (!acc.some(item => item.value === company.company_id)) {
                    acc.push({ label: company.company_name, value: company.company_id });
                }
                return acc;
            }, []);
            setCompaniesList(updatedCompaniesList);
        }
        return () => {
            setEmployeeAssignmentReportList([])
        }
    }, [employeeAssignementReportListState?.loading, employeeAssignementReportListState.response]);

    useEffect(() => {
        if ((formState.available_from !== 0 && formState.available_to !== 0) || ((formState.available_from === 0 && formState.available_to === 0))) {
            getEmpAssignmentReportList();
        }
    }, [formState.available_from, formState.available_to]);

    useEffect(() => {
        if ((formState.available_from !== 0 && formState.available_to !== 0)) {
            setDatePickerOpen(false)
        }
        if ((formState.available_from === 0 && formState.available_to === 0)) {
            openDatePicker();
        }
    }, [formState.available_from, formState.available_to])

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.employeeAssignmentReport,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    company_id: company,
                    department_id: department,
                    download: true,
                    downloadAR: { account_managers: accountManagers, recruiters: recruiters },
                }));
        }
    }

    const getEmpAssignmentReportList = () => {
        dispatch(getEmployeeAssignmentReportList({ start_date: formState.available_from, end_date: formState.available_to }))
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setJobOrderHRDateRange(value);

    }

    const openDatePicker = () => {
        setDatePickerOpen(true);
    };

    function getSSNInfo(value: string, id: string) {
        return (
            <SSNHolder
                value={value}
                iconColor={THEME.tableRowTextColor}
                onClick={() => handleOnSSNView(id)}
                showFormat={false}
            />
        );
    }

    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
    }

    function handleOnSSNView(id: string) {
        dispatch(talentRequestSSNActions.initialiseRequestSSN(id));
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: id });
        } else {
            dispatch(postSnackbarMessage(t("ssn_access_is_disabled")));
        }
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };
    function handleCompanySelect(value: IReportCenterEmpAssignmentReport) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: IReportCenterEmpAssignmentReport) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.company_name,
                departmentId: value.department_id,
                departmentName: value.department_name,
            },
        });
        return;
    }

    function handleTalentSelect(value: IReportCenterEmpAssignmentReport) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${value.talent_id}`,
            state: {
                id: value.talent_id,
                name: value.talent_name,
            },
        });
        return;
    }

    function handleJobTitleSelect(value: IReportCenterEmpAssignmentReport) {
        props.history.push({
            pathname: `${AppRoutes.jobDetailsPage}/${value.job_order_id}`,
            state: {
                id: value.job_order_id,
                name: value.job_title,
            },
        });
        return;
    }

    function getTalentStatusIcon(doc: IReportCenterEmpAssignmentReport) {
        return (
            <div className="status-icon">
                <TalentActiveStatusIcon
                    width={"100%"}
                    height={"100%"}
                    style={{ color: doc.talent_name != null && doc.talent_name != "" ? THEME.buttonColor13 : ""}}
                />
            </div>
        );
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 1;
        return 1;
    }

    const showSpinner = () => employeeAssignementReportListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IReportCenterEmpAssignmentReport[] | undefined = employeeAssignmentReportList!;
        let resultList: IReportCenterEmpAssignmentReport[] | undefined;

        if (employeeAssignmentReportList && sortedField !== null) {
            resultList = [...employeeAssignmentReportList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        resultList = list?.filter(doc => {
            const hasCompany = company && company !== "";
            const hasDepartment = department && department !== "";
            const hasAccountManagers = accountManagers.length > 0;
            const hasRecruiters = recruiters.length > 0;

            const companyMatch = !hasCompany || (doc.company_id === company);
            const departmentMatch = !hasDepartment || (doc.department_id === department);
            const accountManagersMatch = !hasAccountManagers || (
                doc.account_managers &&
                doc.account_managers.length > 0 &&
                doc.account_managers.some(s => accountManagers.includes(s))
            );
            const recruitersMatch = !hasRecruiters || (
                doc.recruiters &&
                doc.recruiters.length > 0 &&
                doc.recruiters.some(s => recruiters.includes(s))
            );

            return companyMatch && departmentMatch && accountManagersMatch && recruitersMatch;
        });


        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (employeeAssignementReportListState?.error != null && employeeAssignmentReportList?.length === 0) {
            return <TableErrorHandler error={employeeAssignementReportListState?.error} onRefresh={getEmpAssignmentReportList} />;
        }

        if (employeeAssignmentReportList?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No data found"} onClick={getEmpAssignmentReportList} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList()?.map((doc) => {
                        return (
                            <tr key={doc.company_id + doc.talent_id}>
                                <TableData customStyle={{ flex: getFlexNumber(0) }}
                                    isButton={true}
                                    onClick={() => handleCompanySelect(doc)}
                                >
                                    <span>{doc.company_name}</span>
                                </TableData>
                                <TableData
                                    customStyle={{ flex: getFlexNumber(1) }}
                                    isButton={true}
                                    onClick={() => handleDepartmentSelect(doc)}
                                >
                                    <span>{(doc.department_name !== "" && doc.department_name !== null) ? doc.department_name : t("corporate")}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}
                                    isButton={true}
                                    onClick={() => handleTalentSelect(doc)}
                                    prefix={getTalentStatusIcon(doc)}
                                >
                                    <span>{doc.talent_name}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                    <span>{doc.talent_ssn ? getSSNInfo(doc.talent_ssn, doc.talent_id) : ""}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4) }}
                                    isButton={true}
                                    onClick={() => handleJobTitleSelect(doc)}
                                >
                                    <span>{doc.job_title}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                    <span>{getDateString(doc.start_date)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                    <span>{getDateString(doc.end_date)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                    <span>{currencyConversion(doc.pay_rate)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                    <span>{currencyConversion(doc.bill_rate)}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                    <span>{getContactIcon(doc.preferred_contact)}</span>
                                </TableData>
                            </tr>
                        )
                    })}
                    {showSSNPopup && (
                        <Portal>
                            <ViewSSNPopup
                                visible={showSSNPopup.visible}
                                title={t("social_security_number")}
                                talentId={showSSNPopup.talentId}
                                onClose={() => setShowSSNPopup(null)}
                                onDissmiss={() => setShowSSNPopup(null)}
                                onSuccessClose={handleOnSuccessSSNClose}
                            />
                        </Portal>
                    )}
                </SortableTable>
            );
        }
    };

    return (
        <div className="open-job-orders-reports-container employee-assignment-report-container">
            <div className="open-job-orders-reports-header">
                <div className="main-header">
                    <div className='cmp-dropdown'>
                        <div className="dateRange">
                            <label>{t("date_range")}</label>
                            <div className="date-input-icon">
                                <div className="calendar-icon-div">
                                    <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={openDatePicker} />
                                </div>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={jobOrderHRStartDate}
                                    endDate={jobOrderHREndDate}
                                    onChange={handleChange}
                                    isClearable={true}
                                    placeholderText={'Start Date -  End Date'}
                                    dateFormat={'MM.dd.yyyy'}
                                    onClickOutside={() => setDatePickerOpen(false)}
                                    open={isDatePickerOpen}
                                    onFocus={() => setDatePickerOpen(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("customer_name")}
                            name={"company_id"}
                            value={company}
                            placeholder={"All"}
                            list={companiesList?.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={(value) => {
                                setCompany(value);
                            }}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomFormSelect
                            label={t("department")}
                            name={"department_id"}
                            value={department}
                            placeholder={"All"}
                            list={departmentsList?.map((doc) => ({
                                label: doc.name,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setDepartment(value);
                            }}
                            disabled={company && company !== "" ? false : true}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("account_manager")}
                            name={"account_manager"}
                            value={accountManagers}
                            placeholder={"All"}
                            list={accountManagersList?.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id,
                            }))}
                            onChange={(value) => {
                                setAccountManagers(value);
                            }}
                        />
                    </div>
                    <div className='cmp-dropdown'>
                        <CustomMultiFormSelect
                            label={t("recruiter")}
                            name={"recruiter"}
                            value={recruiters}
                            placeholder={"All"}
                            list={recruitersList.map((doc) => ({
                                label: `${doc.first_name ?? ''} ${doc.last_name ?? ''}`,
                                value: doc.id
                            }))}
                            onChange={(value) => {
                                setRecruiters(value);
                            }}
                        />
                    </div>
                </div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("employee_assignment_report")}</span>
                        </div>
                    </div>
                    <div className="rc-scsr-btns">
                        <div className="total-count-action">
                            <span>
                                {`${t("total_count")}: `}
                                <span className="total-count-number">
                                    {getFilteredList()?.length}
                                </span>
                            </span>
                        </div>
                        <div className="rc-scsr-btn">
                            <CustomButton
                                leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                                loading={false}
                                name={t("download")}
                                enable={getFilteredList()?.length !== 0 && true}
                                backgroundColor={THEME.buttonColor16}
                                onClick={() => handleDownload()}
                            />
                        </div>
                        <div>
                            <SearchBar
                                value={search}
                                onChange={setSearch}
                                onSearch={() => { }}
                            />
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
        </div>
    );


}

export default EmployeeAssignmentReport;