import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { getBatchDropdownThunk, getTimeCardsReportDownloadUrl, getTimeCardsReportListThunk, getWeekEndingDatesDropdownThunk, selectTCRDownloadUrlState, selectTimeCardsBatchState, selectTimecardsReportListState, selectWeekEndingDatesDDList, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { getDateString } from "../../../../../variables";
import { useEffect, useState } from "react";
import { ITimeCardsBatch, ITimeCardsData, ITimeCardsReport } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { CustomButton, SpinnerScreen, convertNumberToMoney, currencyConversion, formatNumber } from "../../../../../utils";
import SearchBar from "../../../../../components/search_bar";
import './timecard_report.scss'
import { DownloadIcon } from "../../../../../icons";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { RouteComponentProps } from "react-router-dom";
import { AppRoutes } from "../../../../../routes";
import { timecardsReportListSliceActions } from "../../../../../redux/back_office/timecards/time_cards/time_cards_report_list/time_cards_report_list_reducer";


const tableMainHeader = [
    { title: '', code: '', colSpan: 1 },
    { title: 'payroll', code: 'payroll', colSpan: 1 },
    { title: 'billing', code: 'billing', colSpan: 1 },
    { title: 'gross_profit', code: 'gross_profit', colSpan: 1 },
    { title: 'markup', code: 'markup', colSpan: 1 },
]
const tableHeader = [
    { title: 'week_ending', code: 'week_ending_date' },
    { title: 'batch_number', code: 'batch_number' },
    { title: 'employee', code: 'talent_name' },
    { title: 'company', code: 'company_name' },
    { title: 'pay_type', code: 'pay_type' },
    { title: 'units', code: 'pay_units' },
    { title: 'rate', code: 'pay_rate' },
    { title: 'total', code: 'item_pay' },
    { title: 'units', code: 'bill_units' },
    { title: 'rate', code: 'bill_rate' },
    { title: 'total', code: 'item_bill' },
    { title: 'gp$', code: 'gross_profit' },
    { title: 'gp%', code: 'gross_profit_percentage' },
    { title: 'value', code: 'mark_up' },
];

interface Props extends RouteComponentProps<any> { }

const TimeCardsReportPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));
    const batchDropdownState = useAppSelector((state) => selectTimeCardsBatchState(state));
    const timeCardsReprtListState = useAppSelector((state) => selectTimecardsReportListState(state));

    const timecardsDownloadUrlState = useAppSelector((state) => selectTCRDownloadUrlState(state));

    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const [selectedBatch, setSelectedBatch] = useState<{ id: string, batchNum?: string }>({ id: "", batchNum: "" });
    const [batchList, setBatchList] = useState<ITimeCardsBatch[] | null>([]);
    const [timeCardsReportList, setTimeCardsReportList] = useState<ITimeCardsReport | null>(null);
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const queryParam = {
        show_batches_from_other_user: false,
        closed_batches: true,
        show_closed_batches: false,
    }
    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (weekEndingDatesList && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            dispatch(getBatchDropdownThunk({ week_accounting_id: weekEndingDatesList[0].id, queryParams: queryParam }));
            dispatch(getTimeCardsReportListThunk(weekEndingDatesList[0].id));
        }
    }, [weekEndingDatesList.length]);

    useEffect(() => {
        if (timeCardsReprtListState && timeCardsReprtListState.loading === LoadingType.succeeded) {
            setTimeCardsReportList(timeCardsReprtListState.response);
        }
    }, [timeCardsReprtListState, selectedWeekDate, timeCardsReprtListState.loading]);

    useEffect(() => {
        if (batchDropdownState && batchDropdownState.loading === LoadingType.succeeded) {
            setBatchList(batchDropdownState.response)
        }
    }, [selectedWeekDate, batchDropdownState.loading]);

    useEffect(() => {
        if (timecardsDownloadUrlState.loading === LoadingType.succeeded && timecardsDownloadUrlState.response &&
            timecardsDownloadUrlState.response.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', timecardsDownloadUrlState?.response?.url!);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(timecardsReportListSliceActions.clearDownloadState());
        }
    }, [timecardsDownloadUrlState.loading]);

    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getBatchDropdownThunk({ week_accounting_id: val, queryParams: queryParam }));
        dispatch(getTimeCardsReportListThunk(val));
        setSelectedBatch({ id: "", batchNum: "" });
    }

    const handleBatchChange = (val) => {
        const batchNum = batchList!.find((doc) => doc.id === val)?.batch_number;
        setSelectedBatch({ id: val, batchNum: batchNum });
    }

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function handleDownload() {
        dispatch(getTimeCardsReportDownloadUrl(selectedWeekDate?.id!));
    }

    function getFilteredList() {
        let list: ITimeCardsData[] | undefined = timeCardsReportList?.time_cards;
        if (selectedBatch && selectedBatch.batchNum !== "" && selectedBatch.batchNum !== undefined) {
            list = list?.filter(doc => doc.batch_number === selectedBatch.batchNum);
        }
        if (timeCardsReportList && sortedField != null) {
            list = [...timeCardsReportList?.time_cards].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] === typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] === typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            const paymentsFilter = doc.payments.some(subDoc => {
                const payrollUnitsFilter = subDoc.pay_units ? subDoc.pay_units.toString().trim().toLowerCase().includes(str) : false
                const payrollRateFilter = subDoc.pay_rate ? convertNumberToMoney(subDoc.pay_rate).toString().trim().toLowerCase().includes(str) : false
                const payrollTotalFilter = subDoc.item_pay ? convertNumberToMoney(subDoc.item_pay).toString().trim().toLowerCase().includes(str) : false
                const billingUnitsFilter = subDoc.bill_units ? subDoc.bill_units.toString().trim().toLowerCase().includes(str) : false
                const billingRateFilter = subDoc.bill_rate ? convertNumberToMoney(subDoc.bill_rate).toString().trim().toLowerCase().includes(str) : false
                const billingTotalFilter = subDoc.item_bill ? convertNumberToMoney(subDoc.item_bill).toString().trim().toLowerCase().includes(str) : false
                const grossProfitDollerFilter = subDoc.gross_profit ? convertNumberToMoney(subDoc.gross_profit).toString().trim().toLowerCase().includes(str) : false
                const grossProfitPercentageFilter = subDoc.gross_profit_percentage ? subDoc.gross_profit_percentage.toString().trim().toLowerCase().includes(str) : false
                const markUpFilter = subDoc.mark_up ? subDoc.mark_up.toString().trim().toLowerCase().includes(str) : false

                return payrollUnitsFilter || payrollRateFilter || payrollTotalFilter || billingUnitsFilter || billingRateFilter || billingTotalFilter || grossProfitDollerFilter || grossProfitPercentageFilter || markUpFilter
            });

            const batchFilter = doc.batch_number ? doc.batch_number.trim().toLowerCase().includes(str) : false;
            const talentFilter = doc.talent_name ? doc.talent_name.trim().toLowerCase().includes(str) : false;
            const companyFilter = doc.company_name ? doc.company_name.trim().toLowerCase().includes(str) : false;

            return paymentsFilter || batchFilter || talentFilter || companyFilter;
        });
    }

    function handleSearch(value: string) {
        setSearch(value);
    }

    function handleTalentSelect(talentId: string, talentName: string) {
        props.history.push({
            pathname: `${AppRoutes.talentDetailsPage}/${talentId}`,
            state: {
                id: talentId,
                name: talentName,
            },
        });
        return;
    }

    function handleCompanySelect(companyId: string, companyName: string) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${companyId}`,
            state: {
                id: companyId,
                name: companyName,
            },
        });
        return;
    }

    return (<>
        <div className="timecards-report-tab-container">
            <div className="timecards-report-tab-header">
                <div className="col1">
                    <CustomFormSelect
                        list={
                            weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))
                        }
                        customStyle={{ width: "100%" }}
                        name={"week_ending_date"}
                        value={selectedWeekDate.id}
                        label={t("week_ending_date")}
                        placeholder={"Select"}
                        onChange={handleWeekEndChange}
                        isClearable={false}
                    />
                    <CustomFormSelect
                        list={batchList!.map((doc) => ({
                            label: doc.batch_number,
                            value: doc.id
                        }))}
                        customStyle={{ width: "100%" }}
                        name={"select_batch"}
                        value={selectedBatch.id}
                        label={t("select_batch")}
                        placeholder={"Select"}
                        onChange={(val) => handleBatchChange(val)}
                    />
                </div>
                <div className="col2">
                    <div>
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            leftIcon={<DownloadIcon height={"1vw"} width={"1vw"} />}
                            name={t("download")}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                    </div>
                </div>
            </div>
            <div className="timecards-report-tab-content">
                <div>
                    {timeCardsReprtListState && timeCardsReprtListState.loading === LoadingType.pending ?
                        (<>
                            <SpinnerScreen /></>)
                        :
                        (
                            <>
                                {/* <div className="timecards-report-header">
                                    <SortableTable
                                        headerList={tableMainHeader}
                                        flexNumber={getMainHeaderFlexNumber}
                                        customBorderStyle={["payroll", "billing", "gross_profit", "markup"]}
                                        customBGColor={["payroll", "billing"]}
                                        customTextAlign={["payroll", "billing", "gross_profit"]}
                                    >
                                        <></>
                                    </SortableTable>
                                </div> */}
                                <div className="timecards-report-table">
                                    <SortableTable
                                        headerList={tableHeader}
                                        topHeaderList={tableMainHeader}
                                        sortedField={sortedField}
                                        onSortChange={handleSortFieldChange}
                                        flexNumber={getFlexNumber}
                                        topHeaderFlexNumber={getMainHeaderFlexNumber}
                                        isAsc={sortDirection}
                                        customBorderStyle={["units", "gp$", "value"]}
                                        customTopBorderStyle={["payroll", "billing", "gross_profit", "markup"]}
                                        customTopBGColor={["payroll", "billing"]}
                                        customTopTextAlign={["payroll", "billing", "gross_profit"]}
                                    >
                                        {getFilteredList() && getFilteredList()!.length > 0 ?
                                            getFilteredList()?.map((doc, index) => {
                                                return (
                                                    <>
                                                        {(doc.payments.filter((pay) => pay.selected)).map((subDoc, subIndex) => {
                                                            return (
                                                                <tr key={doc.id + subIndex}>
                                                                    <TableData customStyle={{ flex: getFlexNumber(0) }}><span style={{ visibility: subIndex === 0 ? "visible" : "hidden" }}>{getDateString(doc.week_ending_date)}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(1) }}><span style={{ visibility: subIndex === 0 ? "visible" : "hidden" }}>{doc.batch_number}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(2) }} onClick={() => subIndex === 0 ? handleTalentSelect(doc.talent_id, doc.talent_name) : {}} isButton={subIndex === 0 && true}><span style={{ visibility: subIndex === 0 ? "visible" : "hidden" }}>{doc.talent_name}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(3) }} onClick={() => subIndex === 0 ? handleCompanySelect(doc.company_id, doc.company_name) : {}} isButton={subIndex === 0 && true}><span style={{ visibility: subIndex === 0 ? "visible" : "hidden" }}>{doc.company_name}</span></TableData>

                                                                    <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{subDoc.pay_type}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(5), borderLeft: "1px solid #c3cfd6" }}><span>{subDoc.pay_units ? formatNumber(subDoc.pay_units) : 0}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(6) }}><span>{subDoc.pay_rate
                                                                        ? currencyConversion(subDoc.pay_rate)
                                                                        : "$0.00"}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                                                        <span>
                                                                            {
                                                                                subDoc.item_pay
                                                                                    ? currencyConversion(subDoc.item_pay)
                                                                                    : "$0.00"
                                                                            }
                                                                        </span>
                                                                    </TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(8), borderLeft: "1px solid #c3cfd6" }}><span>{subDoc.bill_units ? formatNumber(subDoc.bill_units) : 0}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{subDoc.bill_rate
                                                                        ? currencyConversion(subDoc.bill_rate)
                                                                        : "$0.00"}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(10) }}><span>
                                                                        {
                                                                            subDoc.item_bill
                                                                                ? currencyConversion(subDoc.item_bill)
                                                                                : "$0.00"
                                                                        }
                                                                    </span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(11), borderLeft: "1px solid #c3cfd6" }}><span>{subDoc.gross_profit
                                                                        ? currencyConversion(subDoc.gross_profit)
                                                                        : "$0.00"}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(12) }}><span>{subDoc.gross_profit_percentage ? formatNumber(subDoc.gross_profit_percentage) + `${"%"}` : 0}</span></TableData>
                                                                    <TableData customStyle={{ flex: getFlexNumber(13), borderLeft: "1px solid #c3cfd6" }}><span>{subDoc.mark_up ? formatNumber(subDoc.mark_up) + `${"%"}` : 0}</span></TableData>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                );
                                            })
                                            :
                                            <tr>
                                                <TableData customStyle={{ flex: getFlexNumber(14) }}><div style={{ width: '100%', textAlign: "center", fontSize: '0.9vw' }}>{t("no_timecards_reports_available_weekend")}</div></TableData>
                                            </tr>
                                        }
                                    </SortableTable>
                                </div>
                                {timeCardsReprtListState.loading === LoadingType.succeeded && <div>
                                    <div className="units-div">
                                        <div className="custom-units-div custom-units-card1">
                                            <div className="units-title">
                                                <span>Payroll</span>
                                            </div>
                                            <div className="units-val-row">
                                                <div className="col1">
                                                    <div className='units-val'>
                                                        <div className="units-val-sub-sec">
                                                            <span>Total Units:</span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? formatNumber(timeCardsReportList.total_pay_units) : 0}</span>
                                                        </div>
                                                        <div className="units-val-sub-sec">
                                                            <span>Total Pay:</span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? currencyConversion(timeCardsReportList.total_item_pay) : `${"$ 0.00"}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-units-div custom-units-card2">
                                            <div className="units-title">
                                                <span>Billing</span>
                                            </div>
                                            <div className="units-val-row">
                                                <div className="col1">
                                                    <div className='units-val'>
                                                        <div className="units-val-sub-sec">
                                                            <span>Total Units:</span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? formatNumber(timeCardsReportList.total_bill_units) : 0}</span>
                                                        </div>
                                                        <div className="units-val-sub-sec">
                                                            <span>Total Billed:</span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? currencyConversion(timeCardsReportList.total_item_bill) : `${"$ 0.00"}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-units-div custom-units-card3">
                                            <div className="units-title">
                                                <span>Gross Profit</span>
                                            </div>
                                            <div className="units-val-row">
                                                <div className="col1">
                                                    <div className='units-val'>
                                                        <div className="units-val-sub-sec">
                                                            <span>Gross Profit: </span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? currencyConversion(timeCardsReportList.total_gross_profit) : `${"$ 0.00"}`}</span>
                                                        </div>
                                                        <div className="units-val-sub-sec">
                                                            <span>Gross Profit: </span>
                                                            <span>{timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? formatNumber(timeCardsReportList.total_gross_profit_percentage) + `${"%"}` : `${"0.00 %"}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-units-div custom-units-card4">
                                            <div className="units-title">
                                                <span>Markup</span>
                                            </div>
                                            <div className="units-val-row">
                                                <div className="col1">
                                                    <div className='units-val'>
                                                        <span>
                                                            {timeCardsReportList && timeCardsReportList.time_cards.length > 0 ? formatNumber(timeCardsReportList.markup) + `${"%"}` : `${"0.00 %"}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    </>);
    function getFlexNumber(value: number) {
        if (value === 0) return 1.2;
        if (value === 1) return 0.8;
        if (value === 2) return 1;
        if (value === 3) return 1.5;
        if (value === 4) return 1;
        if (value === 5) return 0.5;
        if (value === 6) return 1.5;
        if (value === 7) return 1.5;
        if (value === 8) return 0.5;
        if (value === 9) return 1.5;
        if (value === 10) return 1.5;
        if (value === 11) return 1.5;
        if (value === 12) return 1;
        if (value === 13) return 0.7;
        if (value === 14) return 1;
        if (value === 15) return 1;
        if (value === 16) return 1;

        return 1;
    }
    function getMainHeaderFlexNumber(value: number) {
        if (value === 0) return 5.85;
        if (value === 1) return 3.544;
        if (value === 2) return 3.546;
        if (value === 3) return 2.397;
        if (value === 4) return 0.581;

        return 1;
    }
}

export default TimeCardsReportPage;