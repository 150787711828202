import { t } from "i18next";
import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import ApiError from "../../../../components/api_error";
import { THEME } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { CustomButton } from "../../../../utils";
import './delete_record_popup.scss';

interface Props {
    clearError: Function;
    error: AppError | null | undefined;
    onRemove: Function;
    onCancel: Function;
    saving: boolean;
    title: string;
    visible:boolean
}

const BODeleteRecordPopup: React.FunctionComponent<Props> = (props) => {
    const { clearError, error, onCancel, onRemove, saving, title,visible } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={() => onCancel()}
            onDissmiss={() => onCancel()}
        >
            <div className="del-record-container">
                <div className="del-record-header">
                    <div className="title-txt">
                        <span>{`${t("attention")}!!`}</span>
                    </div>
                </div>
                <div className="del-record-content">
                    <div className="message-txt">
                        <span>{title}</span>
                    </div>
                    {error && (
                        <div className={"error-section"}>
                            <ApiError message={error.message} onClose={() => clearError()} />
                        </div>
                    )}
                </div>
                <div className="del-record-action-holder">
                    <div className="del-record-action">
                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => onCancel()}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={saving}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("delete")}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => onRemove()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    );
};

export default BODeleteRecordPopup;