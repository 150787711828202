import { useRef, useState } from "react";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../../components/dialog_wrapper/dialog_wrapper";
import { ICompanyTaxSetup, IMasterTaxSetup } from "../../../../../interfaces/master_tax";
import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { updateMasterTaxSetup } from "../../../../../redux/admin_center";
import { MasterTaxSetupState } from "../../../../../redux/admin_center/integration_partners/master_tax/master_tax_setup/master_tax_setup_reducer";
import { useAppDispatch } from "../../../../../redux/store";
import { CustomButton, FormInput, enforceFormat } from "../../../../../utils";
import { einType, companyPaymentMethod } from "../master_tax_dropdown";
import { MasterTaxIcon } from "../../../../../icons";
import ApiError from "../../../../../components/api_error";
import { THEME, LoadingType } from "../../../../../enums";
import './company_tax_popup.scss';

interface Props {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onClearError: () => void,
    masterTaxState: MasterTaxSetupState,
    tax: ICompanyTaxSetup | undefined,
    masterTaxFormState: IMasterTaxSetup

}

const CompanyTaxConfig: React.FunctionComponent<Props> = (props) => {
    const {
        visible, tax,
        onClose,
        onDissmiss,
        onClearError, masterTaxState, masterTaxFormState
    } = props;

    const getFormData = (value?: ICompanyTaxSetup) => {
        if (value) {
            return value;
        }
        return {
            format_id: "",
            ein_type: "N",
            ein: "",
            payment_method: 1,
            eft_password: "",
            county_code: "",
            setup_completed: false,
            tax_code: "",
            tax_desc: "",
        }
    }

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [formState, setFormState] = useState<ICompanyTaxSetup>(getFormData(tax));
    const [checkValidationOnSubmit, setCheckValidationOnSubmit] = useState<boolean>(false);

    const handleFieldChange = (id: string, val: string | null) => {
        setFormState({
            ...formState,
            [id]: val
        })
    }
    const handleSave = () => {
        setCheckValidationOnSubmit(true);
        const updatedFormState = masterTaxFormState.taxes_setup.map((tax) => {
            const updatedTax = {
                ...tax,
                payroll_code: formState.payroll_code
            };
            return updatedTax.tax_code === formState.tax_code ?
                { ...formState, setup_completed: true } : updatedTax;
        });
        if (_isFormValid() && formState) {
            dispatch(updateMasterTaxSetup({
                ...masterTaxFormState,
                taxes_setup: updatedFormState
            }));
            setCheckValidationOnSubmit(false);
        }
    };

    function _isFormValid() {
        const { ein_type, payment_method, ein } = formState;

        if (
            payment_method === null ||
            ein_type === "" || ein_type === null ||
            ((formState.ein_type !== "Y" && formState.ein_type !== "F" && formState.ein_type !== "E") && (ein === "" || ein === null) )
        ) {
            return false;
        }


        return true;
    }
    const formValidators = (value: { text: string; field: string }) => {
        switch (value.field) {
            case "payroll_code":
            case "ein_type":
            case "payment_method": {
                if (value.text === "" || value.text === null) return t("validators.required");
                return null;
            }

            case "ein": {
                if (formState.ein_type !== "Y" && formState.ein_type !== "F" && formState.ein_type !== "E") {
                    if (value.text === "" || value.text === null) return t("validators.required");
                }
                return null;
            }
            default: {
                return null;
            }
        }
    }
    const filterTaxCodes = ["FE0000-001","FE0000-010","TX0000-010"];
    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"60%"}
        onClose={onClose}
        onDissmiss={onDissmiss}
    >
        <DialogWrapper onClose={() => closePopup(onClose)}>
            <div className="cs-popup-container">
                <div className="cs-popup-header">
                    <FormDialogTilteHeader
                        title={t('edit_company_tax')}
                        titleIcon={<MasterTaxIcon width='100%' height="100%" />}
                    />
                </div>
                <div className="cs-poup-content">
                    <div className="cs-input-row">
                        <div className="cs-input-holder">
                            <FormInput
                                id={"tax_code"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.tax_code ?? ""}
                                label={t("tax_code")}
                                placeholder={t("")}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                disabled={true}
                            />
                        </div>
                        <div className="cs-input-holder">
                            <FormInput
                                id={"payroll_code"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.payroll_code ?? ""}
                                label={t("payroll_code")}
                                placeholder={t("")}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                required={true}
                            />
                        </div>
                        <div className="cs-input-holder">
                            <CustomFormSelect
                                list={einType.map((doc) => ({
                                    label: doc.value,
                                    value: doc.id,
                                }))}
                                customStyle={{ width: "-webkit-fill-available" }}
                                name={"ein_type"}
                                value={formState.ein_type}
                                label={t("ein_type")}
                                placeholder={"Select"}
                                onChange={(val) => handleFieldChange('ein_type', val)}
                                required
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                validations={formValidators}
                            />
                        </div>

                    </div>
                    <div className="cs-input-row">
                        <div className="cs-input-holder">
                            <FormInput
                                id={"ein"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.ein ?? ""}
                                label={t("ein")}
                                placeholder={t("EIN")}
                                validations={formValidators}
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                required={(formState.ein_type !== "Y" && formState.ein_type !== "F" && formState.ein_type !== "E")}
                                onKeyUp={enforceFormat}
                                onKeyDown={enforceFormat}
                            />
                        </div>
                        <div className="cs-input-holder">
                            <CustomFormSelect
                                list={companyPaymentMethod
                                    .filter(doc => !(filterTaxCodes.includes(formState.tax_code) && doc.id === 1))
                                    .map((doc) => ({
                                    label: doc.value,
                                    value: doc.id,
                                }))}
                                customStyle={{ width: "-webkit-fill-available" }}
                                name={"payment_method"}
                                value={formState.payment_method}
                                label={t("payment_method")}
                                placeholder={"Select"}
                                onChange={(val) => handleFieldChange('payment_method', val)}
                                required
                                checkValidationOnSubmit={checkValidationOnSubmit}
                                validations={formValidators}
                            />
                        </div>
                        <div className="cs-input-holder">
                            <FormInput
                                id={"eft_password"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.eft_password ?? ""}
                                label={t("eft_password")}
                                placeholder={t("eft_password")}
                                validations={formValidators}
                            />
                        </div>

                    </div>
                    <div className="cs-input-row">
                        <div className="cs-input-holder">
                            <FormInput
                                id={"county_code"}
                                onChange={handleFieldChange}
                                type={"text"}
                                value={formState.county_code ?? ""}
                                label={t("county_code")}
                                placeholder={t("")}
                                validations={formValidators}
                                onKeyUp={enforceFormat}
                                onKeyDown={enforceFormat}
                                maxLength={5}
                            />
                        </div>
                    </div>
                </div>
                <div className="cs-p-actions-row">
                    <div className="">

                        {masterTaxState.update.error && <div className="error-holder">
                            <ApiError message={masterTaxState.update.error.message} onClose={onClearError} />
                        </div>}
                    </div>
                    <div className="cs-actions">
                        <div className="btn-cancel">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: "capitalize" }}
                                name={t("cancel")}
                                enable
                                backgroundColor={THEME.secondaryColor4}
                                onClick={onClose}
                            />
                        </div>
                        <div className="btn-save">
                            <CustomButton
                                textStyle={{ textTransform: 'capitalize' }}
                                loading={masterTaxState.update.loading === LoadingType.pending}
                                name={t("save")}
                                backgroundColor={THEME.buttonColor1}
                                onClick={handleSave}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </DialogWrapper>

    </ActionDialogHolder>
    );
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
}

export default CompanyTaxConfig;