import { combineReducers } from "@reduxjs/toolkit";
import { paycheckRegisterSlice } from "./paycheck_register/paycheck_register_reducer";
import { newHireReportListSlice } from "./new_hire_report/new_hire_report_reducer";
import { payrollJournalListSlice } from "./payroll_journal/payroll_journal_reducer";
import { paymentRegisterListSlice } from "./payment_register/payment_register_reducer";
import { payrollCashSlice } from "./payroll_cash_req/payroll_cash_req_reducer";
import { payrollDeductionsListSlice } from "./payroll_deductions/payroll_deductions_reducer";
import { rcWorkCompSlice } from "./rc_work_comp_code/rc_wc_code_reducer";
import { taxByEmployeeListSlice } from "./tax_by_employee/tax_by_employee_reducer";
import { employerTaxSlice } from "./employer_tax_report/employer_tax_reducer";
import { holidayReportSlice } from "./holiday_vacation_report/holiday_vacation_report_reducer";
import { terminationReportListSlice } from "./termination_report/termination_report_reducer";

export const reportCenterPayrollTaxReducers = combineReducers({
    [paycheckRegisterSlice.name]: paycheckRegisterSlice.reducer,
    [newHireReportListSlice.name]: newHireReportListSlice.reducer,
    [payrollJournalListSlice.name]: payrollJournalListSlice.reducer,
    [paymentRegisterListSlice.name]: paymentRegisterListSlice.reducer,
    [payrollCashSlice.name]: payrollCashSlice.reducer,
    [payrollDeductionsListSlice.name]: payrollDeductionsListSlice.reducer,
    [rcWorkCompSlice.name]: rcWorkCompSlice.reducer,
    [taxByEmployeeListSlice.name]: taxByEmployeeListSlice.reducer,
    [employerTaxSlice.name]: employerTaxSlice.reducer,
    [holidayReportSlice.name]: holidayReportSlice.reducer,
    [terminationReportListSlice.name]: terminationReportListSlice.reducer,



});

export * from "./paycheck_register/paycheck_register_thunk";
export * from "./paycheck_register/paycheck_register_selector";

export * from "./new_hire_report/new_hire_report_thunk";
export * from "./new_hire_report/new_hire_report_selector";

export * from "./payroll_journal/payroll_journal_thunk";
export * from "./payroll_journal/payroll_journal_selector";

export * from "./payment_register/payment_register_thunk";
export * from "./payment_register/payment_register_selector";

export * from "./payroll_cash_req/payroll_cash_req_thunk";
export * from "./payroll_cash_req/payroll_cash_req_selector";

export * from "./payroll_deductions/payroll_deductions_thunk";
export * from "./payroll_deductions/payroll_deductions_selector";
export * from "./rc_work_comp_code/rc_wc_code_thunk";
export * from "./rc_work_comp_code/rc_wc_code_selector";

export * from "./tax_by_employee/tax_by_employee_selector";
export * from "./tax_by_employee/tax_by_employee_thunk";

export * from "./employer_tax_report/employer_tax_selector";
export * from "./employer_tax_report/employer_tax_thunk";

 export * from "./holiday_vacation_report/holiday_vacation_report_selector";
 export * from "./holiday_vacation_report/holiday_vacation_report_thunk";

 export * from "./termination_report/termination_report_selector";
 export * from "./termination_report/termination_report_thunk";




