import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, EndAssignmentReason } from "../../../../interfaces";
import * as thunk from "./end_assignment_reasons_thunk";

export interface EndAssignmentReasonState extends EntityState<EndAssignmentReason> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const endAssignmentReasonAdapter: EntityAdapter<EndAssignmentReason> = createEntityAdapter<EndAssignmentReason>({
    selectId: (doc) => doc.id
})

const initialState: EndAssignmentReasonState = endAssignmentReasonAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const endAssignmentReasonSlice = createSlice({
    name: 'endAssignmentReason',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEndAssignmentReasons.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEndAssignmentReasons.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEndAssignmentReasons.fulfilled, (state, action) => endAssignmentReasonAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createEndAssignmentReason.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createEndAssignmentReason.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createEndAssignmentReason.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteEndAssignmentReason.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteEndAssignmentReason.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteEndAssignmentReason.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateEndAssignmentReason.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateEndAssignmentReason.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateEndAssignmentReason.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = endAssignmentReasonSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = endAssignmentReasonAdapter.getSelectors();
export const selectState = (state: EndAssignmentReasonState) => state;
export const selectLoading = (state: EndAssignmentReasonState) => state.loading;
export const selectError = (state: EndAssignmentReasonState) => state.error;


