import { combineReducers } from "@reduxjs/toolkit";
import { employeePortalUniversalDirectoryReducers } from "./universal_directory";
import { employeePortalPDReducers } from "./payroll_details";

export const employeePortalReducers = combineReducers({
    'universalDirectory': employeePortalUniversalDirectoryReducers,
    'empportalPayrollDetails': employeePortalPDReducers, 
});

export * from './universal_directory';
export * from './payroll_details'