import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../components/action_dialog_holder/action_dialog_holder";
import {
    useAppDispatch, useAppSelector,
    selectProfileState,
    selectCompaniesDropdownList, getCompaniesDropdown, selectAssignmentDropdownList, selectTalentsDropdownList, selectTalentsDropdownListState,
} from "../../../redux/store";

import { LoadingType, } from "../../../enums";
import { SpinnerScreen } from "../../../utils";
import './new_work_comp_claim.scss';
import { ICreateWorkCompClaim, ICreateUnEmploymentClaim, IWorkCompClaimData, IUnEmploymentClaimData, UpdateICreateWorkCompClaim } from "../../../interfaces";
import {
    addUnEmploymentClaim, addWorkCompClaimDetails, getUnEmploymentClaimDocs,
    getWorkCompClaimDocs, selectUnEmploymentClaimSaveResponse, selectWorkCompClaimDocUploadState,
    selectWorkCompClaimSaveResponse, selectUnEmploymentClaimDocUploadState, updateWorkCompClaimDetails, updateUnEmploymentClaim
} from "../../../redux/risk_management";
import { S3API } from "../../../apis/s3_api";
import { workCompClaimsListActions } from "../../../redux/risk_management/universal_directory/work_comp_claims_list/work_comp_claims_list_reducer";
import { unEmploymentClaimsListActions } from "../../../redux/risk_management/universal_directory/unemployment_claims_list/unemployment_claims_list_reducer";
import DialogWrapper from "../../../components/dialog_wrapper/dialog_wrapper";
import WorkCompClaimForm from "../forms/work_comp_claim_form";
import UnemployementClaimForm from "../forms/umemployment_claim_form";

interface ICreateWorkCompClaimDetails {
    type: string
    visible: boolean,
    title: string,
    successTitle: string,
    claim?: IWorkCompClaimData | IUnEmploymentClaimData,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onGotoAgencyDashboard: () => void,
    onGotoCreated: (id: string, name: string, type: string) => void,
    stateTalentData?: { talent_id: string; talent_ssn: string; employee_name: string; };
}

const CreateWorkCompClaim: React.FunctionComponent<ICreateWorkCompClaimDetails> = (props: ICreateWorkCompClaimDetails) => {
    const { claim, type, visible, onClose, onSuccessClose, onDissmiss, stateTalentData } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const talentListDropdownState = useAppSelector((state) => selectTalentsDropdownListState(state));
    const talentDropdownList = useAppSelector((state) => selectTalentsDropdownList(state));
    const saveResponseState = useAppSelector((state) => selectWorkCompClaimSaveResponse(state));
    const saveUN_ResponseState = useAppSelector((state) => selectUnEmploymentClaimSaveResponse(state));
    const companiesDropdown = useAppSelector((state) => selectCompaniesDropdownList(state));
    const assignmentsDropdownList = useAppSelector((state) => selectAssignmentDropdownList(state));
    const workCompClaimDocState = useAppSelector((state) => selectWorkCompClaimDocUploadState(state));
    const unEmploymentDocsState = useAppSelector((state) => selectUnEmploymentClaimDocUploadState(state));
    const [userDocument, setUserDocument] = useState<Array<File> | null>(null);

    useEffect(() => {
        if (saveResponseState.loading == LoadingType.succeeded) {
            if (userDocument && userDocument.length > 0) {
                saveDocument(saveResponseState.response?.object_id);

            }
            else {
                if (claim && claim.id) {
                    closePopup(onSuccessClose);
                }
            }
        }
        if (saveUN_ResponseState.loading == LoadingType.succeeded) {
            if (userDocument && userDocument.length > 0) {
                saveDocument(saveUN_ResponseState.response?.object_id);

            }
            else {
                if (claim && claim.id) {
                    closePopup(onSuccessClose);
                }
            }
        }
        return () => { }
    }, [saveResponseState, saveUN_ResponseState]);

    useEffect(() => {
        if (workCompClaimDocState.loading == LoadingType.succeeded) {
            if (userDocument) {
                userDocument.map((file: any) => {
                    const url = workCompClaimDocState.response[file.name].url;
                    S3API.uploadWithPreSignedURL(url, file);
                })
            }
            if (claim && claim.id) {
                closePopup(onSuccessClose);
            }
        }
        if (unEmploymentDocsState.loading == LoadingType.succeeded) {
            if (userDocument) {
                userDocument.map((file: any) => {
                    const url = unEmploymentDocsState.response[file.name].url;
                    S3API.uploadWithPreSignedURL(url, file);
                })
            }
            if (claim && claim.id) {
                closePopup(onSuccessClose);
            }
        }
        return () => { }
    }, [workCompClaimDocState.loading, unEmploymentDocsState.loading]);


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function saveDocument(id) {
        const docs: any = [];

        if (userDocument) {
            userDocument.map((h: any) => {
                docs.push(h.name);
            })
            if (type === "WORKCOMPCLAIM") {
                dispatch(getWorkCompClaimDocs({ id: id, docs: docs }));
            } else {
                dispatch(getUnEmploymentClaimDocs({ id: id, docs: docs }));
            }

        }
    }
    useEffect(() => {
        dispatch(getCompaniesDropdown());
    }, []);

    function isInitializing() {
        return talentListDropdownState.loading === LoadingType.pending;
    }

    function isLoading() {
        if (type === "WORKCOMPCLAIM") return saveResponseState.loading;

        return saveUN_ResponseState.loading;
    }

    function isError() {
        if (type === "WORKCOMPCLAIM") return saveResponseState.error;
        return saveUN_ResponseState.error;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={handleClose}>
                {isInitializing() ? <div className="loading-spinner"><SpinnerScreen /></div> : type === "WORKCOMPCLAIM" ? <WorkCompClaimForm
                    title={props.title}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    claim={props.claim as IWorkCompClaimData}
                    companiesList={companiesDropdown}
                    talentList={talentDropdownList}
                    assignmentsList={assignmentsDropdownList}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                    selectedDocument={userDocument}
                    onDocumentChange={handleFilesChange}
                    onRemoveDocument={handleFileRemove}
                    stateTalentData={stateTalentData}
                /> : <UnemployementClaimForm
                    title={props.title}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    claim={props.claim as IUnEmploymentClaimData}
                    companiesList={companiesDropdown}
                    talentList={talentDropdownList}
                    assignmentsList={assignmentsDropdownList}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                    selectedDocument={userDocument}
                    onDocumentChange={handleFilesChange}
                    onRemoveDocument={handleFileRemove}
                    stateTalentData={stateTalentData}
                />}
            </DialogWrapper>
        </ActionDialogHolder>
    );

    function handleFilesChange(value: Array<File>) {
        if (userDocument && userDocument?.length > 0) {
            setUserDocument([...userDocument, ...value]);
        } else {
            setUserDocument(value);
        }
    }

    function handleFileRemove(index: number) {
        if (userDocument && userDocument?.length > 1) {
            const newArr = [...userDocument].splice(index, 1);
            setUserDocument(newArr);
        } else {
            setUserDocument(null);
        }

    }

    function handleClearError() {
        if (type === "WORKCOMPCLAIM") {
            dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());

        }
        else {
            dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
        }
    }

    function handleClose() {
        if (saveResponseState.loading === LoadingType.succeeded || saveUN_ResponseState.loading === LoadingType.succeeded) {
            closePopup(onSuccessClose);
        } else {
            closePopup(onClose)
        }
    }

    /// handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateWorkCompClaim | ICreateUnEmploymentClaim }) {

        if (type === "WORKCOMPCLAIM") {
            if (claim) {
                dispatch(updateWorkCompClaimDetails({ ...props.claim, ...(doc.value as ICreateWorkCompClaim) }));
            }
            else {
                dispatch(addWorkCompClaimDetails(doc.value as ICreateWorkCompClaim));
            }
        }

        else {
            if (claim) {
                dispatch(updateUnEmploymentClaim({ ...props.claim, ...(doc.value as ICreateUnEmploymentClaim) }));
            }
            else {
                dispatch(addUnEmploymentClaim(doc.value as ICreateUnEmploymentClaim));
            }
        }
    }

    function handleGotoAgencyDashboard() {
        closePopup(props.onGotoAgencyDashboard);
    }


    function handleCreateNew() {
        if (type === "WORKCOMPCLAIM") {
            dispatch(workCompClaimsListActions.clearWorkCompClaimSaveState());

        }
        else {
            dispatch(unEmploymentClaimsListActions.clearUnEmploymentClaimSaveState());
        }
    }


    function handleGotoCreated(id: string, name: string) {
        if (props.claim && props.claim.id) {
            closePopup(onSuccessClose);
        } else if ((saveResponseState.response && saveResponseState.response.object_id) || (saveUN_ResponseState.response && saveUN_ResponseState.response.object_id)) {
            closePopup(() => props.onGotoCreated(id, name, type));
        }
    }


}

export default CreateWorkCompClaim;
