import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAPI } from "../../../../apis";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import { catchRequestError } from "../../../../utils";

export const getOnlineApplicationsReceivedList = createAsyncThunk<
    Array<OnlineApplicationReceived>,
    void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/online_applications_received',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getOnlineApplicationsReceived();
            if(typeof res.data === 'string') return [];
            return res.data as OnlineApplicationReceived[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);


export const getOnlineApplicationsReceivedCount = createAsyncThunk<
number,
void,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@dashboard/metrics/online_applications_received/count',
    async (_, thunkAPI) => {
        try {
            const res = await DashboardAPI.getOnlineApplicationsReceived(true);
            if(typeof res.data === 'string') return 0;
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);