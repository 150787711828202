import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '../../../../assets/icons/edit_button.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import CloseIcon from '../../../../assets/icons/close-btn2.svg';
import { LoadingType, THEME } from "../../../enums";
import { CustomButton, getPlusBtnIcon } from "../../../utils";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { AddCircleOutline } from "@material-ui/icons";
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import {
    createSuccessProfileQuestions, updateSuccessProfileQuestions, getSuccessProfileQuestions,
    deleteSuccessProfileQuestions, selectSuccessProfileQuestionListState, selectSuccessProfileQuestionList,
    createSuccessProfileQuestionStep, updateSuccessProfileQuestionStep, getSuccessProfileQuestionSteps,
    deleteSuccessProfileQuestionStep, selectSuccessProfileQuestionStepState, selectSuccessProfileQuestionStepList,
    createJobOrderPlacement, updateJobOrderPlacement, getJobOrderPlacements, deleteJobOrderPlacement,
    selectJobOrderPlacementState, selectJobOrderPlacementList,
    createJobOrderSource, updateJobOrderSource, getJobOrderSources, deleteJobOrderSource,
    selectJobOrderSourceState, selectJobOrderSourceList,
    createWorkCompCode, updateWorkCompCode, getWorkCompCodes, deleteWorkCompCode,
    selectWorkCompCodeState, selectWorkCompCodeList
} from "../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../redux/store";
import './system_wide_page.scss';
import { Portal } from "react-portal";
import {
    ActionDialogHolder,
    ActionDialogHolderType
} from "../../../components/action_dialog_holder/action_dialog_holder";
import '../../../custom.scss';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../components/table_empty/table_empty";
import './jobs_page.scss'
import { isValidInputTimeValue } from "@testing-library/user-event/dist/utils";
import { FormInput } from "../../../utils/form_input";
import CustomFormSelect from '../../../components/form_selector/form_select';


const tableHeader = {
    successProfileQuestionStep: [
        { title: 'id', code: 'id' },
        { title: 'step_number', code: 'step_number' },
        { title: 'step', code: 'value' },
        { title: 'actions', code: 'actions' },
    ],
    successProfileQuestion: [
        { title: 'id', code: 'id' },
        { title: 'question_number', code: 'step_number' },
        { title: 'question', code: 'value' },
        { title: 'actions', code: 'actions' },
    ],
    jobOrderPlacement: [
        { title: 'id', code: 'id' },
        { title: 'type', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
    jobOrderSource: [
        { title: 'id', code: 'id' },
        { title: 'source', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ],
    workCompCode: [
        { title: 'id', code: 'id' },
        { title: 'code', code: 'actions' },
        { title: 'description', code: 'actions' },
        { title: 'actions', code: 'actions' },
    ]
}

const categories = {
    1: {
        label: "success_profile_question_steps",
        value: ["step_number", "value"],
        buttonLabel: "add_success_profile_question_step",
        inputLabel: "success_profile_question_step_no",
        inputLabel2: "success_profile_question_step",
        deleteLabel: "delete_success_profile_question_step",
        deleteMessage: "delete_success_profile_question_step_message",
        updateLabel: "update_success_profile_question_step",
        methods: {
            create: createSuccessProfileQuestionStep,
            update: updateSuccessProfileQuestionStep,
            remove: deleteSuccessProfileQuestionStep,
            get: getSuccessProfileQuestionSteps
        },
        headers: tableHeader.successProfileQuestionStep
    },
    2: {
        label: "success_profile_questions",
        value: ["question_number", "success_profile_question"],
        buttonLabel: "add_success_profile_question",
        inputLabel: "success_profile_question_no",
        inputLabel2: "success_profile_question",
        deleteLabel: "delete_success_profile_question",
        deleteMessage: "delete_success_profile_question_message",
        updateLabel: "update_success_profile_question",
        methods: {
            create: createSuccessProfileQuestions,
            update: updateSuccessProfileQuestions,
            remove: deleteSuccessProfileQuestions,
            get: getSuccessProfileQuestions
        },
        headers: tableHeader.successProfileQuestion
    },
    3: {
        label: "job_order_placement_types",
        value: ["title", "description"],
        buttonLabel: "add_job_order_placement_type",
        inputLabel: "type",
        inputLabel2: "description",
        deleteLabel: "delete_job_order_placement_type",
        deleteMessage: "delete_job_order_placement_type_message",
        updateLabel: "update_job_order_placement_type",
        methods: {
            create: createJobOrderPlacement,
            update: updateJobOrderPlacement,
            remove: deleteJobOrderPlacement,
            get: getJobOrderPlacements
        },
        headers: tableHeader.jobOrderPlacement,
        deleteAlertMessage: "Job Order Placement Type Deleted Successfully!"
    },
    4: {
        label: "job_order_sources",
        value: ["job_order_source", "description"],
        buttonLabel: "add_job_order_source",
        inputLabel: "source",
        inputLabel2: "description",
        deleteLabel: "delete_job_order_source",
        deleteMessage: "delete_job_order_source_message",
        updateLabel: "update_job_order_source",
        methods: {
            create: createJobOrderSource,
            update: updateJobOrderSource,
            remove: deleteJobOrderSource,
            get: getJobOrderSources
        },
        headers: tableHeader.jobOrderSource,
        deleteAlertMessage: "Job Order Source Deleted Successfully!"
    },
    // 5: {
    //     label: "work_comp_codes",
    //     value: ["comp_code", "description"],
    //     buttonLabel: "add_work_comp_code",
    //     inputLabel: "code",
    //     inputLabel2: "description",
    //     deleteLabel: "delete_work_comp_code",
    //     deleteMessage: "delete_work_comp_code_message",
    //     updateLabel: "update_work_comp_code",
    //     methods: {
    //         create: createWorkCompCode,
    //         update: updateWorkCompCode,
    //         remove: deleteWorkCompCode,
    //         get: getWorkCompCodes
    //     },
    //     headers: tableHeader.workCompCode,
    //     deleteAlertMessage: "Work Comp Codes Deleted Successfully!"
    // }
}

const JobsPage = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [methodValue, setMethodValue] = useState({
        id: '', value: '', number: '', stepId: '', stepNo: '',
        sequenceNumber: 0, description: ''
    });
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [dropdownChanged, setDropDownChanged] = useState(true);
    const [stepNumber, setStepNumber] = useState('');
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const exceptionList = [3, 4, 5];

    const successProfileQuestionStepState = useAppSelector((state) => selectSuccessProfileQuestionStepState(state));
    const successProfileQuestionStepList = useAppSelector((state) => selectSuccessProfileQuestionStepList(state));
    const successProfileQuestionState = useAppSelector((state) => selectSuccessProfileQuestionListState(state));
    const successProfileQuestionList = useAppSelector((state) => selectSuccessProfileQuestionList(state));
    const jobOrderPlacementTypeState = useAppSelector((state) => selectJobOrderPlacementState(state));
    const jobOrderPlacementTypeList = useAppSelector((state) => selectJobOrderPlacementList(state));
    const jobOrderSourceState = useAppSelector((state) => selectJobOrderSourceState(state));
    const jobOrderSourceList = useAppSelector((state) => selectJobOrderSourceList(state));
    // const workCompCodeState = useAppSelector((state) => selectWorkCompCodeState(state));
    // const workCompCodeList = useAppSelector((state) => selectWorkCompCodeList(state));

    const reduxMapping = {
        1: { state: successProfileQuestionStepState, list: successProfileQuestionStepList },
        2: { state: successProfileQuestionState, list: successProfileQuestionList },
        3: { state: jobOrderPlacementTypeState, list: jobOrderPlacementTypeList },
        4: { state: jobOrderSourceState, list: jobOrderSourceList },
        // 5: { state: workCompCodeState, list: workCompCodeList },
    }

    
    useEffect(() => {
        if (
            jobOrderPlacementTypeState.remove?.loading === LoadingType.succeeded ||
            jobOrderSourceState.remove?.loading === LoadingType.succeeded 
            // || workCompCodeState.remove?.loading === LoadingType.succeeded
            ) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [
        jobOrderPlacementTypeState.remove?.loading,
        jobOrderSourceState.remove?.loading,
        // workCompCodeState.remove?.loading
    ])

    function getCategory(id = null) {
        if (id)
            return categories[id];
        return categories[selectedCategory];
    }

    function getMapping(id = null) {
        if (id)
            return reduxMapping[id];
        return reduxMapping[selectedCategory];
    }

    function handleCreateCloseDialog() {
        setShowCreateDialog(false);
    }

    function handleCloseDeleteDialog() {
        setShowDeleteDialog(false);
        setDeleteItemId(null);
    }

    function handleShowDeleteDialog(id) {
        setShowDeleteDialog(true);
        setDeleteItemId(id);
    }

    function handleDeleteApprove() {
        if (deleteItemId) {
            dispatch(getCategory().methods.remove(deleteItemId));
        }
        setSnackBarMessage(getCategory().deleteAlertMessage)
        handleCloseDeleteDialog();
    }

    function handleShowEditDialog(method) {
        setShowEditDialog(true);
        if (exceptionList.includes(selectedCategory)) {
            setMethodValue({
                ...methodValue, id: method.id, value: method[getCategory().value[0]],
                description: method[getCategory().value[1]], sequenceNumber: method['sequence_number']
            });
        }
        else if (selectedCategory === 2)
            setMethodValue({
                ...methodValue, id: method.id, value: method['success_profile_question'],
                stepId: stepNumber, stepNo: method['question_number']
            });
        else if (selectedCategory === 1)
            setMethodValue({ ...methodValue, id: method.id, value: method['value'], stepNo: method['step_number'] });
    }

    function handleCloseEditDialog() {
        setShowEditDialog(false);
    }

    function handleDropdownChange(value) {
        if (!value)
            value = '1';
        setDropDownChanged(true);
        setSelectedCategory(Number(value));
        dispatch(getCategory(value).methods.get());
    }

    function handleStepNumberChange(value) {
        if (!value) {
            if (successProfileQuestionStepList.length > 0)
                value = successProfileQuestionStepList[0].id;
        }
        setStepNumber(value);
    }

    function retryFetchingData() {
        setDropDownChanged(true);
        dispatch(getCategory().methods.get());
    }

    useEffect(() => {
        if (getMapping().state.loading == LoadingType.succeeded) {
            setDropDownChanged(false);
            if (selectedCategory == 1)
                setStepNumber(successProfileQuestionStepList.length > 0 ? successProfileQuestionStepList[0].id : '')
        }
    }, [getMapping().state.loading])

    useEffect(() => {
        if (getMapping().state.remove.loading == LoadingType.succeeded)
            dispatch(getCategory().methods.get());
    }, [getMapping().state.remove.loading])

    useEffect(() => {
        dispatch(getCategory().methods.get());
        return () => {

        }
    }, [])

    return (
        <div className="ac-sc-container">
            <div className="ac-sc-header">
                <div className="ac-sytm-cns-header">
                    <TabOptionsHeader onClose={() => props.history.goBack()} />
                </div>
            </div>
            <div className="ac-sc-dropdown">
                <div className="ac-sc-dropdown-button">
                    <div style={{ display: "flex", flexDirection: "row", width: "-webkit-fill-available" }}>
                        <CustomFormSelect
                            name={t("jobs_dropdown")}
                            list={Object.keys(categories).map(id => ({ value: id, label: categories[id].label }))}
                            onChange={(val) => handleDropdownChange(val)}
                            value={selectedCategory}
                            placeholder={t('success_profile_question_steps')}
                            required={false}
                            label={t('please_select_jobs_category')}
                            customStyle={{ marginTop: "0 !important", maxWidth: "20vw", marginRight: "10px !important" }}
                        />
                        {selectedCategory == 2 ?
                            <CustomFormSelect
                                name={t("jobs_dropdown")}
                                list={
                                    successProfileQuestionStepList.length != 0 ?
                                        successProfileQuestionStepList.map(id => ({ value: id.id, label: `${id.step_number} - ${id.value}` }))
                                        : []
                                }
                                onChange={(val) => handleStepNumberChange(val)}
                                value={stepNumber}
                                placeholder={
                                    successProfileQuestionStepList.length != 0 ? `${successProfileQuestionStepList[0].step_number} - ${successProfileQuestionStepList[0].value}` : 'No Steps Available'}
                                required={false}
                                label={t('step')}
                                customStyle={{ marginTop: "0 !important", maxWidth: "20vw" }}
                            /> :
                            <></>
                        }
                    </div>
                    {
                        selectedCategory === 1 || selectedCategory == 2 ? null :
                            <div>
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={selectedCategory != 2 || successProfileQuestionStepList.length != 0 ? true : false}
                                    leftIcon={getPlusBtnIcon()}
                                    name={t(categories[selectedCategory].buttonLabel)}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={() => (setShowCreateDialog(true))}
                                    customStyle={{ width: "max-content" }}
                                />
                            </div>
                    }
                </div>
            </div>
            <div className="ac-sc-table ac-sc-jobs-table">
                {getMapping().state.loading == LoadingType.pending && dropdownChanged ? <TableLoading /> :
                    <TableBody
                        state={getMapping().state}
                        onRetry={retryFetchingData}
                        data={selectedCategory == 2 && stepNumber ? getMapping().list.filter((d) => (d.step_id == stepNumber)) : getMapping().list}
                        category={getCategory()}
                        handleShowEdit={handleShowEditDialog}
                        handleShowDelete={handleShowDeleteDialog}
                        selectedCategory={selectedCategory}
                    />
                }
            </div>
            {/* only handled for success profile question and step, need to use separate components for other*/}
            {showCreateDialog && <Portal>
                {exceptionList.includes(selectedCategory) ? <CreateAction2
                    visible={showCreateDialog}
                    onClose={handleCreateCloseDialog}
                    onDismiss={handleCreateCloseDialog}
                    category={getCategory()}
                    category_id={selectedCategory}
                /> :
                    <CreateAction
                        visible={showCreateDialog}
                        onClose={handleCreateCloseDialog}
                        onDismiss={handleCreateCloseDialog}
                        category={getCategory()}
                        category_id={selectedCategory}
                        step_id={selectedCategory == 2 && stepNumber ? stepNumber : ''}
                    />}
            </Portal>}
            {showDeleteDialog && <Portal>
                <DeleteAction
                    visible={showDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onDismiss={handleCloseDeleteDialog}
                    onApprove={handleDeleteApprove}
                    category={getCategory()}
                />
            </Portal>}
            {showEditDialog && <Portal>
                {exceptionList.includes(selectedCategory) ? <EditAction2
                    visible={showEditDialog}
                    onClose={handleCloseEditDialog}
                    onDismiss={handleCloseEditDialog}
                    methodValue={methodValue}
                    category={getCategory()}
                    category_id={selectedCategory}
                /> :
                    <EditAction
                        visible={showEditDialog}
                        onClose={handleCloseEditDialog}
                        onDismiss={handleCloseEditDialog}
                        category={getCategory()}
                        category_id={selectedCategory}
                        previousData={methodValue}
                    />}
            </Portal>}
        </div>

    )
}

const TableBody = ({ state, onRetry, data, category, handleShowEdit, handleShowDelete, selectedCategory }) => {
    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state.error} onRefresh={onRetry} />)
    } else if (data.length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    } else {
        return (
            <SortableTable headerList={category.headers}>
                {data.map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData>{doc.id}</TableData>
                            {category.value.map((value, index) => {
                                return (
                                    <TableData key={doc.id + index} customStyle={{ wordWrap: "break-word" }}>
                                        <span>{doc[value]}</span>
                                    </TableData>
                                )
                            })}
                            <TableData>
                                {
                                    selectedCategory === 1 || selectedCategory == 2 ? null :
                                        <><img src={EditIcon} alt='edit_button' style={{ marginRight: '20px' }}
                                            onClick={() => handleShowEdit(doc)} /><img src={DeleteIcon} alt='delete_button'
                                                onClick={() => handleShowDelete(doc.id)} /></>
                                }
                            </TableData>
                        </tr>
                    );
                })}
            </SortableTable>
        )
    }
}

const CreateAction = ({ onClose, onDismiss, visible, category, category_id, step_id }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState({ stepNo: '', stepValue: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const successProfileQuestionStepState = useAppSelector((state) => selectSuccessProfileQuestionStepState(state));
    const successProfileQuestionStepList = useAppSelector((state) => selectSuccessProfileQuestionStepList(state));
    const successProfileQuestionState = useAppSelector((state) => selectSuccessProfileQuestionListState(state));
    const successProfileQuestionList = useAppSelector((state) => selectSuccessProfileQuestionList(state));

    const reduxMapping = {
        1: { state: successProfileQuestionStepState, list: successProfileQuestionStepList },
        2: { state: successProfileQuestionState, list: successProfileQuestionList }
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (state.create.loading === LoadingType.succeeded && sentRequest) {
            setMethodValue({ ...methodValue, stepValue: '', stepNo: '' })
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading])

    function handleInput(id, value) {
        if (id == 'stepNo') {
            if (Number(value) > -1)
                setMethodValue({ ...methodValue, stepNo: value });
            if (value && methodValue.stepValue.trim() && Number(value) > -1)
                setShowButton(true);
            else
                setShowButton(false);
        } else {
            setMethodValue({ ...methodValue, stepValue: value });
            if (value.trim() && methodValue.stepNo)
                setShowButton(true);
            else
                setShowButton(false);
        }
    }

    function handleSave() {
        setSentRequest(true);
        let data = {};
        if (category_id == 2)
            data = {
                ...data, 'step_id': step_id, 'question_number': Number(methodValue.stepNo),
                'value': methodValue.stepValue.trim()
            };
        else
            data = { ...data, 'step_number': Number(methodValue.stepNo), 'value': methodValue.stepValue.trim() };
        dispatch(category.methods.create(data));

    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="ac-sc-jobs-dialog-container">
                <div className="dialog-header">
                    <h5>{t(category.buttonLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="ac-sc-jobs-dialog-content">
                    <FormInput
                        id={"stepNo"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"number"}
                        value={methodValue.stepNo}
                        label={t(category.inputLabel)}
                        min={"0"}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"stepValue"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.stepValue}
                        label={t(category.inputLabel2)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.create.error && sentRequest ?
                        (<p style={{ color: 'red' }}
                            className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={showButton}
                        loading={getMapping().state.create.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const CreateAction2 = ({ onClose, onDismiss, visible, category, category_id }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [showButton, setShowButton] = useState(false);
    const [methodValue, setMethodValue] = useState({ value: '', description: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const jobOrderPlacementTypeState = useAppSelector((state) => selectJobOrderPlacementState(state));
    const jobOrderPlacementTypeList = useAppSelector((state) => selectJobOrderPlacementList(state));
    const jobOrderSourceState = useAppSelector((state) => selectJobOrderSourceState(state));
    const jobOrderSourceList = useAppSelector((state) => selectJobOrderSourceList(state));
    // const workCompCodeState = useAppSelector((state) => selectWorkCompCodeState(state));
    // const workCompCodeList = useAppSelector((state) => selectWorkCompCodeList(state));

    const reduxMapping = {
        3: { state: jobOrderPlacementTypeState, list: jobOrderPlacementTypeList },
        4: { state: jobOrderSourceState, list: jobOrderSourceList },
        // 5: { state: workCompCodeState, list: workCompCodeList },
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    useEffect(() => {
        let state = getMapping().state;
        if (sentRequest && state.create.loading === LoadingType.succeeded) {
            setMethodValue({ ...methodValue, value: '', description: '' });
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(state.create.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get());
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.create.loading])

    function handleInput(id, value) {
        if (id === 'input-value') {
            setMethodValue({ ...methodValue, value: value })
            if (value.trim() && methodValue.description.trim())
                setShowButton(true);
            else
                setShowButton(false);
        }
        else {
            setMethodValue({ ...methodValue, description: value })
            if (value.trim() && methodValue.value.trim())
                setShowButton(true);
            else
                setShowButton(false);
        }
    }

    function handleSave() {
        setSentRequest(true);
        dispatch(category.methods.create({
            'value': methodValue.value.trim(),
            'description': methodValue.description.trim()
        }));

    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container dialog-container-2">
                <div className="dialog-header">
                    <h5>{t(category.buttonLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.value}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"input-desc"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.description}
                        label={t(category.inputLabel2)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.create.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.create.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('save')}
                        enable={showButton}
                        loading={getMapping().state.create.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleSave}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

const DeleteAction = ({ onClose, onDismiss, visible, onApprove, category }) => {

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={400}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-delete-container" style={{ height: "150px" }}>
                <div className="dialog-header">
                    <h5 style={{ color: THEME.red }}>{t(category.deleteLabel)}</h5>
                </div>
                <div className="dialog-content">
                    <p>{t(category.deleteMessage)}</p>
                </div>
                <div className="dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('yes')}
                            enable={true}
                            backgroundColor={'#0079C1'}
                            onClick={() => closePopup(onApprove)}
                        />
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('no')}
                            enable={true}
                            backgroundColor={'#5A3F98'}
                            onClick={() => closePopup(onClose)}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

const EditAction = ({ onClose, onDismiss, visible, category, category_id, previousData }) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [methodValue, setMethodValue] = useState({ stepNo: previousData.stepNo, stepValue: previousData.value });
    const [showButton, setShowButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const successProfileQuestionStepState = useAppSelector((state) => selectSuccessProfileQuestionStepState(state));
    const successProfileQuestionStepList = useAppSelector((state) => selectSuccessProfileQuestionStepList(state));
    const successProfileQuestionState = useAppSelector((state) => selectSuccessProfileQuestionListState(state));
    const successProfileQuestionList = useAppSelector((state) => selectSuccessProfileQuestionList(state));

    const reduxMapping = {
        1: { state: successProfileQuestionStepState, list: successProfileQuestionStepList },
        2: { state: successProfileQuestionState, list: successProfileQuestionList }
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(id, value) {
        if (id == 'stepNo') {
            if (Number(value) > -1)
                setMethodValue({ ...methodValue, stepNo: value });
            if (value && methodValue.stepValue.trim() && Number(value) > -1)
                setShowButton(true);
            else
                setShowButton(false);
        } else {
            setMethodValue({ ...methodValue, stepValue: value });
            if (value.trim() && methodValue.stepNo)
                setShowButton(true);
            else
                setShowButton(false);
        }
    }

    function handleUpdate() {
        setSentRequest(true);
        let data = {};
        if (category_id == 2)
            data = {
                ...data, 'id': previousData.id, 'question_number': methodValue.stepNo,
                'value': methodValue.stepValue.trim(), step_id: previousData.stepId
            };
        else
            data = { ...data, 'id': previousData.id, 'step_number': methodValue.stepNo, 'value': methodValue.stepValue.trim() };
        dispatch(category.methods.update(data));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && sentRequest) {
            setMethodValue({ ...methodValue, stepValue: methodValue.stepValue.trim() });
            // methodValue.value = inputValue.trim();
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(getMapping().state.update.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="ac-sc-jobs-dialog-container">
                <div className="dialog-header">
                    <h5>{t(category.updateLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="ac-sc-jobs-dialog-content">
                    <FormInput
                        id={"stepNo"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"number"}
                        value={methodValue.stepNo}
                        label={t(category.inputLabel)}
                        min={"0"}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"stepValue"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={methodValue.stepValue}
                        label={t(category.inputLabel2)}
                        min={"0"}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.update.error && sentRequest ?
                        (<p style={{ color: 'red' }}
                            className="message">{getMapping().state.update.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('update')}
                        enable={showButton}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )
}

const EditAction2 = ({ onClose, onDismiss, visible, methodValue, category, category_id }) => {

    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [inputValue, setInputValue] = useState({ value: methodValue.value, description: methodValue.description });
    const [showButton, setShowButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [sentRequest, setSentRequest] = useState(false);

    const jobOrderPlacementTypeState = useAppSelector((state) => selectJobOrderPlacementState(state));
    const jobOrderPlacementTypeList = useAppSelector((state) => selectJobOrderPlacementList(state));
    const jobOrderSourceState = useAppSelector((state) => selectJobOrderSourceState(state));
    const jobOrderSourceList = useAppSelector((state) => selectJobOrderSourceList(state));
    // const workCompCodeState = useAppSelector((state) => selectWorkCompCodeState(state));
    // const workCompCodeList = useAppSelector((state) => selectWorkCompCodeList(state));

    const reduxMapping = {
        3: { state: jobOrderPlacementTypeState, list: jobOrderPlacementTypeList },
        4: { state: jobOrderSourceState, list: jobOrderSourceList },
        // 5: { state: workCompCodeState, list: workCompCodeList },
    }

    function getMapping() {
        return reduxMapping[category_id];
    }

    function handleInput(id, value) {
        if (id === 'input-value') {
            setInputValue({ ...inputValue, value: value });
            if (value.trim() === methodValue.value && methodValue.description === inputValue.description.trim())
                setShowButton(false);
            else {
                if (value.trim() && inputValue.description.trim())
                    setShowButton(true);
                else
                    setShowButton(false);
            }
        }
        else {
            setInputValue({ ...inputValue, description: value })
            if (value.trim() === methodValue.description && methodValue.value === inputValue.value.trim())
                setShowButton(false);
            else {
                if (value.trim() && inputValue.value.trim())
                    setShowButton(true);
                else
                    setShowButton(false);
            }
        }
    }

    function handleUpdate() {
        setSentRequest(true);
        dispatch(category.methods.update({
            'id': methodValue.id, 'value': inputValue.value.trim(),
            'description': inputValue.description.trim(), 'sequence_number': methodValue.sequenceNumber
        }));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    useEffect(() => {
        if (getMapping().state.update.loading == LoadingType.succeeded && sentRequest) {
            setInputValue({ ...inputValue, value: inputValue.value.trim(), description: inputValue.description.trim() });
            methodValue.value = inputValue.value.trim();
            methodValue.description = inputValue.description.trim();
            setShowButton(false);
            setSentRequest(false);
            setSuccessMessage(getMapping().state.update.response);
            setTimeout(() => (setSuccessMessage('')), 5000);
            dispatch(category.methods.get(category.url));
            setTimeout(() => (onClose()), 1000);
        }
    }, [getMapping().state.update.loading])

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={500}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="dialog-container dialog-container-2">
                <div className="dialog-header">
                    <h5>{t(category.updateLabel)}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} />
                </div>
                <div className="dialog-content">
                    <FormInput
                        id={"input-value"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputValue.value}
                        label={t(category.inputLabel)}
                        placeholder=""
                        required={true}
                    />
                    <FormInput
                        id={"input-desc"}
                        onChange={(id, val) => (handleInput(id, val))}
                        type={"text"}
                        value={inputValue.description}
                        label={t(category.inputLabel2)}
                        placeholder=""
                        required={true}
                    />
                    {getMapping().state.update.error && sentRequest ?
                        (<p style={{ color: 'red' }} className="message">{getMapping().state.update.error.response.detail.error}</p>) :
                        (<p style={{ color: 'green' }} className="message">{successMessage}</p>)}
                </div>
                <div className="dialog-footer">
                    <CustomButton
                        textStyle={{ textTransform: 'capitalize' }}
                        name={t('update')}
                        enable={showButton}
                        loading={getMapping().state.update.loading == LoadingType.pending}
                        backgroundColor={THEME.buttonColor1}
                        onClick={handleUpdate}
                    />
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default JobsPage;