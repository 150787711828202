import { useRef } from "react";
import { AppError } from "../../../interfaces";
import { LoadingType } from "../../../enums";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper from "../../../components/dialog_wrapper/dialog_wrapper";
import { SpinnerScreen } from "../../../utils";
import "./view_check.scss";


interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    state:
    {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string;
    },
    responseURL: string;
}
const EDViewCheckInvoicePopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        state,
        responseURL
    } = props;
    const actionRef = useRef<ActionDialogHolderType>(null);
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"60%"}
            onClose={onClose}
            onDissmiss={onClose}>
            <DialogWrapper onClose={onClose}>
                <div className="vc-container">
                    <div className="vc-content">
                        {state.loading !== LoadingType.succeeded ?
                            <SpinnerScreen />
                            :
                            (<iframe src={responseURL} title={title} ></iframe>)}
                    </div>
                </div>
            </DialogWrapper >
        </ActionDialogHolder>
    )
}

export default EDViewCheckInvoicePopup