import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IDeductionHistory } from "../../../../interfaces";
import { useAppDispatch } from "../../../../redux/store";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { getDateString } from "../../../../variables";
import '../paycheck_history/paycheck_history_section.scss'
import { SpinnerScreen, currencyConversion } from "../../../../utils";

interface Props {
    deductionHistoryList: IDeductionHistory[];
    history: RouteComponentProps["history"];
    loading: boolean;
}

const DeductionHistorySection: React.FunctionComponent<Props> = (props) => {
    const {
        deductionHistoryList,
        loading,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const tableheaderList = [
        { title: "deduction_name", code: "deduction_name" },
        { title: "check_date", code: "check_date" },
        { title: "check_number", code: "check_number" },
        { title: "deduction_amount", code: "deduction_amount" },
        { title: "", code: "" },
    ];

    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 1.5;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 2;
        if (value === 20) return 1;
        return 1;
    }

    const getFilteredList = () => {
        let list = deductionHistoryList;

        if (deductionHistoryList && sortedField != null) {
            list = [...deductionHistoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter((doc => {
            const str = search.trim().toLowerCase();
            const deductionNameFilter = doc.deduction_name ? doc.deduction_name.trim().toLowerCase().includes(str) : false;
            const checkNumberFilter = doc.check_number ? doc.check_number.trim().toLowerCase().includes(str) : false;
            const deductionAmountFilter = doc.deduction_amount ? doc.deduction_amount.toString().trim().toLowerCase().includes(str) : false;

            return deductionNameFilter || checkNumberFilter || deductionAmountFilter;

        }));
    }

    return (
        <div className="ed-pd-ph-container">
            <div className="ed-pd-ph-header">
                <TableHeaderOptions />
            </div>
            <div className="ed-pd-ph-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, index) => {
                                        return (
                                            <tr key={doc.id + index}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.deduction_name}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{getDateString(doc.check_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.check_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{currencyConversion(doc.deduction_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}><span></span></TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="ed-pd-ph-nra">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                            <div className="na_div">
                                                {t("no_deduction_history_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
        </div>
    );
}

interface TableHeaderOptionsProps {
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    return (
        <div className="ed-pd-ph-header-container">
            <div className="ed-pd-ph-header-title">
                <span>{t("deduction_history").toUpperCase()}</span>
            </div>
        </div>
    );
};

export default DeductionHistorySection;