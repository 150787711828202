import { useTranslation } from "react-i18next";
import SortableTable, { IHeaderListProps, TableData } from "../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler } from "../../../../components/table_empty/table_empty";
import { LoadingType, THEME } from "../../../../enums";
import { DownloadIcon, PaperPlaneIcon } from "../../../../icons";
import { SalesDocument } from "../../../../interfaces";
import { SalesDocumentState } from "../../../../redux/sales_marketing/marketing_docs/marketing_docs_list/marketing_docs_reducer";
import { selectProfileState, selectSalesDocumentSendByEmailState, sendDocumentByEmail, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton } from "../../../../utils";
import './marketing_docs.scss';
import MarketingDocsDeleteIcon from '../../../../../assets/icons/delete.svg'
import MarketingDocsEditIcon from '../../../../../assets/icons/edit_button.svg'
import { useState } from "react";
import { Portal } from "react-portal";
import SendByEmailPopup from "../../popups/send_by_email/send_by_email";
import { salesDocumentSendByEmailActions } from "../../../../redux/sales_marketing/marketing_docs/marketing_doc_send_by_email/send_by_email_reducer";
import { SendByEmail } from "../../../../interfaces/marketing_campaign";

interface TableBodyProps {
    state: SalesDocumentState;
    data: SalesDocument[];
    sortedField: string;
    sortDirection: string;
    onSortChange: (value: any) => void;
    getFilteredList: () => SalesDocument[];
    onRetry: () => void;
    headers: IHeaderListProps[];
    onDelete: (id: string) => void;
    onUpdate: (value: SalesDocument) => void;
}
const TableBody: React.FC<TableBodyProps> = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { state, data, headers, onRetry, onDelete, onUpdate } = props;

    const profileState = useAppSelector((state) => selectProfileState(state));
    const sendByemailState = useAppSelector((state) => selectSalesDocumentSendByEmailState(state))
    const [showSendByEmail, setShowSendByEmail] = useState<boolean>(false);
    const [showSendByEmailState, setShowSendByEmailState] = useState<string>();

    function getDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString().replaceAll('/', '.');
    }

    function sendByEmail(id: string) {
        setShowSendByEmail(true)
        setShowSendByEmailState(id)
    }

    function handleDownload(filePath) {
        let aTag = document.createElement('a')
        aTag.setAttribute('href', filePath);
        aTag.setAttribute('target', '_blank');
        // aTag.setAttribute('download', 'download');
        aTag.click();
    }

    if (state.loading == LoadingType.failed) {
        return (<TableErrorHandler error={state?.error || {}} onRefresh={onRetry} />)
    }
    if (!data || props.getFilteredList().length == 0) {
        return (<TableEmpty title={"No data found"} onClick={onRetry} />)
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 0.8;
        if (value === 1) return 0.3;
        if (value === 2) return 0.5;
        if (value === 3) return 0.5;
        if (value === 4) return 0.5;
        if (value === 5) return 0.5;
        if (value === 6) return 0.7;
        if (value === 7) return 0.1;
        if (value === 8) return 0.1;
        return 1;
    }

    return (
        <SortableTable headerList={headers} sortedField={props.sortedField} onSortChange={props.onSortChange} isAsc={props.sortDirection} flexNumber={getFlexNumber}
        >
            <div className="scrolling-table">
                {props.getFilteredList().map((doc, index) => {
                    return (
                        <tr key={"job-doc" + doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.file_name}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.file_extension}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{getDate(doc.date_uploaded)}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{doc.uploaded_by}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.notes}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={true}
                                    leftIcon={<div><DownloadIcon style={{height: "1vw", width: "1vw"}}/></div>}
                                    name={t("download")}
                                    // padding={12}
                                    // fontSize={13}
                                    backgroundColor={THEME.buttonColor1}
                                    onClick={() => (handleDownload(doc.file_path))}
                                />
                            </TableData>
                            <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                <CustomButton
                                    textStyle={{ textTransform: 'capitalize' }}
                                    enable={true}
                                    leftIcon={<div><PaperPlaneIcon style={{ color: THEME.white, height: "1vw", width: "1vw" }}/></div>}
                                    name={t("send_by_email")}
                                    // padding={12}
                                    // fontSize={13}
                                    backgroundColor={THEME.buttonBackgroundColor4}
                                    onClick={() => sendByEmail(doc.id)}
                                />
                            </TableData>
                            <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                <div className="icon-btn tdp-edit-icon">
                                    <button
                                        onClick={() => onDelete(doc.id)}
                                    >
                                        <div className="icon-btn tdp-edit-icon">
                                            <img src={MarketingDocsDeleteIcon}
                                                alt='delete-icon'
                                                width={"100%"}
                                                height={"100%"} />
                                        </div>
                                    </button>
                                </div>
                            </TableData>
                            <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                <div className="icon-btn tdp-edit-icon">
                                    <button
                                        onClick={() => onUpdate(doc)}
                                    >
                                        <img src={MarketingDocsEditIcon}
                                            alt='delete-icon'
                                            width={"100%"}
                                            height={"100%"} />
                                    </button>
                                </div>
                            </TableData>
                        </tr>
                    );
                })}
                {showSendByEmail && (
                    <Portal>
                        <SendByEmailPopup
                            visible={showSendByEmail}
                            DocumentId={showSendByEmailState}
                            onClose={() => setShowSendByEmail(false)}
                            onDissmiss={() => setShowSendByEmail(false)}
                            onSuccessClose={handleOnSendByEmailSuccess}
                            onSubmit={handleOnSubmit}
                            profileState={profileState}
                            sendByemailState={sendByemailState}
                            loading={sendByemailState.sendDocumentLinkToEmail.loading}
                            error={sendByemailState.sendDocumentLinkToEmail.error}
                        />
                    </Portal>
                )}
            </div>

        </SortableTable >
    )

    function handleOnSubmit(data: SendByEmail) {
        dispatch(sendDocumentByEmail(data));
    }

    function handleOnSendByEmailSuccess() {
        setShowSendByEmail(false);
        dispatch(salesDocumentSendByEmailActions.clearSendDocumentLinkToEmailState());
    }

}

export default TableBody;