import { createAsyncThunk } from "@reduxjs/toolkit";
import { BackOfficeAPI } from "../../../../../apis/back_office_api";
import { ITimeCards, AppError, ITimeCardsReport, IProfilePictureUploadUrlResponse, IBackOfficeUploadUrlResponse } from "../../../../../interfaces";
import { catchRequestError } from "../../../../../utils";
import { S3API } from "../../../../../apis/s3_api";

export const getTimeCardsReportListThunk = createAsyncThunk<
    ITimeCardsReport,
    string,
    {
        rejectValue: AppError | null;
        rejectedMeta: AppError | null;
    }
>("@back_office/timecards/timecards_report_list/get",
    async (payload, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimecardsReportData(payload);
            return res.data as ITimeCards;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    });

export const getTimeCardsReportDownloadUrl = createAsyncThunk<
    IBackOfficeUploadUrlResponse,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@back_office/timecards/timecards_report/download/get',
    async (weekId, thunkAPI) => {
        try {
            const res = await BackOfficeAPI.getTimeCardsReportDownloadUrl(weekId);
            return res.data;
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);