
import { ICreateUnEmploymentClaim, ICreateWorkCompClaim } from "../interfaces";
import { } from "../interfaces/talent";
import interceptor from "./interceptor";
import UrlBuilder from "./url_builder";

export const EmployeePortalAPI = {

    getAllEmployees(branch_id?: string) {
        if (branch_id) {
            return interceptor.get(
                `${UrlBuilder.allworkCompClaims}?branch_id=${branch_id}`
            );
        }
        return interceptor.get(UrlBuilder.allworkCompClaims);
    },

    getEmployeeById(work_comp_claim_id: string) {
        return interceptor.get(`${UrlBuilder.workCompClaims}?work_comp_claim_id=${work_comp_claim_id}`);
    },

    getEPPayrollDetails(talent_id?: string) {
        return interceptor.get(`${UrlBuilder.employeePortalPayrollDetails}?talent_id=${talent_id}`);
    },

    postEmployees(doc: ICreateWorkCompClaim) {
        return interceptor.post(UrlBuilder.workCompClaims, doc);
    },

    deleteEmployees(work_comp_claims_id: string) {
        return interceptor.delete(`${UrlBuilder.workCompClaims}?work_comp_claims_id=${work_comp_claims_id}`);
    },

    getVacationHistoryDetails(talent_id?: string) {
        return interceptor.get(`${UrlBuilder.talentVacationHistory}?talent_id=${talent_id}`);
    },
}