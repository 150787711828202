import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, MarketingCampaignJourneyStep } from "../../../../interfaces";
import * as thunk from "./marketing_campaign_journey_steps_thunk";

export interface MarketingCampaignJourneyStepState extends EntityState<MarketingCampaignJourneyStep> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const marketingCampaignJourneyStepAdapter: EntityAdapter<MarketingCampaignJourneyStep> = createEntityAdapter<MarketingCampaignJourneyStep>({
    selectId: (doc) => doc.id
})

const initialState: MarketingCampaignJourneyStepState = marketingCampaignJourneyStepAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const marketingCampaignJourneyStepSlice = createSlice({
    name: 'marketingCampaignJourneyStep',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getMarketingCampaignSteps.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getMarketingCampaignSteps.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getMarketingCampaignSteps.fulfilled, (state, action) => marketingCampaignJourneyStepAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createMarketingCampaignStep.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createMarketingCampaignStep.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createMarketingCampaignStep.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteMarketingCampaignStep.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteMarketingCampaignStep.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteMarketingCampaignStep.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateMarketingCampaignStep.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateMarketingCampaignStep.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateMarketingCampaignStep.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = marketingCampaignJourneyStepSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = marketingCampaignJourneyStepAdapter.getSelectors();
export const selectState = (state: MarketingCampaignJourneyStepState) => state;
export const selectLoading = (state: MarketingCampaignJourneyStepState) => state.loading;
export const selectError = (state: MarketingCampaignJourneyStepState) => state.error;


