import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, EngagementActivityType } from "../../../../interfaces";
import * as thunk from "./engagement_activity_type_list_thunk";

export interface EngagementActivityTypeListState extends EntityState<EngagementActivityType> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const engagementActivityTypeListAdapter: EntityAdapter<EngagementActivityType> = createEntityAdapter<EngagementActivityType>({
    selectId: (doc) => doc.id
})

const initialState: EngagementActivityTypeListState = engagementActivityTypeListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const engagementActivityTypeListSlice = createSlice({
    name: 'engagementActivityTypeList',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getEngagementActivityType.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getEngagementActivityType.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getEngagementActivityType.fulfilled, (state, action) => engagementActivityTypeListAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createEngagementActivityType.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createEngagementActivityType.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createEngagementActivityType.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteEngagementActivityType.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteEngagementActivityType.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteEngagementActivityType.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateEngagementActivityType.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateEngagementActivityType.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateEngagementActivityType.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = engagementActivityTypeListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = engagementActivityTypeListAdapter.getSelectors();
export const selectState = (state: EngagementActivityTypeListState) => state;
export const selectLoading = (state: EngagementActivityTypeListState) => state.loading;
export const selectError = (state: EngagementActivityTypeListState) => state.error;


