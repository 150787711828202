import "./job_order_document_page.scss";
import { useEffect, useState } from "react";
import { DocumentIcon, UploadIcon, PlayIcon } from "../../../../../icons";
import { CustomButton } from "../../../../../utils"
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../../enums";
import { TableLoading } from "../../../../../components/table_empty/table_empty";
import {
    getJobDocuments,
    selectSingleJobDocumentStateById,
    deleteJobDocumentUploadDocument,
} from "../../../../../redux/job_order/details/document";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { Portal } from "react-portal";
import { JobDocument } from "../../../../../interfaces";
import DeleteJobsDocumentPopup from "../../../popups/delete_document_popup/delete_document_popup";
import UploadDocument from "./upload_document/upload_document";
import UploadVideo from "./upload_video/upload_video";
import VideoData from "./video_data/video_data";
import TableBody from "./table/table";
import { jobsDocumentActions } from "../../../../../redux/job_order/details/document/document_list/document_list_reducer";

const headers = [
    { 'title': 'file_name', 'code': 'file_name' },
    { 'title': 'file_type', 'code': 'file_type' },
    { 'title': 'date_uploaded', 'code': 'date_uploaded' },
    { 'title': 'uploaded_by', 'code': 'uploaded_by' },
    { 'title': 'notes', 'code': 'notes' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
]


const JobOrderDocumentPage = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [sentRequest, setSentRequest] = useState(false);
    const [showUploadDocument, setShowUploadDocument] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [sortedField, setSortedField] = useState<string | null>(null);

    const [sortDirection, setSortDirection] = useState("asc");

    const [cursor, setCursor] = useState(0);
    const [videoData, setVideoData] = useState<any>([]);
    const [showUpdateDialog, setShowUpdateDialog] = useState<{ visible: boolean, data: JobDocument } | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, id: string } | null>(null);

    const jobDocumentState = useAppSelector((state) => selectSingleJobDocumentStateById(props.match.params.id)(state));

    useEffect(() => {
        callAPI();
        setSentRequest(true);
    }, [])

    useEffect(() => {
        if (jobDocumentState.loading === LoadingType.succeeded && jobDocumentState.data) {
            setVideoData([...jobDocumentState.data.filter(doc => doc.is_video)]);
            setSentRequest(false);
        }

    }, [jobDocumentState.loading])

      useEffect(() => {
        if (jobDocumentState?.documentDelete && jobDocumentState?.documentDelete?.loading === LoadingType.succeeded) {
            dispatch(getJobDocuments(props.match.params.id));
            setShowDeleteDialog({ visible: false, id: showDeleteDialog?.id! });
            dispatch(jobsDocumentActions.clearJobDocDeleteState());
        }

    }, [jobDocumentState?.documentDelete?.loading])


    function getFilteredList() {
        if (!jobDocumentState.data)
            return [];
        let data = jobDocumentState.data.filter(doc => !doc.is_video);
        if (sortedField === null)
            return data;
        return [...data].sort((a, b) => {
            const valueA =
                a[sortedField] != null && a[sortedField] != undefined
                    ? typeof a[sortedField] == typeof "1"
                        ? a[sortedField].trim().toLowerCase()
                        : a[sortedField]
                    : "";
            const valueB =
                b[sortedField] != null
                    ? typeof b[sortedField] == typeof "1"
                        ? b[sortedField].trim().toLowerCase()
                        : b[sortedField]
                    : "";
            if (sortDirection === "asc") {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });
    }

    function onSortChange(value) {
        if (sortedField && value == sortedField) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        }
        else {
            setSortedField(value);
            setSortDirection("asc");
        }
    }

    function cursorLeftClick() {
        if (cursor > 0)
            setCursor(cursor - 1);
    }

    function cursorRightClick() {
        if (((cursor * 2) + 2) < videoData.length)
            setCursor(cursor + 1);
    }

    function callAPI() {
        dispatch(getJobDocuments(props.match.params.id));
    }

    function handleOnDelete(id: string, job_order_id: string) {
        dispatch(deleteJobDocumentUploadDocument({job_order_id: job_order_id, file_object_id: id}));
        callAPI();
    }

    function handleClearError() {
        dispatch(jobsDocumentActions.clearJobDocDeleteState());
    }

    function openUpdateDialog(doc: JobDocument) {
        setShowUpdateDialog({ visible: true, data: doc });
    }

    function openDeleteDialog(id: string) {
        setShowDeleteDialog({ visible: true, id });
    }

    return (
        <div className="job-order-docs">
            <div className="job-order-documents">
                <div className="job-order-documents-header">
                    <div className="title">
                        <span><DocumentIcon style={{ color: THEME.primaryColor, height: "2vw", width: "2vw" }} /></span>
                       <span className='doc-title'>Documents</span>
                    </div>
                    <div className="upload-button">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={<div><UploadIcon style={{height: "1vw", width: "1vw"}}/></div>}
                            name={t("upload_document")}
                            // padding={10}
                            // fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => setShowUploadDocument(true)}
                        />
                    </div>
                </div>
                <div className="job-order-documents-content">
                    {jobDocumentState.loading == LoadingType.pending && sentRequest ? <TableLoading /> :
                        <TableBody
                            state={jobDocumentState}
                            onRetry={callAPI}
                            data={jobDocumentState.data}
                            getFilteredList={getFilteredList}
                            sortedField={sortedField}
                            onSortChange={onSortChange}
                            sortDirection={sortDirection}
                            onDelete={openDeleteDialog}
                            onUpdate={openUpdateDialog}
                            headers={headers}
                        />
                    }
                </div>
            </div>
            <div className="job-order-documents-videos">
                <div className="job-order-documents-videos-header">
                    <div className={"job-order-documents-videos-header-left"}>
                        <PlayIcon style={{ "color": THEME.primaryColor, height: "1.9vw", width: "1.9vw" }} />
                        <span className='vid-title'>{t('videos')}</span>
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={<div><UploadIcon style={{height: "1vw", width: "1vw"}}/></div>}
                            name={t("upload_video")}
                            // padding={12}
                            // fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowUploadVideo(true))}
                        />
                    </div>
                </div>
                <div className="job-order-documents-videos-content">
                    {jobDocumentState.loading === LoadingType.pending && sentRequest ? <TableLoading /> :
                        <VideoData
                            data={videoData}
                            cursorLeftClick={cursorLeftClick}
                            cursorRightClick={cursorRightClick}
                            cursor={cursor}
                            onRetry={callAPI}
                            state={jobDocumentState}
                        />}
                </div>
            </div>
            {showUploadDocument && <Portal>
                <UploadDocument
                    visible={showUploadDocument}
                    onClose={() => (setShowUploadDocument(false))}
                    onDismiss={() => (setShowUploadDocument(false))}
                    onApprove={callAPI}
                    jobOrderId={props.match.params.id}
                    state={jobDocumentState}
                />
            </Portal>}
            {showUploadVideo && <Portal>
                <UploadVideo
                    visible={showUploadVideo}
                    onClose={() => (setShowUploadVideo(false))}
                    onDismiss={() => (setShowUploadVideo(false))}
                    onApprove={callAPI}
                    state={jobDocumentState}
                    jobOrderId={props.match.params.id}
                />
            </Portal>}
            {showUpdateDialog && showUpdateDialog.visible && (<Portal>
                <UploadDocument
                    visible={showUpdateDialog?.visible}
                    onClose={() => setShowUpdateDialog(null)}
                    onDismiss={() => setShowUpdateDialog(null)}
                    onApprove={callAPI}
                    jobOrderId={props.match.params.id}
                    data={showUpdateDialog?.data}
                    state={jobDocumentState}
                />
            </Portal>)}
            {showDeleteDialog && showDeleteDialog.visible && (<Portal>
                <DeleteJobsDocumentPopup
                    visible={showDeleteDialog?.visible}
                    state={jobDocumentState}
                    onClose={()=>setShowDeleteDialog(null)}
                    handleClearError={handleClearError}
                    onConfirm={() => handleOnDelete(showDeleteDialog?.id, props.match.params.id)}
                    onDissmiss={() => setShowDeleteDialog(null)}
                    documentId={showDeleteDialog.id}
                />
            </Portal>)}
        </div>
    )
}

export default JobOrderDocumentPage;