import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import SortableTable from "../../../components/sortable_table/sortable_table";
import { LoadingType, THEME } from "../../../enums";
import { ExclaimOrangeIcon, TickGreenIcon } from "../../../icons";
import {
  getEmpODRSThunk,
  getTalentById,
  updateEmpODRSThunk,
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store";
import { SingleTalentState } from "../../../redux/talent/details/dashboard/talent_details/talent_details_reducer";

import "./e_doc_and_certifications.scss";
import EmployeeHandbookPopup from "../../popups/employee_handbook/employee_handbook";
import NoticeOfHealthcareExchangePopup from "../../popups/notice_of_healthcare_exchange/notice_of_healthcare_exchange";
import { IOnboardingVerifyTokenResponse } from "../../../interfaces";
import DirectDepositPopup from "../../popups/direct_deposit/direct_deposit";
import I9Popup from "../../popups/i9/i9";
import W4Popup from "../../popups/w4/w4";
import { selectSingleEmpODRSStateById } from "../../../redux/employee_dashboard/e_docs/e_docs_onboarding_selector";
import WOTCPopup from "../../../main/talent/docs/wotc/wotc";

interface Props {
  employeeState?: SingleTalentState;
  talentId: string;
  token: string | undefined;
  onboardingState: IOnboardingVerifyTokenResponse | null;
  currentLoginUserName: string;
  talentName: string;
}
interface ODRSOption {
  title: string;
  isReviewd: boolean;
  type: string;
  visible: boolean;
  enable: boolean;
}
const tableHeader = [
  { title: "year", code: "year" },
  { title: "name", code: "name" },
];


export const EDocAndCertifications: React.FunctionComponent<Props> = (
  props
) => {
  const { talentId, token, onboardingState, currentLoginUserName, talentName } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [addOption, setAddOption] = useState<{
    visible: boolean;
    type: string;
  } | null>(null);
  const [removeOption, setRemoveOption] = useState<{
    visible: boolean;
    type: string;
    value: { id: string; value: string };
  } | null>(null);

  function handleSortFieldChange(value: string) {
    if (sortedField && sortedField === value) {
      // setSortedField(null);
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(value);
      setSortDirection("asc");
    }
  }

  function getFlexNumber(value: number) {
    if (value === 0) return 1.5;
    if (value === 1) return 3;
    if (value === 2) return 2;
    if (value === 3) return 2;
    if (value === 4) return 1;
    if (value === 5) return 2.4;
    if (value === 6) return 1.5;
    if (value === 7) return 1;
    if (value === 8) return 1;
    if (value === 9) return 1;
    if (value === 10) return 3;
    if (value === 11) return 2;
    return 1;
  }

  useEffect(() => {
    if (talentId) {
      dispatch(getTalentById(talentId));
    }
  }, []);


  useEffect(() => {
    dispatch(getEmpODRSThunk({ talent_id: talentId }));
  }, []);
  const state = useAppSelector((state) =>
    selectSingleEmpODRSStateById(talentId)(state)
  );
  const [options, setOptions] = useState<{ [key: string]: ODRSOption }>({
    direct_deposit_document: {
      type: "direct_deposit_document",
      title: t("direct_deposit"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    handbook_document: {
      type: "handbook_document",
      title: t("employee_handbook"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    // essential_staffcare_document: { type: "essential_staffcare_document", title: 'essential_staff_care_docs', isReviewd: false, visible: false, enable: true },
    wotc_document: { type: 'wotc_document', visible: false, title: t('wotc'), enable: true, isReviewd: false,},
    notice_of_healthcare_exchange_document: {
      type: "notice_of_healthcare_exchange_document",
      title: t("notice_of_healthcare_exchange"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    w4_document: {
      type: "w4_document",
      title: t("w4"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
    i9_document: {
      type: "i9_document",
      title: t("i9"),
      isReviewd: false,
      visible: false,
      enable: true,
    },
  });

  useEffect(() => {
    if (state.data) {
      const {
        direct_deposit_document,
        handbook_document,
        // essential_staffcare_document,
        wotc_document,
        notice_of_healthcare_exchange_document,
        w4_document,
        i9_document,
      } = state.data;
      setOptions({
        ...options,
        direct_deposit_document: {
          ...options.direct_deposit_document,
          isReviewd: direct_deposit_document.recruiter_completed_review,
        },
        handbook_document: {
          ...options.handbook_document,
          isReviewd: handbook_document.recruiter_completed_review,
        },
        // essential_staffcare_document: { ...options.essential_staffcare_document, isReviewd: essential_staffcare_document.recruiter_completed_review },
        wotc_document: {
          ...options.wotc_document,
          isReviewd: handbook_document.recruiter_completed_review,
        },
        notice_of_healthcare_exchange_document: {
          ...options.notice_of_healthcare_exchange_document,
          isReviewd:
            notice_of_healthcare_exchange_document.recruiter_completed_review,
        },
        w4_document: {
          ...options.w4_document,
          isReviewd: w4_document.recruiter_completed_review,
        },
        i9_document: {
          ...options.i9_document,
          isReviewd: i9_document.recruiter_completed_review,
        },
      });
    }
    if (state && state.update.loading === LoadingType.succeeded) {
      dispatch(getEmpODRSThunk({ talent_id: talentId }));
    }
  }, [state, state.data, state.update.loading]);
  function DocumentStatusCard(props: DSCProps) {
    const { isReviewed, name, onClick } = props;
    return (
      <button className="doc-card-container" onClick={onClick}>
        <div className="stat-icon">
          {isReviewed ? (
            <TickGreenIcon width={"100%"} height={"100%"} />
          ) : (
            <ExclaimOrangeIcon width={"100%"} height={"100%"} />
          )}
        </div>
        <div className="doc-name">
          <span>{name}</span>
        </div>
        <div
          className="stat-banner"
          style={{
            backgroundColor: isReviewed ? THEME.statusActiveColor : "#FF7600",
          }}
        >
          <span>{isReviewed ? t("reviewed") : t("not_reviewed")}</span>
        </div>
      </button>
    );
  }
  function handleCancelClose(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: false } });
  }

  function handleOpenOption(type: string) {
    setOptions({ ...options, [type]: { ...options[type], visible: true } });
  }
  function handleSuccessClose(type: string, value?: any) {
    if (state && state?.data !== null) {
      dispatch(
        updateEmpODRSThunk({
          ...state?.data,
          [type]: {
            ...state?.data[type],
            recruiter_completed_review: false,
          },
          talent_id: talentId,
        })
      );
      setOptions({ ...options, [type]: { ...options[type], visible: false } });
    }
  }
  return (
    <div className="edc-db-container">
      <div className="edc-db-tbc">
        <div className="title">{t("e-documents").toUpperCase()}</div>
      </div>

      {state &&
        state.loading === LoadingType.succeeded &&
        state.error === null && (
          <div className="obd-status-options">
            <div className="option-column">
              {Object.keys(options)
                .slice(0, 3)
                .map((key, index) => {
                  if (key === "wotc_document" && state.data?.wotc_document?.talent_completed_review === false) {
                    return null;
                  }
                  return (
                    <DocumentStatusCard
                      isReviewed={options[key].isReviewd}
                      name={t(options[key].title)}
                      onClick={() => handleOpenOption(options[key].type)}
                    />
                  );
                })}
            </div>
            <div className="option-column">
              {Object.keys(options)
                .slice(3, 5)
                .map((key, index) => {
                  if (key === "i9_document" && options[key].isReviewd) {
                    return null;
                  }
                  return (
                    <DocumentStatusCard
                      isReviewed={options[key].isReviewd}
                      name={t(options[key].title)}
                      onClick={() => handleOpenOption(options[key].type)}
                    />
                  );
                })}
            </div>
          </div>
        )}

      <div className="ep-ud-db-edc-section">
        <div className="title">{t("w2").toUpperCase()}</div>
      </div>
      <div>
        <SortableTable
          headerList={tableHeader}
          sortedField={sortedField}
          onSortChange={handleSortFieldChange}
          flexNumber={getFlexNumber}
          children={""}
          isAsc={sortDirection}
        />
      </div>
      {options.handbook_document.visible && (
        <Portal>
          <EmployeeHandbookPopup
            visible={options.handbook_document.visible}
            type={options.handbook_document.type}
            token={token}
            state={state.data}
            termsAndConditions={
              state?.data?.terms_and_conditions?.terms_and_conditions
            }
            title={options.handbook_document.title}
            onClose={() => handleCancelClose(options.handbook_document.type)}
            successTitle={""}
            onSuccessClose={() => { }}
            isReviewd={options.handbook_document.isReviewd}
          />
        </Portal>
      )}
      {options.notice_of_healthcare_exchange_document.visible && (
        <Portal>
          <NoticeOfHealthcareExchangePopup
            visible={options.notice_of_healthcare_exchange_document.visible}
            type={options.notice_of_healthcare_exchange_document.type}
            token={token}
            state={state?.data}
            recruiter={onboardingState?.team_member_detail}
            agency={onboardingState?.agency_onboarding_data}
            title={options.notice_of_healthcare_exchange_document.title}
            onClose={() =>
              handleCancelClose(
                options.notice_of_healthcare_exchange_document.type
              )
            }
            successTitle={""}
            onSuccessClose={() => { }}
          />
        </Portal>
      )}
      {options.direct_deposit_document.visible && (
        <Portal>
          <DirectDepositPopup
            visible={options.direct_deposit_document.visible}
            type={options.direct_deposit_document.type}
            token={token}
            state={state?.data}
            title={options.direct_deposit_document.title}
            onClose={() =>
              handleCancelClose(options.direct_deposit_document.type)
            }
            successTitle={""}
            onSuccessClose={handleSuccessClose}
            lastStep={0}
            currentLoginUserName={currentLoginUserName}
            talentName={talentName}
          />
        </Portal>
      )}
      {options.w4_document.visible && (
        <Portal>
          <W4Popup
            visible={options.w4_document.visible}
            type={options.w4_document.type}
            token={token}
            state={state?.data}
            recruiter={onboardingState?.team_member_detail}
            agency={onboardingState?.agency_onboarding_data}
            title={options.w4_document.title}
            onClose={() => handleCancelClose(options.w4_document.type)}
            successTitle={""}
            onSuccessClose={handleSuccessClose}
          />
        </Portal>
      )}
      {options.i9_document.visible && (
        <Portal>
          <I9Popup
            visible={options.i9_document.visible}
            type={options.i9_document.type}
            token={token}
            state={state?.data}
            recruiter={onboardingState?.team_member_detail}
            agency={onboardingState?.agency_onboarding_data}
            title={options.i9_document.title}
            onClose={() => handleCancelClose(options.i9_document.type)}
            successTitle={""}
            onSuccessClose={() => { }}
          />
        </Portal>
      )}
       {options.wotc_document.visible && state.data && <Portal>
        <WOTCPopup
          visible={options.wotc_document.visible}
          type={options.wotc_document.type}
          status={state.data?.wotc_document.status}
          cti_confirmation_number={state.data?.wotc_document.cti_confirmation_number!}
          title={options.wotc_document.title}
          onClose={() => handleCancelClose(options.wotc_document.type)}
          successTitle={''}
          onSuccessClose={()=>{}}
        />
      </Portal>}
    </div>
  );
};
interface DSCProps {
  isReviewed: boolean;
  name: string;
  onClick: () => void;
}
