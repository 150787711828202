import { RouteComponentProps } from 'react-router-dom';
import './marketing_docs.scss';
import { useEffect, useState, useRef } from "react";
import { DocumentIcon, UploadIcon, PlayIcon } from "../../../../icons";
import { CustomButton } from "../../../../utils"
import { useTranslation } from "react-i18next";
import { LoadingType, THEME } from "../../../../enums";
import { TableLoading } from "../../../../components/table_empty/table_empty";
import { getSalesDocuments, selectSalesDocumentState, deleteUploadDocument } from "../../../../redux/sales_marketing/marketing_docs";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { Portal } from "react-portal";
import { SalesDocument } from '../../../../interfaces';
import { salesDocumentActions } from '../../../../redux/sales_marketing/marketing_docs/marketing_docs_list/marketing_docs_reducer';
import TableBody from './table';
import UploadDocument from './upload_document';
import UploadVideo from './upload_video';
import VideoData from './video_data';
import DeleteDocumentPopup from './delete_documentpopup';

const headers = [
    { 'title': 'file_name', 'code': 'file_name' },
    { 'title': 'file_type', 'code': 'file_extension' },
    { 'title': 'date_uploaded', 'code': 'date_uploaded' },
    { 'title': 'uploaded_by', 'code': 'uploaded_by' },
    { 'title': 'notes', 'code': 'notes' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
    { 'title': '', 'code': '' },
]

interface Props extends RouteComponentProps<any> { }

const MarketingDocs: React.FunctionComponent<Props> = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [sentRequest, setSentRequest] = useState(false);
    const [showUploadDocument, setShowUploadDocument] = useState<boolean>(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [cursor, setCursor] = useState(0);
    const [videoData, setVideoData] = useState<any>([]);

    const salesDocumentState = useAppSelector((state) => selectSalesDocumentState(state));

    const [showUpdateDialog, setShowUpdateDialog] = useState<{ visible: boolean, data: SalesDocument } | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<{ visible: boolean, id: string } | null>(null);

    function getFilteredList() {
        if (!salesDocumentState.data)
            return [];
        let data = salesDocumentState.data.filter(doc => !doc.is_video);
        if (sortedField === null)
            return data;
        if (sortedField != null) {
            data = [...data].sort((a, b) => {
                const valueA =
                    a[sortedField] != null
                        ? typeof a[sortedField] != typeof 1
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] != typeof 1
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return data
    }

    function onSortChange(value) {
        if (sortedField && value == sortedField)
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        else {
            setSortedField(value);

            setSortDirection("asc");

        }
    }

    function cursorLeftClick(e) {
        if (cursor > 0)
            setCursor(cursor - 1);
    }

    function cursorRightClick(e) {
        if (((cursor * 2) + 2) < videoData.length)
            setCursor(cursor + 1);
    }

    function callAPI() {
        dispatch(getSalesDocuments(props.match.params.id));
    }

    useEffect(() => {
        if (salesDocumentState.loading === LoadingType.succeeded && salesDocumentState.data) {
            setVideoData([...salesDocumentState.data.filter(doc => doc.is_video)]);
            setSentRequest(false);
        }

    }, [salesDocumentState.loading])

    useEffect(() => {
        if (salesDocumentState.documentDelete && salesDocumentState.documentDelete.loading === LoadingType.succeeded) {
            dispatch(getSalesDocuments(props.match.params.id));
            setShowDeleteDialog({ visible: false, id: showDeleteDialog?.id! });
            dispatch(salesDocumentActions.clearDeleteState());
        }

    }, [salesDocumentState.documentDelete.loading])

    function openUpdateDialog(doc: SalesDocument) {
        setShowUpdateDialog({ visible: true, data: doc });
    }

    function openDeleteDialog(id: string) {
        setShowDeleteDialog({ visible: true, id });
    }

    function handleOnDelete(id: string) {
        dispatch(deleteUploadDocument(id));
        callAPI();
    }

    function handleDeleteSuccessClose() {
        setShowDeleteDialog(null);
    }

    const onClose = () => {
        setShowDeleteDialog(null);
    }

    function handleClearError() {
        dispatch(salesDocumentActions.clearDeleteState());
        dispatch(salesDocumentActions.clearUpdateState());
    }

    useEffect(() => {
        callAPI();
        setSentRequest(true);
    }, []);

    return (
        <div className="job-order-docs">
            <div className="job-order-documents">
                <div className="job-order-documents-header">
                    <div className="title">
                        <span><DocumentIcon style={{ color: THEME.primaryColor, height: "2vw", width: "2vw" }} /></span>
                        <span className='doc-title'>Documents</span>
                    </div>
                    <div className="upload-button">
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={<div><UploadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                            name={t("upload_document")}
                            // padding={10}
                            // fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => setShowUploadDocument(true)}
                        />
                    </div>
                </div>
                <div className="job-order-documents-content">
                    {salesDocumentState.loading == LoadingType.pending && sentRequest ? <TableLoading /> :
                        <TableBody
                            state={salesDocumentState}
                            onRetry={callAPI}
                            data={salesDocumentState?.data || []}
                            getFilteredList={getFilteredList}
                            sortedField={sortedField || ""}
                            onSortChange={onSortChange}
                            sortDirection={sortDirection}
                            onDelete={openDeleteDialog}
                            onUpdate={openUpdateDialog}
                            headers={headers}
                        />
                    }
                </div>
            </div>
            <div className="job-order-documents-videos">
                <div className="job-order-documents-videos-header">
                    <div className={"job-order-documents-videos-header-left"}>
                        <PlayIcon style={{ "color": THEME.primaryColor, height: "1.9vw", width: "1.9vw" }} />
                        <span className='vid-title'>{t('videos')}</span>
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={true}
                            leftIcon={<div><UploadIcon style={{height: "1vw", width: "1vw"}}/></div>}
                            name={t("upload_video")}
                            // padding={12}
                            // fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={() => (setShowUploadVideo(true))}
                        />
                    </div>
                </div>
                <div className="job-order-documents-videos-content">
                    {salesDocumentState.loading === LoadingType.pending && sentRequest ? <TableLoading /> :
                        <VideoData
                            data={videoData}
                            cursorLeftClick={cursorLeftClick}
                            cursorRightClick={cursorRightClick}
                            cursor={cursor}
                            onRetry={callAPI}
                            state={salesDocumentState}
                        />}
                </div>
            </div>
            {showUpdateDialog && showUpdateDialog.visible && (<Portal>
                <UploadDocument
                    visible={showUpdateDialog?.visible}
                    onClose={() => setShowUpdateDialog(null)}
                    onDismiss={() => setShowUpdateDialog(null)}
                    onApprove={callAPI}
                    data={showUpdateDialog?.data}
                    salesDocumentState={salesDocumentState}
                />
            </Portal>)}

            {showDeleteDialog && showDeleteDialog.visible && (<Portal>
                <DeleteDocumentPopup
                    visible={showDeleteDialog?.visible}
                    state={salesDocumentState}
                    onClose={onClose}
                    handleClearError={handleClearError}
                    onConfirm={() => handleOnDelete(showDeleteDialog?.id)}
                    onDissmiss={() => handleDeleteSuccessClose}
                    documentId={showDeleteDialog.id}
                />
            </Portal>)}


            {showUploadDocument && <Portal>
                <UploadDocument
                    visible={showUploadDocument}
                    onClose={() => (setShowUploadDocument(false))}
                    onDismiss={() => (setShowUploadDocument(false))}
                    salesDocumentState={salesDocumentState}
                    onApprove={callAPI}
                />
            </Portal>}
            {showUploadVideo && <Portal>
                <UploadVideo
                    visible={showUploadVideo}
                    onClose={() => (setShowUploadVideo(false))}
                    onDismiss={() => (setShowUploadVideo(false))}
                    onApprove={callAPI}
                    state={salesDocumentState}
                    jobOrderId={props.match.params.id}
                />
            </Portal>}
        </div>
    )
}
export default MarketingDocs;