import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../../../components/action_dialog_holder/action_dialog_holder";
import CustomStepIndicator from "../../../../components/custom_step_indicator/custom_step_indicator";
import DialogWrapper, {
  FormDialogTilteHeader,
} from "../../../../components/dialog_wrapper/dialog_wrapper";
import { LoadingType, THEME } from "../../../../enums";
import { BOOpenCloseIcon } from "../../../../icons";
import {
  ITimeCardsData,
  IWeekEndingDatesDropdown,
  PayrollBatch,
} from "../../../../interfaces";
import {
  selectBankAccountList,
  selectBankAccountListState,
  selectRunTypeList,
  selectRunTypeListState,
} from "../../../../redux/admin_center/back_office";
import { selectManagePayrollBatchState } from "../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_selector";
import {
  selectProfileState,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store";
import { CustomButton, FormInput, SpinnerScreen } from "../../../../utils";
import "./create_payroll_batch_popup.scss";
import { CreatePayrollStepOne } from "./create_payroll_step_one";
import { convertDateToTimeStamp } from "../../../../variables";
import { getPayrollTimecardsThunk } from "../../../../redux/back_office/payroll/payroll_timecards/payroll_timecards_thunk";
import {
  selectPayrollTimecardsList,
  selectPayrollTimecardsState,
} from "../../../../redux/back_office/payroll/payroll_timecards/payroll_timecards_selector";
import { CreatePayrollStepTwo } from "./create_payroll_step_two";
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { createPayrollBatchThunk, deletePayrollBatchThunk } from "../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_thunk";
import { PayrollManageBatchSliceActions } from "../../../../redux/back_office/payroll/manage_payroll/payroll_manage_batch_reducer";
import ApiError from "../../../../components/api_error";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import CNPBPayCheckDirectoryPage from "../../pages/payroll/cnpb_paycheck_directory/cnpb_paycheck_directory";

interface Props {
  onClose: () => void;
  onSuccessClose: () => void;
  weekEndingDate: string;
  onSave: (payroll_batch_id: string) => void;
  weekEndingDateList: IWeekEndingDatesDropdown[];
}

export const CreatePayrollBatchPopup = (props: Props) => {
  const { onClose, onSave, weekEndingDate, weekEndingDateList, onSuccessClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const actionRef = useRef<ActionDialogHolderType>(null);
  const runTypeList = useAppSelector((state) => selectRunTypeList(state));
  const bankAccountList = useAppSelector((state) =>
    selectBankAccountList(state)
  );

  const createState = useAppSelector((state) =>
    selectManagePayrollBatchState(state)
  );
  const profileState = useAppSelector((state) => selectProfileState(state));
  const runTypeListState = useAppSelector((state) =>
    selectRunTypeListState(state)
  );
  const bankAccountListState = useAppSelector((state) =>
    selectBankAccountListState(state)
  );
  // const timeCardsState = useAppSelector((state) =>
  //   selectPayrollTimecardsState(state)
  // );
  const timeCardList = useAppSelector((state) =>
    selectPayrollTimecardsList(state)
  );

  const [batch, setBatch] = useState<PayrollBatch>({
    ...new PayrollBatch(),
    check_date: convertDateToTimeStamp(new Date()),
    week_accounting_id: weekEndingDate,
  });

  const [currentStep, setCurrentStep] = useState<{
    label: string;
    value: number;
  }>({ label: t("step_option", { value: 1 }), value: 1 });

  const [payChecks, setPayChecks] = useState<boolean>(false)
  const [proceed, setProceed] = useState<boolean>(false)

  useEffect(() => {
    if (batch.week_accounting_id) {
      dispatch(getPayrollTimecardsThunk(batch.week_accounting_id));
    }
  }, [batch.week_accounting_id]);

  const stepsList: Array<{ label: string; value: number }> = Array.from(
    // Array(7).keys()
    Array(payChecks ? (proceed ? 9 : 8) : (proceed ? 8 : 7)).keys()
  ).map((_doc, index) => ({
    label: t("step_option", { value: index + 1 }),
    value: index + 1,
  }));

  const getSubtitle = () => {
    switch (currentStep.value) {
      case 1:
        return `Hey ${profileState?.profile?.first_name}, Let's process your Payroll. Verify and complete the information below and click Next.`;
      case 2:
        return `Hey ${profileState?.profile?.first_name}, select how would you like your checks grouped. Then select the checks you would like to process and click Next.`;
      case 5:
        return `Hey ${profileState?.profile?.first_name}, you are almost finished. Verify and complete the information below and click Next.`;
      default:
        return "";
    }
  };

  const getTitle = () => {
    switch (currentStep.value) {
      case 1:
        return "Create New Payroll Batch";
      case 2:
        return "Select Payroll Transaction";
      case 3:
      case 4:
        return "Process Payroll Transactions Paychecks";
      default:
        return "Post Paychecks";
    }
  };

  useEffect(() => {
    if (createState.loading === LoadingType.succeeded) {
      setCurrentStep(stepsList[2]);
      if (createState.response) {
        setBatch(createState.response);
      }
    }
  }, [createState.loading]);
  const handleNextStep = () => {
    if (!createState?.error && currentStep.value === 2) {
      dispatch(createPayrollBatchThunk({ ignore_elg_change_file: false, batch: batch }));
      return;
    }
    if (createState.error?.message === "ESC ELGChange file is not uploaded for this week"
      && createState.error.code === 400
      && currentStep.value === 2) {
      dispatch(createPayrollBatchThunk({ ignore_elg_change_file: true, batch: batch }));
      return;
    }
    if (!payChecks && currentStep.value === 6) {
      onSave(batch.id);
      if (createState.post.loading === LoadingType.succeeded) {
        setCurrentStep(stepsList[currentStep.value]);
        return;

      }
    }
    if (!payChecks && proceed && currentStep.value === 7) {
      dispatch(deletePayrollBatchThunk(batch.id))
    }
    if (payChecks && currentStep.value === 7) {
      onSave(batch.id);
      if (createState.post.loading === LoadingType.succeeded) {
        setCurrentStep(stepsList[currentStep.value]);
        return;

      }
    }
    if (currentStep.value === 7) {
      if (payChecks) {
        setCurrentStep(stepsList[currentStep.value]);
      } else if (proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      } else {
        onSuccessClose();
      }
    } else {
      setCurrentStep(stepsList[currentStep.value]);
    }
    if (payChecks && proceed && currentStep.value === 8) {
      dispatch(deletePayrollBatchThunk(batch.id))
    }
    if (currentStep.value === 8) {
      if (payChecks && !proceed) {
        onSuccessClose();
      } else if (!payChecks && proceed) {
        onSuccessClose();
      } else if (payChecks && proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      } else if (!payChecks && !proceed) {
        setCurrentStep(stepsList[currentStep.value]);
      }
    }
    if (payChecks && proceed && currentStep.value === 9) {
      onSuccessClose();
    }
  };

  const handleBack = () => {
    if (currentStep.value === 1) {
      onClose();
    }
    if (!payChecks && proceed && currentStep.value === 7 || payChecks && proceed && currentStep.value === 8) {
      setCurrentStep(stepsList[currentStep.value]);
      setProceed(false)
    }
    if (!payChecks && currentStep.value === 6 || payChecks && currentStep.value === 7) {
      setCurrentStep(stepsList[currentStep.value]);
      setProceed(true)
    }
    else {
      setCurrentStep(stepsList[currentStep.value - 2]);
    }
  };

  const handleToggleChange = (val) => {
    setPayChecks(val)
  }

  const updateBatchProp = (key: string, value: any) => {
    const newBatch = { ...batch };
    newBatch[key] = value;
    setBatch(newBatch);
  };

  const handleTimeCardSelection = (id?: string) => {
    let timeCardSet = new Set(batch.time_cards);
    if (id) {
      if (timeCardSet.has(id)) {
        timeCardSet.delete(id);
      } else {
        timeCardSet.add(id);
      }
    } else {
      if (timeCardSet.size === timeCardList?.length) {
        timeCardSet = new Set();
      } else {
        timeCardSet = new Set(timeCardList?.map((x: any) => x.time_card_id));
      }
    }
    updateBatchProp("time_cards", Array.from(timeCardSet));
  };
  const getCurrentStepView = (): ReactNode => {
    if (
      runTypeListState.loading === LoadingType.pending ||
      bankAccountListState.loading === LoadingType.pending
    ) {
      return <SpinnerScreen></SpinnerScreen>;
    }

    switch (currentStep.value) {
      case 1:
        return (
          <CreatePayrollStepOne
            batch={batch}
            bankAccountList={bankAccountList}
            runTypeList={runTypeList}
            weekEndingDatesList={weekEndingDateList}
            selectedWeekEndingDate={weekEndingDate}
            updateBatchProp={updateBatchProp}
          />
        );
      case 2:
        return (
          <CreatePayrollStepTwo
            payrollTimecards={timeCardList ?? []}
            handleSelection={handleTimeCardSelection}
            selectedCards={new Set(batch.time_cards)}
          // createState={createState}
          // handleClearError={() => dispatch(PayrollManageBatchSliceActions.clearErrorState())}
          />
        );
      case 3:
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>{`Well done ${profileState.profile?.first_name}`}</span>
            <span>{`There are no errors or warnings.`}</span>
            <span>{`Click Next to process your checks.`}</span>
          </DocSavedText>
        );
      case 4:
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>{`Payroll Processing is complete.`}</span>
            <span>{`Total checks Processed: ${batch.check_count}`}</span>
            <span>{`Click Next to process your checks.`}</span>
          </DocSavedText>
        );
      case 5: {
        return (
          <div className="pptp-content">
            <div style={{ width: "50%" }}>
              <FormInput
                id={"starting_check_number"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.starting_check_number}
                label={t("starting_check_number")}
              ></FormInput>
              <FormInput
                id={"ending_check_number"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.ending_check_number}
                label={t("ending_check_number")}
              ></FormInput>
              <FormInput
                id={"bank_name"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.bank_name}
                label={t("checking_account")}
              ></FormInput>
              <FormInput
                id={"run_type"}
                onChange={updateBatchProp}
                required={false}
                disabled
                placeholder={""}
                type={"text"}
                value={batch.run_type}
                label={t("type_of_check_run")}
              ></FormInput>
            </div>
            <div className="pptp-toggle">
              <ToggleSwitch
                label={t("preview_check_prior_to_processing")}
                checked={payChecks}
                onChange={(val) => handleToggleChange(val)}
              />
            </div>
          </div>
        );
      }
      case 6: {
        if (payChecks) {
          return (
            <div className="pc-directory">
              <CNPBPayCheckDirectoryPage
                batch_id={batch.id}
                week_accounting_id={batch.week_accounting_id}
              />
            </div>
          );
        }
        return (
          <DocSavedText customStyle={{ padding: "5em" }}>
            <span>Click Yes and this payroll batch will be posted</span>
          </DocSavedText>
        );
      }
      case 7: {
        if (payChecks) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Did all paychecks process correctly?</span>
              <span>If so, click Yes and this Payroll Batch will be posted.</span>
            </DocSavedText>
          );
        }
        if (proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>This payroll batch will be deleted, please confirm proceed</span>
            </DocSavedText>
          );
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payroll has been posted.</span>
            </DocSavedText>
          );
        }
      }
      case 8: {
        if (payChecks && proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>This payroll batch will be deleted, please confirm proceed</span>
            </DocSavedText>
          );
        }
        if (!payChecks && proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Payroll batch deleted successfully</span>
            </DocSavedText>
          )
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payroll has been posted.</span>
            </DocSavedText>
          );
        }
      }
      case 9: {
        if (proceed) {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Payroll batch deleted successfully</span>
            </DocSavedText>
          )
        }
        else {
          return (
            <DocSavedText customStyle={{ padding: "5em" }}>
              <span>Congratulations</span>
              <span>Payroll has been posted.</span>
            </DocSavedText>
          );
        }
      }
    }
  };

  const getBackBtnText = () => {
    const { value } = currentStep;
    const isStep6 = value === 6;
    const isStep7 = value === 7;
    const isStep8 = value === 8;
    const isStep9 = value === 9;

    if (value === 1) {
      return t("cancel");
    }

    if (isStep6) {
      return payChecks ? t("back") : t("no");
    }

    if (isStep7) {
      if (payChecks) {
        return t("no");
      }
      return proceed ? t("cancel") : null;
    }

    if (isStep8) {
      if (payChecks) {
        return proceed ? t("cancel") : null;
      }
      return proceed ? null : t("no");
    }

    if (isStep9 && payChecks && proceed) {
      return null;
    }

    // Default case
    return t("back");

  };
  const getNextbtnText = () => {
    const { value } = currentStep;
    const isStep6 = value === 6;
    const isStep7 = value === 7;
    const isStep8 = value === 8;
    const isStep9 = value === 9;

    if (createState.error && createState.error.code === 400 && value === 2) {
      return t("proceed_anyway");
    }

    if (isStep6) {
      return !payChecks ? t("yes") : t("next");
    }

    if (isStep7) {
      if (payChecks) {
        return t("yes");
      }
      return proceed ? t("Proceed") : t("ok");
    }

    if (isStep8) {
      if (payChecks) {
        return proceed ? t("ok") : t("ok");
      }
      return t("ok");
    }

    if (isStep9 && payChecks && proceed) {
      return t("ok");
    }

    return t("next");

  };
  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={"50%"}
      maxWrapperWidth={"50%"}
      onClose={onClose}
      onDissmiss={onClose}
    >
      <DialogWrapper onClose={onClose}>
        <div className="create-payroll-batch-container">
          <div className="create-payroll-batch-header">
            <FormDialogTilteHeader
              title={getTitle()}
              subTitle={getSubtitle()}
              titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
            />
          </div>
          <CustomStepIndicator
            onClick={(val) => setCurrentStep(stepsList[val.value])}
            selected={currentStep}
            stepsList={stepsList}
          ></CustomStepIndicator>
          <div className="create-payroll-batch-content">
            {getCurrentStepView()}
          </div>
          <div className="create-payroll-batch-actions">

            <div className="oc-error-holder">
              {currentStep.value === 2 && createState.error &&
                <ApiError message={createState.error.message} onClose={() => dispatch(PayrollManageBatchSliceActions.clearErrorState())} />
              }
              {currentStep.value === 2 && createState.loading === LoadingType.pending &&
                <span className="alert-message">{t("please_wait_we_are_processing_your_paychecks")}</span>
              }
              {currentStep.value === 6 && createState.post.error &&
                <ApiError message={createState.post.error.message} onClose={() => dispatch(PayrollManageBatchSliceActions.clearPostErrorState())} />
              }
            </div>
            <div className="actions-row">
              <div className="btn-cancel" style={{ marginRight: "1em" }}>
                {getBackBtnText() && (
                  <CustomButton
                    loading={false}
                    textStyle={{ textTransform: "capitalize" }}
                    name={getBackBtnText()}
                    enable={currentStep.value === 3 ? (createState.loading === LoadingType.succeeded ? false : true) : true}
                    backgroundColor={THEME.toggleDisableColor}
                    onClick={() => handleBack()}
                  />
                )}
              </div>
              <div className="btn-save">
                <CustomButton
                  loading={createState.loading === LoadingType.pending}
                  textStyle={{ textTransform: "capitalize" }}
                  name={getNextbtnText()}
                  enable={currentStep.value === 2 ? ((timeCardList?.length! > 0 && batch?.time_cards?.length! > 0) ? true : false) : true}
                  backgroundColor={createState.error && createState.error.code === 400
                    && currentStep.value === 2 ? THEME.secondaryColor6 : THEME.defaultHighLightColor}
                  onClick={() => handleNextStep()}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogWrapper>
    </ActionDialogHolder>
  );
};
