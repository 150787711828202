
import { EntityAdapter, EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import * as thunk from "./insurance_company_thunk";
import { AppError } from "../../../../../interfaces";
import { LoadingType } from "../../../../../enums";
import { RiskMgmtInsuranceCompany } from "../../../../../interfaces/risk_mgmt_insurance_company";

export interface InsuranceCompanyListState
  extends EntityState<RiskMgmtInsuranceCompany> {
  error?: AppError | null;
  loading: LoadingType;
  create: {
    response: string;
    error?: AppError | null;
    loading: LoadingType;
  };
  update: {
    response: string;
    error?: AppError | null;
    loading: LoadingType;
  };
  remove: {
    response: string
    error?: AppError | null,
    loading: LoadingType,
  },

}

const insuranceCompanyListAdapter: EntityAdapter<RiskMgmtInsuranceCompany> =
  createEntityAdapter<RiskMgmtInsuranceCompany>({
    selectId: (doc) => doc.id,
  });

const initialState: InsuranceCompanyListState =
  insuranceCompanyListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    update: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
    remove: {
      response: "",
      error: null,
      loading: LoadingType.idle,
    },
  });

export const insuranceCompanyListSlice = createSlice({
  name: "insuranceCompanyList",
  initialState: initialState,
  reducers: {
    clearInsuranceCompanyListError(state) {
      return { ...state, error: null };
    },
    clearInsuranceCompanyListState(state) {
      return insuranceCompanyListAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
    clearICCreateState(state) {
      return {
        ...state,
        create: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
    clearICUpdateState(state) {
      return {
        ...state,
        update: {
          response: "",
          error: null,
          loading: LoadingType.idle,
        }
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunk.getInsuranceCompanyList.pending,
        (state, action) => ({
          ...state,
          loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
          error: null,
        })
      )
      .addCase(
        thunk.getInsuranceCompanyList.rejected,
        (state, action) => ({
          ...state,
          loading: LoadingType.failed,
          error: action.payload,
        })
      )
      .addCase(
        thunk.getInsuranceCompanyList.fulfilled,
        (state, action) =>
          insuranceCompanyListAdapter.setAll(
            { ...state, loading: LoadingType.succeeded, error: null },
            action.payload
          )
      )
      .addCase(
        thunk.createInsuranceCompany.pending,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.createInsuranceCompany.rejected,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.createInsuranceCompany.fulfilled,
        (state, action) => ({
          ...state,
          create: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.updateInsuranceCompany.pending,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.updateInsuranceCompany.rejected,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.updateInsuranceCompany.fulfilled,
        (state, action) => ({
          ...state,
          update: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )
      .addCase(
        thunk.deleteInsuranceCompany.pending,
        (state, action) => ({
          ...state,
          remove: {
            loading: LoadingType.pending,
            error: null,
            response: "",
          },
        })
      )
      .addCase(
        thunk.deleteInsuranceCompany.rejected,
        (state, action) => ({
          ...state,
          remove: {
            loading: LoadingType.failed,
            error: action.payload,
            response: "",
          },
        })
      )
      .addCase(
        thunk.deleteInsuranceCompany.fulfilled,
        (state, action) => ({
          ...state,
          remove: {
            loading: LoadingType.succeeded,
            error: null,
            response: action.payload,
          },
        })
      )

      .addDefaultCase((state) => ({ ...state }));
  },
});

export const insuranceCompanyListActions =
  insuranceCompanyListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } =
  insuranceCompanyListAdapter.getSelectors();
export const selectState = (
  state: InsuranceCompanyListState
) => state;
