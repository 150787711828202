import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { getMasterTaxSetup, selectMasterTaxSetupState } from "../../../../../../redux/admin_center";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { ICompanyTaxSetup, IMasterTaxSetup } from "../../../../../../interfaces/master_tax";
import { masterTaxSetupStateSliceActions } from "../../../../../../redux/admin_center/integration_partners/master_tax/master_tax_setup/master_tax_setup_reducer";
import { masterTaxInitialForm } from "../../master_tax_initialstate";
import { LoadingType } from "../../../../../../enums";
import { SpinnerScreen } from "../../../../../../utils";
import SortableTable, { TableData } from "../../../../../../components/sortable_table/sortable_table";
import { EditIcon } from "../../../../../../icons";
import { Portal } from "react-portal";
import CompanyTaxConfig from "../../popups/company_tax_popup";
import { companyPaymentMethod, einType } from "../../master_tax_dropdown";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "tax_code", code: "tax_code" },
    { title: "tax_desc", code: "tax_desc" },
    { title: "payroll_code", code: "payroll_code" },
    { title: "ein_type", code: "ein_type" },
    { title: "ein", code: "ein" },
    { title: "payment_method", code: "payment_method" },
    { title: "eft_password", code: "eft_password" },
    { title: "county_code", code: "county_code" },
    { title: "", code: "" }
];

const CompanyTaxSetupPage: React.FunctionComponent<Props> = (props: Props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const masterTaxState = useAppSelector((state) => selectMasterTaxSetupState(state));

    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [masterTaxFormState, setMasterTaxFormState] = useState<IMasterTaxSetup>(masterTaxInitialForm);
    const [editCompTax, setEditCompTax] = useState<boolean>(false)

    const [taxData, setTaxData] = useState<ICompanyTaxSetup[]>([]);

    useEffect(() => {
        dispatch(masterTaxSetupStateSliceActions.clearState());
        dispatch(getMasterTaxSetup());
        return () => { }
    }, []);

    useEffect(() => {
        if (masterTaxState.loading === LoadingType.succeeded && masterTaxState.data) {
            setMasterTaxFormState(masterTaxState.data);
            const taxData = masterTaxState.data.taxes_setup;
            if (taxData !== null) {
                setTaxData(taxData)
                // setFormState(getFormData(taxData))
            }
        }
    }, [masterTaxState.loading, masterTaxState.data]);

    useEffect(() => {
        if (masterTaxState.update.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage("Company Tax config Updated successfully!" ?? null));
            setEditCompTax(false);
            dispatch(masterTaxSetupStateSliceActions.clearState());
            dispatch(getMasterTaxSetup());
        }
    }, [masterTaxState.update.loading]);

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function getFilteredList() {
        let list;
        if (sortedField != null && taxData) {
            list = [...taxData].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? taxData)?.filter(doc => {
            const str = search.trim().toLowerCase();
            const tax_code = doc.tax_code ? doc.tax_code.trim().toLowerCase().includes(str) : false;
            const payroll_code = doc.payroll_code ? doc.payroll_code.toString().trim().toLowerCase().includes(str) : false;
            const ein_type = doc.ein_type ? doc.ein_type.toString().trim().toLowerCase().includes(str) : false;

            return tax_code || payroll_code || ein_type;
        });
    }
    const handleClose = () => {
        setEditCompTax(false);
    };
    const handleClearError = () => {
        dispatch(masterTaxSetupStateSliceActions.clearPostState());
    }
    const [taxDetails, setTaxDetails] = useState<ICompanyTaxSetup>();
    const handleEdit = (tax_code: string) => {
        const tax = taxData.find((tax) => tax.tax_code === tax_code);
        setTaxDetails(tax);
        setEditCompTax(true);
    }
    const getValueFromId = (array, id) => {
        return array.reduce((acc, method) => {
            if (method.id === id) {
                acc = method.value;
            }
            return acc;
        }, null);
    };
    return (
        <div className="cs-container">
            <div className="cs-header">
                <span className="title-header">{t('company_tax_setup')}</span>

            </div>
            {masterTaxState.loading === LoadingType.pending ?
                <SpinnerScreen />
                :
                <div className="cs-content no-border">
                    {!masterTaxState.data?.tax_setup_complete &&
                        <span className="cs-warn-title">
                            {t("note_finish_tax_codes")}
                        </span>
                    }
                    <div>
                        <SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList().map((doc) => {
                                return (
                                    <tr key={doc.tax_code}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                            <span>{doc.tax_code}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                            <span>{doc.tax_desc}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                            <span>{doc.payroll_code}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                            <span>{getValueFromId(einType, doc.ein_type)}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            <span>{doc.ein}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                            <span>{getValueFromId(companyPaymentMethod, doc.payment_method)}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                            <span>{doc.eft_password}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                            <span>{doc.county_code}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                            <span>
                                                <button
                                                    className="icon-btn tdp-edit-icon"
                                                    onClick={() => handleEdit(doc.tax_code)}
                                                >
                                                    <EditIcon
                                                        width={"100%"}
                                                        height={"100%"}
                                                        style={{ color: "#fff" }}
                                                    />
                                                </button>
                                            </span>
                                        </TableData>
                                    </tr>
                                )
                            })}

                        </SortableTable>
                    </div>
                </div>

            }

            {editCompTax &&
                (
                    <Portal>
                        <CompanyTaxConfig
                            visible={editCompTax}
                            onClose={handleClose}
                            onClearError={handleClearError}
                            masterTaxState={masterTaxState}
                            tax={taxDetails}
                            masterTaxFormState={masterTaxFormState}
                        />
                    </Portal>
                )}
        </div>

    );
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 0.3;

        return 1;
    }
}

export default CompanyTaxSetupPage;