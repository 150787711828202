import { Portal } from "react-portal";
import { THEME } from "../../../../../../enums";
import { DeleteIcon, PaperPlaneIcon } from "../../../../../../icons";
import { deleteJobDocumentUploadDocument, selectJobDocVidSendByEmailState, sendDocVidByEmail } from "../../../../../../redux/job_order";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { CustomButton } from "../../../../../../utils";
import SendByEmailPopup from "../../../../popups/send_by_email/sen_by_email";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SendByEmail } from "../../../../../../interfaces/marketing_campaign";
import { jobDocumentSendByEmailActions } from "../../../../../../redux/job_order/details/document/document_send_by_email/send_by_email_reducer";

interface Props {
    id: string;
    job_document_id: string;
    title: string;
    url: any;
    onRetry: () => void;
}

const VidePlayer: React.FC<Props> = (props) => {
    const { url, title, onRetry, id, job_document_id } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showSendByEmail, setShowSendByEmail] = useState<boolean>(false);
    const profileState = useAppSelector((state) => selectProfileState(state));
    const sendByemailState = useAppSelector((state) => selectJobDocVidSendByEmailState(state))


    let videoId = '';
    if (url.toLowerCase().includes('v='))
        videoId = url.split('v=')[1].split('&')[0];
    else {
        videoId = url.split('/');
        videoId = videoId[videoId.length - 1];
    }

    function handleDelete(doc: string, job_order_id: string) {
        dispatch(deleteJobDocumentUploadDocument({job_order_id: job_order_id, file_object_id: doc}))
        onRetry()
    }

    return (
        <div>
            <iframe style={{ 'borderRadius': "5px" }} width="420" height="315" src={`https://www.youtube.com/embed/${videoId}?controls=1`} allowFullScreen={true} frameBorder={0}></iframe>
            <div className="mdv-container">
                <h5>{title}</h5>
                <button onClick={() => handleDelete(id, job_document_id)}>
                    <div className="icon-btn tdp-edit-icon">
                        <DeleteIcon width={"100%"} height={"100%"} />
                    </div>
                </button>
            </div>
            <div>
            <div>
                <CustomButton
                    textStyle={{ textTransform: 'capitalize' }}
                    enable={true}
                    leftIcon={
                        <div>
                            <PaperPlaneIcon
                                width={20}
                                height={20}
                                style={{ color: THEME.white }}
                            />
                        </div>
                    }
                    name={t("send_by_email")}
                    padding={12}
                    fontSize={13}
                    backgroundColor={THEME.buttonBackgroundColor4}
                    onClick={() => setShowSendByEmail(true)}
                />
            </div>
            {showSendByEmail && (
                <Portal>
                    <SendByEmailPopup
                        visible={showSendByEmail}
                        DocumentId={id}
                        onClose={() => setShowSendByEmail(false)}
                        onDissmiss={() => setShowSendByEmail(false)}
                        onSuccessClose={handleOnSendByEmailSuccess}
                        onSubmit={handleOnSubmit}
                        profileState={profileState}
                        sendByemailState={sendByemailState}
                        loading={sendByemailState.sendDocVidLinkToEmail.loading}
                        error={sendByemailState.sendDocVidLinkToEmail.error}
                    />
                </Portal>
            )}
            </div>
        </div>
    )
    function handleOnSubmit(data: SendByEmail) {
        dispatch(sendDocVidByEmail(data));
    }

    function handleOnSendByEmailSuccess() {
        setShowSendByEmail(false);
        dispatch(jobDocumentSendByEmailActions.clearSendDocVidLinkToEmailState());
    }
}
export default VidePlayer;