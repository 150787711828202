import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCompanyInvoicesThunk, selectCompanyInvoicesState } from '../../../../../redux/companies';
import { getContactsDropdownThunk, getDownloadFileURLThunk, selectContactsDropdownList, selectFileDownloadState, useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { LoadingType, THEME } from '../../../../../enums';
import { AppError, ICompanyEdocs } from '../../../../../interfaces';
import { getDateString } from '../../../../../variables';
import { CustomButton, CustomCheckBox, currencyConversion } from '../../../../../utils';
import SortableTable, { TableData } from '../../../../../components/sortable_table/sortable_table';
import { DownloadIcon, SearchIcon } from '../../../../../icons';
import { EmailOutlined } from '@material-ui/icons';
import SearchBar from '../../../../../components/search_bar';
import { companyEdocsListActions } from '../../../../../redux/companies/details/company_edocs/company_edocs_reducer';
import { Portal } from 'react-portal';
import SendInvoiceEdocsByMailPopup from '../../../popup/send_invoice_edocs_mail/send_invoice_edocs_mail';
import { fileDownloadSliceActions } from '../../../../../redux/files/file_download/file_download_reducer';


interface Props {
    edocState: {
        error?: AppError | null;
        loading: LoadingType;
        response: ICompanyEdocs[] | null;
    },
    sendEdocsByMail: (email_id: string, edoc_ids: string[]) => void;
    edocsMailState: {
        error?: AppError | null;
        loading: LoadingType;
        response: string | null;
    },
    currentLoginUserName: string;
    company_id?: string;
}

const CompanyEdocsList: React.FunctionComponent<Props> = (props) => {
    const { edocState, sendEdocsByMail, edocsMailState, currentLoginUserName, company_id } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fileDownloadState = useAppSelector((state) => selectFileDownloadState(state));
    const contactsList = useAppSelector((state) => selectContactsDropdownList(state));

    useEffect(() => {
        dispatch(getContactsDropdownThunk({ company_id: company_id! }))
    }, [])

    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState('');
    const [edocsList, setEdocsList] = useState<ICompanyEdocs[] | null>(null);
    const [showMailPopup, setShowMailPopup] = useState<boolean>(false);

    const [selectedItems, setSelectedItems] = useState<
        Map<string, { id: string; name: string }>
    >(new Map());
    useEffect(() => {
        if (edocState && edocState.loading === LoadingType.succeeded) {
            setEdocsList(edocState.response)
        }
    }, [edocState.loading]);
    const SelectAllCheckBox = () => {
        return (
            <CustomCheckBox
                name={""}
                title={""}
                checked={selectedItems.size === edocsList?.length}
                onClick={handleMarkAllItem}
            />
        );
    };
    const tableHeader = [
        { title: "", code: "", sort: false, children: <SelectAllCheckBox /> },
        { title: "department_name", code: "department_name" },
        { title: "document_type", code: "document_type" },
        { title: "document_name", code: "document_name" },
        { title: "upload_date", code: "upload_date" },
        { title: "notes", code: "notes" },
        { title: "", code: "" },
    ];
    const getFlexNumber = (value: number) => {
        if (value === 0) return 0.2;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1.5;
        if (value === 4) return 1;
        if (value === 5) return 3;
        if (value === 6) return 0.5;
        return 1;
    }
    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    function getFilteredList(): ICompanyEdocs[] {
        let list = edocsList ? [...edocsList] : [];

        if (sortedField != null) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }


        return (list).filter(doc => {
            const str = search.trim().toLowerCase();
            const document_type = doc.document_type ? doc.document_type.toString().trim().toLowerCase().includes(str) : false;
            const document_name = doc.document_name ? doc.document_name.toString().trim().toLowerCase().includes(str) : false;
            const upload_date = doc.upload_date ? doc.upload_date.toString().trim().toLowerCase().includes(str) : false;
            const notes = doc.notes ? doc.notes.toString().trim().toLowerCase().includes(str) : false;

            return document_type || document_name || upload_date || notes;
        });
    }
    const handleMarkAllItem = () => {
        const newSet = new Map<string, { id: string; name: string }>();
        if (selectedItems.size === edocsList?.length) {
            setSelectedItems(newSet);
        } else {
            edocsList?.forEach((doc) =>
                newSet.set(doc.id, { id: doc.id, name: "" })
            );
            setSelectedItems(newSet);
        }
    };

    const handleMarkItem = (value) => {
        const newSet = new Map(selectedItems);
        if (selectedItems.has(value.id)) {
            newSet.delete(value.id);
        } else {
            newSet.set(value.id, { id: value.id, name: "" });
        }
        setSelectedItems(newSet);
    };

    const initialForm = { invoice_ids: [], email: "", contact_id: "", contact_name: "", radio: "emailID" }
    const [formState, setFormState] = useState<{ invoice_ids: string[], email: string, contact_id: string, contact_name: string, radio: string }>(initialForm)
    useEffect(() => {
        if (formState.contact_id) {
            const selectedContact = contactsList.find(contact => contact.id === formState.contact_id);
            if (selectedContact) {
                setFormState(prevState => ({
                    ...prevState,
                    contact_name: selectedContact.name
                }));
            }
        }
    }, [formState.contact_id, contactsList]);
    const handleFieldChange = (id: string, val: string) => {
        if (id === "radioEmail") {
            setFormState({ ...formState, radio: val });
        }
        else {
            setFormState({
                ...formState, [id]: val, invoice_ids: Array.from(
                    selectedItems.values(),
                    (entry) => entry.id
                )
            })
        }
    }
    const handleEmailClose = () => {
        setShowMailPopup(false);
        setFormState(initialForm);
        dispatch(companyEdocsListActions.clearMailState());
        setSelectedItems(new Map())
    }
    const [downLoadingFile, setDownLoadingFile]: any = useState("");
    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded && fileDownloadState.response) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.response);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(fileDownloadSliceActions.clearState());
            setDownLoadingFile("");
        }

    }, [fileDownloadState.loading])
    const handleDownload = (filePath) => {
        setDownLoadingFile(filePath);
        dispatch(getDownloadFileURLThunk(filePath));
    }

    return (
        <div className='cmpny-invoice-list'>
            <div className='cmpny-invoice-header'>
                <div>
                    <span className='title-span'>{t('company_related_docs')}</span>
                    <span className='total-count'>{t('total_count')}: <b>{getFilteredList().length}</b></span>
                </div>
                <div className='cmpny-header-right'>
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            leftIcon={<div><EmailOutlined style={{ color: THEME.white, height: "1vw", width: "1vw" }} /></div>}
                            name={t("send_by_email")}
                            backgroundColor={THEME.buttonBackgroundColor4}
                            onClick={() => { setShowMailPopup(true) }}
                            enable={selectedItems.size > 0}
                        />
                    </div>
                    <div className="search-bar">
                        <SearchBar value={search} onChange={(value) => setSearch(value)} onSearch={() => { }} />
                    </div>
                </div>
            </div>
            <div className='invoice-list-table'>
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList() && getFilteredList()?.length! > 0 ?
                        <>
                            {getFilteredList().map((doc, index) => {
                                return (
                                    <tr key={doc.id}>
                                        <TableData customStyle={{ flex: getFlexNumber(0) }}>
                                            <CustomCheckBox
                                                name={""}
                                                title={""}
                                                checked={selectedItems!.has(doc.id)}
                                                onClick={() => handleMarkItem(doc)}
                                            />

                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(1) }}>
                                            <span>{doc.department_name ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                            <span>{doc.document_type ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                            <span>{doc.document_name ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                            <span>{doc.upload_date ? getDateString(doc.upload_date) : ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                            <span>{doc.notes ?? ""}</span>
                                        </TableData>
                                        <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                            <div className="download-icon" onClick={() => handleDownload(doc.document_path)}>
                                                <DownloadIcon width={"100%"} height={"100%"} style={{ color: "#fff" }} />
                                            </div>
                                        </TableData>
                                    </tr>
                                );
                            })}
                        </>
                        :
                        <>
                            <tr>
                                <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                    <div className="na_div" style={{ width: "100%", fontSize: "1vw", textAlign: "center" }}>
                                        {t("no_edocs_available")}
                                    </div>
                                </TableData>
                            </tr>
                        </>


                    }

                </SortableTable>
            </div>
            {showMailPopup &&
                <Portal>
                    <SendInvoiceEdocsByMailPopup
                        visible={showMailPopup}
                        onClose={handleEmailClose}
                        onSubmit={() => sendEdocsByMail(formState.radio === "emailID" ? formState.email : formState.contact_id, formState.invoice_ids)}
                        mailState={edocsMailState}
                        currentLoginUserName={currentLoginUserName}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        title={t("hi_lets_send_edocs", {
                            name: currentLoginUserName,
                        })}
                        successMsg={t("you_sent_edocs_to", { count: selectedItems.size })}
                        successTitle={t("sent_edocs_by_email")}
                        subTitle={t("to_whom_you_send_documents")}
                        isEdocs={true}
                        contactsList={contactsList}
                    />
                </Portal>
            }
        </div>
    )
}

export default CompanyEdocsList;