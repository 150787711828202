import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ProfilePictureType, ROLE } from '../../../../enums';
import { IJobOrderDetail, ICreateJob, ICreateResponse } from '../../../../interfaces';
import {
    getServiceCalls,
    getSuccessProfileQuestions,
    getSuccessProfileQuestionSteps,
    selectServiceCallList,
    selectSuccessProfileQuestionList,
    selectSuccessProfileQuestionListState,
    selectSuccessProfileQuestionStepList,
    selectSuccessProfileQuestionStepState,
    selectServiceCallState,
    selectJobOrderPlacementList,
    selectJobOrderSourceList,
    selectJobOrderSourceState,
    selectJobOrderPlacementState,
    selectSuccessProfileQuestionGroupList,
    getJobOrderPlacements,
    getJobOrderSources,
    getWorkCompConfigList,
    selectWorkCompConfigListState,
    selectWorkCompConfigList
} from '../../../../redux/admin_center';
import { manageJobActions } from '../../../../redux/job_order/universal_directory/manage_job/manage_job_reducer';
import { selectWorkCompCodesListState, selectWorkCompCodesList } from '../../../../redux/selectors/work_comp_list/work_comp_list_selector';
import { getWorkCompCodesThunk } from '../../../../redux/selectors/work_comp_list/work_comp_list_thunk';
import {
    postSnackbarMessage,
    useAppDispatch,
    useAppSelector,
    createJob,
    getAccountManagers,
    getBusinessSectors,
    getRecruiters,
    selectManageJobCreateState,
    selectManageJobUpdateState,
    updateJob,
    getCompetitorsList,
    selectCertificationsList,
    selectCredentialsList,
    selectEducationList,
    selectRequiredEquipmentList,
    selectSkillSetList,
    getSkillSetList,
    getCertificationsList,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    selectSkillSetListState,
    selectCredentialsListState,
    selectCertificationsListState,
    selectEducationListState,
    selectRequiredEquipmentListState,
    selectProfileState,
    selectManageJobFilesStateByType,
    getContactFileUploadURLThunk,
    uploadJobFileThunk,
    getJobFileUploadURLThunk,

} from '../../../../redux/store';
import { SpinnerScreen } from '../../../../utils';
import JobForm from '../../forms/job_form/job_form';

import './create_job.scss';
import { SingleContactState } from '../../../../redux/contacts/details/dashboard/contact_details/contact_details_reducer';

interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    job?: IJobOrderDetail,
    data?: { company_id?: string, department_id?: string, department_ids?: string[] }
    contactState?: SingleContactState,
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,

    onGotoAgencyDashboard: () => void,
    onGotoCreated: (id: string, name: string) => void,
}

const CreateJobPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        job,
        onClose,
        onDissmiss,
        onSuccessClose,
        onGotoAgencyDashboard,
        onGotoCreated,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const photoType = "photo";
    const resumeType = "resume";
    const profileState = useAppSelector((state) => selectProfileState(state));
    const createState = useAppSelector((state) => selectManageJobCreateState(state));
    const updateState = useAppSelector((state) => selectManageJobUpdateState(state));
    const photoState = useAppSelector((state) => selectManageJobFilesStateByType(photoType)(state));
    const resumeState = useAppSelector((state) => selectManageJobFilesStateByType(resumeType)(state));

    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));

    ///Success profile questions
    const successProfileStepState = useAppSelector((state) => selectSuccessProfileQuestionStepState(state));
    const successProfileStepList = useAppSelector((state) => selectSuccessProfileQuestionStepList(state));
    const successProfileQuestionsState = useAppSelector((state) => selectSuccessProfileQuestionListState(state));
    const successProfileQuestionsList = useAppSelector((state) => selectSuccessProfileQuestionList(state));
    const successProfileQuestionsGroupList = useAppSelector((state) => selectSuccessProfileQuestionGroupList(state));

    // const workCompCodesListState = useAppSelector((state) => selectWorkCompCodesListState(state));
    // const workCompCodesList = useAppSelector((state) => selectWorkCompCodesList(state));
    const workCompCodesListState = useAppSelector((state) => selectWorkCompConfigListState(state))
    const workCompCodesList = useAppSelector((state) => selectWorkCompConfigList(state))
    const orderSourceListState = useAppSelector((state) => selectJobOrderSourceState(state));
    const orderSourceList = useAppSelector((state) => selectJobOrderSourceList(state));
    const placementTypeListState = useAppSelector((state) => selectJobOrderPlacementState(state));
    const placementTypeList = useAppSelector((state) => selectJobOrderPlacementList(state));

    const actionRef = useRef<ActionDialogHolderType>(null);

    ///service calls
    const serviceCallListState = useAppSelector((state) => selectServiceCallState(state));
    const serviceCallList = useAppSelector((state) => selectServiceCallList(state));

    /// skills
    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));

    ///Files
    const [contactLogo, setContactLogo] = useState<File | null>(null);
    const [contactResume, setContactResume] = useState<File | null>(null);
    useEffect(() => {
        getData();
        dispatch(getSuccessProfileQuestions());
        dispatch(getSuccessProfileQuestionSteps());
        dispatch(getServiceCalls());
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getJobOrderPlacements());
        dispatch(getJobOrderSources());
        // dispatch(getWorkCompCodesThunk());
        dispatch(getWorkCompConfigList());
        dispatch(manageJobActions.initializeManageJobState([photoType, resumeType]))
    }, [])

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            if (createState.response) handleNextStep(createState.response);
        }
        if (updateState.loading == LoadingType.succeeded) {
            if (updateState.response) handleNextStep(updateState.response);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

    useEffect(() => {
        if (photoState.fileURL.loading == LoadingType.succeeded) {
            if (photoState.fileURL.response) dispatch(uploadJobFileThunk({ type: photoType, data: photoState.fileURL.response, file: contactLogo }));
        }
        return () => { }
    }, [photoState.fileURL.loading])

    useEffect(() => {
        if (resumeState.fileURL.loading == LoadingType.succeeded) {
            if (resumeState.fileURL.response) dispatch(uploadJobFileThunk({ type: resumeType, data: resumeState.fileURL.response, file: contactResume }));
        }
        return () => { }
    }, [resumeState.fileURL.loading])


    function handleNextStep(response: ICreateResponse) {
        if (contactLogo != null || contactResume != null) {
            if (contactLogo != null) dispatch(getJobFileUploadURLThunk({ type: photoType, query: { object_id: response.object_id, object_type: ProfilePictureType.contact, file_name: contactLogo.name } }));
            if (contactResume != null) dispatch(getJobFileUploadURLThunk({ type: resumeType, query: { object_id: response.object_id, object_type: ProfilePictureType.contact, file_name: contactResume.name } }));
        } else if (props.job && props.job.id) {
            dispatch(postSnackbarMessage(response.status ?? null));
            closePopup(onSuccessClose);
        } else {
            ///
        }
    }

    function getData() {
        dispatch(getAccountManagers({ role: ROLE.accountManager, branch_id: '' }));
        dispatch(getRecruiters({ role: ROLE.recruiter, branch_id: '' }));
        dispatch(getBusinessSectors());
        dispatch(getCompetitorsList());
    }

    function isInitializing() {
        return skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            serviceCallListState.loading === LoadingType.pending ||
            successProfileQuestionsState.loading === LoadingType.pending ||
            successProfileStepState.loading === LoadingType.pending ||
            orderSourceListState.loading === LoadingType.pending ||
            placementTypeListState.loading === LoadingType.pending ||
            workCompCodesListState.loading === LoadingType.pending;

    }

    function isLoading() {
        return createState.loading ||
            updateState.loading ||
            photoState.fileURL.loading ||
            photoState.upload.loading ||
            resumeState.fileURL.loading ||
            resumeState.upload.loading;
    }

    function isError() {
        return createState.error ||
            updateState.error ||
            photoState.fileURL.error ||
            photoState.upload.error ||
            resumeState.fileURL.error ||
            resumeState.upload.error;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isInitializing() ? <div className="loading-spinner"><SpinnerScreen /></div> : <JobForm
                    title={props.title}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    job={props.job}
                    data={props.data}
                    contactState={props.contactState}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}

                    workCompCodesList={workCompCodesList}
                    orderSourceList={orderSourceList}
                    placementTypeList={placementTypeList}
                    /// success profile
                    lastStep={Object.keys(successProfileQuestionsGroupList).length}
                    successProfileStepsList={successProfileStepList}
                    successProfileQuestionsList={successProfileQuestionsList}
                    successProfileGroup={successProfileQuestionsGroupList}
                    /// Service calls
                    serviceCallList={serviceCallList}
                    /// Skills
                    skillSetList={skillSetList}
                    credentialsList={credentialsList}
                    certificationsList={certificationsList}
                    educationList={educationList}
                    requiredEquipmentList={requiredEquipmentList}
                    /// Photo
                    selectedPhoto={contactLogo}
                    onPhotoChange={setContactLogo}
                    onRemovePhoto={() => setContactLogo(null)}
                    /// Resume
                    selectedResume={contactResume}
                    onResumeChange={setContactResume}
                    onRemoveResume={() => setContactResume(null)}

                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                />}
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        dispatch(manageJobActions.clearManageJobStateError([photoType, resumeType]));
    }

    /// handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateJob }) {
        if (props.job && props.job.id) {
            dispatch(updateJob({ ...props.job, ...doc.value }));
        }
        else {
            dispatch(createJob(doc));
        }
    }

    function handleGotoAgencyDashboard() {
        closePopup(props.onGotoAgencyDashboard);
    }


    function handleCreateNew() {
        setContactLogo(null);
        setContactResume(null);
        dispatch(manageJobActions.clearManageJobState([photoType, resumeType]));
    }


    function handleGotoCreated(name: string) {
        if (props.job && props.job.id) {
            closePopup(onSuccessClose);
        } else if (createState.response && createState.response.object_id) {
            closePopup(() => props.onGotoCreated(createState?.response?.object_id!, name));
        }
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default CreateJobPopup;