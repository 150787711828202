import { IconButton, Snackbar } from '@material-ui/core';
import { AddCircleOutline, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../../../components/search_bar';
import SortableTable, { TableData } from '../../../components/sortable_table/sortable_table';
import TableEmpty, { TableErrorHandler, TableFilterEmpty, TableLoading } from '../../../components/table_empty/table_empty';
import TabOptionsHeader from '../../../components/tab_options_header/tab_options_header';
import { LoadingType, THEME } from '../../../enums';
import { Profile } from '../../../interfaces';
import { getAdminCenterUserManagementUsers, selectUserStatusState, selectUsersList, selectUsersListState, updateToggleStatus } from '../../../redux/admin_center';
import { manageUserActions } from '../../../redux/admin_center/user_management/manage_user/manage_user_reducer';
import { selectProfileState } from '../../../redux/profile/profile_selector';
import { postSnackbarMessage, useAppDispatch, useAppSelector } from '../../../redux/store';
import { AppRoutes } from '../../../routes';
import { CustomButton, SpinnerScreen, getPlusBtnIcon, getTableAccessIcon } from '../../../utils';
import { getDateString, getDaysRemainingString } from '../../../variables';
import CreateUser from './create_user';
import './user_management_page.scss';
import ToggleSwitch from '../../../components/toggle_switch/toggle_switch';
import ApiError from '../../../components/api_error';
import { clearToggleStatusStateError } from '../../../redux/admin_center/user_management/toggle_status/toggle_status_reducer';

interface Props extends RouteComponentProps<any> { }

const tabOptions = [
    {
        title: "users_directory",
    }
];

const tableHeader = [
    { title: 'access_user', code: 'access_user' },
    { title: 'name', code: 'name' },
    { title: 'email', code: 'email' },
    { title: 'last_login', code: 'last_login' },
    { title: 'password_expiration', code: 'password_expiration' },
    { title: 'status', code: 'status' },
];

const AdminCenterUserManagementPage: React.FunctionComponent<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => selectProfileState(state));
    const { loading, error, } = useAppSelector((state) => selectUsersListState(state));
    const usersList = useAppSelector((state) => selectUsersList(state));
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const { t } = useTranslation();
    const [addUser, setAddUser] = useState<boolean>(false);

    const toggleSwitchState = useAppSelector((state) => selectUserStatusState(state));

    const [selectedId, setSelectedId] = useState<string>('');

    useEffect(() => {
        getUsers();
        return () => {
        }
    }, [])

    useEffect(() => {
        if (toggleSwitchState.loading === LoadingType.succeeded) {
            getUsers();
        }
    }, [toggleSwitchState.loading])

    useEffect(() => {
        if (error != null && usersList.length != 0) {
            dispatch(postSnackbarMessage(error.message));
        }
        return () => {
        }
    }, [error])

    function getUsers() {
        dispatch(getAdminCenterUserManagementUsers());
    }


    function getFilteredList() {
        let list;
        if (sortedField != null) {
            list = [...usersList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] != undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return (list ?? usersList).filter(doc => {
            const str = search.trim().toLowerCase();
            const nameFilter = `${doc.first_name ?? ""} ${doc.last_name ?? ""}`.trim().toLowerCase().includes(str);
            const emailFilter = doc.email.trim().toLowerCase().includes(str);
            return nameFilter || emailFilter;
        });
    }


    return (
        <div className="ac-um-container">
            <div className="ac-um-header">
                <TabOptionsHeader list={tabOptions} onClose={() => props.history.goBack()} />
            </div>
            <div className="ac-um-content">
                <div className="ac-um-table-holder">
                    <div className="ac-um-table-header">
                        <div className="ac-um-table-search-header">
                            <SearchBar value={search} onChange={handleChange} onSearch={handleSearch} />
                            <div className="total-count">
                                <span>{`${t('total_count')}: `} <span className="total-count-number">{getFilteredList()?.length}</span> </span>
                            </div>
                        </div>

                        <div className="ac-um-actions">
                            <div className="ac-um-add-user-btn">
                                <CustomButton
                                    leftIcon={getPlusBtnIcon()}
                                    loading={false}
                                    textStyle={{ textTransform: 'capitalize' }}
                                    name={t('add_user')}
                                    enable={true}
                                    backgroundColor={THEME.defaultHighLightColor}
                                    onClick={() => setAddUser(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ac-um-table">
                        {getUsersTableList()}
                    </div>
                </div>
            </div>


            {addUser && profile && profile.branch_id && <Portal>
                <CreateUser
                    visible={addUser}
                    title={t('add_user')}
                    branchId={profile?.branch_id}
                    onClose={() => setAddUser(false)}
                    onSuccessClose={handleAddUserSuccess}
                />
            </Portal>}
        </div>

    );

    /// Users table list
    function getUsersTableList() {
        if (loading === LoadingType.pending) {
            return (<TableLoading />);
        }
        if (error != null && usersList.length === 0) {
            return (
                <TableErrorHandler error={error} onRefresh={getUsers} />
            );
        }
        if (usersList.length === 0) {
            return (
                <TableEmpty title={t('no_users_found')} onClick={() => getUsers()} />
            );
        }
        if (getFilteredList().length === 0) {
            return (
                <TableFilterEmpty title={t('no_users_found')} />
            );
        }

        function handleClearError() {
            dispatch(clearToggleStatusStateError());
        }

        const handleToggleChange = (id, val) => {
            setSelectedId(id)
            dispatch(updateToggleStatus({ user_id: id, status: val }))
        }
        return (
            <SortableTable
                headerList={tableHeader}
                sortedField={sortedField}
                onSortChange={handleSortFieldChange}
                flexNumber={getFlexNumber}
                isAsc={sortDirection}
            >
                {getFilteredList().map((doc, index) => {
                    return (
                        <tr key={doc.id}>
                            <TableData customStyle={{ flex: getFlexNumber(0) }}>{getTableAccessIcon(() => handleUserSelect(doc))}</TableData>
                            <TableData customStyle={{ flex: getFlexNumber(1) }} isButton={true} onClick={() => handleUserSelect(doc)}><span>{`${doc.first_name ?? ''} ${doc.last_name ?? ''}`}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.email}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{getDaysRemainingString(t, doc?.last_login) ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{getDateString(doc?.password_expiration) ?? ''}</span></TableData>
                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                <span>
                                    {
                                        <ToggleSwitch
                                            label={doc.disabled ? "Disabled" : "Enabled"}
                                            checked={!(doc.disabled)}
                                            onChange={(value) => handleToggleChange(doc.id, value)}

                                        />

                                    }
                                </span>
                                <span className='toggle-spinner'>
                                    {doc.id === selectedId && toggleSwitchState.loading === LoadingType.pending && <SpinnerScreen size={15} />}
                                    {doc.id === selectedId && 
                                        toggleSwitchState.error && <div className="error-holder">
                                            <ApiError message={toggleSwitchState.error.message} onClose={handleClearError} />
                                        </div>
                                    }
                                </span>
                            </TableData>

                        </tr>
                    );
                })}
            </SortableTable>
        )
    }

    function getFlexNumber(value: number) {
        if (value === 0) return 0.5;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 0.5;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 1;
        return 1;
    }

    function handleChange(value: string) {
        setSearch(value);
    }

    function handleSearch() {
        console.log(search);
    }



    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            // setSortedField(null);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }

    function handleUserSelect(value: Profile) {
        props.history.push({
            pathname: `${AppRoutes.adminCenterUserManagement}/${value.id}`,
            state: {
                id: value.id,
                name: `${value.first_name ?? ""} ${value.last_name ?? ""}`,
            }
        })
    }

    ///Add user action
    function handleAddUserSuccess() {
        setAddUser(false);
        getUsers();
        dispatch(manageUserActions.clearCreateState());
    }
}


export default AdminCenterUserManagementPage;