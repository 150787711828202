import { useEffect, useState } from "react";
import { getWeekEndingDatesDropdownThunk, getReportsDirectoryThunk, selectReportDirectoryState, selectWeekEndingDatesDDList, useAppDispatch, useAppSelector, selectFileDownloadState, getDownloadFileURLThunk, uploadToMasterTax, postSnackbarMessage } from "../../../../../redux/store";
import { IEReportsDirectory } from "../../../../../interfaces";
import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import { ActionType, ExportMappingGroup, ImportExportFileStatus, LoadingType, MappingGroup, THEME } from "../../../../../enums";
import { CustomButton, SpinnerScreen } from "../../../../../utils";
import { getDateString } from "../../../../../variables";
import SearchBar from "../../../../../components/search_bar";
import './reports_directory.scss';
import { DownloadIcon, UploadIcon } from "../../../../../icons";
import { fileDownloadSliceActions } from "../../../../../redux/files/file_download/file_download_reducer";
import { RouteComponentProps } from "react-router-dom";
import { reportsDirectorySliceActions } from "../../../../../redux/back_office/import_export/reports_directory/reports_directory_reducer";


interface Props extends RouteComponentProps<any> { }

const IEReportsDirectoryPage: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const actionType = location.pathname.split('/')[2] === "import" ? ActionType.IMPORT : ActionType.EXPORT;

    const tableHeader = [
        { title: 'mapping_group', code: 'mapping_group' },
        { title: 'description', code: 'description' },
        { title: 'file_name', code: 'file_name' },
        { title: 'status', code: 'status' },
        { title: 'action', code: '' },
    ]

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const weekEndingDatesList = useAppSelector((state) => selectWeekEndingDatesDDList(state));
    const reportsDirectoryListState = useAppSelector((state) => selectReportDirectoryState(state));
    const [reportsDirectoryList, setReportsDirecoryList] = useState<IEReportsDirectory[]>([]);
    const [selectedWeekDate, setSelectedWeekDate] = useState<{ id: string, date: string }>({ id: "", date: "" });
    const fileDownloadState = useAppSelector((state) => selectFileDownloadState(state));
    const [uploadBtnIndex, setUploadBtnIndex] = useState<number | null>(null);
    const [dwnldBtnIndex, setDwnldBtnIndex] = useState<number | null>(null);


    useEffect(() => {
        dispatch(getWeekEndingDatesDropdownThunk());
    }, []);
    useEffect(() => {
        if (weekEndingDatesList && weekEndingDatesList.length > 0) {
            setSelectedWeekDate({ id: weekEndingDatesList[0].id, date: getDateString(weekEndingDatesList[0].week_ending_date, "mm/dd/yyyy") });
            dispatch(getReportsDirectoryThunk({ week_ending_id: weekEndingDatesList[0].id, action_type: actionType }));
        }
    }, [weekEndingDatesList.length]);

    useEffect(() => {
        if (reportsDirectoryListState && reportsDirectoryListState.loading === LoadingType.succeeded && reportsDirectoryListState.response) {
            setReportsDirecoryList(reportsDirectoryListState.response);
        }
    }, [reportsDirectoryListState.loading, reportsDirectoryListState.response]);

    useEffect(() => {
        if (reportsDirectoryListState && reportsDirectoryListState.uploadMT.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(reportsDirectoryListState.uploadMT.response));
            dispatch(reportsDirectorySliceActions.clearUploadMTState());
        }
    }, [reportsDirectoryListState.uploadMT.loading, reportsDirectoryListState.uploadMT.response]);

    useEffect(() => {
        if (reportsDirectoryListState && reportsDirectoryListState.uploadMT.loading === LoadingType.failed && reportsDirectoryListState.uploadMT.error) {
            dispatch(postSnackbarMessage(reportsDirectoryListState.uploadMT.error?.message));
            dispatch(reportsDirectorySliceActions.clearUploadMTState());
        }
    }, [reportsDirectoryListState.uploadMT.loading, reportsDirectoryListState.uploadMT.error?.message]);
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [search, setSearch] = useState('');

    function handleSortFieldChange(value: string) {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFilteredList = () => {
        let list: IEReportsDirectory[] = reportsDirectoryList;
        if (sortedField != null) {
            list = [...reportsDirectoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }
        return list.filter(doc => {
            const str = search.trim().toLowerCase();
            const mappingFilter = doc.mapping_group ? doc.mapping_group.trim().toLowerCase().includes(str) : false;
            const descFilter = doc.description ? doc.description.trim().toLowerCase().includes(str) : false;
            const filenameFilter = doc.file_name ? doc.file_name.trim().toLowerCase().includes(str) : false;
            const statusFilter = doc.status ? doc.status.trim().toLowerCase().includes(str) : false;

            return mappingFilter || descFilter || filenameFilter || statusFilter;

        });
    };
    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleWeekEndChange = (val) => {
        const selectedWeekObject = weekEndingDatesList.find(obj => obj.id === val);
        setSelectedWeekDate({ id: val, date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy") });
        dispatch(getReportsDirectoryThunk({ week_ending_id: val, action_type: actionType }));
    };
    const getFlexNumber = (value: number) => {
        if (value === 0) return 2;
        if (value === 1) return 2;
        if (value === 2) return 2;
        if (value === 3) return 1.5;
        if (value === 4) return 2;
        if (value === 5) return 3;

        return 1;
    }

    useEffect(() => {
        if (fileDownloadState.loading === LoadingType.succeeded && fileDownloadState.response) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', fileDownloadState.response);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(fileDownloadSliceActions.clearState());
        }
    }, [fileDownloadState.loading]);

    const handleDownload = (filePath, index) => {
        dispatch(getDownloadFileURLThunk(filePath));
    }

    const hanldeUpload = (index, type) => {
        setUploadBtnIndex(index)
        dispatch(uploadToMasterTax(type));
    }
    return (
        <div className="reports-directory-container">
            <div className="rd-header">
                <div>
                    <CustomFormSelect
                        list={
                            weekEndingDatesList.map((doc) => ({
                                label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                value: doc.id,
                            }))
                        }
                        customStyle={{ width: "100%" }}
                        name={"week_ending_date"}
                        value={selectedWeekDate.id}
                        label={t("week_ending_date")}
                        placeholder={"Select"}
                        onChange={handleWeekEndChange}
                        isClearable={false}
                    />
                </div>
                <div className="search-bar">
                    <SearchBar value={search} onChange={handleSearch} onSearch={() => { }} placeholder="Search..." />
                </div>
            </div>
            <div className="rd-content">
                <div className="rd-table">
                    {reportsDirectoryListState && reportsDirectoryListState.loading === LoadingType.pending ?
                        (<>
                            <SpinnerScreen></SpinnerScreen></>)
                        :
                        (<SortableTable
                            headerList={tableHeader}
                            sortedField={sortedField}
                            onSortChange={handleSortFieldChange}
                            flexNumber={getFlexNumber}
                            isAsc={sortDirection}
                        >
                            {getFilteredList().length > 0 ?
                                <>
                                    {getFilteredList().map((doc, index) => {
                                        return (
                                            <tr key={index + "id"}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.mapping_group}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{doc.description}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{doc.file_name}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span style={{ color: doc.status === ImportExportFileStatus.COMPLETED ? THEME.statusActiveColor : THEME.statusWarningColor }}>{doc.status}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                                    <div className="col-row">
                                                        <div>
                                                            <CustomButton
                                                                textStyle={{ textTransform: 'capitalize' }}
                                                                enable={doc.status === ImportExportFileStatus.COMPLETED}
                                                                leftIcon={<div><DownloadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                                                                name={t("download")}
                                                                backgroundColor={THEME.buttonColor1}
                                                                onClick={() => (handleDownload(doc.file_path, index))}
                                                                loading={index === dwnldBtnIndex && fileDownloadState.loading === LoadingType.pending}
                                                            />
                                                        </div>
                                                        <div>
                                                            {actionType === ActionType.EXPORT && doc.mapping_group === MappingGroup.MASTER_TAX_WEEKLY_PAYROLL_TAX_SUMMARY &&
                                                                <CustomButton
                                                                    textStyle={{ textTransform: 'capitalize' }}
                                                                    leftIcon={<div><UploadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                                                                    name={t("upload_master_tax")}
                                                                    backgroundColor={THEME.buttonColor1}
                                                                    onClick={() => hanldeUpload(index, "pts")}
                                                                    customClassName="um-btn"
                                                                    loading={index === uploadBtnIndex && reportsDirectoryListState.uploadMT.loading === LoadingType.pending}
                                                                />
                                                            }
                                                            {actionType === ActionType.EXPORT && doc.mapping_group === MappingGroup.MASTER_TAX_RECONCILIATION_TAX_SUMMARY &&
                                                                <CustomButton
                                                                    textStyle={{ textTransform: 'capitalize' }}
                                                                    leftIcon={<div><UploadIcon style={{ height: "1vw", width: "1vw" }} /></div>}
                                                                    name={t("upload_master_tax")}
                                                                    backgroundColor={THEME.buttonColor1}
                                                                    onClick={() => hanldeUpload(index, "rts")}
                                                                    customClassName="um-btn"
                                                                    loading={index === uploadBtnIndex && reportsDirectoryListState.uploadMT.loading === LoadingType.pending}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </TableData>
                                            </tr>
                                        );
                                    })}
                                </>
                                :
                                <tr>
                                    <TableData customStyle={{ flex: getFlexNumber(20) }} align="center" >
                                        <div className="na_div" style={{ width: "100%", fontSize: "1vw", textAlign: "center" }}>
                                            {t("no_reports_available")}
                                        </div>
                                    </TableData>
                                </tr>}
                        </SortableTable>)
                    }
                </div>
            </div>

        </div>
    );
}

export default IEReportsDirectoryPage;