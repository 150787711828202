import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionDialogHolder,
  ActionDialogHolderType,
} from "../../components/action_dialog_holder/action_dialog_holder";
import { THEME } from "../../enums";
import { CustomButton } from "../../utils";
import './delete_confirmation_dialog.scss';
import { DeleteIcon } from "../../icons";
import { FormDialogTilteHeader } from "../../components/dialog_wrapper/dialog_wrapper";

export interface DeleteConfirmationDialogProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  deleting: boolean;
  deleteType: string;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = (
  props: DeleteConfirmationDialogProps
) => {
  const { message, onCancel, onConfirm, deleting, deleteType } = props;
  const { t } = useTranslation();
  const actionRef = useRef<ActionDialogHolderType>(null);

  function closePopup(action?: () => void) {
    if (actionRef && actionRef.current) actionRef.current.closeAction(action);
  }

  return (
    <ActionDialogHolder
      ref={actionRef}
      visible
      wrapperWidth={"40%"}
      maxWrapperWidth={"40%"}
      onClose={onCancel}
      onDissmiss={onCancel}
    >
      <div className="common-dialog-delete-container" style={{ height: "150px" }}>
        <div className="delete-dialog-header">
          {/* <span style={{ color: THEME.red }}>
            {t("delete_field", { name: deleteType })}
          </span> */}
          <FormDialogTilteHeader
            title={t("delete_field", { name: deleteType })}
            titleIcon={<DeleteIcon width={"1vw"} height={"1vw"} />}
          />
        </div>
        <div className="delete-dialog-content">
          <p>{message}</p>
        </div>
        <div className="delete-dialog-footer">
          <div className="btn-cancel">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("no")}
              enable={true}
              onClick={() => closePopup(onCancel)}
            />
          </div>
          <div className="btn-save">
            <CustomButton
              textStyle={{ textTransform: "capitalize" }}
              name={t("yes")}
              enable={true}
              backgroundColor={""}
              onClick={() => closePopup(onConfirm)}
              loading={deleting}
            />
          </div>
        </div>
      </div>
    </ActionDialogHolder>
  );
};
