import { Dispatch, AnyAction } from '@reduxjs/toolkit'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionDialogHolder, ActionDialogHolderType } from '../../../../components/action_dialog_holder/action_dialog_holder'
import ApiError from '../../../../components/api_error'
import { THEME, LoadingType } from '../../../../enums'
import { CustomButton } from '../../../../utils'
import { AppError } from '../../../../interfaces'

interface Props {
    visible?: boolean;
    state: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string;
    };
    onClose: () => void;
    handleClearError: () => void;
    onConfirm: () => void;
    onDissmiss: () => void;
    reportName: string;
}

const DeleteReportPopup: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { state, onClose, handleClearError, onConfirm, onDissmiss, visible, reportName } = props
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [sentRequest, setSentRequest] = useState(false);
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    useEffect(() => {
        if (state && state?.loading === LoadingType.succeeded && sentRequest) {
            setSentRequest(false);
            closePopup(onClose);
        }
    }, [state])
    return (
        <div>
            <ActionDialogHolder
                ref={actionRef}
                visible={visible!}
                wrapperWidth={430}
                onClose={onDissmiss}
                onDissmiss={onDissmiss}
            >
                <div className='del-doc-container'>
                    <div className="del-role-container">
                        <div className="del-role-header">
                            <div className="title-txt">
                                <span>{`${t('delete_work_comp_audit_and_compliance_reporting')}`}</span>
                            </div>
                        </div>
                        <div className="del-role-content">
                            <div className="message-txt">
                                <div className='del-msg-text'>
                                    <span>{t('delete_work_comp_audits_and_compliance_reporting_message',{report_name: reportName})}&nbsp;</span>
                                </div>
                            </div>
                            {state.error && <div className={"error-section"}>
                                <ApiError message={state?.error?.message} onClose={handleClearError} />
                            </div>}
                        </div>
                        <div className="del-role-action-holder">
                            <div className="del-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.secondaryColor4}
                                        onClick={onClose}
                                    />
                                </div>
                                <div className="password-update-dialog-footer">
                                    <CustomButton
                                        loading={state?.loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        enable={true}
                                        name={t("delete")}
                                        backgroundColor={THEME.red}
                                        onClick={onConfirm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ActionDialogHolder>
        </div>
    )
}

export default DeleteReportPopup
