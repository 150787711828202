import { useEffect, useRef, useState } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { BOOpenCloseIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, FormInput } from "../../../../utils";
import './create_batch.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError, ICreateBatch, ICreateBatchResponse, IWeekEndingDatesDropdown } from "../../../../interfaces";
import { getTimeCardsListThunk, useAppDispatch } from "../../../../redux/store";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { convertDateToTimeStamp, getDateString } from "../../../../variables";

interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onSuccessClose: () => void,
    batchNum: string | undefined;
    createState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: ICreateBatchResponse | null;
    },
    addTimecardsState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    } | null,
    weekEndingDatesList: IWeekEndingDatesDropdown[];
    handleCreateBatch: (doc: ICreateBatch) => void;
    currentLoginUserName: string;
}

const CreateBatchPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        createState,
        weekEndingDatesList,
        handleCreateBatch,
        currentLoginUserName,
        onSuccessClose
    } = props;
    const { t } = useTranslation();

    const actionRef = useRef<ActionDialogHolderType>(null);
    const dispatch = useAppDispatch();

    const initialForm = {
        week_accounting_id: weekEndingDatesList[0].id ?? "",
        batch_type: "Time Card Entry",
        description: "",
        created_date: convertDateToTimeStamp(new Date()),
    }
    const [createFormState, setCreateFormState] = useState<ICreateBatch>(initialForm);
    const handleOnChange = (id: string, val: string) => {
        setCreateFormState({ ...createFormState, [id]: val })
    };
    const [createBatch, setCreateBatch] = useState<{ id: string, number: string }>({ id: "", number: "" });
    useEffect(() => {
        if (createState.loading === LoadingType.succeeded && createState.response) {
            setCreateBatch({ id: createState.response?.batch_id, number: createState.response?.batch_number });
        }
    }, [createState, createState.loading]);

    const [addTimeCards, setAddTimecards] = useState<boolean>(false);
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"45%"}
            onClose={onClose}
            onDissmiss={onClose}
        >
            <DialogWrapper onClose={onClose}>
                <div className="create-batch-container">
                    <div className="create-batch-header">
                        <FormDialogTilteHeader
                            title={title}
                            titleIcon={<BOOpenCloseIcon width={"100%"} height={"100%"} />}
                        />
                    </div>
                    <div className="create-batch-content">

                        {createState && createState.loading === LoadingType.succeeded ?
                            <div className="saved-doc-holder">
                                <DocSavedText>
                                    <div>
                                        <span className="aknw-name">
                                            {t("create_batch_success", { name: currentLoginUserName, number: createBatch.number })}
                                        </span>
                                    </div>
                                </DocSavedText>
                            </div>
                            :
                            <div className="create-batch-content">
                                <div className="create-batch-form">
                                    <div className="create-form-row">
                                        <div className="cform-input-holder">
                                            <FormInput
                                                id={"batch_type"}
                                                onChange={(id, val) => handleOnChange(id, val)}
                                                type={"text"}
                                                value={createFormState.batch_type}
                                                label={t("batch_type")}
                                                placeholder=""
                                                required={true}
                                            // customStyle={{ minWidth: "15vw" }}
                                            />
                                        </div>
                                        <div className="cform-input-holder">
                                            <FormInput
                                                id={"created_date"}
                                                onChange={(id, val) => handleOnChange(id, val)}
                                                type={"text"}
                                                value={getDateString(createFormState.created_date)}
                                                label={t("created_date")}
                                                placeholder=""
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="cform-input-holder">
                                            <CustomFormSelect
                                                list={
                                                    weekEndingDatesList.map((doc) => ({
                                                        label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
                                                        value: doc.id,
                                                    }))
                                                }
                                                name={"week_ending_date"}
                                                value={createFormState.week_accounting_id}
                                                label={t("week_ending_date")}
                                                placeholder={"Select"}
                                                onChange={(val) => handleOnChange("week_accounting_id", val)}
                                                required={true}

                                            />
                                        </div>
                                    </div>
                                    <div className="create-form-row1">
                                        <div>
                                            <FormInput
                                                id={"description"}
                                                onChange={(id, val) => handleOnChange(id, val)}
                                                type={"text"}
                                                value={createFormState.description}
                                                label={t("description")}
                                                placeholder=""
                                                customStyle={{ width: "100%" }}
                                                multiline={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {createState.error &&
                            <div className="saved-doc-holder error-holder">
                                <DocSavedText>
                                    <div className="error-title">{t("Warning")}</div>
                                    <div>
                                        <span className="aknw-name">
                                            {createState.error.message.msg}
                                        </span>
                                    </div>
                                </DocSavedText>
                            </div>
                        }
                    </div>

                    <div className="create-batch-actions">
                        <div>
                            {createState && createState.loading === LoadingType.succeeded ?
                                (<div className="btn-save">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: "capitalize" }}
                                        name={t("ok")}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={onSuccessClose}

                                    />
                                </div>)
                                :
                                (
                                    <div className="actions-row">
                                        <div className="btn-cancel" style={{ marginRight: "1em" }}>
                                            <CustomButton
                                                loading={false}
                                                textStyle={{ textTransform: "capitalize" }}
                                                name={t("cancel")}
                                                enable={true}
                                                backgroundColor={THEME.toggleDisableColor}
                                                onClick={onClose}
                                            />
                                        </div>
                                        <div className="btn-save">
                                            <CustomButton
                                                loading={createState.loading === LoadingType.pending}
                                                textStyle={{ textTransform: "capitalize" }}
                                                name={t("create")}
                                                enable={createFormState.batch_type !== "" && createFormState.week_accounting_id !== ""}
                                                backgroundColor={THEME.defaultHighLightColor}
                                                onClick={() => handleCreateBatch(createFormState)}
                                            />
                                        </div>
                                    </div>

                                )
                            }

                        </div>
                    </div>
                </div >
            </DialogWrapper >

        </ActionDialogHolder >
    );
}

export default CreateBatchPopup;