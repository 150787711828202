import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateInput } from "../../../../components/date_input/date_input";
import CustomFormSelect from "../../../../components/form_selector/form_select";
import { IWeekEndingDatesDropdown, PayrollBatch } from "../../../../interfaces";
import { AdminCenterBackOfficeType } from "../../../../interfaces/admin_center_back_office_type";
import { convertDateToTimeStamp, getDateString } from "../../../../variables";
import { FormInput } from "../../../../utils";

interface Props {
  runTypeList: AdminCenterBackOfficeType[];
  bankAccountList: AdminCenterBackOfficeType[];
  weekEndingDatesList: IWeekEndingDatesDropdown[];
  selectedWeekEndingDate: string;
  updateBatchProp: (key: string, value: any) => void;
  batch: PayrollBatch;
}
export const CreatePayrollStepOne = (props: Props) => {
  const {
    bankAccountList,
    batch,
    runTypeList,
    selectedWeekEndingDate,
    weekEndingDatesList,
    updateBatchProp,
  } = props;
  const { t } = useTranslation();
  const [selectedWeekDate, setSelectedWeekDate] = useState<{
    id: string;
    date: string;
  }>({ id: "", date: "" });

  const handleWeekEndChange = (val: string) => {
    const selectedWeekObject = weekEndingDatesList.find(
      (obj) => obj.id === val
    );
    setSelectedWeekDate({
      id: val,
      date: getDateString(selectedWeekObject?.week_ending_date, "mm/dd/yyyy"),
    });
    updateBatchProp("week_accounting_id", val);
  };

  const handleKeyDown = (event) => {
    if (event.key === "t" || event.key === "T") {
      event.preventDefault();
      updateBatchProp(
        "check_date",
        convertDateToTimeStamp(new Date()).toString()
      );
    }
  };
  return (
    <div className="create-payroll-batch-step-one">
      <div>
        <CustomFormSelect
          list={weekEndingDatesList.map((doc) => ({
            label: getDateString(doc.week_ending_date, "mm/dd/yyyy"),
            value: doc.id,
          }))}
          customStyle={{ width: "100%" }}
          name={"week_ending_date"}
          value={batch.week_accounting_id}
          label={t("week_ending_date")}
          placeholder={"Select"}
          onChange={handleWeekEndChange}
          isClearable={false}
        />
        <DateInput
          id={"check_date"}
          label={t("check_date")}
          onChange={updateBatchProp}
          onKeyDown={handleKeyDown}
          placeholder={""}
          value={batch.check_date}
        />
        <CustomFormSelect
          list={bankAccountList.map((doc) => ({
            label: `${doc.bank_name} - ${doc.purpose}`,
            value: doc.id,
          }))}
          customStyle={{ width: "100%" }}
          name={"bank_id"}
          value={batch.bank_id}
          label={t("Bank")}
          placeholder={"Select"}
          onChange={(id) => updateBatchProp("bank_id", id)}
          isClearable={false}
        />
        <CustomFormSelect
          list={runTypeList.map((doc) => ({
            label: doc.title,
            value: doc.id,
          }))}
          customStyle={{ width: "100%" }}
          name={"run_type_id"}
          value={batch.run_type_id}
          label={t("run_type")}
          placeholder={"Select"}
          onChange={(id) => updateBatchProp("run_type_id", id)}
          isClearable={false}
        />
      </div>
      <div className="s1-col2">
        <div className="cpb1-form-input">
          <FormInput
            id={"check_message"}
            onChange={updateBatchProp}
            required={false}
            placeholder={"Enter a message here which will appear on checks (optional)"}
            type={"text"}
            multiline={true}
            value={batch.check_message}
            numberOfLines={3}
            // fieldStyles={{
            //   marginLeft: "5em",
            //   width: "500px"
            // }}
            label={t("check_message")}
          />
        </div>
        <div className="cpb1-form-input">
          <FormInput
            id={"batch_description"}
            onChange={updateBatchProp}
            required={false}
            placeholder={"Enter a descrition for this batch (optional)"}
            type={"text"}
            multiline={true}
            value={batch.batch_description}
            label={t("batch_description")}
            numberOfLines={3}
          // fieldStyles={{
          //   marginLeft: "5em ",
          // }}
          />
        </div>
      </div>
    </div>
  );
};
