import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, THEME } from '../../../../enums';
import { CalenderIcon, PaperPlaneIcon } from '../../../../icons';
import { sendInterviewCalendarInviteStateActions } from '../../../../redux/dashboard/metrics/send_interview_calendar_invite/send_interview_calendar_redux';
import { useAppDispatch, useAppSelector, postSnackbarMessage, selectInterviewCalendarInviteSaveState, saveInterviewCalendarInviteThunk, updateInterviewCalendarInviteThunk, selectInterviewCalendarInviteUpdateState, getInterviewCalendarInviteThunk } from '../../../../redux/store';
import './send_interview_calendar_invite.scss';
import { TimeInput } from '../../../../components/time_input/time_input';
import { CustomButton, FormInput } from '../../../../utils';
import { DateInput } from '../../../../components/date_input/date_input';
import { convertDateToTimeStamp, formatAMPM, getDateFromTimeStamp, getDateString } from '../../../../variables';
import moment from 'moment';
import { getOnlineApplicationsReceivedList } from '../../../../redux/dashboard/metrics/online_applications_received_list/online_applications_received_list_thunk';
import { OnlineApplicationReceived } from '../../../../interfaces/online_application_received';
import { IInterviewCalendarInvite } from '../../../../interfaces';
import TimePickerComponent from '../../../../components/time_picker/time_picker';
import { Timer } from '@material-ui/icons';

interface Props {
    setShowInvite: any,
    data: OnlineApplicationReceived | IInterviewCalendarInvite,
    closePopup: any,
    isReschedule?: boolean,
}

const SendInterviewCalendarInvitePopup: React.FunctionComponent<Props> = (props) => {
    const {
        setShowInvite,
        data,
        closePopup,
        isReschedule
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sendInterviewCalendarInviteSaveState = useAppSelector((state) => selectInterviewCalendarInviteSaveState(state))
    const sendInterviewCalendarInviteUpdateState = useAppSelector((state) => selectInterviewCalendarInviteUpdateState(state))
    const [message, setMessage] = useState(null);
    const [inviteDate, setInviteDate] = useState(null);
    const [inviteTime, setInviteTime] = useState(null);

    useEffect(() => {
        if (sendInterviewCalendarInviteSaveState.loading == LoadingType.succeeded || sendInterviewCalendarInviteUpdateState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(sendInterviewCalendarInviteSaveState.response || sendInterviewCalendarInviteUpdateState.response || null));
            dispatch(sendInterviewCalendarInviteStateActions.clearSaveResponse());
            dispatch(sendInterviewCalendarInviteStateActions.clearUpdateResponse());
            if (isReschedule)
                dispatch(getInterviewCalendarInviteThunk({ this_week: true, get_count: false, completed: false }));
            else
                dispatch(getOnlineApplicationsReceivedList());
            setShowInvite(false);
            closePopup();
        }

    }, [sendInterviewCalendarInviteSaveState.loading, sendInterviewCalendarInviteUpdateState.loading])

    const getError = () => {
        return sendInterviewCalendarInviteSaveState.error;
    }

    const handleClearTime = () => {
        setInviteTime(null)
    }

    return (
        <div className="interview-dialog-container">
            <div className="interview-dialog-header">
                <div className="left-fields">
                    {isReschedule ? <CalenderIcon /> : <PaperPlaneIcon />}
                    <span><h4>{t(isReschedule ? "change_interview_date" : "send_calendar_invite")}</h4></span>
                </div>
            </div>
            <div className="interview-dialog-content">
                <p className="ac-header-text"> {t(isReschedule ? "please_select_new_date_time" : "please_select_invite_date_time")}: </p>
                <div className='ac-eng-field-row'>
                    <div className='ac-eng-fields'>
                        <DateInput
                            id={'date'}
                            onChange={(fieldId, value) => {
                                setInviteDate(value);
                            }}
                            required={true}
                            placeholder={''}
                            value={inviteDate}
                            label={t('date')}
                        />

                    </div>
                    <div className='ac-eng-fields-sci-time' style={{ width: "30%" }}>
                        {/* <TimeInput
                            id={'time'}
                            onChange={(fieldId, value) => {
                                setInviteTime(value);
                            }}
                            required={true}
                            placeholder={''}
                            value={inviteTime}
                            label={t('time')}
                            isDateTime
                        /> */}
                        <TimePickerComponent
                            id={"schedule_time"}
                            onChange={(fieldId, value) => {
                                setInviteTime(value);
                            }}
                            placeholder={''}
                            value={inviteTime}
                            icon={<Timer />}
                            label={t("time")}
                            onClearTime={() => handleClearTime()}
                            required
                        />
                    </div>

                </div>
                {!isReschedule &&
                    <div className='ac-eng-field-row'>
                        <div className='ac-sc-desc-fields'>
                            <FormInput
                                id={'message'}
                                onChange={(field: any, value: any) => {
                                    setMessage(value);
                                }}
                                multiline={true}
                                required={false}
                                placeholder={""}
                                type={"text"}
                                value={message}
                                label={t("message")}
                            />
                        </div>

                    </div>
                }
            </div>
            <div className={"interview-dialog-footer"}>
                <div className="cmpny-op-f-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('cancel')}
                            enable={true}
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                if (getError()) handleClearError();
                                setShowInvite(false);
                                closePopup();
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={sendInterviewCalendarInviteSaveState.loading === LoadingType.pending || sendInterviewCalendarInviteUpdateState.loading === LoadingType.pending}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('send')}
                            enable={inviteDate != null && inviteTime != null}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleOnSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    function handleOnSubmit() {
        const dateTime: any = inviteDate ? getDateFromTimeStamp(inviteDate) : "";
        const time = inviteTime ? inviteTime : "";
        // const time = inviteTime ? formatAMPM(getDateFromTimeStamp(inviteTime)) : "";
        var momentObj = moment(moment(new Date(dateTime)).format("YYYY-MM-DD") + " " + time, 'YYYY-MM-DDLT');
        var calendarInvite = momentObj.format('MM-DD-YYYY HH:mm:s');

        const payload: any = {
            id: isReschedule ? data.id : null,
            date_time: convertDateToTimeStamp(new Date(calendarInvite), false),
            message: message,
            talent_online_application_id: isReschedule ? (data as IInterviewCalendarInvite).talent_online_application_id : data.id,
            branch_id: data.branch_id,
            agency_id: data.agency_id,
            is_completed: false
        }
        dispatch(isReschedule ? updateInterviewCalendarInviteThunk(payload) : saveInterviewCalendarInviteThunk(payload));
    }

    function handleClearError() {
        dispatch(sendInterviewCalendarInviteStateActions.clearSaveResponse());
    }
}

export default SendInterviewCalendarInvitePopup;