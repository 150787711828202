import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import { OnlineApplicationReceived } from "../../../../interfaces/online_application_received";
import * as thunk from "./online_applications_received_list_thunk";

export interface OnlineApplicationReceivedListState
  extends EntityState<OnlineApplicationReceived> {
  error?: AppError | null;
  loading: LoadingType;
  countState: {
    error?: AppError | null;
    loading: LoadingType;
    response?: number,
  }
}

const onlineApplicationReceivedListAdapter: EntityAdapter<OnlineApplicationReceived> =
  createEntityAdapter<OnlineApplicationReceived>({
    selectId: (doc) => doc.id,
  });

const initialState: OnlineApplicationReceivedListState =
  onlineApplicationReceivedListAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    countState: {
      error: null,
      loading: LoadingType.idle,
    }
  });

export const onlineApplicationReceivedListSlice = createSlice({
  name: "onlineApplicationReceivedList",
  initialState: initialState,
  reducers: {
    clearOnlineApplicationReceivedListError(state) {
      return { ...state, error: null };
    },
    clearOnlineApplicationReceivedListState(state) {
      return onlineApplicationReceivedListAdapter.removeAll({
        ...state,
        loading: LoadingType.idle,
        error: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunk.getOnlineApplicationsReceivedList.pending,
        (state, action) => ({
          ...state,
          loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
          error: null,
        })
      )
      .addCase(
        thunk.getOnlineApplicationsReceivedList.rejected,
        (state, action) => ({
          ...state,
          loading: LoadingType.failed,
          error: action.payload,
        })
      )
      .addCase(
        thunk.getOnlineApplicationsReceivedList.fulfilled,
        (state, action) =>
          onlineApplicationReceivedListAdapter.setAll(
            { ...state, loading: LoadingType.succeeded, error: null },
            action.payload
          )
      )


      .addCase(
        thunk.getOnlineApplicationsReceivedCount.pending,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: state.ids.length === 0 ? LoadingType.pending : state.loading,
            error: null,
          }
        })
      )
      .addCase(
        thunk.getOnlineApplicationsReceivedCount.rejected,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: LoadingType.failed,
            error: action.payload,
          }

        })
      )
      .addCase(
        thunk.getOnlineApplicationsReceivedCount.fulfilled,
        (state, action) => ({
          ...state,
          countState: {
            ...state.countState,
            loading: LoadingType.succeeded,
            response: action.payload,
          }

        })
      )
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const {
  clearOnlineApplicationReceivedListError,
  clearOnlineApplicationReceivedListState,
} = onlineApplicationReceivedListSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } =
  onlineApplicationReceivedListAdapter.getSelectors();
export const selectState = (state: OnlineApplicationReceivedListState) => state;
export const selectLoading = (state: OnlineApplicationReceivedListState) =>
  state.loading;
export const selectError = (state: OnlineApplicationReceivedListState) =>
  state.error;

  export const selectCountState = (state: OnlineApplicationReceivedListState) => state.countState
