import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { RouteComponentProps } from 'react-router-dom';
import StateChangeHandler from '../../../../../components/state_change_handler/state_change_handler';
import CurveTabNavigation from '../../../../../components/curve_tab_navigation/curve_tab_navigation';
import { LoadingType } from '../../../../../enums';
import { EditIcon, DocumentIcon, PayrollIcon } from '../../../../../icons';
import { ITalent } from '../../../../../interfaces';
import {
    getTalentById,
    selectTalentDetailsByIdState,
    postSnackbarMessage,
    selectSingleResumeFileStateById,
    getResumeFileUrlForDownload,
    selectTerminateTalentState,
    getTerminateTalent
} from '../../../../../redux/store';
import { useAppDispatch, useAppSelector, selectProfileState } from '../../../../../redux/store';
import { AppRoutes } from '../../../../../routes';
import { handleDownload } from '../../../../../utils';
import EmployeePortalDetailsSection from '../../../../../components/employee_portal_details_section/employee_portal_details_section'
import { manageTalentActions } from '../../../../../redux/talent/universal_directory/manage_talent/manage_talent_reducer';
import { getTrafficLightEngagementMetrics } from '../../../../../redux/admin_center';
import { talentRequestSSNActions } from '../../../../../redux/talent/details/dashboard/request_ssn/request_ssn_reducer';
import { resumeFileSliceActions } from '../../../../../redux/files/resume_files/resume_file_reducer';
import CreateTalentPopup from '../../../../talent/popups/create_talent/create_talent';
import ViewSSNPopup from '../../../../talent/popups/view_ssn/view_ssn';
import { PayrollDetails } from '../../../components/payroll_details/payroll_details';
import { EDocAndCertifications } from '../../../components/e_doc_and_certifications/e_doc_and_certifications';
import MarkEmployeeAvailablePopup from "../../../popups/mark_employee_available";
import './employee_portal_dashboard.scss';
import { clearTalentTerminationState, clearTalentTerminationStateError } from '../../../../../redux/talent/details/terminate_talent/terminate_talent_reducer';
import TerminateTalentPopup from '../../../../talent/popups/terminate_talent/terminate_talent';


interface Props extends RouteComponentProps<any> { }
const EmployeePortalDashboardPage: React.FunctionComponent<Props> = (props) => {
    const { history, location, match } = props;
    const { params } = match;
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));

    const currentLoginUserName = `${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`;

    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(params.id)(state));
    const terminateEmployeeState = useAppSelector((state) => selectTerminateTalentState(state));

    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [branchId, setBranchId] = useState<string | null>(null);
    const [editTalent, setEditTalent] = useState<{ visible: boolean, data: ITalent } | null>(null);

    const [showMarkTalentAvailable, setShowMarkTalentAvailable] = useState(false);
    const [terminateEmployeePopUp, setTerminateEmployeePopUp] = useState(false);

    /// [Talent] SSN

    const [showSSNPopup, setShowSSNPopup] = useState<{ talentId: string, visible: boolean } | null>(null);
    const resumeDownloadState = useAppSelector((state) => selectSingleResumeFileStateById(params.id)(state))
    useEffect(() => {
        if (resumeDownloadState.loading === LoadingType.succeeded && resumeDownloadState.response) handleDownload(resumeDownloadState.response);
        return () => { }
    }, [resumeDownloadState.loading])



    useEffect(() => {
        /// Get Talent Details
        getTalentDetails();
        return () => {
            dispatch(resumeFileSliceActions.clearResumeFileState(params.id))
        }
    }, [])

    function getTalentDetails() {
        if (params && params.id != null) {
            setBranchId(null);
            dispatch(getTalentById(params.id));
            dispatch(getTrafficLightEngagementMetrics());
            dispatch(talentRequestSSNActions.initialiseRequestSSN(params.id));
        }

    }

    function handleBranchChange(value: string) {
        if (value != '') {
            history.push({
                pathname: `${location.pathname}/${value}`,
                state: {
                    ...(location.state as any),
                    departmentId: value,
                    departmentName: value,
                }
            });
        } else {
            history.push({
                pathname: `${AppRoutes.companiesDetailsPage}/${params.id}/dashboard`,
                state: {
                    ...(location.state as any),
                    departmentId: null,
                    departmentName: null,
                }
            });
        }

    }

    /// [Navigation update when details edited]
    useEffect(() => {
        if (talentState.loading === LoadingType.succeeded) {
            updateNavigationNames();
        }
        return () => { }
    }, [talentState.loading])


    function updateNavigationNames() {
        if (params) {
            if (params.id != null && talentState.data != null) {
                const talent = talentState.data;
                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...(location.state as any),
                        name: `${talent.first_name ?? ""} ${talent.middle_name ?? ""} ${talent.last_name ?? ""}`,
                    }
                });
            }

        }

    }
    function hanleOnResume(path: string) {
        dispatch(getResumeFileUrlForDownload({ id: params.id, file_path: path }));
    }

    const handleTerminateEmployee = (selectedDate: number) => {
        if (params.id && selectedDate) {
            dispatch(getTerminateTalent({ talent_id: params.id, termination_date: selectedDate }))
        }
    }

    const handleClearError = () => {
        dispatch(clearTalentTerminationStateError())
    }
    const handleCloseSuccess = () => {
        setTerminateEmployeePopUp(false)
        dispatch(clearTalentTerminationState())
        dispatch(clearTalentTerminationStateError())
    }


    return (
        <StateChangeHandler
            loading={talentState.loading}
            error={talentState.error}
            onRefresh={getTalentDetails}
        >
            <div className="tdp-container epdp-container">
                <div className="tdp-left-panel">
                    <EmployeePortalDetailsSection
                        talent={talentState.data}
                        onActionClick={handleActionPress}
                        onBranchChange={handleBranchChange}
                        onSSNClick={handleOnSSNView}
                        onResumeClick={hanleOnResume}
                        isTalent={profileState.profile?.is_talent}
                    />
                </div>
                <div className="tdp-right-panel">
                    <CurveTabNavigation
                        tabs={getTabList()}
                        selected={tabIndex}
                        onChange={(value) => setTabIndex(value)}
                        actions={getEditAction()}
                        customStyle={{ height: 'fit-content' }}
                    />
                </div>
            </div>
            {editTalent && (
                <Portal>
                    <CreateTalentPopup
                        visible={editTalent.visible}
                        talent={editTalent.data}
                        title={t('edit_talent')}
                        successTitle={t('edit_talent')}
                        onClose={() => setEditTalent(null)}
                        onSuccessClose={handleUpdateTalentSuccess}
                        onGotoAgencyDashboard={() => { }}
                        onGotoCreated={() => { }}
                    />
                </Portal>
            )}
            {showMarkTalentAvailable && (
                <Portal>
                    <MarkEmployeeAvailablePopup
                        visible={showMarkTalentAvailable}
                        title={t("hi_who_would_you_like_to_mark_available")}
                        onClose={() => setShowMarkTalentAvailable(false)}
                        onDissmiss={() => setShowMarkTalentAvailable(false)}
                        onSuccessClose={() => setShowMarkTalentAvailable(false)}
                        talentId={params.id}
                        talentName={`${talentState?.data?.first_name ?? ""} ${talentState?.data?.middle_name ?? ""} ${talentState?.data?.last_name ?? ""}`}
                        profile={currentLoginUserName}
                    />
                </Portal>
            )}

            {showSSNPopup && (
                <Portal>
                    <ViewSSNPopup
                        visible={showSSNPopup.visible}
                        title={t('social_security_number')}
                        talentId={params.id}
                        onClose={() => setShowSSNPopup(null)}
                        onDissmiss={() => setShowSSNPopup(null)}
                        onSuccessClose={handleOnSuccessSSNClose}
                    />
                </Portal>
            )}
            {terminateEmployeePopUp && (
                <Portal>
                    <TerminateTalentPopup 
                        visible={terminateEmployeePopUp} 
                        headerTitle={'terminate_employee'}
                        state={terminateEmployeeState}
                        talentName={`${talentState.data?.first_name ?? ""} ${talentState.data?.middle_name ?? ""} ${talentState.data?.last_name ?? ""}`}
                        onClose={handleCloseSuccess}
                        onApprove={handleTerminateEmployee}
                        onClearError={handleClearError}
                    />
                </Portal>
            )}
        </StateChangeHandler>
    );

    function getTabList() {
        return [
            {
                title: t('payroll details').toUpperCase(),
                icon: PayrollIcon,
                content: <PayrollDetails
                    talentId={params.id}
                    history={history}
                />
            },
            {
                title: t('e-docs & certifications').toUpperCase(),
                icon: DocumentIcon,
                content: <EDocAndCertifications
                    talentState={talentState}
                    history={history}
                    location={location}
                    match={match} />
            },
        ];
    }

    /// Edit icon actions
    function getEditAction() {
        return (
            <button className="icon-btn tdp-edit-icon" onClick={handleEdit}>
                <EditIcon width={"100%"} height={"100%"} style={{ color: '#fff' }} />
            </button>
        );
    }


    function handleEdit() {
        setEditTalent({ visible: true, data: talentState.data! });
    }

    ///Add user action
    function handleUpdateTalentSuccess() {
        setEditTalent(null);
        getTalentDetails();
        dispatch(manageTalentActions.clearManageTalentState());
    }


    function handleActionPress(value: string) {

        if (value === "mark_talent_available") {
            setShowMarkTalentAvailable(true);
        }
        if (value === "send_message_to_emp" && talentState.data !== null) {
            history.push({
                pathname: `${AppRoutes.talentDetailsPage}/${talentState.data.id}/dashboard`,
                state: {
                  redirectFrom:"assignment",
                }
            })
        }
        if (value === "terminate_employee") {
            setTerminateEmployeePopUp(true);
        }
    }


    /// [SSN View]
    function handleOnSSNView() {
        if (profileState.profile && profileState.profile.show_ssn) {
            setShowSSNPopup({ visible: true, talentId: params.id });
        } else {
            dispatch(postSnackbarMessage(t('ssn_access_is_disabled')));
        }

    }

    function handleOnSuccessSSNClose() {
        setShowSSNPopup(null);
    }
}

export default EmployeePortalDashboardPage;