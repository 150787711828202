import { useEffect } from "react";
import { getRoleUsersList, postSnackbarMessage, selectRoleUsersList, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { getAgencyOnBoarding, selectAgencyOnboardingListState, selectOnboardingUpdatestate, updateAgencyOnboarding } from "../../../../../redux/admin_center";
import { LoadingType } from "../../../../../enums";
import { getAgencyOnboardingDataSlice } from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";
import HealthCareForm from "../forms/healthcare_form";
import { ErrorHandler, LoadingHandler } from "../../../../../components/error_handler/error_handler";

interface Props {}

const AdminCenterOnboardingHealthCare: React.FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const agencyOnboardingState = useAppSelector((state) =>
        selectAgencyOnboardingListState(state))
    const saveResponse = useAppSelector((state) =>
        selectOnboardingUpdatestate(state));
    const roleUsersList = useAppSelector((state) => selectRoleUsersList(state));

    useEffect(() => {
        getOnboardingData();
        return () => { };
    }, []);

    useEffect(() => {
        if (
            saveResponse.loading === LoadingType.succeeded &&
            saveResponse.response
        ) {
            dispatch(postSnackbarMessage(saveResponse.response ?? null));
            dispatch(getAgencyOnboardingDataSlice.actions.clearUpdateState());
        }
    }, [saveResponse.loading]);

    const getOnboardingData = () => {
        dispatch(getRoleUsersList());
        dispatch(getAgencyOnBoarding());
    }

    if (agencyOnboardingState?.loading === LoadingType.pending) {
        return (
            <div>
                <LoadingHandler />
            </div>
        );
    }

    if (agencyOnboardingState.error) {
        return <ErrorHandler error={agencyOnboardingState?.error} onRefresh={getOnboardingData} />;
    }

    return (
        <>
            <HealthCareForm
                roleUsersList={roleUsersList}
                data={agencyOnboardingState?.agencyOnboardingData}
                saveResponse={saveResponse}
            />
        </>
    )
}

export default AdminCenterOnboardingHealthCare