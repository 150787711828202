import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, SuccessProfileQuestionStep } from "../../../../interfaces";
import * as thunk from "./success_profile_question_steps_thunk";

export interface SuccessProfileQuestionStepState extends EntityState<SuccessProfileQuestionStep> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const successProfileQuestionStepAdapter: EntityAdapter<SuccessProfileQuestionStep> = createEntityAdapter<SuccessProfileQuestionStep>({
    selectId: (doc) => doc.id
})

const initialState: SuccessProfileQuestionStepState = successProfileQuestionStepAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const successProfileQuestionStepSlice = createSlice({
    name: 'successProfileQuestionStep',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getSuccessProfileQuestionSteps.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getSuccessProfileQuestionSteps.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getSuccessProfileQuestionSteps.fulfilled, (state, action) => successProfileQuestionStepAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createSuccessProfileQuestionStep.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createSuccessProfileQuestionStep.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createSuccessProfileQuestionStep.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteSuccessProfileQuestionStep.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteSuccessProfileQuestionStep.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteSuccessProfileQuestionStep.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateSuccessProfileQuestionStep.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateSuccessProfileQuestionStep.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateSuccessProfileQuestionStep.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = successProfileQuestionStepSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = successProfileQuestionStepAdapter.getSelectors();
export const selectState = (state: SuccessProfileQuestionStepState) => state;
export const selectLoading = (state: SuccessProfileQuestionStepState) => state.loading;
export const selectError = (state: SuccessProfileQuestionStepState) => state.error;


