import { TFunction } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { THEME } from "../enums";
import { AppRoutes } from "./routes";
import * as icons from "../icons";

/// Admin center
export const getAdminCenterNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;
  switch (location.pathname) {
    case AppRoutes.adminCenterPage:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [{ title: t("admin_center"), route: AppRoutes.adminCenterPage }],
        icon: icons.AdminCenterIcon,
      };
    /// [User management --->]
    case AppRoutes.adminCenterUserManagement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("user_management"),
            route: AppRoutes.adminCenterUserManagement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case `${AppRoutes.adminCenterUserManagement}/${id}`:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("user_management"),
            route: AppRoutes.adminCenterUserManagement,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.adminCenterUserManagement}/${id}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.AdminCenterIcon,
      };

    /// [Team management --->]
    case AppRoutes.adminCenterTeamManagement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("team_management"),
            route: AppRoutes.adminCenterTeamManagement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case `${AppRoutes.adminCenterTeamManagement}/${id}`:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("team_management"),
            route: AppRoutes.adminCenterTeamManagement,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.adminCenterTeamManagement}/${id}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [System configuration --->]
    case AppRoutes.adminCenterSystemConfiguration:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationSkillSets:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("skill_sets"),
            route: AppRoutes.systemConfigurationSkillSets,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Role Management --->]
    case AppRoutes.adminCenterRoleManagement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementAdministrator:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          {
            title: t("administrator"),
            route: AppRoutes.roleManagementAdministrator,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementSuperUser:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          { title: t("super_user"), route: AppRoutes.roleManagementSuperUser },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementBranchManager:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          {
            title: t("branch_manager"),
            route: AppRoutes.roleManagementBranchManager,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementAccountManager:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          {
            title: t("account_manager"),
            route: AppRoutes.roleManagementAccountManager,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementRecruiter:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          { title: t("recruiter"), route: AppRoutes.roleManagementRecruiter },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.roleManagementCallDeckOwner:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("role_management"),
            route: AppRoutes.adminCenterRoleManagement,
          },
          {
            title: t("call_deck_owner"),
            route: AppRoutes.roleManagementCallDeckOwner,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationSystemWide:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("system_wide"),
            route: AppRoutes.systemConfigurationSystemWide,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationSalesAndMarketing:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("sales_and_marketing"),
            route: AppRoutes.systemConfigurationSalesAndMarketing,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationContacts:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("contacts"),
            route: AppRoutes.systemConfigurationContacts,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationTrafficLightEngagementLight:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("dashboard"),
            route: AppRoutes.systemConfigurationTrafficLightEngagementLight,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationJobs:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          { title: t("jobs"), route: AppRoutes.systemConfigurationJobs },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationAssignments:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("assignments"),
            route: AppRoutes.systemConfigurationAssignments,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationTalent:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          { title: t("talent"), route: AppRoutes.systemConfigurationTalent },
        ],
        icon: icons.AdminCenterIcon,
      };

    case AppRoutes.systemConfigurationRiskManagement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("risk_management"),
            route: AppRoutes.systemConfigurationRiskManagement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationOnboardingWOTCandESC:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("onboarding"),
            route: AppRoutes.systemConfigurationOnboardingWOTCandESC,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationOnboardingWelcomeVideo:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
          {
            title: t("onboarding"),
            route: AppRoutes.systemConfigurationOnboardingWelcomeVideo,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationOnboardingCompany:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },

          {
            title: t("onboarding"),
            route: AppRoutes.systemConfigurationOnboardingCompany,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationOnboardingPaperWork:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },

          {
            title: t("onboarding"),
            route: AppRoutes.systemConfigurationOnboardingPaperWork,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.systemConfigurationOnboardingHealthCare:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("system_configuration"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },

          {
            title: t("onboarding"),
            route: AppRoutes.systemConfigurationOnboardingHealthCare,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.adminCenterSecurityPolicy:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("security_policy"),
            route: AppRoutes.adminCenterSecurityPolicy,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.adminCenterAgencySetup:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          { title: t("agency_setup"), route: AppRoutes.adminCenterAgencySetup },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Payroll Tax Configuration --->]
    case AppRoutes.adminCenterPayrollTaxConfiguration:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("payroll_tax_configuration"),
            route: AppRoutes.adminCenterPayrollTaxConfiguration,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Sales Tax Configuration --->]
    case AppRoutes.adminCenterSalesTaxConfiguration:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("sales_tax_configuration"),
            route: AppRoutes.adminCenterSalesTaxConfiguration,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Work Comp Configuration --->]
    case AppRoutes.adminCenterWorkCompConfiguration:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("work_comp_configuration"),
            route: AppRoutes.adminCenterWorkCompConfiguration,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Back Office --->]
    case AppRoutes.adminCenterBackOffice:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("back_office"),
            route: AppRoutes.adminCenterBackOffice,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.adminCenterInfoText:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          { title: t("info_text"), route: AppRoutes.adminCenterInfoText },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.adminCenterAgencyManagement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("agency_management"),
            route: AppRoutes.adminCenterAgencyManagement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case `${AppRoutes.adminCenterAgencyManagement}/${id}`:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("agency_management"),
            route: AppRoutes.adminCenterAgencyManagement,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.adminCenterAgencyManagement}/${id}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    //admin-center-report-center
    case AppRoutes.adminCenterReportCenter:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("report_center"),
            route: AppRoutes.adminCenterReportCenter,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.acRCVacationReport:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("report_center"),
            route: AppRoutes.acRCVacationReport,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    /// [Integration Partners --->]
    case AppRoutes.adminCenterIntegrations:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.integrationPartnersTextEmAll:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: t("text_em_all"),
            route: AppRoutes.integrationPartnersTextEmAll,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxCredentials:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("credentials")}`,
            route: AppRoutes.masterTaxCredentials,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxCompanySetup:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("company_setup")}`,
            route: AppRoutes.masterTaxCompanySetup,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxCompanyDisbursement:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("company_disbursement_setup")}`,
            route: AppRoutes.masterTaxCompanyDisbursement,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxPayrollCCSetup:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("payroll_cash_care_setup")}`,
            route: AppRoutes.masterTaxPayrollCCSetup,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxCompanyTaxSetup:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("company_tax_setup")}`,
            route: AppRoutes.masterTaxCompanyTaxSetup,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    case AppRoutes.masterTaxCompanyExport:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionActiveColor,
        path: [
          { title: t("admin_center"), route: AppRoutes.adminCenterPage },
          {
            title: t("integration_partners"),
            route: AppRoutes.adminCenterIntegrations,
          },
          {
            title: `${t("master_tax")} - ${t("export")}`,
            route: AppRoutes.masterTaxCompanyExport,
          },
        ],
        icon: icons.AdminCenterIcon,
      };
    default:
      return {
        backgroundColor: THEME.adminCenterOptionBackgroundColor,
        activeColor: THEME.adminCenterOptionTextColor,
        path: [{ title: t("admin_center"), route: AppRoutes.adminCenterPage }],
        icon: icons.AdminCenterIcon,
      };
  }
};

/// Client portal
export const getClientPortalNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  switch (location.pathname) {
    case AppRoutes.clientPortalPage:
      return {
        backgroundColor: THEME.clientPortalOptionBackgroundColor,
        activeColor: THEME.clientPortalOptionTextColor,
        path: [
          { title: t("client_portal"), route: AppRoutes.clientPortalPage },
        ],
        icon: icons.ClientportalIcon,
      };
    default:
      return {
        backgroundColor: THEME.clientPortalOptionBackgroundColor,
        activeColor: THEME.clientPortalOptionTextColor,
        path: [
          { title: t("client_portal"), route: AppRoutes.clientPortalPage },
        ],
        icon: icons.ClientportalIcon,
      };
  }
};

/// Employee portal
export const getEmployeePortalNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const talentId = state && state.id ? state.id : "";
  const name = state?.name ?? talentId;

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.employeePortalDetailsPage}/${talentId}/dashboard`
      )
    )
      return "dashboard";
  }

  switch (location.pathname) {
    case AppRoutes.employeePortalPage:
      return {
        backgroundColor: THEME.employeePortalOptionBackgroundColor,
        activeColor: THEME.employeePortalOptionTextColor,
        path: [
          { title: t("employee_portal"), route: AppRoutes.employeePortalPage },
        ],
        icon: icons.EmplportalIcon,
      };

    case `${AppRoutes.employeePortalDetailsPage}/${talentId}/${getPathname()}`:
      return {
        backgroundColor: THEME.employeePortalOptionBackgroundColor,
        activeColor: THEME.employeePortalOptionTextColor,
        path: [
          { title: t("employee_portal"), route: AppRoutes.employeePortalPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.employeePortalDetailsPage
                }/${talentId}/${getPathname()}`,
              state: {
                id: talentId,
                name: name,
              },
            },
          },
        ],
        icon: icons.EmplportalIcon,
      };

    default:
      return {
        backgroundColor: THEME.employeePortalOptionBackgroundColor,
        activeColor: THEME.employeePortalOptionTextColor,
        path: [
          { title: t("employee_portal"), route: AppRoutes.employeePortalPage },
        ],
        icon: icons.EmplportalIcon,
      };
  }
};

/// Backoffice portal
export const getBackOfficeNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;
  const value = state && state.value ? state.value : {};

  switch (location.pathname) {
    case AppRoutes.backOfficePage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [{ title: t("back_office"), route: AppRoutes.backOfficePage }],
        icon: icons.BackOfficeIcon,
      };
    /// [Weekly Accounting --->]
    case AppRoutes.weeklyAccountingPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("weekly_accounting"),
            route: AppRoutes.weeklyAccountingPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.weeklyAccountingDashboardPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("weekly_accounting"),
            route: AppRoutes.weeklyAccountingDashboardPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.weeklyAccountingErrorsReportPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("weekly_accounting"),
            route: AppRoutes.weeklyAccountingErrorsReportPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    // TimeCards
    case AppRoutes.timeCardsPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("timecards"),
            route: AppRoutes.timeCardsPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.timeCardsDashboard:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("timecards"),
            route: AppRoutes.timeCardsDashboard,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.timeCardsTab:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("timecards"),
            route: AppRoutes.timeCardsTab,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.timeCardsReport:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("timecards"),
            route: AppRoutes.timeCardsReport,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.timeCardsErrorReport:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("timecards"),
            route: AppRoutes.timeCardsErrorReport,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    //Payroll Page
    case AppRoutes.payrollPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("payroll"),
            route: AppRoutes.payrollPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.payrollDashboard:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("payroll"),
            route: AppRoutes.payrollDashboard,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.payrollPaycheckDirectory:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("payroll"),
            route: AppRoutes.payrollPaycheckDirectory,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.payrollErrorsReport:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("payroll"),
            route: AppRoutes.payrollErrorsReport,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.payrollTransactionSummary:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("payroll"),
            route: AppRoutes.payrollTransactionSummary,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    // Billing & Invoicing page
    case AppRoutes.billingInvoicesPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("billing_invoicing"),
            route: AppRoutes.billingInvoicesPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.billingInvoicesDashboard:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("billing_invoicing"),
            route: AppRoutes.billingInvoicesDashboard,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.billingInvoicesReport:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("billing_invoicing"),
            route: AppRoutes.billingInvoicesReport,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.billingInvoicesErrorsReport:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("billing_invoicing"),
            route: AppRoutes.billingInvoicesErrorsReport,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.billingInvoiceCorrection:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("billing_invoicing"),
            route: AppRoutes.billingInvoiceCorrection,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    // Accounts Recievable Page
    case AppRoutes.accountsReceivablePage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("accounts_receivable"),
            route: AppRoutes.billingInvoicesPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.accountsReceivableDashboardPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("accounts_receivable"),
            route: AppRoutes.accountsReceivableDashboardPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };

    //Import & Export
    case AppRoutes.boImportPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("import"),
            route: AppRoutes.boImportPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.boExportPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("export"),
            route: AppRoutes.boExportPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.boImportFile:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("import"),
            route: AppRoutes.boImportFile,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.boExportFile:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("export"),
            route: AppRoutes.boExportFile,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.importReportsDirectory:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("import"),
            route: AppRoutes.importReportsDirectory,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.exportReportsDirectory:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("export"),
            route: AppRoutes.exportReportsDirectory,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case AppRoutes.deductionAuthoritiyPage:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("third_party_deduction_authority"),
            route: AppRoutes.deductionAuthoritiyPage,
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    case `${AppRoutes.deductionAuthoritiyDetailedPage}/${id}/dashboard`:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [
          { title: t("back_office"), route: AppRoutes.backOfficePage },
          {
            title: t("third_party_deduction_authority"),
            route: AppRoutes.deductionAuthoritiyPage,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.deductionAuthoritiyDetailedPage}/${id}/dashboard`,
              state: {
                id: id,
                name: name,
                value: value
              },
            },
          },
        ],
        icon: icons.BackOfficeIcon,
      };
    default:
      return {
        backgroundColor: THEME.backOfficeOptionBackgroundColor,
        activeColor: THEME.backOfficeOptionTextColor,
        path: [{ title: t("back_office"), route: AppRoutes.backOfficePage }],
        icon: icons.BackOfficeIcon,
      };
  }
};

/// Report center
export const getReportCenterNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  switch (location.pathname) {
    case AppRoutes.reportCenterPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterSCSRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("sales_call_summary_report"), route: AppRoutes.reportCenterSCSRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterMSRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("marketing_summary_report"), route: AppRoutes.reportCenterMSRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterCompaniesBilled:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("companies_billed"), route: AppRoutes.reportCenterCompaniesBilled },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterOJORPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("open_job_orders_report"), route: AppRoutes.reportCenterOJORPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterJOHRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("job_orders_history_report"), route: AppRoutes.reportCenterJOHRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterACCRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("arrival_check_calls_report"), route: AppRoutes.reportCenterACCRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterEOFDCRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("end_of_first_day_call_report"), route: AppRoutes.reportCenterEOFDCRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterEOSDCRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("end_of_second_day_calls_report"), route: AppRoutes.reportCenterEOSDCRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterFCRPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("friday_calls_report"), route: AppRoutes.reportCenterFCRPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterNewHireReportPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("new_hire_report"), route: AppRoutes.reportCenterNewHireReportPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.terminationReport:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("termination_report"), route: AppRoutes.terminationReport },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterEmpHoliday:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("employee_holiday_report"), route: AppRoutes.reportCenterEmpHoliday },
        ],
        icon: icons.ReportCenterIcon,
      };
      case AppRoutes.reportCenterVacation:
        return {
          backgroundColor: THEME.reportCenterOptionBackgroundColor,
          activeColor: THEME.reportCenterOptionTextColor,
          path: [
            { title: t("report_center"), route: AppRoutes.reportCenterPage },
            { title: t("employee_vacation_report"), route: AppRoutes.reportCenterVacation },
          ],
          icon: icons.ReportCenterIcon,
        };
    case AppRoutes.reportCenterTaxByEmployee:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("tax_by_employee"), route: AppRoutes.reportCenterTaxByEmployee },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterInvoiceRegisterPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("invoice_register"), route: AppRoutes.reportCenterInvoiceRegisterPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterCashReceiptsPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("cash_receipts"), route: AppRoutes.reportCenterCashReceiptsPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterARAgingReportPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("ar_aging_report"), route: AppRoutes.reportCenterARAgingReportPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterPayrollJournalPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("payroll_journal"), route: AppRoutes.reportCenterPayrollJournalPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterPayrollDeductionsPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("payroll_deductions"), route: AppRoutes.reportCenterPayrollDeductionsPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterPaymentRegisterPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("payment_register"), route: AppRoutes.reportCenterPaymentRegisterPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterEmployeeAssignmentReportPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("employee_assignment_report"), route: AppRoutes.reportCenterEmployeeAssignmentReportPage },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterTalentAssignmentReport:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          { title: t("employee_assignment_report"), route: AppRoutes.reportCenterTalentAssignmentReport },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterTalentAvailabilityReport:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("availability_report"), route: AppRoutes.reportCenterTalentAvailabilityReport

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportPayCheckRegister:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("paycheck_register"), route: AppRoutes.reportPayCheckRegister

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterPayrollCashPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("payroll_cash_required"), route: AppRoutes.reportCenterPayrollCashPage

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterGrossProfitPage:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("gross_profit_report"), route: AppRoutes.reportCenterGrossProfitPage

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterWorkCompCode:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("workcomp_class_code"), route: AppRoutes.reportCenterWorkCompCode

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    case AppRoutes.reportCenterEmployerTax:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
          {
            title: t("employer_taxes"), route: AppRoutes.reportCenterEmployerTax

          },
        ],
        icon: icons.ReportCenterIcon,
      };
    default:
      return {
        backgroundColor: THEME.reportCenterOptionBackgroundColor,
        activeColor: THEME.reportCenterOptionTextColor,
        path: [
          { title: t("report_center"), route: AppRoutes.reportCenterPage },
        ],
        icon: icons.ReportCenterIcon,
      };
  }
};

/// Assignments
export const getAssignmentsNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;

  function getPathname() {
    if (
      location.pathname ===
      `${AppRoutes.assignmentsDetailsPage}/${id}/dashboard`
    )
      return "dashboard";
    return "dashboard";
  }

  switch (location.pathname) {
    case AppRoutes.assignmentsPage:
      return {
        backgroundColor: THEME.assignmentsOptionBackgroundColor,
        activeColor: THEME.assignmentsOptionTextColor,
        path: [{ title: t("assignments"), route: AppRoutes.assignmentsPage }],
        icon: icons.AssignIcon,
      };
    case `${AppRoutes.assignmentsDetailsPage}/${id}/${getPathname()}`:
      return {
        backgroundColor: THEME.assignmentsOptionBackgroundColor,
        activeColor: THEME.assignmentsOptionTextColor,
        path: [
          { title: t("assignments"), route: AppRoutes.assignmentsPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.assignmentsDetailsPage
                }/${id}/${getPathname()}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.AssignIcon,
      };
    default:
      return {
        backgroundColor: THEME.assignmentsOptionBackgroundColor,
        activeColor: THEME.assignmentsOptionTextColor,
        path: [{ title: t("assignments"), route: AppRoutes.assignmentsPage }],
        icon: icons.AssignIcon,
      };
  }
};

/// Jobs
export const getJobsNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const jobId = state && state.id ? state.id : "";
  const departmentId = state && state.departmentId ? state.departmentId : "";
  const name = state?.name ?? jobId;
  const departmentName = state?.departmentName ?? departmentId;

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/dashboard`
      )
    )
      return "dashboard";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/success-profile-job-posting`
      )
    )
      return "success-profile-job-posting";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/interviews`
      )
    )
      return "interviews";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/assignments`
      )
    )
      return "assignments";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/contacts`
      )
    )
      return "contacts";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/service-profile`
      )
    )
      return "service-profile";
    if (
      location.pathname.startsWith(
        `${AppRoutes.jobDetailsPage}/${jobId}/documents`
      )
    )
      return "documents";
    return "dashboard";
  }
  switch (location.pathname) {
    case AppRoutes.jobsPage:
      return {
        backgroundColor: THEME.jobsOptionBackgroundColor,
        activeColor: THEME.jobsOptionTextColor,
        path: [{ title: t("jobs"), route: AppRoutes.jobsPage }],
        icon: icons.Jobsicon,
      };
    case `${AppRoutes.jobDetailsPage}/${jobId}/${getPathname()}`:
      return {
        backgroundColor: THEME.jobsOptionBackgroundColor,
        activeColor: THEME.jobsOptionTextColor,
        path: [
          { title: t("jobs"), route: AppRoutes.jobsPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.jobDetailsPage}/${jobId}/${getPathname()}`,
              state: {
                id: jobId,
                name: name,
              },
            },
          },
        ],
        icon: icons.Jobsicon,
      };

    case `${AppRoutes.jobDetailsPage
      }/${jobId}/${getPathname()}/${departmentId}`:
      return {
        backgroundColor: THEME.jobsOptionBackgroundColor,
        activeColor: THEME.jobsOptionTextColor,
        path: [
          { title: t("jobs"), route: AppRoutes.jobsPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.jobDetailsPage}/${jobId}/${getPathname()}`,
              state: {
                id: jobId,
                name: name,
              },
            },
          },
          {
            title: departmentName,
            route: {
              pathname: `${AppRoutes.jobDetailsPage
                }/${jobId}/${getPathname()}/${departmentId}`,
              state: {
                name: name,
                id: jobId,
                departmentName: departmentName,
                departmentId: departmentId,
              },
            },
          },
        ],
        icon: icons.Jobsicon,
      };
    default:
      return {
        backgroundColor: THEME.jobsOptionBackgroundColor,
        activeColor: THEME.jobsOptionTextColor,
        path: [{ title: t("jobs"), route: AppRoutes.jobsPage }],
        icon: icons.Jobsicon,
      };
  }
};

/// Risk management portal
export const getRiskManagementNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;
  const value = state && state.value ? state.value : {};

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.workCompClaimDetailsPage}/${id}/dashboard`
      )
    ) {
      return "dashboard";
    }
    if (
      location.pathname.startsWith(
        `${AppRoutes.unEmplomentDetailsPage}/${id}/dashboard`
      )
    ) {
      return "dashboard";
    }

    return "dashboard";
  }
  switch (location.pathname) {
    case AppRoutes.riskManagementPage:
      return {
        backgroundColor: THEME.riskManagementOptionBackgroundColor,
        activeColor: THEME.riskManagementOptionTextColor,
        path: [
          { title: t("risk_management"), route: AppRoutes.riskManagementPage },
        ],
        icon: icons.RiskmanagIcon,
      };

    case `${AppRoutes.workCompClaimDetailsPage}/${id}/${getPathname()}`:
      return {
        backgroundColor: THEME.riskManagementOptionBackgroundColor,
        activeColor: THEME.riskManagementOptionTextColor,
        path: [
          { title: t("work_comp_claims"), route: AppRoutes.riskManagementPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.workCompClaimDetailsPage
                }/${id}/${getPathname()}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.RiskmanagIcon,
      };
    case `${AppRoutes.unEmplomentDetailsPage}/${id}/${getPathname()}`:
      return {
        backgroundColor: THEME.riskManagementOptionBackgroundColor,
        activeColor: THEME.riskManagementOptionTextColor,
        path: [
          {
            title: t("unemployment_claims"),
            route: AppRoutes.riskManagementPage,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.unEmplomentDetailsPage
                }/${id}/${getPathname()}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.RiskmanagIcon,
      };
    case `${AppRoutes.workCompComplianceReportDetailsPage}/${id}/dashboard`:
      return {
        backgroundColor: THEME.riskManagementOptionBackgroundColor,
        activeColor: THEME.riskManagementOptionTextColor,
        path: [
          {
            title: t("work_comp_audit_and_compliance_reporting"),
            route: AppRoutes.riskManagementPage,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.workCompComplianceReportDetailsPage
                }/${id}/dashboard`,
              state: {
                id: id,
                name: name,
                value: value
              },
            },
          },
        ],
        icon: icons.RiskmanagIcon,
      };

    default:
      return {
        backgroundColor: THEME.riskManagementOptionBackgroundColor,
        activeColor: THEME.riskManagementOptionTextColor,
        path: [
          { title: t("risk_management"), route: AppRoutes.riskManagementPage },
        ],
        icon: icons.RiskmanagIcon,
      };
  }
};

/// Talents
export const getTalentsNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const talentId = state && state.id ? state.id : "";
  const departmentId = state && state.departmentId ? state.departmentId : "";
  const name = state?.name ?? talentId;
  const departmentName = state?.departmentName ?? departmentId;

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/dashboard`
      )
    )
      return "dashboard";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/assignments`
      )
    )
      return "assignments";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/employment-history`
      )
    )
      return "employment-history";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/interviews`
      )
    )
      return "interviews";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/back-office-payroll-set-up`
      )
    )
      return "back-office-payroll-set-up";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/risk-management`
      )
    )
      return "risk-management";
    if (
      location.pathname.startsWith(
        `${AppRoutes.talentDetailsPage}/${talentId}/onboarding-and-docs`
      )
    )
      return "onboarding-and-docs";
    return "dashboard";
  }

  switch (location.pathname) {
    case AppRoutes.talentPage:
      return {
        backgroundColor: THEME.talentOptionBackgroundColor,
        activeColor: THEME.talentOptionTextColor,
        path: [{ title: t("talents"), route: AppRoutes.talentPage }],
        icon: icons.TalentIcon,
      };
    case `${AppRoutes.talentDetailsPage}/${talentId}/${getPathname()}`:
      return {
        backgroundColor: THEME.talentOptionBackgroundColor,
        activeColor: THEME.talentOptionTextColor,
        path: [
          { title: t("talents"), route: AppRoutes.talentPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.talentDetailsPage
                }/${talentId}/${getPathname()}`,
              state: {
                id: talentId,
                name: name,
              },
            },
          },
        ],
        icon: icons.TalentIcon,
      };

    case `${AppRoutes.talentDetailsPage
      }/${talentId}/${getPathname()}/${departmentId}`:
      return {
        backgroundColor: THEME.talentOptionBackgroundColor,
        activeColor: THEME.talentOptionTextColor,
        path: [
          { title: t("talents"), route: AppRoutes.talentPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.talentDetailsPage
                }/${talentId}/${getPathname()}`,
              state: {
                id: talentId,
                name: name,
              },
            },
          },
          {
            title: departmentName,
            route: {
              pathname: `${AppRoutes.talentDetailsPage
                }/${talentId}/${getPathname()}/${departmentId}`,
              state: {
                name: name,
                id: talentId,
                departmentName: departmentName,
                departmentId: departmentId,
              },
            },
          },
        ],
        icon: icons.TalentIcon,
      };
    default:
      return {
        backgroundColor: THEME.talentOptionBackgroundColor,
        activeColor: THEME.talentOptionTextColor,
        path: [{ title: t("talents"), route: AppRoutes.talentPage }],
        icon: icons.TalentIcon,
      };
  }
};

/// Contacts
export const getContactsNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;

  function getPathname() {
    if (
      location.pathname === `${AppRoutes.contactsDetailsPage}/${id}/dashboard`
    )
      return "dashboard";
    if (
      location.pathname ===
      `${AppRoutes.contactsDetailsPage}/${id}/sales-profile`
    )
      return "sales-profile";
    if (
      location.pathname ===
      `${AppRoutes.contactsDetailsPage}/${id}/service-profile`
    )
      return "service-profile";
    if (
      location.pathname === `${AppRoutes.contactsDetailsPage}/${id}/job-orders`
    )
      return "job-orders";
    if (
      location.pathname === `${AppRoutes.contactsDetailsPage}/${id}/assignments`
    )
      return "assignments";
    if (location.pathname === `${AppRoutes.contactsDetailsPage}/${id}/contacts`)
      return "contacts";
    if (
      location.pathname ===
      `${AppRoutes.contactsDetailsPage}/${id}/back-office-format-and-set-up`
    )
      return "back-office-format-and-set-up";
    if (
      location.pathname ===
      `${AppRoutes.contactsDetailsPage}/${id}/back-office-invoices-and-docs`
    )
      return "back-office-invoices-and-docs";
    return "dashboard";
  }

  switch (location.pathname) {
    case AppRoutes.contactsPage:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [{ title: t("contacts"), route: AppRoutes.contactsPage }],
        icon: icons.ContactsIcon,
      };
    case `${AppRoutes.contactsDetailsPage}/${id}/${getPathname()}`:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [
          { title: t("contacts"), route: AppRoutes.contactsPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.contactsDetailsPage
                }/${id}/${getPathname()}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.ContactsIcon,
      };

    default:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [{ title: t("contacts"), route: AppRoutes.contactsPage }],
        icon: icons.ContactsIcon,
      };
  }
};

/// Companies
export const getCompaniesNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const comapanyId = state && state.id ? state.id : "";
  const departmentId = state && state.departmentId ? state.departmentId : "";
  const name = state?.name ?? comapanyId;
  const departmentName = state?.departmentName ?? departmentId;
  switch (location.pathname) {
    case AppRoutes.companiesPage:
      return {
        backgroundColor: THEME.companiesOptionBackgroundColor,
        activeColor: THEME.companiesOptionTextColor,
        path: [{ title: t("companies"), route: AppRoutes.companiesPage }],
        icon: icons.CompaniesIcon,
      };
    case `${AppRoutes.companiesDetailsPage}/${comapanyId}/${getPathname()}`:
      return {
        backgroundColor: THEME.companiesOptionBackgroundColor,
        activeColor: THEME.companiesOptionTextColor,
        path: [
          { title: t("companies"), route: AppRoutes.companiesPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.companiesDetailsPage
                }/${comapanyId}/${getPathname()}`,
              state: {
                id: comapanyId,
                name: name,
              },
            },
          },
        ],
        icon: icons.CompaniesIcon,
      };

    case `${AppRoutes.companiesDetailsPage
      }/${comapanyId}/${getPathname()}/${departmentId}`:
      return {
        backgroundColor: THEME.companiesOptionBackgroundColor,
        activeColor: THEME.companiesOptionTextColor,
        path: [
          { title: t("companies"), route: AppRoutes.companiesPage },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.companiesDetailsPage
                }/${comapanyId}/${getPathname()}`,
              state: {
                id: comapanyId,
                name: name,
              },
            },
          },
          {
            title: departmentName,
            route: {
              pathname: `${AppRoutes.companiesDetailsPage
                }/${comapanyId}/${getPathname()}/${departmentId}`,
              state: {
                name: name,
                id: comapanyId,
                departmentName: departmentName,
                departmentId: departmentId,
              },
            },
          },
        ],
        icon: icons.CompaniesIcon,
      };

    default:
      return {
        backgroundColor: THEME.companiesOptionBackgroundColor,
        activeColor: THEME.companiesOptionTextColor,
        path: [{ title: t("companies"), route: AppRoutes.companiesPage }],
        icon: icons.CompaniesIcon,
      };
  }

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/dashboard`
      )
    )
      return "dashboard";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/sales-profile`
      )
    )
      return "sales-profile";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/service-profile`
      )
    )
      return "service-profile";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/job-orders`
      )
    )
      return "job-orders";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/assignments`
      )
    )
      return "assignments";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/contacts`
      )
    )
      return "contacts";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/back-office-format-and-set-up`
      )
    )
      return "back-office-format-and-set-up";
    if (
      location.pathname.startsWith(
        `${AppRoutes.companiesDetailsPage}/${comapanyId}/back-office-invoices-and-docs`
      )
    )
      return "back-office-invoices-and-docs";
    return "dashboard";
  }
};

/// Sales & Marketing
export const getSalesNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;

  switch (location.pathname) {
    case AppRoutes.salesAndMarketingPage:
      return {
        backgroundColor: THEME.stateMarketingOptionBackgroundColor,
        activeColor: THEME.stateMarketingOptionTextColor,
        path: [
          {
            title: t("sales_and_marketing"),
            route: AppRoutes.salesAndMarketingPage,
          },
        ],
        icon: icons.SalesmarketIcon,
      };
    case `${AppRoutes.salesAndMarketingCompetitorSheet}/${id}/dashboard`:
      return {
        backgroundColor: THEME.stateMarketingOptionBackgroundColor,
        activeColor: THEME.stateMarketingOptionTextColor,
        path: [
          {
            title: t("sales_and_marketing"),
            route: AppRoutes.salesAndMarketingPage,
          },
          {
            title: t("competitor_sheet"),
            route: AppRoutes.salesAndMarketingCompetitorSheet,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.salesAndMarketingCompetitorSheet}/${id}/dashboard`,
              state: {
                name: name,
                id: id,
              },
            },
          },
        ],
        icon: icons.SalesmarketIcon,
      };
    default:
      return {
        backgroundColor: THEME.stateMarketingOptionBackgroundColor,
        activeColor: THEME.stateMarketingOptionTextColor,
        path: [
          {
            title: t("sales_and_marketing"),
            route: AppRoutes.salesAndMarketingPage,
          },
        ],
        icon: icons.SalesmarketIcon,
      };
  }
};

/// Dashboard
export const getDashboardNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;
  const type: string =
    state && state.type ? state.type : (match.params as any).type;
  switch (location.pathname) {
    case AppRoutes.dashboardPage:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [{ title: t("dashboard"), route: AppRoutes.dashboardPage }],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.dashboardPage
      }/${type}/company-engagement/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: `${type.toLowerCase()} ${t("company_engagement")}`,
            route: `${AppRoutes.dashboardPage}/${type}/company-engagement`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.dashboardPage
      }/${type}/contact-engagement/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: `${type.toLowerCase()} ${t("contact_engagement")}`,
            route: `${AppRoutes.dashboardPage}/${type}/contact-engagement`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.dashboardPage
      }/${type}/talent-engagement/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: `${type.toLowerCase()} ${t("talent_engagement")}`,
            route: `${AppRoutes.dashboardPage}/${type}/talent-engagement`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.dashboardPage}/available-candidates/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("available-candidates"),
            route: `${AppRoutes.dashboardPage}/available-candidates`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsReceived}/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_received"),
            route: `${AppRoutes.onlineApplicationsReceived}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsReceived}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_received"),
            route: `${AppRoutes.onlineApplicationsReceived}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsSent}/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_sent"),
            route: `${AppRoutes.onlineApplicationsSent}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsSent}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_sent"),
            route: `${AppRoutes.onlineApplicationsSent}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsReceived}/details/${id}/online-application`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_received"),
            route: `${AppRoutes.onlineApplicationsReceived}`,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.onlineApplicationsReceived}/details/${id}/online-application`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.DashboardIcon,
      };

    case `${AppRoutes.onlineApplicationsReceived}/details/${id}/employment-history`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_received"),
            route: `${AppRoutes.onlineApplicationsReceived}`,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.onlineApplicationsReceived}/details/${id}/employment-history`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onlineApplicationsSent}/details/${id}/online-application`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("online_applications_sent"),
            route: `${AppRoutes.onlineApplicationsSent}`,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.onlineApplicationsSent}/details/${id}/online-application`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.dashboardPage
      }/internal-interviews-scheduled/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("remaining_internal_interviews_week"),
            route: `${AppRoutes.internalInterviewsScheduled}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.internalInterviewsScheduled}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("remaining_internal_interviews_week"),
            route: `${AppRoutes.internalInterviewsScheduled}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.internalInterviewsCompleted}/${getPathname()}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("completed_internal_interviews_week"),
            route: `${AppRoutes.internalInterviewsCompleted}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.internalInterviewsCompleted}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("completed_internal_interviews_week"),
            route: `${AppRoutes.internalInterviewsCompleted}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onboardingCandidateInputRequired}/active-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", { name: t("candidate_input_required") }),
            route: `${AppRoutes.onboardingCandidateInputRequired}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onboardingCandidateInputRequired}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", { name: t("candidate_input_required") }),
            route: `${AppRoutes.onboardingCandidateInputRequired}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onboardingReadyForApprovalByRecruiter}/active-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", {
              name: t("ready_for_approval_by_recruiter"),
            }),
            route: `${AppRoutes.onboardingReadyForApprovalByRecruiter}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onboardingReadyForApprovalByRecruiter}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", {
              name: t("ready_for_approval_by_recruiter"),
            }),
            route: `${AppRoutes.onboardingReadyForApprovalByRecruiter}`,
          },
        ],
        icon: icons.DashboardIcon,
      };

    case `${AppRoutes.onboardingCompletedThisWeek}/active-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", { name: t("completed_this_week_to_date") }),
            route: `${AppRoutes.onboardingCompletedThisWeek}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    case `${AppRoutes.onboardingCompletedThisWeek}/archive-directory`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("dashboard"), route: AppRoutes.dashboardPage },
          {
            title: t("on_boarding", { name: t("completed_this_week_to_date") }),
            route: `${AppRoutes.onboardingCompletedThisWeek}`,
          },
        ],
        icon: icons.DashboardIcon,
      };
    default:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [{ title: t("dashboard"), route: AppRoutes.dashboardPage }],
        icon: icons.DashboardIcon,
      };
  }

  function getPathname() {
    if (
      location.pathname.startsWith(
        `${AppRoutes.dashboardPage}/${type}/company-engagement/universal-directory`
      )
    )
      return "universal-directory";
    if (
      location.pathname.startsWith(
        `${AppRoutes.dashboardPage}/${type}/contact-engagement/universal-directory`
      )
    )
      return "universal-directory";
    if (
      location.pathname.startsWith(
        `${AppRoutes.dashboardPage}/${type}/talent-engagement/universal-directory`
      )
    )
      return "universal-directory";
    if (
      location.pathname.startsWith(
        `${AppRoutes.dashboardPage}/available-candidates/universal-directory`
      )
    )
      return "universal-directory";
    return "universal-directory";
  }
};

/// Admin Center OnBoarding
export const getAdminOnboardingNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;

  function getPathname() {
    if (
      location.pathname ===
      `${AppRoutes.systemConfigurationOnboarding}/welcome-video`
    )
      return "welcome-video";
    if (
      location.pathname ===
      `${AppRoutes.systemConfigurationOnboarding}/terms-and-conditions`
    )
      return "terms-and-conditions";
    if (
      location.pathname ===
      `${AppRoutes.systemConfigurationOnboarding}/hand-book`
    )
      return "hand-book";
    if (
      location.pathname ===
      `${AppRoutes.systemConfigurationOnboarding}/health-care`
    )
      return "health-care";

    return "welcome-video";
  }

  switch (location.pathname) {
    case AppRoutes.systemConfigurationOnboarding:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [
          {
            title: t("system_configuration"),
            route: AppRoutes.systemConfigurationOnboarding,
          },
        ],
        icon: icons.ContactsIcon,
      };
    case `${AppRoutes.systemConfigurationOnboarding}/${id}/${getPathname()}`:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [
          {
            title: t("system_configuration"),
            route: AppRoutes.systemConfigurationOnboarding,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.contactsDetailsPage
                }/${id}/${getPathname()}`,
              state: {
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.ContactsIcon,
      };

    default:
      return {
        backgroundColor: THEME.contactsOptionBackgroundColor,
        activeColor: THEME.contactsOptionTextColor,
        path: [
          {
            title: t("contacts"),
            route: AppRoutes.adminCenterSystemConfiguration,
          },
        ],
        icon: icons.ContactsIcon,
      };
  }
};

/// My Profile DashBoard
export const getMyProfileNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const id = state && state.id ? state.id : "";
  const name = state?.name ?? id;
  switch (location.pathname) {
    case AppRoutes.myProfileDashboardPage:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          { title: t("my_profile"), route: AppRoutes.myProfileDashboardPage },
        ],
        icon: icons.UserIcon,
      };
  }
};

/// Apollo Search People
export const getApolloSearchNavHeaderProps = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  t: TFunction<"translation", undefined>
) => {
  const state = location.state as any;
  const source = state && state.source ? state.source : "";
  const domain = state && state.domain ? state.domain : "";
  const id = state && state.id ? state.id : "";
  const name = state && state.name ? state.name : "";
  const lastIndex = location.pathname.lastIndexOf("/");
  const sourcePathName = location.pathname.slice(lastIndex + 1);
  const result = location.pathname;

  switch (location.pathname) {
    case `${AppRoutes.apolloSearchImport}/${source}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          {
            title: result.includes("companies")
              ? t("search_companies")
              : result.includes("talent")
                ? t("search_talent")
                : t("search_contacts"),
            route: `${AppRoutes.apolloSearchImport}/${sourcePathName}`,
          },
        ],
        icon: icons.SearchIcon,
      };
    case `${AppRoutes.apolloCompanyDetailsPage}/${id}`:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          {
            title: t("companies"),
            route: `${AppRoutes.apolloSearchImport}/companies`,
          },
          {
            title: name,
            route: {
              pathname: `${AppRoutes.apolloCompanyDetailsPage}/${id}`,
              state: {
                domain: domain,
                id: id,
                name: name,
              },
            },
          },
        ],
        icon: icons.CompaniesIcon,
      };
    default:
      return {
        backgroundColor: THEME.dasboardOptionBackgroundColor,
        activeColor: THEME.dasboardOptionTextColor,
        path: [
          {
            title: result.includes("companies")
              ? t("search_companies")
              : result.includes("talent")
                ? t("search_talent")
                : t("search_contacts"),
            route: `${AppRoutes.apolloSearchImport}/${sourcePathName}`,
          },
        ],
        icon: icons.SearchIcon,
      };
  }
};

