import RenderInput from "../../../../../components/render_input/render_input";
import { FormInputOption, ICompaniesList, IContactDropdownItem, ICreateJobForm, IDepartmentsList, JobOrderPlacement, JobOrderSource } from "../../../../../interfaces";
import { convertNumberToMoney, formatToMoney } from "../../../../../utils";
import { jobsStatusOptions } from "../../../../../variables";
import './step_one.scss';

interface Props {
    companies: ICompaniesList[],
    departments: IDepartmentsList[],
    contactsList: IContactDropdownItem[],
    orderSourceList: JobOrderSource[],
    placementTypeList: JobOrderPlacement[],
    data?: { company_id?: string, department_id?: string, department_ids?: string[] },
    formState: ICreateJobForm,
    handleFieldChange: (id: string, value: any, parent?: string | undefined) => void,
    validators: ((value: {
        text: string;
        field: string;
    }) => string | null) | undefined,
    checkValidationOnSubmit: boolean
}

const StepOneForm: React.FunctionComponent<Props> = (props) => {
    const { checkValidationOnSubmit, companies, departments, contactsList, data, orderSourceList, placementTypeList, formState, handleFieldChange, validators } = props;

    const formOptions: (FormInputOption | FormInputOption[])[] = [
        {
            field: "placement_type_id",
            type: "selector",
            label: 'placement_type',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: placementTypeList.map((doc) => ({ label: doc.title, value: doc.id })),
            multi: false,
            labelPosition: "left",
        },
        {
            field: "job_order_source_id",
            type: "selector",
            label: 'job_order_source',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: orderSourceList.map((doc) => ({ label: doc.job_order_source, value: doc.id })),
            multi: false,
            labelPosition: "left",
        },
        {
            field: "company_id",
            type: "selector",
            label: 'company',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: companies.map((doc) => ({ label: doc.name, value: doc.id })),
            multi: false,
            disabled: (data && data.company_id) ? true : false,
            labelPosition: "left",
        },
        {
            field: "department_id",
            type: "selector",
            label: 'department',
            secure: false,
            required: false,
            placeholder: 'Corporate',
            keyboardtype: '',
            selectorList: departments.map((doc) => ({ label: doc.name, value: doc.id })),
            multi: false,
            disabled: (data && data.company_id && data.department_id) ? true : false,
            labelPosition: "left",
        },
        {
            field: "job_title",
            type: "text",
            label: 'job_title',
            secure: false,
            required: true,
            placeholder: '',
            keyboardtype: '',
            labelPosition: "left",
        },

        {
            field: "order_date",
            type: "date",
            label: 'order_date',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: '',
            labelPosition: "left",
            isJobFormDateField: true,
        },

        {
            field: "contact_id_for_placement",
            type: "selector",
            label: 'contact_for_placement',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
        },

        {
            field: "ordered_by_contact_id",
            type: "selector",
            label: 'ordered_by',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },

        {
            field: "report_to_contact_id",
            type: "selector",
            label: 'report_to',
            secure: false,
            required: false,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: (formState.company_id) ? contactsList.map(doc => ({ label: doc.name, value: doc.id })) : [],
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },

        {
            field: "regular_pay_rate",
            type: "text",
            label: 'pay_rate',
            secure: false,
            required: false,
            placeholder: '',
            keyboardtype: '',
            min: 0.00,
            step: 0.01,
            prefix: (<span>$</span>),
            labelPosition: "left",
            onBlur: formatToMoney,
            onKeyDown: formatToMoney,
            onKeyUp: formatToMoney,
        },
        {
            field: "status",
            type: "selector",
            label: 'status',
            secure: false,
            required: true,
            placeholder: 'select',
            keyboardtype: '',
            selectorList: jobsStatusOptions,
            multi: false,
            labelPosition: "left",
            menuPlacement: "top",
        },
    ]

    return (
        <div className="jb-f-stp-one">
            {formOptions.map((doc, index) => {
                if (Array.isArray(doc)) {
                    return (
                        <div key={index + 'row'} className="jb-f-row">
                            {doc.map((subDoc, subIndex) => {
                                return (
                                    <RenderInput
                                        key={index + subIndex}
                                        doc={subDoc}
                                        index={subIndex}
                                        formState={formState}
                                        handleFieldChange={handleFieldChange}
                                        formValidators={validators}
                                        className={"jb-f-input-holder"}
                                        checkValidationOnSubmit={true}
                                    />
                                );
                            })}
                        </div>
                    );
                }

                return (
                    <RenderInput
                        key={index}
                        doc={doc}
                        index={index}
                        formState={formState}
                        handleFieldChange={handleFieldChange}
                        formValidators={validators}
                        className={"jb-f-input-holder"}
                        checkValidationOnSubmit={checkValidationOnSubmit}
                    />
                )
            })}
        </div>
    )
}

export default StepOneForm;