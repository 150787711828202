import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../../../components/api_error';
import { LoadingType, ROLE, TALENT_SKILLS_TYPE, THEME } from '../../../../enums';
import { ISkillOption,  IRemoveSkill, IRemoveTalentStaff } from '../../../../interfaces';
import { selectManageUserToggleRoleState, toggleUserRole } from '../../../../redux/admin_center';
import { useAppDispatch, useAppSelector, postSnackbarMessage, selectTalentDetailsByIdState, removeTalentSkills, removeTalentRecruiters, removeTalentAccountManagers } from '../../../../redux/store';
import { CustomButton } from '../../../../utils';
import './remove_option.scss';
import { talentDetailsSliceActions } from '../../../../redux/talent/details/dashboard/talent_details/talent_details_reducer';


interface Props {
    type: string,
    visible: boolean,
    talentId: string,
    data: {id: string, value: string},
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
}

export const RemoveTalentOptionPopup = (props: Props) => {
    const {
        type,
        data,
        talentId,
        visible,
        onClose,
        onDissmiss,
        onSuccessClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// Create optin states
    const talentState = useAppSelector((state) => selectTalentDetailsByIdState(talentId)(state));
    const skillSetRemoveState = talentState.professional_skills.remove;
    const credentialsRemoveState = talentState.credentials.remove;
    const certificationsRemoveState = talentState.certifications.remove;
    const educationRemoveState = talentState.education.remove;
    const requiredEquipmentRemoveState = talentState.safety_equipment.remove;
    const accountManagerRemoveState = talentState.account_managers.remove;
    const recruitersRemoveState = talentState.recruiters.remove;

    useEffect(() => {
        if (skillSetRemoveState.loading === LoadingType.succeeded ||
            credentialsRemoveState.loading === LoadingType.succeeded ||
            certificationsRemoveState.loading === LoadingType.succeeded ||
            educationRemoveState.loading === LoadingType.succeeded ||
            requiredEquipmentRemoveState.loading === LoadingType.succeeded ||
            accountManagerRemoveState.loading === LoadingType.succeeded ||
            recruitersRemoveState.loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(skillSetRemoveState.response ||
                credentialsRemoveState.response ||
                certificationsRemoveState.response ||
                educationRemoveState.response ||
                requiredEquipmentRemoveState.response ||
                accountManagerRemoveState.response ||
                recruitersRemoveState.response));
            closePopup(onSuccessClose);
        }
        return () => { }
    }, [
        skillSetRemoveState.loading,
        credentialsRemoveState.loading,
        certificationsRemoveState.loading,
        educationRemoveState.loading,
        requiredEquipmentRemoveState.loading,
        accountManagerRemoveState.loading,
        recruitersRemoveState.loading,
    ])

    function isAddLoading() {
        return skillSetRemoveState.loading === LoadingType.pending ||
            credentialsRemoveState.loading === LoadingType.pending ||
            certificationsRemoveState.loading === LoadingType.pending ||
            educationRemoveState.loading === LoadingType.pending ||
            requiredEquipmentRemoveState.loading === LoadingType.pending ||
            accountManagerRemoveState.loading === LoadingType.pending ||
            recruitersRemoveState.loading === LoadingType.pending;
    }

    function getError() {
        return skillSetRemoveState.error ||
            credentialsRemoveState.error ||
            certificationsRemoveState.error ||
            educationRemoveState.error ||
            requiredEquipmentRemoveState.error ||
            accountManagerRemoveState.error ||
            recruitersRemoveState.error;
    }


    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={430}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div className="del-role-container">
                <div className="del-role-header">
                    <div className="title-txt">
                        <span>{`${t('attention')}!!`}</span>
                    </div>
                </div>
                <div className="del-role-content">
                    <div className="message-txt">
                        <span>{t('are_you_sure_you_want_to_delete', { value: `${data.value} ${t(type)}` })}</span>
                    </div>
                    {getError() && <div className={"error-section"}>
                        <ApiError message={getError()?.message} onClose={handleClearError} />
                    </div>}
                </div>
                <div className="del-role-action-holder">
                    <div className="del-role-action">
                        <div className="btn-no">
                            <CustomButton
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('cancel')}
                                enable={true}
                                backgroundColor={THEME.secondaryColor4}
                                onClick={() => closePopup(onClose)}
                            />
                        </div>
                        <div className="btn-yes">
                            <CustomButton
                                loading={isAddLoading()}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('delete')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={handleDeleteOption}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ActionDialogHolder>

    );

    function handleDeleteOption() {
        switch (type) {
            case TALENT_SKILLS_TYPE.account_managers: {
                const payload: IRemoveTalentStaff = {
                    talent_id: talentId,
                    user_id: data.id,
                    role: ROLE.accountManager,
                }
                dispatch(removeTalentAccountManagers(payload))
                break;
            }
            case TALENT_SKILLS_TYPE.recruiters: {
                const payload: IRemoveTalentStaff = {
                    talent_id: talentId,
                    user_id: data.id,
                    role: ROLE.recruiter,
                }
                dispatch(removeTalentRecruiters(payload));
                break;
            }
            case TALENT_SKILLS_TYPE.professional_skills:
            case TALENT_SKILLS_TYPE.credentials:
            case TALENT_SKILLS_TYPE.certifications:
            case TALENT_SKILLS_TYPE.education:
            case TALENT_SKILLS_TYPE.safety_equipment: {
                const payload: IRemoveSkill = {
                    skill_ids: [
                        data.id,
                    ],
                    skill_type: type,
                }
                dispatch(removeTalentSkills({ talentId: talentId, value: payload }))
                break;
            }
            default: { break; }
        }

    }

    function handleClearError() {
        dispatch(talentDetailsSliceActions.clearTalentDetailsRemoveOptionError({id: talentId, type: type}));
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}