import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IPaycheckHistory } from "../../../../interfaces";
import { getPaycheckPDFURLThunk, selectePaycheckInvoiceState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { CustomButton, SpinnerScreen, currencyConversion } from "../../../../utils";
import SortableTable, { TableData } from "../../../../components/sortable_table/sortable_table";
import { SearchIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import ToggleSwitch from "../../../../components/toggle_switch/toggle_switch";
import { getDateString } from "../../../../variables";
import { Portal } from "react-portal";
import EDViewCheckInvoicePopup from "../../../popups/view_check/view_check";
import './paycheck_history_section.scss'
import { paycheckInvoiceSliceActions } from "../../../../redux/back_office/view_paycheck_invoice/view_paycheck_invoice_reducer";

interface Props {
    paycheckHistoryList: IPaycheckHistory[];
    history: RouteComponentProps["history"];
    loading: boolean;
}

const PaycheckHistorySection: React.FunctionComponent<Props> = (props) => {
    const {
        paycheckHistoryList,
        loading,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [payCheckViewURL, setPayCheckViewURL] = useState<string>("");
    const [openViewCheckPopup, setOpenViewCheckPopup] = useState<boolean>(false)

    const paycheckPDFURLState = useAppSelector((state) => selectePaycheckInvoiceState(state));

    useEffect(() => {
        if (paycheckPDFURLState.paycheck.loading === LoadingType.succeeded && paycheckPDFURLState.paycheck.response !== "") {
            setPayCheckViewURL(paycheckPDFURLState.paycheck.response)
        }
    }, [paycheckPDFURLState.paycheck, paycheckPDFURLState.paycheck.loading, paycheckPDFURLState.paycheck.response]);

    const tableheaderList = [
        { title: "check_hash", code: "check_hash" },
        { title: "check_date", code: "check_date" },
        { title: "gross_amount", code: "gross_amount" },
        { title: "taxes", code: "taxes" },
        { title: "deductions", code: "deductions" },
        { title: "net_amount", code: "net_amount" },
        { title: "direct_deposit", code: "direct_deposit" },
        { title: "bank_name", code: "bank_name" },
        { title: "account_number", code: "account_number" },
        { title: "routing_number", code: "routing_number" },
        { title: "", code: "" },
    ];

    const handleSearch = (value: string) => {
        setSearch(value);
    }
    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(value);
            setSortDirection('asc');
        }
    }
    const getFlexNumber = (value: number) => {
        if (value === 0) return 0.8;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 0.5;
        if (value === 20) return 1;
        return 1;
    }

    const handleViewCheck = (pay_check_id: string) => {
        dispatch(getPaycheckPDFURLThunk(pay_check_id));
        setOpenViewCheckPopup(true)
    }

    const handleClose = () => {
        setOpenViewCheckPopup(false);
        dispatch(paycheckInvoiceSliceActions.clearPaycheckState());
        setPayCheckViewURL("");
    }

    const getFilteredList = () => {
        let list = paycheckHistoryList;

        if (paycheckHistoryList && sortedField != null) {
            list = [...paycheckHistoryList].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return list?.filter((doc => {
            const str = search.trim().toLowerCase();
            const checkNumberFilter = doc.check_number ? doc.check_number.toString().trim().toLowerCase().includes(str) : false;
            const grossAmountFilter = doc.gross_amount ? doc.gross_amount.toString().trim().toLowerCase().includes(str) : false;
            const totalTaxAmountFilter = doc.total_tax_amount ? doc.total_tax_amount.toString().trim().toLowerCase().includes(str) : false;
            const totalDeductionFilter = doc.total_deduction ? doc.total_deduction.toString().trim().toLowerCase().includes(str) : false;
            const netAmountFilter = doc.net_amount ? doc.net_amount.toString().trim().toLowerCase().includes(str) : false;
            const bankNameFilter = doc.bank_name ? doc.bank_name.trim().toLowerCase().includes(str) : false;
            const accountNumberMsgFilter = doc.account_number ? doc.account_number.trim().toLowerCase().includes(str) : false;
            const routingNumberFilter = doc.routing_number ? doc.routing_number.trim().toLowerCase().includes(str) : false;

            return checkNumberFilter || grossAmountFilter || totalTaxAmountFilter || totalDeductionFilter || netAmountFilter || bankNameFilter || accountNumberMsgFilter || routingNumberFilter;

        }));
    }

    return (
        <div className="ed-pd-ph-container">
            <div className="ed-pd-ph-header">
                <TableHeaderOptions />
            </div>
            <div className="ed-pd-ph-table">
                <SortableTable
                    headerList={tableheaderList}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {loading ?
                        <SpinnerScreen />
                        :
                        <>
                            {getFilteredList() && getFilteredList()?.length! > 0 ? (
                                <>
                                    {getFilteredList()?.map((doc, index) => {
                                        return (
                                            <tr key={doc.id + index}>
                                                <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.check_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(1) }}><span>{getDateString(doc.check_date)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(2) }}><span>{currencyConversion(doc.gross_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(3) }}><span>{currencyConversion(doc.total_tax_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{currencyConversion(doc.total_deduction, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(5) }}><span>{currencyConversion(doc.net_amount, 2)}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                                    <span>
                                                        {
                                                            <ToggleSwitch
                                                                checked={true}
                                                                onChange={() => { }}
                                                            />

                                                        }
                                                    </span>
                                                </TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(7) }}><span>{doc.bank_name}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(8) }}><span>{doc.account_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(9) }}><span>{doc.routing_number}</span></TableData>
                                                <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                                    <div>
                                                        <CustomButton
                                                            loading={false}
                                                            customStyle={{ width: '80%', padding: "0 0.5em 0 0.5em", cursor: "pointer" }}
                                                            leftIcon={<span style={{ color: "#fff" }}><SearchIcon width={"1vw"} height={"1vw"} /></span>}
                                                            textStyle={{ textTransform: 'capitalize' }}
                                                            name={t("")}
                                                            enable={true}
                                                            backgroundColor={THEME.buttonBackgroundColor3}
                                                            onClick={() => handleViewCheck(doc.id)}
                                                        />
                                                    </div>
                                                </TableData>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                                :
                                <>
                                    <tr className="ed-pd-ph-nra">
                                        <TableData customStyle={{ flex: getFlexNumber(20) }} align="center">
                                            <div className="na_div">
                                                {t("no_paycheck_history_available")}
                                            </div>
                                        </TableData>
                                    </tr>
                                </>
                            }

                        </>
                    }
                </SortableTable>
            </div>
            {openViewCheckPopup &&
                (
                    <Portal>
                        <EDViewCheckInvoicePopup
                            visible={openViewCheckPopup}
                            title={t("view_check")}
                            onClose={handleClose}
                            state={paycheckPDFURLState.paycheck}
                            responseURL={payCheckViewURL}
                        />
                    </Portal>
                )
            }
        </div>
    );
}

interface TableHeaderOptionsProps {
}

const TableHeaderOptions = (props: TableHeaderOptionsProps) => {
    const { t } = useTranslation();
    return (
        <div className="ed-pd-ph-header-container">
            <div className="ed-pd-ph-header-title">
                <span>{t("paycheck_history").toUpperCase()}</span>
            </div>
        </div>
    );
};

export default PaycheckHistorySection;