import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountAPI, firebaseRegister, TalentAPI } from "../../../../../apis";
import { AppError, ITalent, RegisterCredentials } from "../../../../../interfaces";
import preferences from "../../../../../preferences";
import { catchRequestError } from "../../../../../utils";
import { checkAuthToken, getProfile } from "../../../../profile/profile_thunk";


export const createLoginForTalentThunkUser = createAsyncThunk<
    string,
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@createLoginForTalent/post',
    async (talentId, thunkAPI) => {
        try {
            const res = await TalentAPI.createLoginTalent(talentId);
            // console.log(res)
            return res.data;

        } catch (error) {
            // console.log(error)
            return catchRequestError(error, thunkAPI);
        }
    }
);