import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
import { THEME } from "../../../../../../enums";
import { CustomButton } from "../../../../../../utils";
import "./delete_action.scss"

const DeleteAction = ({onClose, onDismiss, visible, onApprove, category, data, deleteItemId}) => {

    const { t } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null)
     
    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    
    const findRecordById = (deleteItemId) => {
        return data.find(record => record.id === deleteItemId);
    };
    
    const selectedRecord = findRecordById(deleteItemId);
    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={400}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="ac-bo-dialog-header ac-sc-rm-delete-action-container">
                <div className="dialog-header">
                    <span style={{color: THEME.red}}>{t(category.deleteLabel)}</span>
                </div>
                <div className="dialog-content">
                    <span>{t(category.deleteMessage, { title: selectedRecord?.insurance_company_name})}</span>
                </div>
                <div className="dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            name={t('yes')}
                            enable={true}
                            backgroundColor={'#0079C1'}
                            onClick={() => closePopup(onApprove)}
                            customClassName="yes-btn"
                        />
                    </div>
                    <div>
                        <CustomButton
                            textStyle={{textTransform: 'capitalize'}}
                            name={t('no')}
                            enable={true}
                            backgroundColor={'#5A3F98'}
                            onClick={() => closePopup(onClose)}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default DeleteAction;