import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import './payroll_details.scss'
import ContributionHistorySection from "./contribution_history/contribution_history_section";
import DeductionHistorySection from "./deduction_history/deduction_history_section";
import PaycheckHistorySection from "./paycheck_history/paycheck_history_section";
import { getEPPayrollDetailsList, getVacationHistoryThunk, selectEPPayrollDetailsState, selectVacationHistoryState, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { LoadingType } from "../../../../enums";
import VacationHistorySection from "./vacation_history/vacation_history";

interface Props {
    talentId: string;
    history: RouteComponentProps["history"];
}

export const PayrollDetails: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { history, talentId } = props;
    const dispatch = useAppDispatch();

    const epPayrollDetailsState = useAppSelector((state) => selectEPPayrollDetailsState(state));
    const vacationHistState = useAppSelector((state) => selectVacationHistoryState(state));

    const contributionHistoryList = []

    useEffect(() => {
        dispatch(getEPPayrollDetailsList(talentId));
        dispatch(getVacationHistoryThunk(talentId));
    }, [])

    return (
        <div className="ep-pd-container">
            <div className="ep-pd-section">
                <PaycheckHistorySection
                    paycheckHistoryList={epPayrollDetailsState?.response?.paychecks!}
                    loading={epPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
            <div className="ep-pd-section">
                <VacationHistorySection
                    vacationHistoryList={vacationHistState?.response!}
                    loading={vacationHistState.loading === LoadingType.pending}
                />
            </div>

            <div className="ep-pd-section">
                <DeductionHistorySection
                    deductionHistoryList={epPayrollDetailsState?.response?.deductions!}
                    loading={epPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
            <div className="ep-pd-section">
                <ContributionHistorySection
                    contributionHistoryList={contributionHistoryList}
                    loading={epPayrollDetailsState.loading === LoadingType.pending}
                />
            </div>
        </div>
    );
}

