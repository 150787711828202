import { createEntityAdapter, createSlice, EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError, CompanyStatus } from "../../../../interfaces";
import * as thunk from "./company_status_thunk";

export interface CompanyStatusState extends EntityState<CompanyStatus> {
    error?: AppError | null,
    loading: LoadingType,
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    remove: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const companyStatusAdapter: EntityAdapter<CompanyStatus> = createEntityAdapter<CompanyStatus>({
    selectId: (doc) => doc.id
})

const initialState: CompanyStatusState = companyStatusAdapter.getInitialState({
    error: null,
    loading: LoadingType.idle,
    create: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    remove: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    },
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
})


export const companyStatusSlice = createSlice({
    name: 'companyStatus',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getCompanyStatus.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getCompanyStatus.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getCompanyStatus.fulfilled, (state, action) => companyStatusAdapter.setAll({ ...state, loading: LoadingType.succeeded, error: null }, action.payload))

            .addCase(thunk.createCompanyStatus.pending, (state, action) => ({...state, create: {...state.create, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.createCompanyStatus.rejected, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.createCompanyStatus.fulfilled, (state, action) => ({ ...state, create: {...state.create, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.deleteCompanyStatus.pending, (state, action) => ({...state, remove: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.deleteCompanyStatus.rejected, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.deleteCompanyStatus.fulfilled, (state, action) => ({ ...state, remove: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))

            .addCase(thunk.updateCompanyStatus.pending, (state, action) => ({...state, update: {...state.remove, loading: LoadingType.pending, error: null} }))
            .addCase(thunk.updateCompanyStatus.rejected, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updateCompanyStatus.fulfilled, (state, action) => ({ ...state, update: {...state.remove, loading: LoadingType.succeeded, error: null, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = companyStatusSlice.actions;
export const { selectAll, selectEntities, selectIds, selectById } = companyStatusAdapter.getSelectors();
export const selectState = (state: CompanyStatusState) => state;
export const selectLoading = (state: CompanyStatusState) => state.loading;
export const selectError = (state: CompanyStatusState) => state.error;


