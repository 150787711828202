import { useTranslation } from "react-i18next";
import SectionCard from "../../../../../components/section_card/section_card";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { AppError, ICompanyBackOfficeCreditProfile, ICompanyBackOfficeSetup } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "../../../../../icons";
import './credit_profile.scss'
import { CustomButton, currencyConversion, getPlusBtnIcon } from "../../../../../utils";
import { Portal } from "react-portal";
import AddCreditProfilePopup from "../../../popup/add_credit_profile/add_credit_profile";
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import ToggleSwitch from "../../../../../components/toggle_switch/toggle_switch";
import TableEmpty from "../../../../../components/table_empty/table_empty";
import BODeleteRecordPopup from "../../../popup/delete_record_popup/delete_record_popup";
interface Props {
    creditProfileUpdateState: {
        error?: AppError | null,
        loading: LoadingType,
        response: string | null
    };
    formState: ICompanyBackOfficeSetup;
    handleAddCreditProfile: (doc: ICompanyBackOfficeCreditProfile, index?: number, removeItem?: boolean) => void;
    onClearError: () => void;
    onUpdateClear: () => void;
    isLoading: boolean;

}
const creditProfileTableHeader = [
    // { title: 'access_company', code: 'access_company' },
    { title: 'credit_limit', code: 'credit_limit' },
    { title: 'currently_on_hold', code: 'currently_on_hold' },
    { title: 'default_payment_option', code: 'default_payment_option_id' },
    { title: 'warning_by_dollar', code: 'warning_by_dollar' },
    { title: 'comments', code: 'comment' },
    { title: '', code: '' },


]
const CreditProfile: React.FC<Props> = props => {
    const { creditProfileUpdateState, formState, handleAddCreditProfile, onClearError, isLoading, onUpdateClear } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profileState = useAppSelector((state) => selectProfileState(state));

    const initialForm = {
        credit_limit: 0,
        default_payment_option_id: "",
        default_payment_option_value: "",
        currently_on_hold: false,
        warning_by_dollar: 0,
        warning_by_percentage: 0,
        comment: "",
    }
    const [editIndex, setEditIndex] = useState<number | undefined>();
    const [addCreditProfile, setAddCreditProfile] = useState<boolean>(false);
    const [creditFormState, setCreditFormState] = useState<ICompanyBackOfficeCreditProfile>(initialForm);
    const [delRecord, setDelRecord] = useState<boolean>(false);

    function handleFormFieldChange(fieldId: string, value: any, parent: string) {
        if (fieldId === "warning_by_percentage") {
            if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
                setCreditFormState({
                    ...creditFormState,
                    [fieldId]: value,
                });
            }
        }
        else {
            setCreditFormState({
                ...creditFormState,
                [fieldId]: value,
            });
        }

    };

    const handleSave = () => {
        if (JSON.stringify(creditFormState) === JSON.stringify(initialForm)) {
            handleOnSuccessClose();
        }
        else{
            handleAddCreditProfile(creditFormState, editIndex);
        }
    };
    const handleOnSuccessClose = () => {
        setEditIndex(undefined);
        setCreditFormState(initialForm);
        setAddCreditProfile(false);
        onUpdateClear();
    }
    const isAddLoading = () => {
        return isLoading && creditProfileUpdateState?.loading === LoadingType.pending;
    };
    const [creditProfileSorted, setCreditProfileSorted] = useState<string | null>(null);
    function handleCPSortFieldChange(value: string) {
        if (creditProfileSorted && creditProfileSorted === value) {
            setCreditProfileSorted(null);
        } else {
            setCreditProfileSorted(value);
        }
    }
    function getCPFilteredList(): ICompanyBackOfficeCreditProfile[] {
        let sortedList: ICompanyBackOfficeCreditProfile[] | undefined;
        if (creditProfileSorted != null) {
            sortedList = [...(formState.credit_profile ?? [])].sort((a, b) => {
                if (a[creditProfileSorted] < b[creditProfileSorted]) {
                    return -1;
                }
                if (a[creditProfileSorted] > b[creditProfileSorted]) {
                    return 1;
                }
                return 0;
            });
        }
        return (sortedList ?? (formState.credit_profile ?? []));
    }
    const handleEdit = (val: ICompanyBackOfficeCreditProfile, index: number) => {
        setAddCreditProfile(true);
        setCreditFormState(val);
        setEditIndex(index);
    };
    const handleDelete = (val: ICompanyBackOfficeCreditProfile, index: number) => {
        setDelRecord(true);
        setCreditFormState(val);
        setEditIndex(index);
    };
    const handleOnSuccessRemove = () => {
        handleAddCreditProfile(creditFormState, editIndex, true);
        handleOnSuccessClose();
        setDelRecord(false);
        onUpdateClear();
    }
    return (
        <SectionCard
            backgroundColor="#EBF1F8"
            activeColor="#0B3E78"
            title={t('credit_profile')} >
            <div className="cp-container">
                <div className="cp-header">
                    <div className="section-header">{t("credit_profile")}</div>
                    <div><CustomButton
                        leftIcon={getPlusBtnIcon()}
                        loading={false}
                        textStyle={{ textTransform: "capitalize" }}
                        name={t("add_credit_profile")}
                        enable={true}
                        backgroundColor={THEME.defaultHighLightColor}
                        onClick={() => setAddCreditProfile(true)}
                    /></div>
                </div>
                <div className="cp-content">
                    {/* {formState?.credit_profile && formState?.credit_profile?.length === 0 ?
                        (
                            <div className="empty-data-msg">
                                <span>{t("no_data_available")}</span>
                            </div>
                        )
                        :
                        ( */}
                            <SortableTable
                                headerList={creditProfileTableHeader}
                                sortedField={creditProfileSorted}
                                onSortChange={handleCPSortFieldChange}
                                flexNumber={getFlexNumber}
                            >
                                {getCPFilteredList().map((doc, index) => {
                                    return (
                                        <tr key={index} onClick={() => { }}>
                                            <TableData customStyle={{ flex: getFlexNumber(0) }}><span>{doc.credit_limit
                                                ? currencyConversion(doc.credit_limit)
                                                : "$0.00"}</span></TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(1) }}> <div>
                                                <ToggleSwitch
                                                    onChange={(value) => { }}
                                                    label={""}
                                                    checked={doc.currently_on_hold!}
                                                />
                                            </div></TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(2) }}>{doc.default_payment_option_value}</TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(3) }}>{doc.warning_by_dollar
                                                ? currencyConversion(doc.warning_by_dollar)
                                                : ""}</TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(4) }}><span>{doc.comment !== undefined && doc.comment.length > 50 ? doc?.comment?.substring(0, 50) + "..." : doc?.comment}</span></TableData>
                                            <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                                <span>
                                                    <button
                                                        className="edit-icon"
                                                        onClick={() => handleEdit(doc, index)}
                                                    >
                                                        <EditIcon
                                                            width={"100%"}
                                                            height={"100%"}
                                                            style={{ color: "#fff" }}
                                                        />
                                                    </button>
                                                    <button className="delete-icon" >
                                                        <DeleteIcon width={"100%"} height={"100%"} onClick={() => handleDelete(doc, index)} />
                                                    </button>
                                                </span>
                                            </TableData>

                                        </tr>
                                    );
                                })}
                            </SortableTable>
                        {/* )
                    } */}

                </div>
            </div>
            {addCreditProfile &&
                <Portal>
                    <AddCreditProfilePopup
                        visible={addCreditProfile}
                        title={t("add_new_credit_profile")}
                        successTitle={t("new_credit_profile")}
                        onClose={() => { setCreditFormState(initialForm); setAddCreditProfile(false); setEditIndex(undefined); }}
                        onSuccessClose={handleOnSuccessClose}
                        onDissmiss={() => { setAddCreditProfile(false); setEditIndex(undefined); }}
                        creditFormState={creditFormState}
                        handleFormFieldChange={handleFormFieldChange}
                        handleSave={handleSave}
                        isAddLoading={isAddLoading()}
                        loading={creditProfileUpdateState?.loading === LoadingType.succeeded}
                        currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                        index={editIndex}
                    />
                </Portal>}
            {delRecord &&
                <Portal>
                    <BODeleteRecordPopup
                        clearError={onClearError}
                        error={creditProfileUpdateState.error}
                        onRemove={handleOnSuccessRemove}
                        onCancel={() => setDelRecord(false)}
                        saving={false}
                        title={t("delete_bo_record_message", {
                            name: "this credit profile",
                        })}
                        visible={delRecord}
                    />
                </Portal>
            }
        </SectionCard>);
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1.5;
        if (value === 2) return 1.9;
        if (value === 3) return 1.7;
        if (value === 4) return 1;
        if (value === 5) return 0.6;


        return 1;
    }
}

export default CreditProfile;