import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./manage_user_thunk";

export interface ManageUserState {
    create: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    },
    toggleRole: {
        error?: AppError | null,
        loading: LoadingType,
        response?: string,
    }
}

const initialState: ManageUserState = {
    create: {
        error: null,
        loading: LoadingType.idle,
    },
    update: {
        error: null,
        loading: LoadingType.idle,
    },
    toggleRole: {
        error: null,
        loading: LoadingType.idle,
    }
}


export const manageUserSlice = createSlice({
    name: 'manageUser',
    initialState: initialState,
    reducers: {
        clearCreateState(state) {
            state.create = { ...initialState.create };
        },
        clearUpdateState(state) {
            state.update = { ...initialState.update };
        },
        clearToggleRoleState(state) {
            state.toggleRole = { ...initialState.toggleRole };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.createUser.pending, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.createUser.rejected, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.createUser.fulfilled, (state, action) => ({ ...state, create: { ...state.create, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            .addCase(thunk.updateUser.pending, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.updateUser.rejected, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.updateUser.fulfilled, (state, action) => ({ ...state, update: { ...state.update, loading: LoadingType.succeeded, error: null, response: action.payload } }))

            ///Toggle user role
            .addCase(thunk.toggleUserRole.pending, (state, action) => ({ ...state, toggleRole: { ...state.toggleRole, loading: LoadingType.pending, error: null, } }))
            .addCase(thunk.toggleUserRole.rejected, (state, action) => ({ ...state, toggleRole: { ...state.toggleRole, loading: LoadingType.failed, error: action.payload, } }))
            .addCase(thunk.toggleUserRole.fulfilled, (state, action) => ({ ...state, toggleRole: { ...state.toggleRole, loading: LoadingType.succeeded, error: null, response: action.payload } }))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const manageUserActions = manageUserSlice.actions;
export const selectState = (state: ManageUserState) => state;
export const selectCreateState = (state: ManageUserState) => state.create;
export const selectUpdate = (state: ManageUserState) => state.update;
export const selectToggleRoleState = (state: ManageUserState) => state.toggleRole;


