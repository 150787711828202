import { RouteComponentProps } from "react-router-dom";
import TabOptionsHeader from "../../../../components/tab_options_header/tab_options_header";
import { postSnackbarMessage, useAppDispatch, useAppSelector } from "../../../../redux/store";
import { deleteTeamManagementTeams, getTeamManagementTeamMembersDetailsById, getTeamManagementTeamsDetails, selectTeamManagementTeamsDetailsList, selectTeamManagementTeamsDetailsState } from "../../../../redux/admin_center/team_management";
import { useEffect, useState } from "react";
import MyProfileUserInfoCard from "../../../../components/my_profile_info_card/my_profile_info_card";
import { CustomButton, SpinnerScreen, getPlusBtnIcon } from "../../../../utils";
import { LoadingType, THEME } from "../../../../enums";
import { useTranslation } from "react-i18next";
import './team_list_page.scss'
import { AppRoutes } from "../../../../routes";
import { DeleteConfirmationDialog } from "../../../components/delete_confirmation_dialog";
import { selectMyProfileTeamsDetailsState } from "../../../../redux/my_profile";
import { teamManagementActions } from "../../../../redux/admin_center/team_management/team_services/team_services_reducer";
import TeamDetailsListPage from "../team_details_list/team_details_page";
import { Portal } from "react-portal";
import AddTeams from "../../popups/add_team/add_team";



interface Props extends RouteComponentProps<any> { }

const TeamListPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showAddTeam, setShowAddTeam] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [teamState, setTeamState] = useState<{ id: string, name: string }>()
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [showEditTeam, setShowEditTeam] = useState<boolean>(false);

    const [deleteTeam, setDelteTeam] = useState<{ teamid: string | undefined, teamname: string | undefined }>()
    const [teamId, setTeamId] = useState<string>("")

    const teamManagementTeamDetailsList = useAppSelector((state) => selectTeamManagementTeamsDetailsList(state))
    const teamManagementTeamDetailsState = useAppSelector((state) => selectTeamManagementTeamsDetailsState(state))

    useEffect(() => {
        dispatch(getTeamManagementTeamsDetails())
    }, [])

    useEffect(() => {
        if (teamManagementTeamDetailsState?.CreateTeam?.loading === LoadingType.succeeded) {
            dispatch(getTeamManagementTeamsDetails())
            setShowAddTeam(false)
        }
        if (teamManagementTeamDetailsState?.UpdateTeam?.loading === LoadingType.succeeded) {
            dispatch(getTeamManagementTeamsDetails())
            setShowEditTeam(false)
        }
        if (teamManagementTeamDetailsState?.DeleteTeam?.loading === LoadingType.succeeded) {
            dispatch(getTeamManagementTeamsDetails())
            setShowDeleteDialog(false)
        }
    }, [teamManagementTeamDetailsState?.CreateTeam?.loading, teamManagementTeamDetailsState?.UpdateTeam?.loading, teamManagementTeamDetailsState?.DeleteTeam?.loading])

    useEffect(() => {
        if (teamManagementTeamDetailsState?.DeleteTeam?.loading === LoadingType.succeeded) {
            if (snackBarMessage != "")
                dispatch(postSnackbarMessage(snackBarMessage ?? null));
        }
    }, [teamManagementTeamDetailsState?.DeleteTeam?.loading])
    function handleDelete(id: string | undefined) {
        if (id) {
            dispatch(deleteTeamManagementTeams(id))
        }
        setShowDeleteDialog(false)
        setSnackBarMessage("Team Deleted Successfully!");
        dispatch(teamManagementActions.clearTeamManagementError())
    }

    function handleClearTeamError() {
        setShowAddTeam(false)
        setShowEditTeam(false)
        dispatch(teamManagementActions.clearTeamManagementError())
        dispatch(teamManagementActions.clearCreateTeamManagementState())
        dispatch(teamManagementActions.clearUpdateTeamManagementState())
    }

    function isError() {
        return teamManagementTeamDetailsState?.CreateTeam.error ||
            teamManagementTeamDetailsState?.UpdateTeam.error
    }

    function isLoading() {
        return teamManagementTeamDetailsState?.CreateTeam.loading ||
            teamManagementTeamDetailsState?.UpdateTeam.loading
    }

    function handleBack() {
        props.history.push({
            pathname: `${AppRoutes.adminCenterPage}`,
        })
    }

    function handleTeamSelect(value) {
        props.history.push({
            pathname: `${AppRoutes.adminCenterTeamManagement}/${value.id}`,
            state: {
                id: value.id,
                name: value.name,
            }
        })
    }

    const handleDeleteTeam = (id: string | undefined, name: string | undefined) => {
        setShowDeleteDialog(true);
        setDelteTeam({ teamid: id, teamname: name })
    }

    const handleUpdateTeam = (teamId: string | undefined) => {
        if (teamId !== undefined) {
            dispatch(getTeamManagementTeamMembersDetailsById(teamId));
            setShowEditTeam(true);
            setTeamId(teamId);
        }

    }
    /// spinner 

    const DetailsLoading = () => {

        return (
            <div className="ac-tm-team-loading">
                <SpinnerScreen />
            </div>
        );
    }
    return (
        <div className="team-manage-container">
            <div className="team-manage-header">
                <TabOptionsHeader list={[]} onClose={handleBack} />
            </div>
            <div className="team-manage-content">
                <div className="team-total-count" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="total-count">
                        <span>{`${t('total_count')}: `} <span className="total-count-number">{teamManagementTeamDetailsList?.length}</span> </span>
                    </div>
                    <div>
                        <CustomButton
                            leftIcon={getPlusBtnIcon()}
                            loading={false}
                            textStyle={{ textTransform: 'capitalize' }}
                            name={t('add_team')}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => setShowAddTeam(true)}
                        />
                    </div>
                </div>
                <div className="team-section">
                    {teamManagementTeamDetailsState.loading === LoadingType.pending ? DetailsLoading() : 
                        teamManagementTeamDetailsList?.map((option, index) => (
                            <div key={index} className="tms-flex-card">
                                <MyProfileUserInfoCard
                                    teamData={option}
                                    onRemove={handleDeleteTeam}
                                    onTeamSelect={() => handleTeamSelect(option)}
                                    onUpdate={handleUpdateTeam}
                                    hideActions={true}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            {showAddTeam &&
                <Portal>
                    <AddTeams
                        visible={showAddTeam}
                        onClose={handleClearTeamError}
                        onDissmiss={() => setShowAddTeam(false)}
                        onSuccessClose={() => setShowAddTeam(false)}
                        error={isError()}
                        loading={isLoading()}
                        TMTeamState={teamManagementTeamDetailsState}
                    />
                </Portal>
            }
            {showEditTeam &&
                <Portal>
                    <AddTeams
                        visible={showEditTeam}
                        onClose={handleClearTeamError}
                        onDissmiss={() => setShowEditTeam(false)}
                        onSuccessClose={() => setShowEditTeam(false)}
                        teamId={teamId}
                        error={isError()}
                        loading={isLoading()}
                        TMTeamState={teamManagementTeamDetailsState}
                    />
                </Portal>
            }
            {showDeleteDialog && (
                <DeleteConfirmationDialog
                    message={t("delete_team_message", {
                        team: deleteTeam?.teamname,
                    })}
                    onCancel={() => setShowDeleteDialog(false)}
                    onConfirm={() => handleDelete(deleteTeam?.teamid)}
                    deleting={teamManagementTeamDetailsState?.DeleteTeam?.loading === LoadingType.pending}
                    deleteType={t("team")}
                />
            )}
        </div>

    )
}

export default TeamListPage;