import { Portal, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { LoadingType, THEME } from '../../enums';
import { IEngagement } from '../../interfaces';
import { selectEngagementDeleteState, selectEngagementListState } from '../../redux/engagement/engagement_selector';
import { deleteEngagement, getEngagementDataById, postSnackbarMessage, useAppDispatch, useAppSelector } from '../../redux/store';
import { CustomButton, getPlusBtnIcon } from '../../utils';
import AddEnagement from './engagementsPopup';
import './engagementDetails.scss';
import { DeleteIcon, EditIcon, TickBlueIcon } from '../../icons';
import moment from 'moment';
import { dateToTimestamp, extractDateFromTimestamp, extractTimeFromTimestamp, getDateString } from '../../variables';
import { ActionDialogHolder, ActionDialogHolderType } from '../../components/action_dialog_holder/action_dialog_holder';
import ApiError from '../../components/api_error';
import { engagementListActions } from '../../redux/engagement/engagement_reducer';

interface IEngamentDetails {
    id: string;
    departmentId?: string,
    source: string,
    customStyle?: React.CSSProperties,
    onSuccessCreate?: () => void
}

const EngagementDetails = ({ id, departmentId, source, customStyle, onSuccessCreate }: IEngamentDetails) => {
    const dispatch = useAppDispatch();
    const engagementListState = useAppSelector((state) => selectEngagementListState(state));
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [engagementData, setEngagementData]: any = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const actionRef = useRef<ActionDialogHolderType>(null);
    const { loading, error, response } = useAppSelector((state) => selectEngagementDeleteState(state));
    useEffect(() => {
        dispatch(getEngagementDataById(id));
    }, [id]);

    useEffect(() => {
        if (loading === LoadingType.succeeded) {
            dispatch(postSnackbarMessage(response ?? null));
            setShowDelete(false);
            setEngagementData(null);
            dispatch(engagementListActions.clearDeleteState());
            if (onSuccessCreate) onSuccessCreate();
        }
        return () => { }
    }, [loading])

    const isLoading = () => {
        return engagementListState.loading === LoadingType.pending;
    }


    const handleClose = () => {
        setShowPopup(false);
    }

    function handleSuccessClose() {
        handleClose();
        if (onSuccessCreate) onSuccessCreate();
        dispatch(engagementListActions.clearSaveState());

    }

    function getStepIcon() {
        return (
            <div className="ac-um-add-icon">
                <TickBlueIcon fontSize='small' fill='#0B3E78' />
            </div>

        )
    }
    function getDateTime(timestamp) {
        let date = new Date(timestamp * 1000);
        return date.toLocaleString().replace(',', '');
    }

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }
    function handleClearError() {
        dispatch(engagementListActions.clearDeleteState());
    }
    const onClose = () => {
        setShowDelete(false);
    }
    const onDissmiss = () => {
        setShowDelete(false);
    }

    const handleEdit = (val: IEngagement) => {
        const dateSting = extractDateFromTimestamp(val.date_time!)
        const dateTimeStamp = dateToTimestamp(dateSting)
        const time = extractTimeFromTimestamp(val.date_time!)
        const updatedEngagementData = {
            ...val,
            date: dateTimeStamp,
            time: time
        }
        // setEngagementData(h);
        setEngagementData(updatedEngagementData);
        setShowPopup(true)
    };

    return (
        <>
            <div className="eng-ud-container" style={customStyle}>
                <div className="eng-ud-table-header">
                    <div className="eng-ud-table-headerText">
                        {t('engagement_details')}
                    </div>
                    <div className="eng-ud-actions">
                        <div className="tlnt-ud-add-user-btn">
                            <CustomButton
                                leftIcon={getPlusBtnIcon()}
                                loading={false}
                                textStyle={{ textTransform: 'capitalize' }}
                                name={t('add_event')}
                                enable={true}
                                backgroundColor={THEME.defaultHighLightColor}
                                onClick={() => {
                                    setEngagementData(null)
                                    setShowPopup(true)

                                }}
                            />
                        </div>
                    </div>

                </div>
                <div className="eng-ud-table">
                    {isLoading() ? <div className="spinner-holder"><Spinner /></div> :
                        <Stepper orientation="vertical" >
                            {engagementListState.data.map((h: IEngagement, index) => {
                                return (
                                    <Step key={index} active>
                                        <StepLabel StepIconComponent={getStepIcon}>
                                            <div className="action-btn">
                                                <Typography variant='h6' style={{ color: THEME.defaultHighLightColor }}>
                                                    {h.event_title}
                                                </Typography>
                                                {
                                                    h.can_delete &&
                                                    <button style={{ width: "15px", height: "20px", color: "#0b3e78", margin: 5 }}
                                                        // onClick={() => {
                                                        //     setEngagementData(h);
                                                        //     setShowPopup(true)
                                                        // }}
                                                        onClick={() => handleEdit(h)}
                                                    >
                                                        <EditIcon width={"100%"} height={"100%"} style={{ color: "#0b3e78" }} />
                                                    </button>
                                                }
                                                {
                                                    h.can_delete &&
                                                    <button style={{ width: "15px", height: "20px", color: "#0b3e78", margin: 5 }} onClick={() => {
                                                        setEngagementData(h);
                                                        setShowDelete(true)
                                                    }}>
                                                        <DeleteIcon width={"100%"} height={"100%"} style={{ color: "#0b3e78" }} />
                                                    </button>
                                                }

                                            </div>
                                        </StepLabel>
                                        <StepContent>
                                            <div>
                                                <span style={{ color: THEME.defaultHighLightColor, textTransform: 'none' }}>{h.description}</span>
                                            </div>
                                            <div>
                                                <span style={{ color: THEME.defaultHighLightColor }}>{h.date_time ? getDateString(h.date_time) : '--'}</span>
                                            </div>
                                        </StepContent>
                                    </Step>
                                )
                            }
                            )}

                        </Stepper>
                    }
                </div>

                {showPopup && <Portal>
                    <AddEnagement
                        showPopup={showPopup}
                        source={source}
                        object_id={(departmentId && departmentId !== null && departmentId !== "") ? departmentId : id}
                        onClose={handleClose}
                        onDissmiss={handleClose}
                        onSuccessClose={handleSuccessClose}
                        engagementData={engagementData}
                    />
                </Portal>}
            </div>
            {
                showDelete &&
                <ActionDialogHolder
                    ref={actionRef}
                    visible={true}
                    wrapperWidth={430}
                    onClose={onClose}
                    onDissmiss={onDissmiss}
                >
                    <div className="del-role-container">
                        <div className="del-role-header">
                            <div className="title-txt">
                                <span>{`${t('attention')}!!`}</span>
                            </div>
                        </div>
                        <div className="del-role-content">
                            <div className="message-txt">
                                <span>{t('are_you_sure_you_want_to_delete', { value: engagementData.event_title })}</span>
                            </div>
                            {error && <div className={"error-section"}>
                                <ApiError message={error.message} onClose={handleClearError} />
                            </div>}
                        </div>
                        <div className="del-role-action-holder">
                            <div className="del-role-action">
                                <div className="btn-no">
                                    <CustomButton
                                        loading={false}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('cancel')}
                                        enable={true}
                                        backgroundColor={THEME.secondaryColor4}
                                        onClick={() => closePopup(onClose)}
                                    />
                                </div>
                                <div className="btn-yes">
                                    <CustomButton
                                        loading={loading === LoadingType.pending}
                                        textStyle={{ textTransform: 'capitalize' }}
                                        name={t('delete')}
                                        enable={engagementData.id != ""}
                                        backgroundColor={THEME.defaultHighLightColor}
                                        onClick={() => {
                                            dispatch(deleteEngagement(engagementData));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ActionDialogHolder>
            }
        </>
    )
}
export default EngagementDetails;