import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { S3API } from "../../../../../../apis/s3_api";
import { ActionDialogHolderType, ActionDialogHolder } from "../../../../../../components/action_dialog_holder/action_dialog_holder";
import DropZone from "../../../../../../components/file_upload/file_upload_page";
import { LoadingType, THEME } from "../../../../../../enums";
import CloseIcon from "../../../../../../../assets/icons/close-btn2.svg";
import { createJobDocumentUploadDocument, updateJobDocumentUploadDocument } from "../../../../../../redux/job_order";
import { useAppDispatch } from "../../../../../../redux/store";
import { FormInput, CustomButton } from "../../../../../../utils";
import { CreateJobDocument, JobDocument } from "../../../../../../interfaces";
import { SingleJobDocumentState, jobsDocumentActions } from "../../../../../../redux/job_order/details/document/document_list/document_list_reducer";


interface Props {
    onClose: () => void,
    onDismiss: () => void,
    visible: boolean,
    onApprove: () => void,
    data?: JobDocument,
    state?: SingleJobDocumentState,
    jobOrderId: string;
}

const initial = {
    id: "",
    job_order_id: "",
    file_name: "",
    file_category: "",
    file_extension: "",
    date_uploaded: 0,
    uploaded_by: "",
    notes: "",
    branch_id: "",
    file_path: "",
    is_video: false,
}

const UploadDocument: React.FC<Props> = (props) => {
    const { onClose, onDismiss, visible, onApprove, jobOrderId, state, data } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    const [files, setFiles] = useState<any>();
    const [sentRequest, setSentRequest] = useState(false);
    const [formState, setFormState] = useState<CreateJobDocument>(data ?? initial);

    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

    function handleFiles(files) {
        setFiles(files);
    }

    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    function _isFormValid() {
        if (!data?.id)
            if (!files)
                return false;
        return true;
    }

    useEffect(() => {
        if (state?.documentUpload && state.documentUpload?.loading === LoadingType.succeeded && sentRequest) {
            S3API.uploadWithPreSignedURL(state?.documentUpload?.response, files[0]);
            setSentRequest(false);
            onApprove();
            closePopup(onClose);
            dispatch(jobsDocumentActions.clearJobDocUploadState());
        }
    }, [state?.documentUpload])

    useEffect(() => {
        if (state?.documentUpdate && state?.documentUpdate?.loading === LoadingType.succeeded && sentRequest) {
            S3API.uploadWithPreSignedURL(state?.documentUpdate?.response, files ? files[0] : data?.file_name);
            setSentRequest(false);
            onApprove();
            closePopup(onClose);
            dispatch(jobsDocumentActions.clearJobDocUpdateState());
        }
    }, [state?.documentUpdate, state?.documentUpdate?.loading])

    function saveDocument() {
        if (data && data?.id) {
            dispatch(updateJobDocumentUploadDocument(formState));
        }
        else {
            dispatch(createJobDocumentUploadDocument({ job_order_id: jobOrderId, file_name: files[0].name, notes: formState.notes }));
        }
        setSentRequest(true);
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={600}
            onClose={onClose}
            onDissmiss={onDismiss}>
            <div className="document-upload-container">
                <div className="document-upload-container-dialog-header">
                    <h5>{data?.id ? t('update_document') : t('upload_document')}</h5>
                    <img src={CloseIcon} alt="close_btn" onClick={() => closePopup(onClose)} style={{ "cursor": "pointer" }} />
                </div>
                <div className="document-upload-container-dialog-content">
                    <FormInput
                        id={"notes"}
                        onChange={handleFieldChange}
                        type={"text"}
                        value={formState?.notes}
                        label={t("note")}
                        placeholder=""
                    />
                    {
                        !data?.id &&
                            <div>
                                <p>{t('documents')}:</p>
                                <div>
                                    <DropZone maxFiles={1} handleFiles={handleFiles} maxSize={26214400} />
                                </div>
                            </div>
                    }
                </div>
                <div className="document-upload-container-dialog-footer">
                    <div>
                        <CustomButton
                            textStyle={{ textTransform: 'capitalize' }}
                            enable={_isFormValid()}
                            loading={sentRequest}
                            name={data?.id ? t('update_document') : t('upload_document')}
                            padding={12}
                            fontSize={15}
                            backgroundColor={THEME.buttonColor1}
                            onClick={saveDocument}
                        />
                    </div>
                </div>
            </div>
        </ActionDialogHolder>
    )
}

export default UploadDocument