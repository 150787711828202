import { useTranslation } from "react-i18next";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import PageNavHeader from "../../components/page_header/page_nav_header";
import TabNavigation from "../../components/tab_navigation/tab_navigation";
import { AppRoutes, getTalentsNavHeaderProps } from "../../routes";
import TalentDashboardPage from "./pages/talent_dashboard/talent_dashboard_page";
import UniversalDirectoryPage from "./pages/universal_directory/universal_directory_page";
import { useEffect } from "react";
import { selectProfileState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getCertificationsList, getCredentialsList, getEducationList, getRequiredEquipmentList, getSkillSetList, } from "../../redux/store";
import { getCompetitorsList, getRoleUsersList } from "../../redux/selectors";
import EmploymentHistoryPage from "./pages/employment_history/employment_history_page";
import InDevelopment from "../../others/in_development";
import Interviews from "./pages/interviews/interviews";
import TalentsAssignmentPage from "./pages/talent_assignments/talent_assignments_page";
import OnBoardingAndDocs from "./pages/on_boarding_and_docs/on_boarding_and_docs";
import { GetCloseAction } from "../../utils";
import RiskManagement from "./pages/risk_management/risk_management";
import BackOfficePayRollSetUp from "./pages/back_office_payroll_set_up/back_office_payroll_set_up";

interface Props extends RouteComponentProps<any> { }


/// Company Details
const TalentDetailsTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { params } = match;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const pathSplit = location.pathname.split('/');
    const departmentId = pathSplit.length === 6 ? pathSplit[pathSplit.length - 1] : null;
    const profileState = useAppSelector((state) => selectProfileState(state));
    const canViewOnboardDocs = profileState.profile?.view_onboarding_data;
    useEffect(() => {
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(getRoleUsersList());
        dispatch(getCompetitorsList());

        return () => { }
    }, [])

    function handleNavigationChange(value: string) {
        history.push({
            pathname: departmentId ? `${value}/${departmentId}` : value,
            state: location.state
        });
    }

    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'dashboard', route: `${AppRoutes.talentDetailsPage}/${params.id}/dashboard` },
                { title: 'assignments', route: `${AppRoutes.talentDetailsPage}/${params.id}/assignments` },
                { title: 'employment_history', route: `${AppRoutes.talentDetailsPage}/${params.id}/employment-history` },
                { title: 'interviews', route: `${AppRoutes.talentDetailsPage}/${params.id}/interviews` },
                { title: 'back_office_payroll_set_up', route: `${AppRoutes.talentDetailsPage}/${params.id}/back-office-payroll-set-up` },
                { title: 'risk_management', route: `${AppRoutes.talentDetailsPage}/${params.id}/risk-management` },
                ...canViewOnboardDocs ? [{ title: 'onboarding_and_docs', route: `${AppRoutes.talentDetailsPage}/${params.id}/onboarding-and-docs` }] : [],

            ]}
            onChange={handleNavigationChange}
            actions={<GetCloseAction name={t('close')} onClick={() => history.push(AppRoutes.talentPage)} />}
        >
            <Switch>
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/dashboard/:departmentId?`} component={TalentDashboardPage} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/assignments/:departmentId?`} component={TalentsAssignmentPage} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/employment-history/:departmentId?`} component={EmploymentHistoryPage} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/interviews/:departmentId?`} component={Interviews} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/back-office-payroll-set-up/:departmentId?`} component={BackOfficePayRollSetUp} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/risk-management/:departmentId?`} component={RiskManagement} />
                <Route exact path={`${AppRoutes.talentDetailsPage}/:id/onboarding-and-docs/:departmentId?`} component={OnBoardingAndDocs} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: `${location.pathname}/dashboard`,
                            state: { ...(location.state as any) }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );

}

/// Universal directory folder
const TalentTabNav: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    return (
        <TabNavigation
            activeRoute={location.pathname}
            tabList={[
                { title: 'universal_directory', route: AppRoutes.talentUniversalDirectory },
            ]}
            onChange={(value) => history.push(value)}
        >
            <Switch>
                <Route exact path={AppRoutes.talentUniversalDirectory} component={UniversalDirectoryPage} />
                <Route path={'/'} render={({ location }) => (
                    <Redirect
                        to={{
                            pathname: AppRoutes.talentUniversalDirectory,
                            state: { from: location }
                        }}
                    />
                )} />
            </Switch>
        </TabNavigation>
    );
}



export const TalentNavigation: React.FunctionComponent<Props> = (props: Props) => {
    const { history, location, match } = props;
    const { t } = useTranslation();
    const headerOptions = getTalentsNavHeaderProps(location, match, t);

    return (
        <PageNavHeader data={headerOptions} history={history} location={location}>
            <Switch>
                <Route path={`${AppRoutes.talentDetailsPage}/:id`} component={TalentDetailsTabNav} />
                <Route path={'/'} component={TalentTabNav} />
            </Switch>
        </PageNavHeader>
    );
}

