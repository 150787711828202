import { useTranslation } from "react-i18next";
import CustomFormSelect from "../../../../../components/form_selector/form_select";
import { CustomButton, FormInput, getUserNameFromProfileDropdown } from "../../../../../utils";
import { onBoardingHealthCareOptions } from "../../../../../variables";
import { useAppDispatch } from "../../../../../redux/store";
import { useState } from "react";
import { EditIcon } from "../../../../../icons";
import { AgencyOnboarding, AppError, IProfileDropdown } from "../../../../../interfaces";
import { LoadingType, THEME } from "../../../../../enums";
import { updateAgencyOnboarding } from "../../../../../redux/admin_center";
import { Form } from "reactstrap";
import ApiError from "../../../../../components/api_error";
import { clearHealthCareError } from "../../../../../redux/admin_center/system_configuration/onboarding/onboarding_reducer";

interface Props {
    roleUsersList: IProfileDropdown[]
    data: AgencyOnboarding;
    saveResponse: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    }
}

const getInitialForm = (value?: AgencyOnboarding): AgencyOnboarding => {
    if (value) {
        return {
            id: value?.id,
            esc_api_key: value?.esc_api_key,
            wotc_api_key: value?.wotc_api_key,
            welcome_video_path: value?.welcome_video_path,
            employee_handbook_path: value?.employee_handbook_path,
            terms_and_conditions: value?.terms_and_conditions,
            agency_id: value?.agency_id,
            is_deleted: value?.is_deleted,
            video_upload_timestamp: value?.video_upload_timestamp,
            employee_handbook_upload_timestamp: value?.employee_handbook_upload_timestamp,
            health_care_info: value?.health_care_info,
        } as AgencyOnboarding;
    }

    return {
        id: "",
        esc_api_key: "",
        wotc_api_key: "",
        welcome_video_path: "",
        employee_handbook_path: "",
        terms_and_conditions: "",
        agency_id: "",
        is_deleted: false,
        video_upload_timestamp: 0,
        employee_handbook_upload_timestamp: 0,
        health_care_info: {
            all_employees_are_eligible: false,
            dependent_coverage_available: false,
            dependents_covered: "",
            eligible_employees: "",
            health_plan_meets_minimum_value_standard: false,
            healthcare_adviser_email: "",
            healthcare_adviser_id: "",
            healthcare_adviser_name: "",
            healthcare_adviser_phone: "",
            is_employee_eligible: false
        },
    } as AgencyOnboarding;
}

const HealthCareForm: React.FC<Props> = props => {
    const { roleUsersList, data, saveResponse } = props
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [formState, setFormState] = useState<AgencyOnboarding>(getInitialForm(data));
    const [inputEnable, setInputEnable] = useState<boolean>(true);


    function handleFieldChange(fieldId: string, value: any, parent?: string) {
        if (parent) {
            setFormState({
                ...formState,
                [parent]: { ...formState[parent], [fieldId]: value },
            });
        } else {
            setFormState({ ...formState, [fieldId]: value });
        }
    }

    const isAddLoading = () => {
        return saveResponse.loading === LoadingType.pending;
    };

    const handleEdit = () => {
        setInputEnable(false);
    };

    function handleClearError() {
        dispatch(clearHealthCareError());
    }

    const handleSave = () => {
        if (formState) {
            dispatch(
                updateAgencyOnboarding({
                    ...formState,
                    health_care_info: {
                        ...formState.health_care_info,
                        healthcare_adviser_name: formState?.health_care_info?.healthcare_adviser_name,
                        all_employees_are_eligible: formState?.health_care_info?.all_employees_are_eligible,
                        eligible_employees: formState?.health_care_info?.all_employees_are_eligible ? "" : formState?.health_care_info?.eligible_employees,
                        dependent_coverage_available: formState?.health_care_info?.dependent_coverage_available,
                        dependents_covered: formState?.health_care_info?.dependents_covered,
                        health_plan_meets_minimum_value_standard: formState?.health_care_info?.health_plan_meets_minimum_value_standard,
                    },

                })
            );
            setInputEnable(true);
        }

    };

    return (
        <div>
            <div className="ac-sc-ob-content-container">
                <div className="ac-ob-btn-header">
                    <button className="icon-btn ac-ob-edit-icon" onClick={() => { }}>
                        <EditIcon
                            width={"100%"}
                            height={"100%"}
                            style={{ color: "#fff" }}
                            onClick={handleEdit}
                            className="hv-icon-btn"
                        />
                    </button>
                </div>
                <div className="ac-sc-ob-content ac-sc-ob-company-content">
                    <Form>
                        <div>
                            <CustomFormSelect
                                name={t("healthcare_adviser_name")}
                                list={roleUsersList.map(doc => ({ label: getUserNameFromProfileDropdown(doc), value: doc.id }))}
                                onChange={(value) => handleFieldChange("healthcare_adviser_id", value, "health_care_info")}
                                value={formState?.health_care_info?.healthcare_adviser_id}
                                placeholder={"Select"}
                                label={t("healthcare_adviser_name")}
                                customStyle={{ width: "15vw" }}
                                labelStyle={{ fontSize: "14px" }}
                                disabled={inputEnable}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <CustomFormSelect
                                    name={t("all_employees_are_eligible")}
                                    list={onBoardingHealthCareOptions}
                                    onChange={(value) => handleFieldChange("all_employees_are_eligible", value, "health_care_info")}
                                    value={formState?.health_care_info?.all_employees_are_eligible}
                                    placeholder={"Select"}
                                    label={t("all_employees_are_eligible")}
                                    customStyle={{ width: "15vw" }}
                                    labelStyle={{ fontSize: "14px" }}
                                    disabled={inputEnable}
                                />
                            </div>
                            <div style={{ marginLeft: "2rem" }}>
                                {
                                    formState?.health_care_info?.all_employees_are_eligible === false ? <FormInput
                                        id={"eligible_employees"}
                                        onChange={(id, value) => handleFieldChange(id, value, "health_care_info")}
                                        type={"text"}
                                        value={formState?.health_care_info?.eligible_employees}
                                        label={t("eligible_employees")}
                                        placeholder=""
                                        required={false}
                                        customStyle={{ minWidth: "30vw" }}
                                        disabled={inputEnable}
                                    /> :
                                        null
                                }
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div>
                                <CustomFormSelect
                                    name={t("dependent_coverage_available")}
                                    list={onBoardingHealthCareOptions}
                                    onChange={(value) => handleFieldChange("dependent_coverage_available", value, "health_care_info")}
                                    value={formState?.health_care_info?.dependent_coverage_available}
                                    placeholder={"Select"}
                                    label={t("dependent_coverage_available")}
                                    customStyle={{ width: "15vw" }}
                                    labelStyle={{ fontSize: "14px" }}
                                    disabled={inputEnable}
                                />
                            </div>
                            <div style={{ marginLeft: "2rem" }}>
                                {formState?.health_care_info?.dependent_coverage_available === true ? <FormInput
                                    id={"dependents_covered"}
                                    onChange={(id, value) => handleFieldChange(id, value, "health_care_info")}
                                    type={"text"}
                                    value={formState?.health_care_info?.dependents_covered}
                                    label={t("dependents_covered")}
                                    placeholder=""
                                    required={false}
                                    customStyle={{ minWidth: "30vw" }}
                                    disabled={inputEnable}
                                /> :
                                    null
                                }
                            </div>
                        </div>
                        <div>
                            <CustomFormSelect
                                name={t("health_plan_meets_minimum_value_standard")}
                                list={onBoardingHealthCareOptions}
                                onChange={(value) => handleFieldChange("health_plan_meets_minimum_value_standard", value, "health_care_info")}
                                value={formState?.health_care_info?.health_plan_meets_minimum_value_standard}
                                placeholder={"Select"}
                                label={t("health_plan_meets_minimum_value_standard")}
                                customStyle={{ width: "15vw" }}
                                labelStyle={{ fontSize: "14px" }}
                                disabled={inputEnable}
                            />
                        </div>
                        {saveResponse?.error && <div className="error-holder">
                            <ApiError message={saveResponse?.error.message} onClose={handleClearError} />
                        </div>}
                    </Form>
                </div>
                <div className="ac-sc-ob-content-actions company-actions">
                    <div className="btn-cancel">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("cancel")}
                            enable
                            backgroundColor={THEME.secondaryColor4}
                            onClick={() => {
                                setFormState(getInitialForm(data));
                                setInputEnable(true);
                            }}
                        />
                    </div>
                    <div className="btn-save">
                        <CustomButton
                            loading={isAddLoading()}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("save")}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={handleSave}
                            enable={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthCareForm;
