import { createSlice } from "@reduxjs/toolkit";
import { LoadingType } from "../../../../enums";
import { AppError } from "../../../../interfaces";
import * as thunk from "./security_policy_thunk";

export interface PasswordExpirationDaysState {
    error?: AppError | null,
    loading: LoadingType,
    passwordExpirationDays: number | null,
    update: {
        error?: AppError | null,
        loading: LoadingType,
        response: string
    }
}

const initialState: PasswordExpirationDaysState = {
    error: null,
    loading: LoadingType.idle,
    passwordExpirationDays: null,
    update: {
        error: null,
        loading: LoadingType.idle,
        response: ''
    }
}

export const passwordExpirationDaysSlice = createSlice({
    name: 'passwordExpirationDays',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getPasswordExpirationDays.pending, (state, action) => ({ ...state, loading: LoadingType.pending, error: null, }))
            .addCase(thunk.getPasswordExpirationDays.rejected, (state, action) => ({ ...state, loading: LoadingType.failed, error: action.payload, }))
            .addCase(thunk.getPasswordExpirationDays.fulfilled, (state, action) => ({...state, loading: LoadingType.succeeded, passwordExpirationDays: action.payload}))

            .addCase(thunk.updatePasswordExpirationDays.pending, (state, action) => ({...state, update: {...state.update, loading: LoadingType.pending, error: null}}))
            .addCase(thunk.updatePasswordExpirationDays.rejected, (state, action) => ({...state, update: {...state.update, loading: LoadingType.failed, error: action.payload}}))
            .addCase(thunk.updatePasswordExpirationDays.fulfilled, (state, action) => ({...state, update: {...state.update, loading: LoadingType.succeeded, response: action.payload}}))
            .addDefaultCase(state => ({ ...state }));
    }
});

export const { } = passwordExpirationDaysSlice.actions;
export const selectState = (state: PasswordExpirationDaysState) => state;


