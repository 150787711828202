import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmployeePortalAPI } from "../../../../apis";
import { IVacationHistory, AppError } from "../../../../interfaces";
import { catchRequestError } from "../../../../utils";

export const getVacationHistoryThunk = createAsyncThunk<
    IVacationHistory[],
    string,
    {
        rejectValue: AppError | null,
        rejectedMeta: AppError | null,
    }
>(
    '@employee-portal/dashbord/payroll_details/vacation_histroy/get',
    async (id, thunkAPI) => {
        try {
            const res = await EmployeePortalAPI.getVacationHistoryDetails(id);
            if (typeof res.data === 'string') return [];
            return res.data as IVacationHistory[];
        } catch (error) {
            return catchRequestError(error, thunkAPI);
        }
    }
);