import { CloseIcon } from '../../icons';
import './dialog_wrapper.scss';

interface Props {
    children: React.ReactNode,
    onClose: () => void
}

const DialogWrapper: React.FunctionComponent<Props> = (props) => {
    return (
        <div id="dialog-wrapper" className="dg-wrapper-container">
            {props.children}
            <button className="icon-btn close-action" onClick={props.onClose}>
                <CloseIcon width={"100%"} height={"100%"} />
            </button>
        </div>
    );
}

interface FormDialogTilteHeaderProps {
    title: string,
    subTitle?: string,
    titleStyle?: React.CSSProperties,
    subTitleStyle?: React.CSSProperties,
    titleIcon: React.ReactNode,
}

export const FormDialogTilteHeader: React.FunctionComponent<FormDialogTilteHeaderProps> = (props) => {

    return (
        <div className="title-holder">
            <div className="title-icon" style={props.titleStyle}>
                {props.titleIcon}
            </div>
            <div className="title-holder">
                <div className="title-name">
                    <span>{props.title}</span>
                </div>
                {props.subTitle && <div className="sub-title-name">
                    <span style={props.subTitleStyle}>{props.subTitle}</span>
                </div>}
            </div>
        </div>
    )
}

export default DialogWrapper;