

import { createSlice } from '@reduxjs/toolkit';
import * as thunk from './file_download_thunk';
import { AppError } from '../../../interfaces';
import { LoadingType } from '../../../enums';

export interface  FileDownloadURLState {
    response: string | null,
    error?: AppError | null,
    loading: LoadingType,
}

const initialState:  FileDownloadURLState = {
    error: null,
    loading: LoadingType.idle,
    response: null,
}

export const fileDownloadSlice = createSlice({
    name: "fileDownloadSlice",
    initialState: initialState,
    reducers: {
        clearState(state) {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(thunk.getDownloadFileURLThunk.pending, (state, action) => ({
                ...state,
                loading: LoadingType.pending,
                error: null,
            }))
            .addCase(thunk.getDownloadFileURLThunk.rejected, (state, action) => ({
                ...state,
                loading: LoadingType.failed,
                error: action.payload,
            }))
            .addCase(thunk.getDownloadFileURLThunk.fulfilled, (state, action) => ({
                ...state,
                loading: LoadingType.succeeded,
                response: action.payload,
            }))

            .addDefaultCase((state) => ({ ...state }));
    }
});
export const fileDownloadSliceActions = fileDownloadSlice.actions;
export const selectState = (state:  FileDownloadURLState) => state;