import TableEmpty, { TableErrorHandler } from "../../../../components/table_empty/table_empty"
import { LoadingType, THEME } from "../../../../enums"
import { LeftArrowIcon, RightArrowIcon } from "../../../../icons"
import VidePlayer from "./video_player"
import './marketing_docs.scss';

const VideoData = ({ data, cursorLeftClick, cursorRightClick, cursor, onRetry, state }) => {
    if (state.loading === LoadingType.failed)
        return (<div className="job-order-documents-videos-content-body no-data"><TableErrorHandler error={state.error} onRefresh={onRetry} /></div>)
    if (data.length > 0) {
        return (
            <div className="job-order-documents-videos-content-body data-found">
                <LeftArrowIcon onClick={cursorLeftClick} style={{ "cursor": cursor === 0 ? "auto" : "pointer" }} />
                <VidePlayer url={data[2 * cursor].file_path} title={data[2 * cursor].file_name} id={data[2 * cursor]?.id} onRetry={onRetry} />
                {data.length > 1 + (2 * cursor) ? <VidePlayer url={data[(2 * cursor) + 1].file_path} title={data[(2 * cursor) + 1].file_name} id={data[(2 * cursor) + 1]?.id} onRetry={onRetry} /> : <></>}
                {/* <RightArrowIcon onClick={cursorRightClick} style={{"cursor": Math.ceil(data.length/2) - 1 === cursor ? "auto": "pointer"}}/> */}
                <RightArrowIcon onClick={cursorRightClick} style={{ "cursor": data.length > (2 * cursor) + 2 ? "pointer" : "auto", color: THEME.defaultHighLightColor }} />
            </div>
        )
    }
    return (
        (<div className="job-order-documents-videos-content-body no-data"><TableEmpty title={"No data found"} onClick={onRetry} /></div>)
    )
}


export default VideoData;