import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDialogHolderType, ActionDialogHolder } from '../../../../components/action_dialog_holder/action_dialog_holder';
import DialogWrapper from '../../../../components/dialog_wrapper/dialog_wrapper';
import { LoadingType, ProfilePictureType, ROLE } from '../../../../enums';
import { IContact, ICreateContact, ICreateResponse } from '../../../../interfaces';
import { manageContactActions } from '../../../../redux/contacts/universal_directory/manage_contact/manage_contact_reducer';
import {
    postSnackbarMessage,
    useAppDispatch,
    useAppSelector,
    createContact,
    getAccountManagers,
    getBusinessSectors,
    getRecruiters,
    selectAccountManagersList,
    selectAccountManagersListState,
    selectBusinessSectorList,
    selectBusinessSectorListState,
    selectCompetitorsList,
    selectCompetitorsListState,
    selectManageContactCreateState,
    selectManageContactUpdateState,
    selectRecruitersList,
    selectRecruitersListState,
    updateContact,
    getCompetitorsList,
    selectCertificationsList,
    selectCredentialsList,
    selectEducationList,
    selectRequiredEquipmentList,
    selectSkillSetList,
    getSkillSetList,
    getCertificationsList,
    getCredentialsList,
    getEducationList,
    getRequiredEquipmentList,
    selectSkillSetListState,
    selectCredentialsListState,
    selectCertificationsListState,
    selectEducationListState,
    selectRequiredEquipmentListState,
    selectProfileState,
    selectManageContactFilesStateByType,
    getContactFileUploadURLThunk,
    uploadContactFileThunk,
} from '../../../../redux/store';
import { SpinnerScreen } from '../../../../utils';
import ContactForm from '../../forms/contact_form/contact_form';

import './create_contact.scss';
import { selectContactStatusState, selectContactStatusList, getContactStatus } from '../../../../redux/admin_center';

interface Props {
    visible: boolean,
    title: string,
    successTitle: string,
    contact?: IContact,
    data?: { company_id: string, department_ids?: string[] }
    onClose: () => void,
    onDissmiss?: () => void,
    onSuccessClose: () => void,
    onGotoAgencyDashboard: () => void,
    onGotoCreated: (id: string, name: string) => void,
}

const CreateContactPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        contact,
        data,
        onClose,
        onDissmiss,
        onSuccessClose,
        onGotoAgencyDashboard,
        onGotoCreated,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const photoType = "photo";
    const resumeType = "resume";
    const profileState = useAppSelector((state) => selectProfileState(state));
    const createState = useAppSelector((state) => selectManageContactCreateState(state));
    const updateState = useAppSelector((state) => selectManageContactUpdateState(state));
    const photoState = useAppSelector((state) => selectManageContactFilesStateByType(photoType)(state));
    const resumeState = useAppSelector((state) => selectManageContactFilesStateByType(resumeType)(state));
    const accountManagersListState = useAppSelector((state) => selectAccountManagersListState(state));
    const recruitersListState = useAppSelector((state) => selectRecruitersListState(state));
    const businessSectorListState = useAppSelector((state) => selectBusinessSectorListState(state));
    const competitorsListState = useAppSelector((state) => selectCompetitorsListState(state));

    const skillSetListState = useAppSelector((state) => selectSkillSetListState(state));
    const credentialsListState = useAppSelector((state) => selectCredentialsListState(state));
    const certificationsListState = useAppSelector((state) => selectCertificationsListState(state));
    const educationListState = useAppSelector((state) => selectEducationListState(state));
    const requiredEquipmentListState = useAppSelector((state) => selectRequiredEquipmentListState(state));

    /// list
    const accountManagersList = useAppSelector((state) => selectAccountManagersList(state));
    const recruitersList = useAppSelector((state) => selectRecruitersList(state));
    const businessSectorList = useAppSelector((state) => selectBusinessSectorList(state));
    const competitorsList = useAppSelector((state) => selectCompetitorsList(state));
    const actionRef = useRef<ActionDialogHolderType>(null);

    /// skills
    const skillSetList = useAppSelector((state) => selectSkillSetList(state));
    const credentialsList = useAppSelector((state) => selectCredentialsList(state));
    const certificationsList = useAppSelector((state) => selectCertificationsList(state));
    const educationList = useAppSelector((state) => selectEducationList(state));
    const requiredEquipmentList = useAppSelector((state) => selectRequiredEquipmentList(state));

    ///Files
    const [contactLogo, setContactLogo] = useState<File | null>(null);
    const [contactResume, setContactResume] = useState<File | null>(null);

    ///Contact Status
    const contactStatusState = useAppSelector((state) => selectContactStatusState(state));
    const contactStatusList = useAppSelector((state) => selectContactStatusList(state));

    useEffect(() => {
        getData();
        dispatch(getSkillSetList());
        dispatch(getCertificationsList());
        dispatch(getCredentialsList());
        dispatch(getEducationList());
        dispatch(getRequiredEquipmentList());
        dispatch(manageContactActions.initializeManageContactState([photoType, resumeType]))
        dispatch(getContactStatus());
    }, [])

    useEffect(() => {
        if (createState.loading == LoadingType.succeeded) {
            if (createState.response) handleNextStep(createState.response);
        }
        if (updateState.loading == LoadingType.succeeded) {
            if (updateState.response) handleNextStep(updateState.response);
        }
        return () => { }
    }, [createState.loading, updateState.loading])

    useEffect(() => {
        if (photoState.fileURL.loading == LoadingType.succeeded) {
            if (photoState.fileURL.response) dispatch(uploadContactFileThunk({ type: photoType, data: photoState.fileURL.response, file: contactLogo }));
        }
        return () => { }
    }, [photoState.fileURL.loading])

    useEffect(() => {
        if (resumeState.fileURL.loading == LoadingType.succeeded) {
            if (resumeState.fileURL.response) dispatch(uploadContactFileThunk({ type: resumeType, data: resumeState.fileURL.response, file: contactResume }));
        }
        return () => { }
    }, [resumeState.fileURL.loading])

    useEffect(() => {
        if (_isFileUploaded() == LoadingType.succeeded) {
            if(props.contact && props.contact.id) closePopup(onSuccessClose);
        }
        return () => { }
    }, [_isFileUploaded()])

    function _isFileUploaded(): LoadingType {
        return photoState.upload.loading || resumeState.upload.loading;
    }

    function handleNextStep(response: ICreateResponse) {
        if (contactLogo != null || contactResume != null) {
            if (contactLogo != null) dispatch(getContactFileUploadURLThunk({ type: photoType, query: { object_id: response.object_id, object_type: ProfilePictureType.contact, file_name: contactLogo.name } }));
            if (contactResume != null) dispatch(getContactFileUploadURLThunk({ type: resumeType, query: { object_id: response.object_id, object_type: ProfilePictureType.contact, file_name: contactResume.name } }));
        } else if (props.contact && props.contact.id) {
            dispatch(postSnackbarMessage(response.status ?? null));
            closePopup(onSuccessClose);
        } else {
            ///
        }
        // dispatch(postSnackbarMessage(response.status ?? null));
        // closePopup(onSuccessClose);
    }

    function getData() {
        dispatch(getAccountManagers({ role: ROLE.accountManager, branch_id: '' }));
        dispatch(getRecruiters({ role: ROLE.recruiter, branch_id: '' }));
        dispatch(getBusinessSectors());
        dispatch(getCompetitorsList());
    }

    function isInitializing() {
        return accountManagersListState.loading === LoadingType.pending ||
            recruitersListState.loading === LoadingType.pending ||
            businessSectorListState.loading === LoadingType.pending ||
            competitorsListState.loading === LoadingType.pending ||
            skillSetListState.loading === LoadingType.pending ||
            credentialsListState.loading === LoadingType.pending ||
            certificationsListState.loading === LoadingType.pending ||
            educationListState.loading === LoadingType.pending ||
            requiredEquipmentListState.loading === LoadingType.pending ||
            contactStatusState.loading === LoadingType.pending;
    }

    function isLoading() {
        return createState.loading ||
            updateState.loading ||
            photoState.fileURL.loading ||
            photoState.upload.loading ||
            resumeState.fileURL.loading ||
            resumeState.upload.loading;
    }

    function isError() {
        return createState.error ||
            updateState.error ||
            photoState.fileURL.error ||
            photoState.upload.error ||
            resumeState.fileURL.error ||
            resumeState.upload.error;
    }

    return (
        <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={"50%"}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <DialogWrapper onClose={() => closePopup(onClose)}>
                {isInitializing() ? <div className="loading-spinner"><SpinnerScreen /></div> : <ContactForm
                    title={props.title}
                    data={props.data}
                    successTitle={props.successTitle}
                    currentLoginUserName={`${profileState.profile?.first_name ?? ''} ${profileState.profile?.last_name ?? ''}`}
                    contact={props.contact}
                    accountManagers={accountManagersList}
                    recruiters={recruitersList}
                    businessSectors={businessSectorList}
                    competitors={competitorsList}
                    loading={isLoading()}
                    error={isError()}
                    onClearError={handleClearError}
                    onClose={() => closePopup(onClose)}
                    onSubmit={handleSubmit}
                    skillSetList={skillSetList}
                    credentialsList={credentialsList}
                    certificationsList={certificationsList}
                    educationList={educationList}
                    requiredEquipmentList={requiredEquipmentList}
                    /// Photo
                    selectedPhoto={contactLogo}
                    onPhotoChange={setContactLogo}
                    onRemovePhoto={() => setContactLogo(null)}
                    /// Resume
                    selectedResume={contactResume}
                    onResumeChange={setContactResume}
                    onRemoveResume={() => setContactResume(null)}

                    onGotoAgencyDashboard={handleGotoAgencyDashboard}
                    onCreateNew={handleCreateNew}
                    onGotoCreated={handleGotoCreated}
                    ContactStatus={contactStatusList}
                />}
            </DialogWrapper>
        </ActionDialogHolder>

    );

    ///Handle clear error
    function handleClearError() {
        dispatch(manageContactActions.clearManageContactStateError([photoType, resumeType]));
    }

    /// handle submit
    function handleSubmit(doc: { forceAdd: boolean, value: ICreateContact }) {
        if (props.contact && props.contact.id) {
            dispatch(updateContact({ ...props.contact, ...doc.value }));
        }
        else {
            dispatch(createContact(doc));
        }
    }

    function handleGotoAgencyDashboard() {
        closePopup(props.onGotoAgencyDashboard);
    }


    function handleCreateNew() {
        setContactLogo(null);
        setContactResume(null);
        dispatch(manageContactActions.clearManageContactState([photoType, resumeType]));
    }


    function handleGotoCreated(name: string) {
        if (props.contact && props.contact.id) {
            closePopup(onSuccessClose);
        } else if (createState.response && createState.response.object_id) {
            closePopup(() => props.onGotoCreated(createState?.response?.object_id!, name));
        }
    }


    function closePopup(action?: () => void) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(action);
    }

}

export default CreateContactPopup;