import { useRef } from "react";
import { ActionDialogHolder, ActionDialogHolderType } from "../../../../components/action_dialog_holder/action_dialog_holder";
import DialogWrapper, { FormDialogTilteHeader } from "../../../../components/dialog_wrapper/dialog_wrapper";
import { useTranslation } from "react-i18next";
import { BOVerifyBatchIcon } from "../../../../icons";
import { LoadingType, THEME } from "../../../../enums";
import { CustomButton, SpinnerScreen } from "../../../../utils";
import './verify_batch.scss';
import DocSavedText from "../../../../components/doc_saved_text/doc_saved_text";
import { AppError } from "../../../../interfaces";
interface Props {
    visible: boolean,
    title: string,
    onClose: () => void,
    onDissmiss?: () => void,
    batchNum: string | undefined;
    verifyState: {
        error?: AppError | null | undefined;
        loading: LoadingType;
        response: string | null;
    };
    onSuccessClose: () => void;
}

const VerifyBatchPopup: React.FunctionComponent<Props> = (props) => {
    const {
        visible,
        title,
        onClose,
        onDissmiss,
        onSuccessClose,
        batchNum, verifyState
    } = props;
    const { t } = useTranslation();
    // const dispatch = useAppDispatch();

    const actionRef = useRef<ActionDialogHolderType>(null);
    // const manageBatchState = useAppSelector((state) => selectManageBatchState(state));

    return (<ActionDialogHolder
        ref={actionRef}
        visible={visible}
        wrapperWidth={"40%"}
        maxWrapperWidth={"50%"}
        onClose={onClose}
        onDissmiss={onClose}
    >
        <DialogWrapper onClose={onClose}>
            <div className="verify-batch-container">
                <div className="verify-batch-header">
                    <FormDialogTilteHeader
                        title={title}
                        titleIcon={<BOVerifyBatchIcon width={"100%"} height={"100%"} />}
                    />
                </div>
                <div className="verify-batch-content">
                    {verifyState && verifyState.loading === LoadingType.pending &&
                        <SpinnerScreen />
                    }
                    {verifyState && verifyState.loading === LoadingType.succeeded &&
                        (
                            <div className="saved-doc-holder">
                                <DocSavedText>
                                    <div>
                                        <span className="aknw-name">
                                            {t("batch_verification_completed", { number: batchNum })}
                                        </span>
                                    </div>
                                </DocSavedText>
                            </div>
                        )
                    }
                    {verifyState?.error &&
                        <div className="saved-doc-holder error-holder">
                            <DocSavedText>
                                <div className="error-title">{t("Warning")}</div>
                                <div>
                                    <span className="aknw-name">
                                        {verifyState.error.message.msg ?? ""}
                                    </span>
                                    {verifyState.error.message.errors.length !== 0 &&
                                        <div className="error-table-container">
                                            <table className="error-table">
                                                <thead>
                                                    <th>{t("company_name")}</th>
                                                    <th>{t("talent_name")}</th>
                                                    <th>{t("validation_errors")}</th>

                                                </thead>
                                                <tbody>
                                                    {verifyState.error.message.errors.map((error) => {
                                                        return (
                                                            <tr>
                                                                <td>{error.company_name}</td>
                                                                <td>{error.talent_name}</td>
                                                                <td>{error.validation_errors}</td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </DocSavedText>
                        </div>
                    }
                </div>

                <div className="verify-batch-actions">
                    <div className="btn-save">
                        <CustomButton
                            loading={false}
                            textStyle={{ textTransform: "capitalize" }}
                            name={t("ok")}
                            enable={true}
                            backgroundColor={THEME.defaultHighLightColor}
                            onClick={() => { onSuccessClose(); onClose() }}
                        />
                    </div>

                </div>
            </div>
        </DialogWrapper>

    </ActionDialogHolder>);
}

export default VerifyBatchPopup;