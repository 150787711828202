export enum GlobalRoutes {
  onlineApplication = "/online-application",
  onBoardingPortal = "/onboarding",
  redirectEmployeeDashboard = "/employee/dashboard",
  kinisoJobOrders = "/job-orders-list",
  kinisoJobOrderDetailPage = "/job-order-details",
}

export enum AuthRoutes {
  loginPage = "/login",
}

export enum AppRoutes {
  /// [Dashboard]
  dashboardPage = "/dashboard",
  dashboardAvailableCandidates = "/dashboard/available-candidates",
  dashboardAvailableCandidatesUniversalDirectoryPage = "/dashboard/available-candidates/universal-directory",
  dashboardCompanyEngagementPage = "/dashboard/company-engagement",
  dashboardCompanyEngagementUniversalDirectoryPage = "/dashboard/company-engagement/universal-directory",
  dashboardTalentEngagementPage = "/dashboard/talent-engagement",
  dashboardTalentEngagementUniversalDirectoryPage = "/dashboard/talent-engagement/universal-directory",

  internalInterviewsScheduled = "/dashboard/internal-interviews-scheduled",
  internalInterviewsCompleted = "/dashboard/internal-interviews-completed",

  onlineApplicationsReceived = "/dashboard/online-applications-received",
  onlineApplicationsSent = "/dashboard/online-applications-sent",

  onboardingCandidateInputRequired = "/dashboard/onboarding-candidate-input-required",
  onboardingReadyForApprovalByRecruiter = "/dashboard/onboarding-ready-for-approval-by-recruiter",
  onboardingCompletedThisWeek = "/dashboard/onboarding-completed-this-week",

  ///[Sales & Marketing]
  salesAndMarketingPage = "/sales-marketing",
  salesAndMarketingMyCallDeck = "/sales-marketing/my-call-deck",
  salesAndMarketingCompetitorSheet = "/sales-marketing/competitor-sheet",
  salesAndMarketingPlanMySalesCalls = "/sales-marketing/plan-my-sales-calls",
  salesAndMarketingPlanMarketingCampaign = "/sales-marketing/plan-marketing-campaign",
  salesAndMarketingSalesCallActivity = "/sales-marketing/sales-call-activity",
  salesAndMarketingServiceCallActivity = "/sales-marketing/service-call-activity",
  salesAndMarketingMarketingActivity = "/sales-marketing/marketing-activity",
  salesAndMarketingMarketingDocs = "/sales-marketing/marketing-docs",
  salesAndMarketingDetailsPage = "/sales-marketing/details",

  /// [Companies]
  companiesPage = "/companies",
  companiesUniversalDirectory = "/companies/universal-directory",
  companiesDetailsPage = "/companies/details",

  /// [contacts]
  contactsPage = "/contacts",
  contactsUniversalDirectory = "/contacts/universal-directory",
  contactsDetailsPage = "/contacts/details",

  /// [Talent]
  talentPage = "/talent",
  talentUniversalDirectory = "/talent/universal-directory",
  talentDetailsPage = "/talent/details",

  /// [Jobs]
  jobsPage = "/jobs",
  jobsUniversalDirectory = "/jobs/universal-directory",
  jobDetailsPage = "/jobs/details",

  /// [Assignments]
  assignmentsPage = "/assignments",
  assignmentsUniversalDirectory = "/assignments/universal-directory",
  assignmentsDetailsPage = "/assignments/details",

  /// [Report Center]
  reportCenterPage = "/report-center",
  reportCenterDashboardPage = "/report-center/dashboard",
  reportCenterDetailsPage = "/report-center/details",
  reportCenterTalentAvailabilityReport = "/report-center/availability-report",
  reportCenterTalentAssignmentReport = "/report-center/assignment-report",
  reportCenterSCSRPage = "/report-center/sales-call-summary-report",
  reportCenterMSRPage = "/report-center/marketing-summary-report",
  reportCenterOJORPage = "/report-center/open-job-orders-report",
  reportCenterJOHRPage = "/report-center/job-orders-history-report",
  reportCenterACCRPage = "/report-center/arrival-check-calls-report",
  reportCenterEOFDCRPage = "/report-center/end-of-first-days-calls-report",
  reportCenterEOSDCRPage = "/report-center/end-of-second-days-calls-report",
  reportCenterFCRPage = "/report-center/friday-calls-report",
  reportPayCheckRegister = "/report-center/paycheck-register",
  reportCenterNewHireReportPage = "/report-center/new-hire-report",
  reportCenterInvoiceRegisterPage = "/report-center/invoice-register",
  reportCenterCashReceiptsPage = "/report-center/cash-receipts",
  reportCenterARAgingReportPage = "/report-center/ar-aging-report",
  reportCenterPayrollJournalPage = "/report-center/payroll-journal",
  reportCenterPayrollDeductionsPage = "/report-center/payroll-deductions",
  reportCenterPaymentRegisterPage = "/report-center/payment-register",
  reportCenterEmployeeAssignmentReportPage = "/report-center/employee-assignment-report",
  reportCenterPayrollCashPage = "/report-center/payroll-cash-required",
  reportCenterGrossProfitPage = "/report-center/gross-profit-report",
  reportCenterWorkCompCode = "/report-center/work-comp-code",
  reportCenterTaxByEmployee = "/report-center/tax-by-employee",
  reportCenterEmployerTax = "/report-center/employer-tax",
  reportCenterCompaniesBilled = "/report-center/companies-billed",
  reportCenterEmpHoliday = "/report-center/employee-holiday-report",
  reportCenterVacation = "/report-center/employee-vacation-report",
  terminationReport= "/report-center/termination-report",




  /// [Back Office]
  backOfficePage = "/back-office",
  weeklyAccountingPage = "/back-office/weekly-accounting",
  weeklyAccountingDashboardPage = "/back-office/weekly-accounting/dashboard",
  weeklyAccountingErrorsReportPage = "/back-office/weekly-accounting/errors-report",
  timeCardsPage = "/back-office/timecards",
  timeCardsDashboard = "/back-office/timecards/dashboard",
  timeCardsTab = "/back-office/timecards/time-cards",
  timeCardsReport = "/back-office/timecards/timecards-report",
  timeCardsErrorReport = "/back-office/timecards/errors-report",
  payrollPage = "/back-office/payroll",
  payrollDashboard = "/back-office/payroll/dashboard",
  payrollPaycheckDirectory = "/back-office/payroll/paycheck-directory",
  payrollErrorsReport = "/back-office/payroll/errors-report",
  payrollTransactionSummary = "/back-office/payroll/transaction-summary",
  billingInvoicesPage = "/back-office/billing-invoices",
  billingInvoicesDashboard = "/back-office/billing-invoices/dashboard",
  billingInvoicesReport = "/back-office/billing-invoices/report",
  billingInvoicesErrorsReport = "/back-office/billing-invoices/errors-report",
  billingInvoiceCorrection = "/back-office/billing-invoices/invoice-correction",
  boImportPage = "/back-office/import",
  boExportPage = "/back-office/export",

  // billingInvoiceImportExportDashboard = "/back-office/import-export/dashboard",
  boImportFile = "/back-office/import/import-file",
  boExportFile = "/back-office/export/export-file",
  importReportsDirectory = "/back-office/import/reports-directory",
  exportReportsDirectory = "/back-office/export/reports-directory",




  /// [Risk Management]
  riskManagementPage = "/risk-management",
  riskManagementUniversalDirectory = "/risk-management/universal-directory",
  workCompClaimDetailsPage = "/risk-management/work-com-claim/details",
  unEmplomentDetailsPage = "/risk-management/un-employment-claim/details",
  workCompComplianceReportDetailsPage = "/risk-management/work-comp-and-compliance-report/details",

  /// [Employee Portal]
  employeePortalPage = "/employee-portal",
  employeePortalUniversalDirectory = "/employee-portal/universal-directory",
  employeePortalDetailsPage = "/employee-portal/details",

  /// [Client Portal]
  clientPortalPage = "/client-portal",
  clientPortalUniversalDirectory = "/employee-portal/universal-directory",
  clientPortalDetailsPage = "/employee-portal/details",

  ///[Admin center]
  adminCenterPage = "/admin-center",
  adminCenterUserManagement = "/admin-center/user-management",
  adminCenterRoleManagement = "/admin-center/role-management",
  adminCenterSystemConfiguration = "/admin-center/system-configuration",
  adminCenterSecurityPolicy = "/admin-center/security-policy",
  adminCenterAgencySetup = "/admin-center/agency-setup",
  adminCenterTeamManagement = "/admin-center/team-management",
  adminCenterPayrollTaxConfiguration = "/admin-center/payroll-tax-configuration",
  adminCenterSalesTaxConfiguration = "/admin-center/sales-tax-configuration",
  adminCenterWorkCompConfiguration = "/admin-center/work-comp-configuration",
  adminCenterBackOffice = "/admin-center/back-office",
  adminCenterInfoText = "/admin-center/info-text",
  adminCenterIntegrations = "/admin-center/integrations-partners",
  adminCenterReportCenter = "/admin-center/report-center",

  ///[Admin center] Back Office

  adminCenterAgencyManagement = "/admin-center/agency-management",

  ///[Admin center] System configuration
  systemConfigurationSkillSets = "/admin-center/system-configuration/skill-sets",
  systemConfigurationSystemWide = "/admin-center/system-configuration/system-wide",
  systemConfigurationSalesAndMarketing = "/admin-center/system-configuration/sales-and-marketing",
  systemConfigurationContacts = "/admin-center/system-configuration/contacts",
  systemConfigurationCompanies = "/admin-center/system-configuration/companies",
  systemConfigurationTrafficLightEngagementLight = "/admin-center/system-configuration/traffic-light-engagement-metrics",
  systemConfigurationJobs = "/admin-center/system-configuration/jobs",
  systemConfigurationAssignments = "/admin-center/system-configuration/assignments",
  systemConfigurationTalent = "/admin-center/system-configuration/talent",
  systemConfigurationOnboarding = "/admin-center/system-configuration/onboarding",
  systemConfigurationOnboardingWOTCandESC = "/admin-center/system-configuration/onboarding/wotc-and-esc",
  systemConfigurationOnboardingWelcomeVideo = "/admin-center/system-configuration/onboarding/welcome-video",
  systemConfigurationOnboardingCompany = "/admin-center/system-configuration/onboarding/terms-and-conditions",
  systemConfigurationOnboardingPaperWork = "/admin-center/system-configuration/onboarding/hand-book",
  systemConfigurationOnboardingHealthCare = "/admin-center/system-configuration/onboarding/health-care",
  systemConfigurationRiskManagement = "/admin-center/system-configuration/risk-management",
  myProfileDashboardPage = "/my-profile-dashboard-page",

  ///[Admin center] Role management
  roleManagementAdministrator = "/admin-center/role-management/administrator",
  roleManagementSuperUser = "/admin-center/role-management/super-user",
  roleManagementBranchManager = "/admin-center/role-management/branch-manager",
  roleManagementAccountManager = "/admin-center/role-management/account-manager",
  roleManagementRecruiter = "/admin-center/role-management/recruiter",
  roleManagementCallDeckOwner = "/admin-center/role-management/call-deck-owner",

  apolloSearchImport = "/search-import",
  apolloCompanyDetailsPage = "/search-import/companies/details",

  deductionAuthoritiyPage = "/back-office/deduction-authority/universal-directory",
  deductionAuthoritiyDetailedPage = "/back-office/deduction-authority/details",

  accountsReceivablePage = "/back-office/accounts-receivable",
  accountsReceivableDashboardPage = "/back-office/accounts-receivable/dashboard",
  // accountsReceivableRecordPage="/back-office/accounts-receivable/record-payment"

  integrationPartnersTextEmAll = "/admin-center/integration-partners/text-em-all-config",
  integrationPartnersMasterTax = "/admin-center/integration-partners/master-tax",
  masterTaxCredentials = "/admin-center/integration-partners/master-tax/credentials",
  masterTaxCompanySetup = "/admin-center/integration-partners/master-tax/company-setup",
  masterTaxCompanyDisbursement = "/admin-center/integration-partners/master-tax/company-disbursement-bank-setup",
  masterTaxPayrollCCSetup = "/admin-center/integration-partners/master-tax/payroll-cash-care-setup",
  masterTaxCompanyTaxSetup = "/admin-center/integration-partners/master-tax/company-tax-setup",
  masterTaxCompanyExport = "/admin-center/integration-partners/master-tax/export",

    //admin-center-report-center
    acRCVacationReport = "/admin-center/report-center/vacation-report",

}
