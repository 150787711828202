import { RouteComponentProps } from "react-router-dom"
import SortableTable, { TableData } from "../../../../../components/sortable_table/sortable_table";
import TableEmpty, { TableErrorHandler, TableLoading } from "../../../../../components/table_empty/table_empty";
import DatePicker from "react-datepicker";
import { CalenderIcon, DownloadIcon } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import TabOptionsHeader from "../../../../../components/tab_options_header/tab_options_header";
import SearchBar from "../../../../../components/search_bar";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../../../../routes";
import { LoadingType, THEME } from "../../../../../enums";
import { convertDateToTimeStamp, getDateFromTimeStamp, invoiceDateType } from "../../../../../variables";
import { getCompaniesBilledList, getReportDownloadURL, getReportStartEndDate, selectCompaniesBilledListState, selectReportsDownloadState, selectReportStartEndDateState, useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { currencyConversion, CustomButton } from "../../../../../utils";
import { IRCCompaniesBilled, IRCCompaniesBilledInvoice } from "../../../../../interfaces/report_center_companies";
import CustomFormSelect, { CustomMultiFormSelect } from "../../../../../components/form_selector/form_select";
import './companies_billed.scss'
import { reportDownloadSliceActions } from "../../../../../redux/report_center/download_report/download_report_reducer";
import UrlBuilder from "../../../../../apis/url_builder";

interface Props extends RouteComponentProps<any> { }

const tableHeader = [
    { title: "customer", code: "customer" },
    { title: "department", code: "department" },
    { title: "total_gps", code: "gross_margin_amount" },
    { title: "gp_percentage", code: "gross_profit_percentage" },
    { title: "gp_per_hour", code: "gross_margin_per_hour" },
    { title: "amount_billed", code: "amount_billed" },
    { title: "sales_tax", code: "sales_tax" },
    { title: "total_billed", code: "total_billed" },
    { title: "total_hours_billed", code: "total_hours_billed" },
    { title: "avg_br_per_hour", code: "avg_bill_rate_per_hour" },
    { title: "percentage_of_gp", code: "percentage_of_gross_profit" },
    { title: "percentage_of_sales", code: "percentage_of_sales" },
];

const CompaniesBilled: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const companiesBilledListState = useAppSelector((state) => selectCompaniesBilledListState(state));
    const reportStartEndDate = useAppSelector((state) => selectReportStartEndDateState(state));
    const reportDownloadUrlState = useAppSelector((state) => selectReportsDownloadState(state));

    const [companiesBilledList, setCompaniesBilledList] = useState<IRCCompaniesBilled | null>(null);
    const [sortedField, setSortedField] = useState<string | null>("id");
    const [sortDirection, setSortDirection] = useState("asc");
    const [search, setSearch] = useState("");

    const [formState, setFormState] = useState<{ available_from: number, available_to: number }>()
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [isInvoiceDate, setIsInvoiceDate] = useState<boolean>(false);
    const [companiesList, setCompaniesList] = useState<{ label: string, value: string }[]>([])
    const [companies, setCompanies] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getReportStartEndDate({}));
    }, []);

    useEffect(() => {
        if (reportStartEndDate.loading === LoadingType.succeeded && reportStartEndDate.response.length !== 0) {
            const startDate = reportStartEndDate.response[0];
            const EndDate = reportStartEndDate.response[1];
            setFormState({ available_from: startDate, available_to: EndDate });
            setDateRange([getDateFromTimeStamp(startDate), getDateFromTimeStamp(EndDate)]);
        }
    }, [reportStartEndDate.loading, reportStartEndDate.response]);

    useEffect(() => {
        if ((formState?.available_from !== 0 && formState?.available_to !== 0)
            || ((formState?.available_from !== 0 && formState?.available_to !== 0) || (formState?.available_from === 0 && formState?.available_to === 0) && isInvoiceDate)) {
            getInvoiceRegister();
            setDatePickerOpen(false)
        }
        else if ((formState?.available_from === 0 && formState?.available_to === 0)) {
            getInvoiceRegister();
            setDatePickerOpen(true);
        }

    }, [formState?.available_from, formState?.available_to, isInvoiceDate]);

    useEffect(() => {
        if (companiesBilledListState && companiesBilledListState?.loading === LoadingType.succeeded && companiesBilledListState.response) {
            setCompaniesBilledList(companiesBilledListState?.response);
            const updatedCompaniesList = companiesBilledListState.response?.invoices?.reduce<{ label: string; value: string }[]>((acc, invoice) => {
                if (!acc.some(item => item.value === invoice.company_id)) {
                    acc.push({ label: invoice.customer, value: invoice.company_id });
                }
                return acc;
            }, []);
            setCompaniesList(updatedCompaniesList);
        }
    }, [companiesBilledListState?.loading, companiesBilledListState.response]);

    useEffect(() => {
        if (reportDownloadUrlState.loading === LoadingType.succeeded && reportDownloadUrlState.response?.url &&
            reportDownloadUrlState.response?.url) {
            let aTag = document.createElement('a')
            aTag.setAttribute('href', reportDownloadUrlState.response?.url);
            aTag.setAttribute('target', '_blank');
            aTag.click();
            dispatch(reportDownloadSliceActions.clearDownloadState());
        }
    }, [reportDownloadUrlState.loading]);

    const getInvoiceRegister = () => {
        if (formState) {
            dispatch(getCompaniesBilledList({ start_date: formState.available_from, end_date: formState.available_to, is_invoice_date: isInvoiceDate }))
        }
    }

    function handleChange(value) {
        setFormState({
            ...formState, available_from: value[0] !== null ? convertDateToTimeStamp(value[0], true) : 0,
            available_to: value[1] !== null ? convertDateToTimeStamp(new Date(value[1]), true) : 0,
        });
        setDateRange(value);
    }

    const handleSortFieldChange = (value: string) => {
        if (sortedField && sortedField === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortedField(value);
            setSortDirection("asc");
        }
    };

    function handleCompanySelect(value: IRCCompaniesBilledInvoice) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}`,
            state: {
                id: value.company_id,
                name: value.customer,
            },
        });
        return;
    }
    function handleDepartmentSelect(value: IRCCompaniesBilledInvoice) {
        props.history.push({
            pathname: `${AppRoutes.companiesDetailsPage}/${value.company_id}/dashboard/${value.department_id}`,
            state: {
                id: value.company_id,
                name: value.customer,
                departmentId: value.department_id,
                departmentName: value.department,
            },
        });
        return;
    }

    function handleDownload() {
        if (formState) {
            dispatch(getReportDownloadURL(
                {
                    baseUrl: UrlBuilder.companiesBilled,
                    start_date: formState.available_from,
                    end_date: formState.available_to,
                    companies: companies,
                    download: true
                }));
        }
    }
    
    function getFlexNumber(value: number) {
        if (value === 0) return 1;
        if (value === 1) return 1;
        if (value === 2) return 1;
        if (value === 3) return 1;
        if (value === 4) return 1;
        if (value === 5) return 1;
        if (value === 6) return 1;
        if (value === 7) return 1;
        if (value === 8) return 1;
        if (value === 9) return 1;
        if (value === 10) return 1;
        if (value === 11) return 1;
        return 1;
    }

    const showSpinner = () => companiesBilledListState?.loading === LoadingType.pending;

    const getFilteredList = () => {
        let list: IRCCompaniesBilledInvoice[] | undefined = companiesBilledList?.invoices;
        let resultList: IRCCompaniesBilledInvoice[] | undefined;

        if (companies.length !== 0) {
            resultList = list?.filter(doc => {
                return companies.includes(doc.company_id);
            });
        }

        if (list && sortedField != null) {
            list = [...list].sort((a, b) => {
                const valueA =
                    a[sortedField] != null && a[sortedField] !== undefined
                        ? typeof a[sortedField] == typeof "1"
                            ? a[sortedField].trim().toLowerCase()
                            : a[sortedField]
                        : "";
                const valueB =
                    b[sortedField] != null
                        ? typeof b[sortedField] == typeof "1"
                            ? b[sortedField].trim().toLowerCase()
                            : b[sortedField]
                        : "";
                if (sortDirection === "asc") {
                    return valueA > valueB ? 1 : -1;
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            });
        }

        return (resultList ?? list)?.filter(doc => {
            const str = search.trim().toLowerCase();
            return Object.values(doc).some(val =>
                val && val.toString().trim().toLowerCase().includes(str)
            );
        });
    }

    const handleFormattedPercentage = (field: string) => {
        switch (field) {
            case "percentage_of_gross_profit": {
                const totalPercentage = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0
                    ? getFilteredList()?.reduce(function (prev: any, current: any) {
                        return prev + +current.percentage_of_gross_profit;
                    }, 0)
                    : 0);
                const finalPercentage = totalPercentage >= 100 ? (100).toFixed(2) : totalPercentage.toFixed(2);
                return finalPercentage ? finalPercentage + "%" : 0;
            }
            case "percentage_of_sales": {
                const totalPercentage = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0
                    ? getFilteredList()?.reduce(function (prev: any, current: any) {
                        return prev + +current.percentage_of_sales;
                    }, 0)
                    : 0);
                const finalPercentage = totalPercentage >= 100 ? (100).toFixed(2) : totalPercentage.toFixed(2);
                return finalPercentage ? finalPercentage + "%" : 0;
            }
            case "gross_profit_percentage": {
                const totalGPPercentage = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.gross_margin_amount
                }, 0) : 0)
                const totalBilled = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.total_billed
                }, 0) : 0)
                return (totalGPPercentage && totalBilled) ? ((totalGPPercentage / totalBilled)  * 100).toFixed(2) + "%" : 0;
                return totalGPPercentage ? ((totalGPPercentage / totalBilled) * 100).toFixed(2) + "%" : "";
            }
            case "gross_margin_per_hour": {
                const totalGrossMarginPerHour = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.gross_margin_amount
                }, 0) : 0)
                const totalHoursBilled = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.total_hours_billed
                }, 0) : 0)
                return (totalGrossMarginPerHour && totalHoursBilled) ? currencyConversion((totalGrossMarginPerHour / totalHoursBilled), 2): 0;
            }
            case"avg_bill_rate_per_hour": {
                const totalBilled = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.total_billed
                }, 0) : 0)
                const totalHoursBilled = (companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                    return prev + +current.total_hours_billed
                }, 0) : 0)
                return (totalBilled && totalHoursBilled) ? currencyConversion((totalBilled / totalHoursBilled), 2): 0;
            }
        }
    }

    const getTable = () => {
        if (showSpinner()) return <TableLoading />;

        if (companiesBilledListState?.error != null && companiesBilledList?.invoices?.length === 0) {
            return <TableErrorHandler error={companiesBilledListState?.error} onRefresh={getInvoiceRegister} />;
        }

        if (companiesBilledList?.invoices?.length === 0 || getFilteredList()?.length === 0) {
            return <TableEmpty title={"No Data found"} onClick={getInvoiceRegister} />;
        }

        if (getFilteredList()?.length !== 0) {
            return (
                <SortableTable
                    headerList={tableHeader}
                    sortedField={sortedField}
                    onSortChange={handleSortFieldChange}
                    flexNumber={getFlexNumber}
                    isAsc={sortDirection}
                >
                    {getFilteredList()?.map((doc) => {
                        return (
                            <tr key={doc.company_id + doc.department_id}>
                                <TableData customStyle={{ flex: getFlexNumber(0) }}
                                    isButton={true}
                                    onClick={() => handleCompanySelect(doc)}
                                >
                                    <span>{doc.customer}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(1) }}
                                    isButton={true}
                                    onClick={() => handleDepartmentSelect(doc)}
                                >
                                    <span>{doc.department}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(2) }}>
                                    <span>{doc.gross_margin_amount !== null ? currencyConversion(doc.gross_margin_amount) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(3) }}>
                                    <span>{doc.gross_profit_percentage !== null ? `${doc.gross_profit_percentage}` + `${"%"}` : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(4) }}>
                                    <span>{doc.gross_margin_per_hour !== null ? currencyConversion(doc.gross_margin_per_hour) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(5) }}>
                                    <span>{doc.amount_billed ? currencyConversion(doc.amount_billed) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(6) }}>
                                    <span>{doc.sales_tax !== null ? currencyConversion(doc.sales_tax) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(7) }}>
                                    <span>{doc.total_billed !== null ? currencyConversion(doc.total_billed) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(8) }}>
                                    <span>{doc.total_hours_billed}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(9) }}>
                                    <span>{doc.total_billed !==null && doc.total_hours_billed !==null ? currencyConversion((doc.total_billed / doc.total_hours_billed), 2) : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(10) }}>
                                    <span>{doc.percentage_of_gross_profit !== null ? `${doc.percentage_of_gross_profit}` + `${"%"}` : 0}</span>
                                </TableData>
                                <TableData customStyle={{ flex: getFlexNumber(11) }}>
                                    <span>{doc.percentage_of_sales !== null ? `${doc.percentage_of_sales}` + `${"%"}` : 0}</span>
                                </TableData>
                            </tr>
                        )
                    })}
                </SortableTable>
            );
        }
    };

    return (
        <div className="companies-biiled-container">
            <div>
                <TabOptionsHeader onClose={() => props.history.goBack()} />
            </div>
            <div className="companies-biiled-header">
                <div className="ir-h-left">
                    <div className="dateRange">
                        <label>{t("date_range")}</label>
                        <div className="date-input-icon">
                            <div className="calendar-icon-div">
                                <CalenderIcon width={'100%'} height={'100%'} className="calendar-icon" onClick={() => setDatePickerOpen(true)} />
                            </div>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleChange}
                                isClearable={true}
                                placeholderText={'Start Date -  End Date'}
                                dateFormat={'MM.dd.yyyy'}
                                onClickOutside={() => setDatePickerOpen(false)}
                                open={isDatePickerOpen}
                                onFocus={() => setDatePickerOpen(true)}
                            />
                        </div>
                    </div>
                    <div className="fs-btn">
                        <CustomFormSelect
                            name={t("date_type")}
                            list={invoiceDateType.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={setIsInvoiceDate}
                            value={isInvoiceDate}
                            placeholder={t('select')}
                            required={false}
                            label={t('date_type')}
                        />
                    </div>
                    <div className="select-type">
                        <CustomMultiFormSelect
                            label={t("customer")}
                            name={"company_ids"}
                            value={companies}
                            placeholder={"All"}
                            list={companiesList?.map((doc) => ({
                                label: doc.label,
                                value: doc.value,
                            }))}
                            onChange={(value) => {
                                setCompanies(value);
                            }}
                        />
                    </div>
                </div>
                <div className="ir-h-right">
                    <div className="total-count-action">
                        <span>
                            {`${t("total_count")}: `}
                            <span className="total-count-number">
                                {getFilteredList()?.length}
                            </span>
                        </span>
                    </div>
                    <div className="download-btn">
                        <CustomButton
                            leftIcon={<DownloadIcon width={"1vw"} height={"1vw"} style={{ color: "#fff" }} />}
                            loading={false}
                            name={t("download")}
                            enable={getFilteredList()?.length !== 0 && true}
                            backgroundColor={THEME.buttonColor16}
                            onClick={() => handleDownload()}
                        />
                    </div>
                    <div>
                        <SearchBar
                            value={search}
                            onChange={setSearch}
                            onSearch={() => { }}
                        />
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="header-container">
                    <div className="table-header">
                        <div className="table-header-title">
                            <span>{t("companies_billed")}</span>
                        </div>
                    </div>
                </div>
                {getTable()}
            </div>
            {companiesBilledListState.loading === LoadingType.succeeded && <div>
                <div className="total-counts-card">
                    <div className="total-title">
                        Totals
                    </div>
                    <div className="total-count-main">
                        <div className="total-count">
                            {currencyConversion(companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.gross_margin_amount
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {handleFormattedPercentage("gross_profit_percentage")}
                        </div>
                        <div className="total-count">
                            {handleFormattedPercentage("gross_margin_per_hour")}
                        </div>
                        <div className="total-count">
                            {currencyConversion(companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.amount_billed
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.sales_tax
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {currencyConversion(companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.total_billed
                            }, 0) : 0)}
                        </div>
                        <div className="total-count">
                            {(companiesBilledList?.invoices && companiesBilledList?.invoices.length > 0 ? getFilteredList()?.reduce(function (prev: any, current: any) {
                                return prev + +current.total_hours_billed
                            }, 0) : 0).toFixed(2)}
                        </div>
                        <div className="total-count">
                            {handleFormattedPercentage("avg_bill_rate_per_hour")}
                        </div>
                        <div className="total-count">
                            {handleFormattedPercentage("percentage_of_gross_profit")}
                        </div>
                        <div className="total-count">
                            {handleFormattedPercentage("percentage_of_sales")}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default CompaniesBilled;